.#{$gik-prefix}modify-claimed-event-confirmation {
  @screen md {
    &.modals.modal-v2:not(.dialog) {
      @apply tw-max-w-sm;
    }
  }

  .content {
    textarea {
      @apply tw-min-h-24;
      resize: vertical !important; /* TODO: remove MVC override*/
    }
  }
}
