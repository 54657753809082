$blockName: '#{$gik-prefix}tip-modal';

.ReactModal__Content.modals.modal-v2.#{$blockName} {
  min-height: 401px;
  height: auto;
  width: 327px;
  user-select: none;
}

.#{$blockName} {
  .content {
    @apply tw-flex tw-flex-col;
    overflow-y: hidden !important;
  }

  &__title {
    @apply tw-font-heading tw-text-neutral-900 tw-font-semibold tw-text-2xl tw-leading-8 tw-text-center #{!important};
    @apply tw-mt-8 tw-mb-3 tw-py-0 #{!important};
  }

  &__content {
    @apply tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-between;
    @apply tw-font-body tw-text-neutral-900 tw-font-normal tw-leading-6;
    @apply tw-pt-0 tw-pb-6 tw-text-center;
  }

  &__description {
    @apply tw-m-0;
  }

  &__values {
    @apply tw-flex tw-items-center;
  }

  &__amount {
    @apply tw-text-2xl tw-leading-7 tw-font-bold tw-text-neutral-900;
  }

  &__percentage {
    @apply tw-text-lg tw-leading-5 tw-text-neutral-600 tw-font-light;
  }

  &__icon-container {
    width: 3.75rem;
    height: 3.75rem;

  }

  &__slider-container {
    @apply tw-w-full tw-px-2;
  }

  &__slider-labels {
    @apply tw-flex tw-justify-between tw-w-full;
    @apply tw-text-sm tw-leading-4 tw-text-neutral-600;
  }

  .footer.button-footer {
    .gik-button {
      @apply tw-w-32;
    }
    @screen xs-down {
      .gik-button {
        @apply tw-w-24;
      }
    }
  }

  &__slider--customValue {

      .rc-slider-track {
        @apply tw-bg-neutral-600;
      }

  }
}
