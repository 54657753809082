+b(product-preview-skeleton)
  .gik-frame-bone
    --width: 400px
    --height: 400px

+b(product-preview)
  @apply tw-flex-none tw-w-full tw-cursor-pointer
  width: calc(100vw - 4rem)

  &__image--tc
    @apply tw-rounded

  @screen md
    width: $productPageWidth
    &__image
      min-width: $productPageWidth

  .gik-carousel
    .slick-list
      @apply tw-rounded

    img
      @apply tw-rounded

  &__product-image:focus
    outline: 0

  .gik-keep-proportions
    @apply tw-w-full tw-h-full

  .gik-keep-proportions__wrapper
    @apply tw-h-full
    > *
      @apply tw-bg-cover tw-bg-center

  @screen md-down
    @apply tw-flex tw-flex-col tw-flex-grow tw-flex-shrink
    // width: unset

+b(modal)
  +b(product-preview)
    @screen md
      width: $productModalWidth
      &__image
        min-width: $productModalWidth
