br {
  border-color: transparent;
  &.xs {
    display: block;
    content: '';
    border-bottom-width: 0.5rem;
  }
  &.sm {
    display: block;
    content: '';
    border-bottom-width: 0.75rem;
  }
  &.base {
    display: block;
    content: '';
    border-bottom-width: 1rem;
  }
  &.lg {
    display: block;
    content: '';
    border-bottom-width: 1.5rem;
  }
  &.xl {
    display: block;
    content: '';
    border-bottom-width: 2rem;
  }
}

@-moz-document url-prefix() {
  br {
    &.xs {
      margin-bottom: 0.5rem;
    }
    &.sm {
      margin-bottom: 0.75rem;
    }
    &.base {
      margin-bottom: 1rem;
    }
    &.lg {
      margin-bottom: 1.5rem;
    }
    &.xl {
      margin-bottom: 2rem;
    }
  }
}
