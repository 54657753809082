+b(new-page-welcome-modal)
  @apply tw-h-full

  @screen md
    max-height: 500px !important

  .content
    @apply tw-py-8 #{!important}

  @screen md
    .content
      @apply tw-p-0 #{!important}

  // modal footer
  .gik-modal__toolbar
    @apply tw-flex tw-justify-between tw-items-center
  .gik-button:last-child
    @apply tw-ml-auto


+b(new-page-welcome)
  @apply tw-flex tw-flex-col tw-items-center tw-h-full

  @screen md
    @apply tw-flex-row-reverse

  +e(text)
    > div
      @apply tw-my-0 tw-font-heading tw-text-3xl tw-font-semibold tw-leading-none tw-text-neutral-900
    > p
      @apply tw-mb-0 tw-mt-3 tw-font-body tw-text-base tw-font-normal tw-leading-tight tw-text-neutral-900
      max-width: 210px

  @screen md
    +e(text)
      @apply tw--ml-3
      flex-basis: 335px

  +e(image)
    width: 100%
    max-width: 340px
    height: 340px
    background-image: url(#{$public-root + 'inkind-page/img/new-page-welcome/welcome-image-mobile.svg'})
    background-repeat: no-repeat
    background-size: cover
    background-position: center

  @screen md
    +e(image)
      background-image: url(#{$public-root + 'inkind-page/img/new-page-welcome/welcome-image-desktop.svg'})
      height: 370px
      flex-basis: 287px
