+b(error-boundary-fallback)
  @apply tw-cursor-pointer tw-overflow-auto tw-text-center tw-border tw-border-danger-500 tw-overflow-hidden
  max-height: var(--max-height)

  &__scrollbars
    @apply tw-h-full
  &__error
    @apply tw-text-danger-500 tw-m-4 tw-h-full tw-flex-1

  &__dialog-error
    @apply tw-text-neutral-900 tw-bg-lgray-200 tw-p-4
