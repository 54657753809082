$blockName: '#{$gik-prefix}textarea';

.#{$blockName} {
  // ---------- Elements

  &__wrapper {
    @apply tw-relative tw-rounded-sm tw-flex;
  }

  &__textarea {
    // border
    @apply tw-border tw-border-neutral-500 tw-rounded-sm tw-outline-none;
    // other
    @apply tw-w-full tw-px-2 tw-py-1;

    color: theme('colors.neutral.900');
    &:disabled {
      @apply tw-bg-transparent tw-cursor-not-allowed;
    }

    &::placeholder {
      @extend .gik-placeholder;
    }
  }

  &__max-length {
    @extend .gik-text-muted;
    @apply tw-absolute tw-bg-white tw-opacity-0;
    transition: opacity 300ms ease-out;
    right: 18px;
    bottom: -8px;
    text-align: right;
    font-size: 0.8em;
    padding: 0px 4px;
  }

  &--max-length-visible &__max-length {
    @apply tw-opacity-100;
  }

  // maxlength error should turn the maxLength indicator to red
  &__max-length--warning,
  &__max-length--error {
    @apply tw-text-danger-500;
  }

  // ---------- Modifiers

  &--default-solid &__textarea {
    @apply tw-bg-neutral-100 tw-border-neutral-100;
  }
  &--default-solid &__wrapper {
    @apply tw-border-neutral-100;
  }
  &--default-solid &__max-length {
    @apply tw-bg-white tw-rounded-full tw-py-0 tw-shadow-sm tw-font-normal tw-font-body;
    font-size: 13px;
  }

  &--has-autogrow &__textarea {
    @apply tw-overflow-y-hidden;
  }

  &--noresize &__textarea {
    @apply tw-resize-none;
  }

  // the max length indicator can be closer to the right edge if the resize icon is not visible
  &--noresize &__max-length {
    right: 5px;
  }

  // the textarea should have a danger border if it has an error
  &--has-error &__textarea {
    @apply tw-border-danger-500;
  }

  // the textarea should have a warning border if it has a warning
  &--has-warning &__textarea {
    @apply tw-border-warn-500;
  }

  // the textarea should have a warning border if it has a warning
  &--has-success &__success {
    @apply tw-border-success-500;
  }

  // the max-length indicator should have red text when displayed as a warning or an error
  &--has-error &__max-length,
  &--has-warning &__max-length {
    @apply tw-text-danger-500;
  }

  // ----- States

  &--focus {
    //@apply tw-ring-2 tw-ring-info-500 tw-ring-offset-0 tw-ring-offset-info-500;
    //--tw-ring-inset: /*!*/ /*!*/;
    box-shadow: rgb(95, 147, 243) 0 0 0 0, rgb(95, 147, 243) 0 0 0 2px, rgba(0, 0, 0, 0) 0 0 0 0;
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }

  &--pill {
    border-radius: 100rem;
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs;
  }

  &--size-sm {
    @apply tw-text-sm;
  }

  &--size-base {
    @apply tw-text-base;
  }

  &--size-lg {
    @apply tw-text-lg;
  }

  &--size-xl {
    @apply tw-text-xl;
  }
}
