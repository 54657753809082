+b(hero-computer)
  overflow: hidden

  +e(container)
    @apply tw-flex tw-flex-col tw-items-center tw-mt-16
    margin-right: 240px

  +e(image)
    width: 1142px

  @screen md-down
    +e(container)
      margin-right: 75px

    +e(image)
      width: 150%
