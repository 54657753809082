
// override margin: -2px from PageFeatureCard which hides the border
// both classes on same tag
.gik-wishlist-card-with-amazon.gik-page-feature-card--disabled
  .gik-page-feature-card__secondary-content
    @apply tw-m-0

+b(wishlist-card-with-amazon)

  .gik-page-feature-card__secondary-content
    @apply tw-p-2 tw-pt-0

  // override enabled main card secondary content bg-color
  &:not(.gik-page-feature-card--disabled)
    .gik-page-feature-card__secondary-content
      @apply tw-bg-white tw-transition-all tw-ease-in-out tw-duration-200

  +e(footer)
    @apply tw-p-2 tw-bg-white
    +m(main-disabled)
      @apply tw-shadow-xl
      border-radius: 0.5rem
    +m(amazon-disabled)
      @apply tw-rounded-full
      box-shadow: none

  @screen md
    +e(footer)
      @apply tw-p-4 tw-mx-auto
      max-width: 100%
      +m(main-disabled)
        @apply tw-rounded-md
      +m(amazon-disabled)
        @apply tw-rounded-full tw-py-2
        max-width: 297px

    .gik-page-feature-card__secondary-content
      @apply tw-p-3 tw-pt-0

  +e(checkbox-container)
    @apply tw-text-center

  +e(checkbox)
    @apply tw-font-body tw-text-neutral-900 tw-font-bold tw-text-left tw-mr-2
    // decrease letter-spacing so icon doesn't collapse into new line on mobile
    @apply tw-tracking-tighter

  @screen md
    +e(checkbox)
      @apply tw-tracking-normal

  +e(icon)
    @apply tw-text-neutral-500
    &:hover
      @apply tw-cursor-pointer

  +e(popover-content)
    @apply tw-text-center tw-leading-tight
    max-width: 16rem
    > p
      @apply tw-my-0

  +e(learn-more)
    @apply tw-text-sm
    &:hover
      @apply tw-no-underline #{!important}

  +e(accordion)
    // override default padding-y
    .gik-accordion-item__main > div
      @apply tw-p-0 tw-pt-4 tw-text-center

  +e(input validation-error)
    @apply tw-max-w-sm tw-mx-auto

  +e(help-link)
    // accordion adds underline to links
    @apply tw-no-underline #{!important}
    @apply tw-text-sm
