+b(embedded-video)
  @apply tw-rounded tw-shadow-lg tw-max-w-full tw-min-w-full tw-max-h-full tw-min-h-full

  +e(wrapper)
    @apply tw-absolute

  +e(lightbox-overlay)
    @apply tw-absolute tw-cursor-pointer
    left: 0
    right: 0
    top: 0
    bottom: 0

  +m(with-lightbox-overlay)
    pointer-events: none
