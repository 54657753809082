+b(inkind-uses-grid)
  +b(article-tile)
    @apply tw-mx-3

  @screen md-down
    @apply tw--mx-2
    .slick-list
      @apply tw-mx-6
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 4%, rgba(0, 0, 0, 1) 96%, rgba(255, 255, 255, 0) 100%)
      @screen sm
        mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 3%, rgba(0, 0, 0, 1) 97%, rgba(255, 255, 255, 0) 100%)

+b(inkind-uses-grid-skeleton)
  @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start tw-gap-5
