+b(menu-bar)
  @apply tw-flex

  +e(item)
    @apply tw-flex tw-flex-col tw-justify-center

  +e(icon)
    @apply tw-text-xs tw-text-neutral-600
    +m(prepend)
      @apply tw-mr-2

    +m(append)
      @apply tw-ml-2

  > * + *
      @apply tw-ml-4

  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-tight tw-text-neutral-900

  +e(item-title)
    @apply tw-cursor-pointer
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-tight tw-text-neutral-900 tw-text-center tw-flex tw-h-full
    align-items: center
    display: flex
    height: 100%
    > svg
      @apply tw-flex-shrink-0

  .gik-button--default-plain,
  .gik-button--default-link
    --color: theme('colors.neutral.900')
    --color-hover: theme('colors.black')
    text-transform: none
