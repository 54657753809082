body {
  line-height: 1.5;
  font-size: 16px;
  font-weight: 400;

  // allow break word by default helps with responsiveness
  word-break: break-word;
  word-wrap: normal;

  &.gik-has-overlay {
    overflow: hidden;
  }

  // Increase font size for prism theme
  pre[class*='language-'],
  code[class*='language-'] {
    font-size: 16px;
  }
}

html,
body {
  //height: 100%; // breaks safari on iPhone 6,7,... - just use flex properly!
}

#__next {
  @apply tw-flex tw-flex-col tw-flex-1 tw-h-full;
}

// Do not apply a max-width for phone size
@screen md-down {
  .container {
    max-width: none !important;
  }
}

// global styling on p tags.
// this should be consistent accros all apps
p {
  @apply tw-my-2;
}

p.#{$tw-prefix}lead {
  @apply tw-text-xl tw-mx-auto;
}

a {
  @apply tw-cursor-pointer;
  font-weight: 400;
}

code {
  @apply tw-bg-primary-200 tw-break-words;
  padding: 0.15em 0.1em 0.1em;
}

pre code {
  @apply tw-p-0 tw-bg-transparent;
}

.aria-hidden {
  @apply tw-hidden;
}
