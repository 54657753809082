+b(oauth-error-modal-content)
  +e(oauth-error-message)
    @apply tw-flex tw-flex-col tw-items-center tw-justify-start tw-mx-auto
    max-width: 330px
    > header
      @apply tw-flex tw-items-center tw-justify-center tw-mb-4
      svg
        @apply tw-w-16
      span
        @apply tw-font-body tw-text-2xl tw-font-bold tw-ml-2 tw-text-gray-50
    > p
      @apply tw-font-body tw-text-base tw-mb-8 tw-text-gray-600 tw-text-center

    @screen md
      max-width: 286px
      text-align: left
