$blockName: #{$gik-prefix}geosuggest;

.#{$blockName} {
  position: relative;

  &__suggests--hidden {
    box-shadow: none !important;
    max-height: 0;
    overflow: hidden;
    border-width: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  &__suggests {
    text-align: flex-start;
    border: 1px solid #eff2f2;
    box-shadow: 0 2px 6px 0 rgba(70, 101, 108, 0.28), 0 7px 33px 0 rgba(70, 101, 108, 0.26);
    @apply tw-font-ui tw-text-base tw-text-neutral-900 tw-bg-white;

    list-style: none;
    padding: 1em;
    margin: 0;

    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  &--variant-default-solid &__input {
    @apply tw-bg-neutral-100 tw-rounded-sm;
  }
  &--variant-default-solid .geosuggest__input-wrapper {
    @apply tw-border-0;
  }

  &__suggest-item {
    @apply tw-cursor-pointer;
    &:hover {
      @apply tw-bg-neutral-50;
    }
  }

  .geosuggest__input-wrapper {
    width: 100%;
  }

  .geosuggest__suggests-wrapper {
    position: absolute;
    top: 34px;
    width: 100%;
    z-index: 1;
  }
}
