+b(by-recipient-section)
  @apply tw-text-center tw-pb-0 tw-pt-4


  &__show-all
    @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none

  .gik-recipients-grid,
  .gik-recipients-grid-skeleton
    @apply tw-mx-auto
    max-width: 360px
    @screen sm
      max-width: 730px
