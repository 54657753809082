@import './components/Login/LoginForm'
@import './components/Login/LoginFormSection'
@import './components/Login/OAuthErrorModalContent'
@import './components/ExternalLogin/ExternalLoginForm'

@import './components/ForgotPassword/ForgotPasswordForm'
@import './components/ForgotPassword/ForgotPasswordSection'
@import './components/ResetPassword/ResetPasswordForm'
@import './components/ResetPassword/ResetPasswordSection'
@import './components/EmailConfirmation/EmailConfirmationSection'

@import './components/Signup/SignupForm'
@import './components/Signup/SignupSection'
@import './components/SignInFlow/SignInFlow'

@import './utils/FacebookOAuth'

+b(button)
  &.social-login
    @apply tw-border-none

  &.facebook-login
    --bg: #2d4587
    --bg-hover: #2d4587

  &.google-login
    --bg: #018af4
    --bg-hover: #018af4

+.modals.#{prefix(modal)}.#{prefix(signup-modal)}
  @screen md
    @apply tw-w-full
    max-width: 800px
