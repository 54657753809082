.#{$gik-prefix}user-avatar-menu {
  &__avatar-wrapper {
    @apply tw-flex tw-px-2 tw-items-center; /* give more hover space */

    > a {
      @apply tw-flex;
      @apply tw-flex-shrink-0 tw-flex-grow-0;
      > * {
        @apply tw-flex-shrink-0 tw-flex-grow-0;
      }
    }
  }

  > li {
    @apply tw-flex;
    > * {
      @apply tw-flex-shrink-0 tw-flex-grow-0;
    }
  }

  .gik-page-rows-section {
    @apply tw-mt-4;
  }
}
