+b(about-step)
  @apply tw-text-center

  & > *
    +b(form-group)
      +e(label-wrapper)
        @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +b(situation-tile-alt)
    +e(label)
      @apply tw-text-xl tw-leading-tight
      +lineClamp(2, 1.25)

  +e(terms-disclaimer)
    @apply tw-leading-5

  @screen md
    +b(form-group--email)
      +b(form-group__content-wrapper)
        @apply tw-mx-auto
        max-width: 70%

    +b(about-step__terms-disclaimer)
      @apply tw-mx-auto
      max-width: 70%

    +b(select-list)
      @apply tw-grid-cols-3

    +b(situation-tile-alt)
      @apply tw-block tw-text-center

      +e(image)
        @apply tw-mx-auto tw-mt-0 tw-mb-4
        height: 96px

    +b(situation-tile-alt--horizontal)
      img
        @apply tw-mx-auto tw-mt-0 tw-mb-4
        height: 96px
