+b(taxonomy-list)

  @apply tw-flex tw-flex-wrap tw-justify-center
  gap: .25rem
  column-gap: .5rem
  @screen sm
    gap: .5rem
    column-gap: 1rem

  +b(image)
    height: 24px
    width: 24px
    @apply tw-mr-2

  &__item
    @apply tw-border-2 tw-border-transparent
  &__item--selected
    @apply tw-border-2 tw-border-primary-500 tw-bg-transparent
