$blockName: '#{$gik-prefix}how-it-works-sample-pages-section';

.#{$blockName} {
  @apply tw-text-center tw-pb-0;

  &__title {
    @apply tw-block tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-mb-16;
  }

  .#{$gik-prefix}sample-pages-grid {
    @apply tw-mx-auto tw-mb-8;
  }

  &__see-more {
    @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none;
  }
}
