.#{$gik-prefix}app-header-dropdown {
  &__nav {
    > li {
      @apply tw-flex;
      > a {
        @apply tw-py-2 tw-px-3 tw-pr-6 tw-w-full tw-flex tw-items-center;
        @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900;

        min-height: 50px;

        &:hover {
          @apply tw-bg-neutral-200;
        }
        .gik-avatar {
          @apply tw-mr-2 tw-flex-shrink-0;
          width: 35px;
          height: 35px;
        }

        > span {
          @apply tw-whitespace-nowrap tw-overflow-hidden tw-block tw-py-2;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
