+b(privacy-settings-card)
  @apply tw-block
  max-width: 530px

  +e(card)
    @apply tw-flex tw-items-center
    @apply tw-bg-white tw-w-full
    min-height: 4.8rem
    border: 1px solid theme('colors.neutral.200')
    border-radius: 0.62rem
    padding: 0.625rem 0.5rem 0.623rem 1rem

  +e(icon)
    @apply tw-w-8 tw-h-8 tw-flex-shrink-0

  +e(text)
    @apply tw-ml-3 tw-mr-2 tw-text-left tw-flex tw-flex-col tw-justify-center

  +e(title)
    @apply tw-font-heading tw-text-xl tw-font-semibold tw-text-neutral-900 tw-leading-none tw-mb-1

  +e(description note)
    @apply tw-font-body tw-m-0

  +e(description)
    @apply tw-text-base

  +e(note)
    @apply tw-text-sm

  +e(note)
    @apply tw-leading-none

  +e(description)
    @apply tw-leading-tight tw-text-neutral-600

  +e(padlock)
    @apply tw-mr-2 tw-text-secondary-500
  +e(checkbox)
    @apply tw-ml-auto tw-self-start

  +e(note)
    @apply tw-mt-1 tw-text-left

