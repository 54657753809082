+b(attachments-preview)
  $blockName: &

  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-gap-8
  align-content: stretch

+b(attachment-preview)
  $blockName: &

  @apply tw-relative

  +e(image)
    & > *
      @apply tw-rounded tw-w-full

  +e(remove-button-wrapper)
    @apply tw-absolute tw-right-0
    top: -14px
