+b(group-crm-layout)
  @apply tw-flex tw-flex-1 tw-flex-row
  // min-height: 855px

  > .gik-container
    @apply tw-flex tw-flex-1

  +b(supporters-page-content)
    @apply tw-flex tw-flex-col tw-flex-1
    +b(table)
      @apply tw-flex-1

  &__layout
    @apply tw-w-full
    @screen lg
      @apply tw-flex

  +e(aside-return)
    @apply tw-text-primary-600 tw-text-xl tw-font-semibold tw-px-2 tw-pb-2 tw-py-2
    @apply tw-flex tw-items-center tw-font-heading tw-pr-2 tw-mb-4 tw-cursor-pointer
    margin-top: 2px
    > svg
      @apply tw-mr-2
    @screen md
      @apply tw-px-3

  +e(main)
    @apply tw-flex tw-flex-row tw-flex-1 tw-p-2 tw-pt-4 tw-w-full tw-flex-1
    @screen lg
      @apply tw-px-8 tw-bg-white tw-rounded-md

  .gik-svg-icon--UserGroupOutline
    @apply tw-text-info-500

  +e(aside)
    @apply tw-px-2 tw-py-2 tw-mb-0
    @screen md
      @apply tw-px-0
    @screen lg
      @apply tw-px-2
      @apply tw-mb-4 tw-px-0

    +b(select)
      @screen lg
        @apply tw-hidden



  +b(group-crm-navigation)
    @apply tw-hidden
    @screen lg
      @apply tw-block

  +e(mobile-header)
      @apply tw-text-2xl tw-font-semibold tw-px-3 tw-flex tw-items-center
      @apply tw-font-heading tw-text-neutral-900

      svg
        @apply tw-mr-3

      @screen lg
        @apply tw-hidden

  @screen lg
    @apply tw-p-4

    > .gik-container
      @apply tw-flex
    +e(aside)
      @apply tw-mr-4 tw-mb-0



// NOTE: these must be declared globally so the icons inside the
// dropdown of the select component are also styled
.gik-svg-icon--group-info
  @apply tw-text-primary-500

.gik-svg-icon--group-users
  @apply tw-text-secondary-500
