+b(color-swatch)
  --size: 28px
  width: var(--size)
  height: var(--size)
  @apply tw-rounded-sm tw-border-transparent tw-border-2

  background: var(--color)

  &--circle
    border-radius: 50%
    width: var(--size)
    height: var(--size)

  &--size-lg
    --size: 2rem

  &--size-xl
    --size: 3rem

  // --- predefined colors for ThankYou messages
  &--white
    @apply tw-bg-white tw-border-neutral-500

  &--aqua
    @apply tw-bg-primary-200
  &--grey
    @apply tw-bg-neutral-200
  &--red
    @apply tw-bg-danger-200
  &--orange
    @apply tw-bg-secondary-200
  &--green
    @apply tw-bg-success-200
  &--blue
    @apply tw-bg-info-200
  &--purple
    @apply tw-bg-purple-200
  &--yellow
    @apply tw-bg-warn-200
  &--pink
    @apply tw-bg-pink-700

  &--aqua-gradient
    background:  linear-gradient(0deg, #0EB5DA 0%, #41CBCE 33.3%, #41CBCE 64.48%, #03D0C4 100%)
  &--grey-gradient
    background: linear-gradient(0deg, #86909A 0%, #9B9EA9 34.25%, #9B9EA9 66.27%, #B8C0CE 100%)
  &--red-gradient
    background: linear-gradient(181.77deg, #FF5E49 -0.01%, #EF5C84 32.17%, #EF5C84 64.51%, #D53F7E 97.01%)
  &--orange-gradient
    background: linear-gradient(0.05deg, #E66924 0.08%, #F5893D 31.67%, #F5893D 68.62%, #FFA800 100%)
  &--green-gradient
    background: linear-gradient(180deg, #B1D682 0%, #8FBB6A 33.33%, #8FBB6A 68.23%, #67AC4F 100%)
  &--blue-gradient
    background: linear-gradient(0deg, #4E7AEA 0%, #5F93F3 30.98%, #5F93F3 68.27%, #3ABDF5 100%)
  &--purple-gradient
    background: linear-gradient(359.56deg, #694EB5 0%, #956CC3 33.44%, #956CC3 65.97%, #C880DE 99.24%)
  &--yellow-gradient
    background: linear-gradient(0deg, #EAA51F 0%, #FFC700 45.31%, #FFFA85 100%)
  &--pink-gradient
    background: linear-gradient(181.77deg, #FF5E49 -0.01%, #EF5C84 32.17%, #EF5C84 64.51%, #D53F7E 97.01%)

  &--active
    @apply tw-border-info-500
