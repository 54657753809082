+b(related-products)

  &__slick-button
    $margin-align: 3px

    &--prev svg
      margin-left: -$margin-align


    &--next svg
      margin-left: $margin-align



  .slick-arrow
    @apply tw--mt-8

    > div
      @apply tw-flex tw-items-center tw-justify-center tw-h-full


    @apply tw-w-10 tw-h-10

    &::before
      display: none



  .slick-next
    @apply tw-mr-2


  .slick-prev
    @apply tw-ml-2


  @screen md
    .gik-carousel__slider--has-arrows
      @apply tw-px-12


    .slick-next
      @apply tw-mr-0

    .slick-prev
      @apply tw-ml-0

.#{$gik-prefix}carousel-product-tile
  @apply tw-px-2 tw-text-left
  @screen md
    min-height: 338px

    > *
       @apply tw-w-full

  .gik-product-tile
    > main
      @include lineClamp(2)
      // set maximum height of the text to 2 lines
      height: 3.5em

  @screen md
    @apply tw-px-3

+b(related-products)
  .slick-slide
    max-width: 280px

.#{$gik-prefix}related-products-skeleton
  @apply tw-flex tw-text-left tw-gap-8

  // > *
    // @apply tw-flex-shrink-0

  > *:nth-child(2),
  > *:nth-child(3)
    @apply tw-hidden

  .gik-paragraph-bone
    max-height: 3rem
    overflow: hidden

  &__item
    @apply tw-border tw-border-neutral-400 tw-rounded tw-bg-white

    .gik-frame-bone
      @apply tw-bg-neutral-200

    > main
      @apply tw-p-4

  &__image
    @apply tw-mx-auto tw-rounded
    --width: 100%
    --height: 200px

  @screen md
    > *:nth-child(2)
      @apply tw-block

  @screen lg
    > *:nth-child(3)
      @apply tw-block
