+b(calendar-announcement-form)
  .gik-form-group__label
    @apply tw-text-neutral-700 tw-text-xs tw-font-bold

  +e(title)
    @apply tw-text-2xl tw-font-bold tw-ml-2

  +b(calendar-announcement)
    @apply tw-mb-2

  +b(calendar-announcement-select-list)
    @apply tw-mb-4

  +e(section1)
    @apply tw-flex tw-justify-between tw-items-end

  .gik-form-group--sendEmailToPageFollowers
    @apply tw-bg-neutral-50 tw-p-2 tw-mt-4 tw-text-center tw-rounded
