@mixin type($typeName)
  +m($typeName)
    @apply tw-bg-#{ $typeName }-100

+b(features-aside)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  +e(care-calendar)
    @apply tw-bg-success-100

  +e(wishlist)
    @apply tw-bg-primary-100

  +e(donations)
    +gradient-light-sage()

  +e(feature-with-thumbnail)
    @apply tw-flex-1
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch

    +b(video)
      object-fit: cover
      @screen md-down
        @apply tw-w-full

    @screen md-down
      @apply tw-p-5 tw-justify-start tw-min-h-0

    +type(primary)
    +type(danger)
    +type(secondary)

    +m(success)
      +gradient-light-sage()


  +e(animation)
    position: relative
    @apply tw-flex-1
    margin-top: -1.5rem
    margin-bottom: -1.5rem
    margin-left: -1rem
    margin-right: -1rem
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch
    flex: 1

    @screen md-down
      @apply tw--m-5 tw-min-h-0

  +e(overlay)
    @apply tw-absolute
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-cursor-pointer
    left: 0
    right: 0
    top: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 40%)
    svg
      color: white

  +e(features-indicator-wrapper)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  // TODO: find a way to combine modifiers in +m like that
  &--substep-0.gik-features-aside--substep-0-covered,
  &--substep-1.gik-features-aside--substep-1-covered,
  &--substep-2.gik-features-aside--substep-2-covered
    @screen md-down
      @apply tw-min-h-0
      +e(animation feature-with-thumbnail)
          @apply tw-min-h-0
