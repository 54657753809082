+b(group-image-picker)
  @apply tw-rounded tw-relative tw-bg-neutral-600 tw-p-6
  @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
  height: 135px
  width: 289px

  +e(remove-button)
    @apply tw-absolute tw-bottom-1 tw-left-1

  +e(image-preview)
    @apply tw-flex tw-justify-center
    img, svg
      max-height: 87px
      width: auto
      // max-width: calc(100% - 0.5rem)

  +e(placeholder)
    @apply tw-absolute tw-opacity-50
    max-height: 100px
    max-width: 222px

  +e(add-button)
    @apply tw-z-above

  +m(square)
    width: 135px
