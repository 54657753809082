$blockName: #{$gik-prefix}inkind-page-wishlist-header;

.#{$blockName} {
  @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full;
  @apply tw-min-h-10 tw-mb-4;

  & > * {
    @apply tw-mr-2;
  }
}
