.backdrop-v2 {
  // this needs to be a sass var instead of a css var
  // since "&.backdrop-lift-children + *" is outside of the ".backdrop-v2" scope
  // and thus the css var is then not defined
  $z-index: theme('zIndex.modalBackdrop');
  --animation-speed: 0.7s;

  background-color: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: visibility var(--animation-speed), opacity var(--animation-speed) linear;

  &.backdrop-v2--visible {
    visibility: visible;
    opacity: 1;
    z-index: $z-index;

    &:not(.backdrop-lift-children) + * {
      z-index: $z-index - 1;
    }

    &.backdrop-lift-children + * {
      z-index: $z-index;
    }
  }

  &:not(.backdrop-v2--visible) {
    visibility: hidden;
    opacity: 0;
  }
}

.backdrop-v2__body--visible {
  overflow-y: hidden;
  position: relative;
}
