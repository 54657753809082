+b(inkind-page-card-base)
  @apply tw-bg-white tw-rounded-md tw-shadow-lg
  @apply tw-text-left tw-cursor-pointer tw-w-full
  min-width: 140px

  &--admin
    @apply tw-border-secondary-500 tw-border-3

  +b(keep-proportions)
    flex-shrink: 0

  &__image
    @apply tw-h-full
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    border-top-left-radius: .8rem
    border-top-right-radius: .8rem

  &__content
    @apply tw-px-4 tw-py-3

  &__title
    @apply tw-font-semibold tw-font-heading tw-text-xl
    +lineClamp(2)
    height: 3.5rem

  &__location
    @apply tw-text-sm tw-text-neutral-600 tw-text-right tw-uppercase tw-mt-1

  &--row
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch

  &--row
    +b(keep-proportions)
      @apply tw-w-28
      height: unset
      min-height: 7rem

  &--row &__title
    height: unset

  &--row &__image
    border-top-left-radius: .8rem
    border-bottom-left-radius: .8rem
    border-top-right-radius: 0

  &--row &__content
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch

  &--row &__location
    @apply tw-text-left


