+b(calendar-claim-event)
  +e(giftcard-view)
    @apply tw-flex tw-items-center tw-mx-auto tw-text-left tw-justify-center
    +b(image)
      max-width: 150px
    > main
      @apply tw-ml-4 tw-mb-5
  +e(giftcard-product-name)
    @apply tw-text-sm tw-text-neutral-700 tw-mt-1 tw-block tw-text-center

  +e(event-claim)
    @apply tw-flex

    +b(avatar)
      @apply tw-flex-shrink-0

    > main
      @apply tw-pt-1 tw-flex-1

  +e(event-claim--bottom-border)
    @apply tw-border-b tw-border-neutral-300 tw-pb-2 tw-mb-2

  +e(username)
    @apply tw-text-xl tw-font-bold tw-text-neutral-900 tw-leading-3 tw-mt-2 tw-block tw-mb-2
  +e(providing)
    @apply tw-text-sm tw-font-bold tw-text-neutral-600 tw-leading-3
  +e(provided-by)
    @apply tw-text-sm tw-font-bold tw-text-neutral-600 tw-leading-3 tw-block
  +e(provided-by-name)
    @apply tw-text-xl tw-font-bold

  +e(claim-type-title)
    @apply tw-font-bold tw-mb-0 tw-block

  +e(claim-description-title)
    @apply tw-font-bold tw-mb-0 tw-inline-block

  +e(claim-description)
    @apply tw-m-0 tw-whitespace-pre-wrap

  +e(notes)
    @apply tw-rounded tw-bg-warn-50 tw-p-1 tw-px-2 tw-mt-4

  +e(user-content)
    @apply tw-flex
  +e(user-content)
    .gik-calendar-claim-event__claim-description
      @apply tw-text-right tw-flex-1 tw-pl-4
    .gik-calendar-claim-event__providing
      @apply tw-block

  +e(notes-title)
    @apply tw-uppercase tw-font-bold tw-block tw-text-warn-800 tw-text-sm
  +e(notes-description)
    @apply tw-my-0 tw-whitespace-pre-wrap

  +e(buttons)
    @apply tw-justify-between tw-flex tw-flex-1
    > section + section
      @apply tw-ml-2

  +e(image-container)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-mb-4

  +e(resend)
    margin-top: -15px

  +b(avatar)
    @apply tw-mr-2

  +b(line-clamp__more-button)
    @apply tw-text-right


