+b(interstitial)
  &__portal
    @apply tw-fixed tw-w-full tw-flex tw-h-full tw-left-0 tw-top-0 tw-justify-center tw-bg-primary-500 tw-z-interstitial
    opacity: 0
    top:-100%
    overflow-y: scroll

  &__content
    @apply tw-relative tw-mt-4 tw-max-w-full tw-flex tw-flex-col tw-items-center tw-mx-auto
    @screen sm
      @apply tw-p-8 tw-max-w-xl tw-px-2 tw-mt-0 tw-p-4

    @screen lg
      @apply tw-mt-16

  &__close
    @apply tw-absolute tw-top-0 tw-right-0 tw-mt-0 tw-mr-4 tw-cursor-pointer
    @screen sm
      @apply tw-mt-0 tw-mr-0

  &__body-hook
    overflow-y: hidden // disable body scrolling behaviour - only scroll in interstitial
    position: relative // fix iOS/safari overflow-y not working

