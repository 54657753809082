@import 'RecipientInfoStep/RecipientInfoAddressForm'
@import './PageFeatureCard/PageFeatureCard'
@import './DonationsCard/DonationsCard'
@import './DonationsContainer/DonationsContainer'
@import './WishlistCard/WishlistCard'
@import './GiftCardSuggestion/GiftCardSuggestion'
@import './GiftCardSkeleton/GiftCardSkeleton'
@import './CreateWrapper/CreateWrapper'
@import './InKindPageBackground/InKindPageBackground'
@import './CreatePage/CreatePage'
@import './CreatePageNavigation/CreatePageNavigation'
@import './AboutStep/AboutStep'
@import './FeatureStep/FeatureStep'
@import './CareCalendarStep/CareCalendarStep'
@import './WishlistStep/WishlistStep'
@import './FeaturesAside/FeaturesAside'
@import './FeaturesAside/CareCalendarAside'
@import './FeaturesAside/WishlistAside'
@import './FeaturesAside/DonationsAside'
@import './DonationsStep/DonationsStep'
@import './SituationStep'
@import './PageStep'
@import './PrivacyStep/PrivacyStep'
@import './RecipientInfoStep/RecipientInfoStep'
@import './DonationProvider/DonationProvider'
@import './CreatePageSplitLayout/CreatePageSplitLayout'
@import './FinishStep/FinishStep'

@mixin create-section-title()
  @apply tw-font-semibold tw-text-xl tw-leading-6 tw-mb-6 tw-font-body tw-text-center tw-text-neutral-900 tw-mt-8

+b(form-group)
  +b(create-section-title)
      @apply tw-block
      +create-section-title()

+b(create-section-title)
  +create-section-title()

