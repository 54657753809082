
+b(google-pay-button)
  $blockName: &

  @apply tw-relative

  +e(disabled-overlay)
    @apply tw-absolute tw-inset-0 tw-hidden tw-cursor-not-allowed

  +m(disabled)
    #{$blockName}__disabled-overlay
      @apply tw-block tw-bg-neutral-100 tw-opacity-50

  .google-pay-button-container
    @apply tw-w-full
