+b(products-grid)
  &--emphasis
    *:first-child
      grid-column-start: 1
      grid-column-end: 3
      grid-row-start: 1
      grid-row-end: 3

+b(products-grid-skeleton)
  &__item
    @apply tw-border tw-border-neutral-400 tw-rounded tw-bg-white

    .gik-frame-bone
      @apply tw-bg-neutral-200

    > main
      @apply tw-p-4

  &__image
    @apply tw-mx-auto
    --width: 100%
    --height: 200px
