.#{$gik-prefix}payment-confirmation {
  &__shipping-title {
    @apply tw-block tw-text-2xl tw-font-bold tw-mb-2 tw-mt-4;
  }

  &__section-title {
    @apply tw-block tw-font-body tw-font-bold tw-break-words tw-text-3xl tw-mt-2 tw-mb-3 tw-leading-10;
  }

  &__section-text-body {
    @apply tw-mb-8;
  }

  &__header {
    @apply tw-mb-4;
  }

  .gik-circle {
    @apply tw-mt-8;
  }

  &__collapse,
  .gik-tablelist {
    @apply tw-text-left;
  }

  &__icon {
    width: 110px;
    height: 110px;
    & > div, svg {
      width: 110px !important;
      height: 110px !important;
    }
  }
}
