$image-height: 90px
$image-container-height: 70px
$border-length: 5px
$margin-length: 1em
$card-width: 260px
$card-height: 243px

+b(user-page-card)
  min-width: $card-width
  width: $card-width
  height: $card-height
  margin-top: calc($image-height - $image-container-height - $border-length + $margin-length)
  margin-left: 0

  background-color: white
  border-radius: $border-radius

  > a
    @apply tw-flex tw-flex-col tw-justify-start tw-text-center tw-h-full

  &__premium-star
    @apply tw-text-warn-500

  &__hover-wrapper
    margin-right: $margin-length
    margin-bottom: $margin-length

    .gik-hover-animation__shadow
      box-shadow: $box-shadow
      border-radius: $border-radius
      border: 1px solid transparent
      top: 31px

  &__image-container
    height: $image-container-height
    margin-top: -30px
    margin-bottom: 32px
    display: flex
    justify-content: center

  &__confirm-dialog
    width: 320px
    height: 220px

  &__page-image
    border-radius: 50%
    background-position: 50%
    background-repeat: no-repeat
    background-size: cover
    height: $image-height
    width: $image-height

  &__content
    flex: 1
    display: flex
    flex-direction: column
    align-content: center
    justify-content: flex-start
    text-align: center
    align-items: center
    padding-left: 16px
    padding-right: 16px

  &__title
    @apply tw-font-heading tw-text-xl tw-text-neutral-800 tw-font-medium tw-leading-6
    max-width: 100%
    margin-bottom: 6px
    position: inherit

    & > *
      margin: 0
      text-overflow: ellipsis
      overflow: hidden

  &__location
    @apply tw-font-body tw-text-base tw-text-neutral-600

  &__buttons
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-around
    padding: 8px
    border-top: 2px dotted #e2eded
    color: #99babb
    fill: #99babb

  &__button-wrapper
    width: 28px
    height: 28px

    .gik-icon-v2
      font-size: 21px

  &--admin
    border: $border-length solid #ff9e5a

  &--admin &__image-container
      margin-top: -34px


  &--admin &__buttons
      padding-bottom: 4px


  @screen md-down
    width: unset

    &__hover-wrapper
      flex: 1
