+b(calendar-announcement-view)
  @apply tw-text-center
  +e(icon)
    @apply tw-block tw-justify-center tw-mx-auto
    width: 48px
    height: 48px
    color: var(--color)
  +e(title)
    @apply tw-text-xl tw-font-bold tw-block
    color: var(--color)
  +b(date-time-display)
    color: var(--color-alt)
    @apply tw--mt-1
  +e(description)
    @apply tw-text-left tw-mt-2

  +m(information)
    --bg: theme('colors.info.50')
    --color: theme('colors.info.500')
    --color-alt: theme('colors.info.800')
  +m(love)
    --bg: theme('colors.danger.50')
    --color: theme('colors.danger.500')
    --color-alt: theme('colors.danger.800')
  +m(warn)
    --bg: theme('colors.secondary.50')
    --color: theme('colors.secondary.500')
    --color-alt: theme('colors.secondary.800')


  // wider buttons for the announcement view modal footer
// +b(calendar-view-announcement)
//   +b(button)
//     @apply tw-px-24
