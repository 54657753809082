+b(by-gift-type-section)
  @apply tw-text-center tw-pb-0 tw-pt-4 tw-px-0

  &__show-all
    @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none

  .gik-gift-type-grid,
  .gik-gift-type-grid-skeleton
    @apply tw-mx-auto tw-mb-8

