+b(homepage-hero-section)
  +e(header)
    @apply tw-pt-8 tw-pb-0
    @screen md
      @apply tw-pt-16

  +b(hero-card)
    +e(aside)
      @screen md-down
        margin: 0 -290px 0 -310px
