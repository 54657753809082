+b(app-update-notification)
  &__title
    @apply tw-font-bold
  &__content
    @apply tw-my-0 tw-text-sm
    a
      @apply tw-underline tw-font-bold
  &__icon
    @apply tw-text-warn-500 tw-mr-1
    svg
      width: 24px
      height: 24px
