+b(premium-page-upgrade-modal)

  &.modals.modal-v2 // need use a selector with greater specificity to override the default value without using !important
    +gradient-warm()

  +m(free-premium-page-upgrades)
    &.modals.modal-v2
      background: linear-gradient(256.49deg, #406D05 0%, #55832E 33.5%, #55832E 68.23%, #225311 100%)

  +b(popover__reference-element)
    @apply tw-w-full

  +b(modal-header)
    @apply tw-z-max
    background: transparent
    +e(close-button)
      @apply tw-text-white

+b(premium-page-upgrade-content)
  +e(content)
    @apply tw-flex tw-items-end tw-flex-col
    @screen md
      @apply tw-flex-row tw-justify-start tw-items-start tw-content-start

  +e(main)
    @apply tw-px-0 tw-pb-4 tw-w-full tw-z-above tw-px-4

    @screen md
      @apply tw-px-0 tw-pr-4


  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-white tw-my-0

  +e(subtitle)
    @apply tw-font-bold tw-text-sm tw-leading-tight tw-text-white tw-pt-1

  +e(value-container)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start tw-flex-shrink-0 tw-mt-4 tw-mb-5

  +e(currency)
    @apply tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-text-white tw-not-italic

  +e(value-integer)
    @apply tw-font-heading tw-font-semibold tw-text-6xl tw-leading-none tw-text-white tw-not-italic

  +e(value-decimals)
    @apply tw-font-heading tw-font-semibold tw-text-xl tw-leading-none tw-text-white tw-not-italic
    @apply tw-mt-2
    border-bottom: 2px solid #fff
    padding-bottom: 2px

  +e(per-unit)
    @apply tw-font-normal tw-text-sm tw-leading-none tw-text-secondary-50
    @apply tw-mt-3 tw-italic

  +e(decimals-unit-container)
    @apply tw-flex tw-flex-col tw-justify-between tw-items-start tw-content-start

  +e(body)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-start tw-content-center tw-max-w-full tw-mb-2
    @screen md
      @apply tw-flex-row tw-justify-start tw-items-center

  +e(description)
    @screen md
    @apply tw-mt-2

  +e(description-item)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-white tw-flex tw-items-start tw-justify-start tw-mb-1


  +e(item-icon)
    @apply tw-mx-2 tw-overflow-visible
    margin-top: -4px

  +e(image)
    @apply tw-flex-shrink-0 tw-relative tw-z-10
    margin-top: -40px
    img
      @screen md-down
        @apply tw-w-full
      @screen md
        @apply tw-h-full
    @screen md-down
      @apply tw-w-full
    @screen md
      @apply tw-bottom-0


  +e(foliage)
    @apply tw-absolute tw-inset-y-0 tw-left-0 tw-items-end tw-pointer-events-none
    @screen md-down
      @apply tw-w-full
      > svg
        @apply tw-w-full
        margin-top: -72px
    @screen md
      @apply tw-flex

    +m(free-premium-page-upgrades)
      svg
        path
          fill: #8FBB6A !important

.modals.modal-v2.gik-premium-page-upgrade-modal
  +b(modal-header)
    height: 40px
  > .gik-modal__content-wrapper
    overflow-y: visible
    > .content
      @apply tw-p-0

      @screen md
        margin-top: 0
  +b(modal-button-footer)
    @apply tw-block
    background: none
    @screen md
      @apply tw-hidden
  // +b(modal__content-wrapper)
  //   > .content
  //     @apply tw-flex
