+b(give-page)
  &__lead
    @apply tw-pt-8

  +b(by-situation-section)
    @apply tw-mb-16

  +b(by-recipient-section)
    @apply tw-mt-16 tw-mb-16
  +b(by-gift-type-section)
    @apply tw-mb-10

