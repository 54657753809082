+b(featured-inkinds-section)
  @apply tw-text-center tw-bg-neutral-50 tw-pb-16 tw-pt-12

  &__title
    @apply tw-font-semibold tw-font-heading tw-text-4xl tw-leading-9 tw-mb-4
  &__nav
    @apply tw-justify-center tw-my-4
  &__search
    @apply tw-mb-8 tw-mt-2
  &__search-btn
    padding-left: 7px
    --color: theme('colors.neutral.800')
    @apply tw-normal-case tw-font-body tw-font-normal tw-text-neutral-800

  +b(featured-inkinds)
    max-width: 1051px
