$productPageWidth: 400px;
$productModalWidth: 320px;

.#{$gik-prefix}order-form {
  &__item {
    @apply tw-mt-2;
    .productType-wrapper {
      @apply tw-mb-4;
    }

    > header {
      @apply tw-mb-6;
      > figure {
        @apply tw-flex tw-items-start tw-justify-center tw-w-64 tw-min-h-48;
      }
      > main {
        @apply tw-my-4 tw-flex-1 tw-min-w-32;
        .gik-form-group--product-price {
          @apply tw-mb-4;
        }
      }

      @screen md {
        @apply tw-flex;
        > main {
          @apply tw-ml-4 tw-my-0;
        }
      }
    }

    .gik-sticky {
      @apply tw-pointer-events-none;
    }
  }

  &__anonymous {
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full;
    & > :last-child {
      @apply tw-ml-1;
    }
  }

  &__item &__anonymous-override {
    @apply tw-rounded tw-bg-neutral-100 tw-p-4 tw-mb-2;
  }

  &__title {
    @apply tw-block tw-font-semibold tw-text-3xl tw-font-heading;
  }

  &__subtitle {
    @apply tw-block tw-font-bold tw-mb-4 tw-text-neutral-600 tw-text-sm;
  }

  &__note,
  &__terms,
  &__disclaimer,
  &__tango-card-disclaimer {
    color: theme('colors.lgray.other');
  }

  &__note > *:first-child:before {
    content: 'Note: ';
  }

  &__disclaimer > *:first-child:before {
    content: 'Disclaimer: ';
  }

  &__terms > *:first-child:before {
    content: 'Terms & Conditions: ';
  }

  &__preview-fade {
    background: linear-gradient(#ffffff, #ffffff00);
    height: 30px;
    @apply tw-pointer-events-none;
  }

  &__preview-wrapper {
    @apply tw-bg-white tw-pointer-events-auto;

    @screen md {
      width: $productModalWidth;
    }

    max-width: 100%;
    .gik-product-preview {
      margin: 0 auto;
      cursor: auto;
      @screen md {
        width: 320px;

        .gik-product-preview__image {
          width: 320px;
        }
        .gik-perfectgift-faceplate-preview {
          zoom: 0.8;
        }
      }
    }
  }

  &__preview-note {
    @apply tw-text-sm tw-text-neutral-600 tw-mt-4;
  }

  &__section-title {
    @apply tw-font-bold tw-text-base tw-mt-2 tw-text-neutral-700;
  }
}
