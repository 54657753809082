.gik-login-form
  @apply tw-text-left

  .gik-login-form__btnSubmit
    @apply tw-w-full tw-text-xl

  .gik-login-form__register
    @apply tw-text-center

  .gik-form-error
    @apply tw-uppercase tw-font-semibold tw-text-sm
  .gik-form-group__error
    @apply tw-uppercase tw-font-semibold tw-text-sm

  &__links
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full
    .gik-button span
      @apply tw-text-sm

  @screen md
    .gik-modal-button-footer .gik-button
      @apply tw-h-8

  +e(password-error)
    text-transform: initial !important
    font-weight: initial !important
    text-align: left !important
    @apply tw-m-0
    span
      // make it look like a link, also not bold
      @apply tw-cursor-pointer tw-underline tw-font-normal
