+b(event-services-tile)
  @apply tw-text-left tw-bg-white tw-shadow-xs tw-rounded tw-px-1 tw-py-2 tw-border-3 tw-border-transparent tw-select-none tw-relative tw-flex-shrink-0

  &--value-176927
    .gik-event-services-tile__label
      @apply tw-ml-1

  // &__image-wrapper
    // width: 50px

  +e(image)
    height: 50px
    @apply tw-max-w-none

  +m(vertical)
   @apply tw-flex-col tw-items-center tw-justify-center

  +m(vertical, image)
      @apply tw-mx-auto tw-mb-2

  +b(checkbox)
    @apply tw-absolute tw-pointer-events-none
    top: 10px
    right: 10px

  +e(label)
    @apply tw-font-semibold tw-leading-4 tw-break-normal

  +m(horizontal)
    @apply tw-flex tw-text-left tw-items-center tw-flex-row

  +m(horizontal, image)
    @apply tw-mx-0 tw-mr-2 tw-mb-0 tw-ml-1

  +m(compact)
    @apply tw-flex

    .gik-event-services-tile__label
      @apply tw-font-semibold tw-leading-4 tw-hidden

      @screen xs-down
        @apply tw-block

      @screen md
        @apply tw-block

  &.gik-event-services-tile--selected
    @apply tw-border-primary-500 tw-border-3 tw-flex-1

    .gik-event-services-tile__label
      @apply tw-block
