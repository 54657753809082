.#{$gik-prefix}inkind-picker {
  .gik-popover-list-item {
    @apply tw-px-4 tw-flex-shrink-0;
  }

  &__title {
    @apply tw-text-neutral-900 tw-font-semibold tw-text-lg tw-mb-3 tw-px-4;
  }

  &__search {
    @apply tw-mx-4;
  }

  &__footer {
    @apply tw-text-center tw-py-2;
  }

  > footer {
    @apply tw-py-1 tw-px-4;
  }

  &__external-link {
    @apply tw-text-primary-600 tw-font-bold tw-text-sm tw-mb-1;
  }

  &__cannot-find-page {
    @apply tw-bg-neutral-50;
    @apply tw-text-neutral-700 tw-text-sm tw-leading-4 tw-italic;
  }

  .#{$gik-prefix}inkind-list-item {
    max-width: calc(100% - 19px);
  }

  .gik-search-input {
    @apply tw-mb-2;
  }
}

.#{$gik-prefix}inkind-picker-popover {
  > .#{$gik-prefix}popover__popper-content {
    // full width with min and max constraints
    @apply tw-w-full tw-max-w-xs tw-min-w-20;
    max-height: 22rem;
  }
}
