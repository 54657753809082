// TODO: move toolbar styling out of product tile and into ProductTileToolbar
.#{$gik-prefix}product-tile {
  --width: theme('width.56');
  --height: theme('width.56');
  --row-size: theme('height.24');

  @apply tw-rounded tw-inline-block tw-box-border tw-relative tw-bg-white tw-cursor-pointer tw-overflow-hidden tw-shadow-xs tw-text-black;
  transition: box-shadow 300ms ease-in-out, background-color 300ms ease-in-out;

  &:hover {
    background: rgba(59, 65, 66, 0.03);

    @screen md {
      background-color: theme('colors.white');
      opacity: 1;
      @apply tw-shadow-2xl;
    }
    @apply tw-text-primary-600 #{!important};
  }

  &__box-shadow {
    @apply tw-absolute tw-inset-0 tw-rounded tw-z-above;
    pointer-events: none;
  }

  &:active &__box-shadow {
    $inset-shadow: inset 0px 0px 8px 1px rgba(59, 65, 66, 0.2), 0px 0px 0px 1px rgba(59, 65, 66, 0.15);
    @apply tw-text-primary-700;
    box-shadow: $inset-shadow;
  }

  @screen md {
    @apply tw-shadow-xs;
  }

  &--skeleton.#{$gik-prefix}product-tile--row {
    .gik-frame-bone {
      --width: 112px;
      --height: 112px;
      width: var(--width);
      height: var(--height);
    }
  }

  figure {
    @apply tw-overflow-hidden tw-flex tw-items-center tw-justify-center;
  }

  &__link {
    @apply tw-flex tw-w-full tw-h-full;
  }

  > main {
    @apply tw-p-4 tw-inline-block tw-pb-3;
    > div {
      @apply tw-mt-0;
    }
  }

  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-xl tw-leading-tight tw-text-left tw-mb-0;
    @include lineClamp(3, 1.6);
  }

  &__description {
    @apply tw-text-primary-600 tw-text-base tw-leading-normal;
    & > p {
      @apply tw-my-2;
      & > * {
        @apply tw-m-0 #{!important};
      }
    }
  }

  &__close {
    @apply tw-absolute tw-top-0 tw-right-0 tw-mt-1 tw-mr-1 tw-text-danger-500 tw-cursor-pointer;
  }

  .#{$gik-prefix}bg-image {
    background-size: cover;
    background-position: center;
  }

  &--col {
    --width: 100%;
    --height: auto;
    .#{$gik-prefix}product-tile__title {
      min-height: 75px
    }
    width: 100%;
    height: 100%;
    .#{$gik-prefix}bg-image {
      @apply tw-rounded-t;
      // full-width image with squared aspect ratio
      width: 100%;
      padding-bottom: 100%;
    }

    figure {
      @apply tw-rounded-t;
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      > img {
        position: absolute;
        top: 0;

        height: 100%;
        max-width: unset;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    > main {
      @apply tw-mb-2;
    }
  }

  &--col.#{$gik-prefix}product-tile--contain-image {
    figure {
      > img {
        @apply tw-m-4;
        right: 1rem;
        width: calc(100% - 2rem);
        @apply tw-rounded;
        top: 50%;
        left: 0;
        bottom: 1rem;
        transform: translate3D(0, calc(-50% - 1rem), 0);
        height: auto;
      }
    }
  }

  &--col &__toolbar {
    @apply tw-absolute tw-z-10 tw-top-0 tw-left-0 tw-w-full tw-p-2 tw-text-white;
    transform: translate3d(0, -100%, 0);

    transition: transform 300ms ease-in-out;

    background: linear-gradient(-180deg, rgba(0, 0, 0, 0.38), rgba(0, 0, 0, 0.09) 70%, transparent);
  }

  &--col.#{$gik-prefix}product-tile--hover &__toolbar,
  &--col:hover &__toolbar {
    transform: translate3d(0, 0, 0);
  }

  &__image-wrapper {
    width: var(--width);
    height: var(--height);
    @apply tw-flex tw-items-center tw-justify-center tw-text-center tw-bg-neutral-50;
  }

  .gik-image--fallback {
    @apply tw-py-4;
    width: var(--width);
  }

  &--row {
    @apply tw-inline-flex tw-items-center tw-w-full;

    .#{$gik-prefix}bg-image {
      @apply tw-rounded-l tw-flex-shrink-0;
      width: calc(var(--row-size) + 0.5rem);
      height: var(--row-size);
    }

    figure {
      @apply tw-rounded-l tw-p-1;
      flex-shrink: 1;
      height: var(--row-size);
      & > img {
        @apply tw-w-full tw-rounded-sm;
      }
    }
    img {
      max-height: var(--row-size);
      max-width: var(--row-size);
    }
    > main {
      @apply tw-py-0;
    }

    .gik-product-tile__image-wrapper {
      @apply tw-inline-flex;
      width: var(--row-size);
      height: auto;
      > * {
        width: var(--row-size) !important;
        height: var(--row-size) !important;
      }
    }

    &.gik-product-tile--description .gik-product-tile__image-wrapper {
      &,
      & img {
        @apply tw-rounded;
      }

      figure img {
        border-radius: 0;
      }
    }
  }

  &--row &__toolbar {
    @apply tw-absolute tw-top-0 tw-right-0;
  }

  &__toolbar {
    @apply tw-flex tw-rounded;
    > * {
      @apply tw-flex-1 tw-flex tw-text-center;
      > * {
        @apply tw-flex-1;
      }
    }

    svg {
      transition: color 300ms ease-in-out;
    }
  }

  &--description {
    @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start;
    border: 0;
    box-shadow: none !important;

    figure {
      @apply tw-rounded;
    }

    main {
      @include unclamp();
      @apply tw-py-0;
      flex: unset;
      height: unset !important;
      min-height: unset !important;
      max-height: unset !important;
    }
  }
}
