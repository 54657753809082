$blockName: #{$gik-prefix}user-pages-section;

.#{$blockName} {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: baseline;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0.5em;

    a {
      margin-left: 1em;
    }
  }

  &__header-text {
    @apply tw-font-heading tw-text-xl tw-font-semibold tw-mb-4;
  }

  &__about {
    @apply tw-mb-4;
  }

  &__about-text {
    @apply tw-font-body tw-text-base;
  }

  &__create-button-container {
    background-color: $empty-color;
    border-radius: $border-radius;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 260px;
    height: 243px;
    margin: calc(15px + 1em) 1em 1em 0;
  }
}

@screen md-down {
  .#{$blockName} {
    @apply tw-mx-4 tw-mt-12;

    &__list-container {
      @apply tw-flex-col;
    }

    &__list {
      align-self: stretch;
    }

    &__create-button-container {
      background-color: transparent !important;
      border-radius: 0 !important;
      width: 100%;
      height: auto !important;
      margin-right: 0;
    }
  }
}
