+b(features-section)

  &__visa-square
    background: linear-gradient(90deg, #23285F 0%, #25479F 100%)
    width: 33px
    height: 33px
    padding-left: 1px
    @apply tw-flex tw-items-center tw-rounded-sm

    &--disabled
      background: linear-gradient(90deg, #aaaaaa 0%, #b8b8b8 100%)

  +e(cards)
    @apply tw-flex tw-flex-col tw-items-center
    > :not(:last-child)
      @apply tw-mb-6

  // override max-width to match Figma
  // md-down was used in original component PageFeatureCard
  @screen md-down
    +e(cards)
      .gik-page-feature-card
        max-width: 300px
