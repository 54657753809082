.#{$gik-prefix}popover {
  @apply tw-max-w-full;

  &__portal {
    @apply tw-fixed tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-popovers;
    visibility: hidden;
    &--open {
      visibility: visible;
    }
  }
  &__popper {
    &[data-popper-placement*='bottom'] {
      .#{$gik-prefix}popover__arrow {
        top: 0;
        &::before {
          width: 1rem;
          height: 1rem;
          transform: rotate(45deg);
          background: linear-gradient(
            135deg,
            var(--bgColor) 0%,
            var(--bgColor) 51%,
            rgba(0, 0, 0, 0) 51%,
            rgba(0, 0, 0, 0) 100%
          );
          box-shadow: -5px -5px 10px -4px rgb(59 65 66 / 25%);
        }
      }
    }
    &[data-popper-placement*='top'] {
      .#{$gik-prefix}popover__arrow {
        bottom: 16px;
        &::before {
          width: 1rem;
          height: 1rem;
          transform: rotate(45deg);
          background: linear-gradient(
            -45deg,
            var(--bgColor) 0%,
            var(--bgColor) 51%,
            rgba(0, 0, 0, 0) 51%,
            rgba(0, 0, 0, 0) 100%
          );
          box-shadow: 5px 5px 10px -4px rgb(59 65 66 / 25%);
        }
      }
    }
  }
  &__reference-element {
    display: inline-block;
  }
  &__popper-content {
    @apply tw-rounded-sm tw-z-20 tw-relative;
    background: theme('colors.white');
    box-shadow: 0px 2px 6px rgba(70, 101, 108, 0.280403), 0px 7px 33px rgba(70, 101, 108, 0.261036);
    overflow-y: auto;
    padding: 0.58rem 1.0625rem;
    word-break: break-word;
    max-height: 80vh;
  }
  &__arrow {
    position: absolute;
    width: 3rem;
    height: 0;
    left: 0;
    z-index: 10;
    --bgColor: white;
    &::before {
      content: '';
      margin: auto;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      background-color: var(--bgColor);
    }
  }

  &__popper--nopad &__popper-content {
    @apply tw-p-0;
  }

  &__popper--arrow[data-popper-placement*='bottom'] &__popper-content {
    margin-top: 8px;
  }

  &__popper--arrow[data-popper-placement*='top'] &__popper-content {
    margin-bottom: 8px;
  }

  &--overlap {
    &[data-popper-placement*='bottom'] {
      margin-top: -0.5rem;
    }
    &[data-popper-placement*='top'] {
      margin-bottom: -0.5rem;
    }
  }
}
