+b(donation-provider)
  @apply tw-flex-1 tw-gap-2 tw-p-2 tw-flex tw-flex-col tw-items-center tw-content-center tw-max-w-full tw-justify-start tw-text-center
  @screen md
    @apply tw-flex-row

  a
    @apply tw-no-underline #{!important}

  main, aside
    @apply tw-flex-1 tw-flex tw-flex-col tw-items-center tw-content-center tw-max-w-full tw-justify-start tw-text-center
    background: transparent !important

  +e(icon)
    @apply tw-flex-shrink-0
    height: 41px
    width: 129px

  +e(target)
    @apply tw-font-body tw-font-bold tw-text-base

  +e(validation-error)
    max-width: 220px

  +e(help-link)
    @apply tw-font-normal tw-text-sm tw-leading-none tw-text-primary-600 tw-mt-4
    a
      @apply tw-no-underline

  +e(description)
    @apply tw-font-body tw-font-normal tw-text-xs tw-text-neutral-800
    @apply tw-mt-4 tw-mb-1 tw-whitespace-pre tw-italic

  +e(pre-form-field)
    @apply tw-flex-shrink-0

  +e(form-wrapper)
    @apply tw-flex tw-flex-col
    // make the input smaller if there is a pre-form-field element
    >:nth-child(2)
      input
        width: 90px !important

  +e(widget-desc)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700
