+b(recipient-info-step)
  height: 100%
  @apply tw-text-center
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  .gik-create-section-title
    @apply tw-text-white

  & > div:first-child
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
    @apply tw-flex-1
    & > *:first-child
      @apply tw-flex-1
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

  +e(recipient-info)
    @apply tw-px-4 tw-pb-8
    > div
      @apply tw-mx-auto
      max-width: 350px
      width: 100%

  +e(recipient-address)
    @apply tw-px-4 tw-pt-4 tw-pb-4

    .gik-checkbox
      @include gik-recipient-info-address-form
      +e(label)
        & > div > span:first-of-type
          @apply tw-font-normal tw-leading-none
        & > div > span:nth-of-type(2)
          @apply tw-font-normal tw-text-sm tw-leading-none tw-text-neutral-700

  +b(form)
    @apply tw-z-20
    +e(actions)
      display: none

  @screen md-down
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch

    & > div:first-child
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-flex-1
      align-content: stretch

    +e(recipient-info)
      @apply tw-flex-1
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

