+b(group-crm-navigation)

  +e(item)
    @apply tw-text-base tw-font-semibold tw-px-3 tw-py-4 tw-flex tw-items-center
    @apply tw-font-heading tw-text-neutral-900

  +e(icon)
    @apply tw-mr-3

  &__item--disabled
    @apply tw-opacity-70

  &__item--active
    @apply tw-bg-white tw-rounded-sm tw-bg-opacity-100 tw-shadow-base

  &__item + &__item
    @apply tw-mt-2
