$blockName: '#{$gik-prefix}collapse';

.#{$blockName} {
  &__title {
    @apply tw-text-primary-600 tw-text-xl tw-inline-block tw-font-bold;
  }

  > header {
    @apply tw-cursor-pointer;
  }

  > main {
    @apply tw-mt-4;
  }
}
