+b(inkind-page-settings-form)

  // reset default form styles
  +e(form)
    & > * + *
      @apply tw-mt-0

  // left align validation error messages in entire form
  .gik-form-group__error
    @apply tw-text-left

  // fix vertical centering on checkboxes for 16px label
  // TODO: remove when checkbox is fixed
  .gik-checkbox
    @apply tw-items-center

  // ----- sections ------

  // add space to the bottom of the last section
  // so it can take whole modal height on mobile
  // and it's scroll nav item can be selected
  // NOTE: if base modal component's max-height, header or footer height are changed
  // this needs to be updated
  +e(organizers-section)
    min-height: calc( 100vh - 156px ) // 100vh - (header + footer)

  @screen md
    +e(organizers-section)
      min-height: calc( min(96vh, 705px) - 156px ) // modal height (95vh or 700px) - (header(100px) + footer(56px))

  +b(inkind-group-selector)
    @apply tw-mt-8 tw-w-full tw-mx-auto
    max-width: 280px


// ----- footer ------

// reuse same styling for footer in both form and skeleton
#{b(inkind-page-settings-form__footer)}, #{b(inkind-page-settings-skeleton__footer)}
  @apply tw-flex tw-items-center tw-bg-white tw-px-4 tw-py-2
  > .gik-button
    white-space: nowrap

  > a:first-child
    > span
      @apply tw-hidden

  > a:nth-child(2)
    @apply tw-mr-auto

  +m(is-not-modal)
    > a:first-child, > a:nth-child(3)
      @apply tw-mr-2

  @screen sm
    > a:first-child
      > span
        @apply tw-flex
