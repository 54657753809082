+b(page-title-section)
  @apply tw-text-center

  // reset default form styles
  > *
    @apply tw-mt-0

  +b(fieldset)
    @apply tw-mx-auto
    max-width: 530px // same as wishlist cards

  +e(max-length)
    @apply tw-font-body tw-text-sm tw-text-neutral-800 tw-m-0 tw-text-center

  .gik-page-title-section__group
    @apply tw-text-center
    .gik-select
      max-width: 280px
      @apply tw-mx-auto tw-mt-6
