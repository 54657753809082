+b(not-found-page)
  @apply tw-text-center tw-flex-1
  @apply tw-h-full tw-flex tw-justify-center tw-items-center tw-content-center

  &__description
    @apply tw-whitespace-pre-wrap

  > hgroup
    @apply tw-text-center
  +b(input-search)
    @apply tw-mx-auto
    max-width: 600px
