+b(search-button)
  // TODO: this should be a variant on the button
  --bg: theme('colors.neutral.100')
  --bg-hover: theme('colors.neutral.300')
  @apply tw-z-10 tw-relative
  > span
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-text-neutral-900
  svg
    @apply tw-text-neutral-900

