+b(stream-input)
  @apply tw-w-full tw-flex tw-pr-2 tw-rounded

  +b(avatar)
    @apply tw-flex-shrink-0

  +b(input-mentions)
    @apply tw-flex-1 tw-pointer-events-none

  &--variant-plain
    // colors
    @apply tw-text-neutral-900 tw-bg-white
    // border
    @apply tw-border tw-border-neutral-500

  &--variant-neutral
    @apply tw-bg-neutral-100 tw-text-neutral-700 tw-border-0

  &__action-icon-wrapper
    @apply tw-flex tw-items-center tw-gap-2
  &__action-icon
    @apply tw-px-1 tw-py-3 tw-cursor-pointer
    &:hover
      @apply tw-text-neutral-800
