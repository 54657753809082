+b(recipient-info-fieldset)
  max-width: 30rem

  > div
    @apply tw-mb-2

  @screen sm
    +e(email-1 email-2)
      @apply tw-mx-auto tw-max-w-xs

  // not in figma, but to make 3 fields work bellow xs
  +e(inline-fields)
    @apply tw-flex tw-flex-wrap
    .gik-form-group
      @apply tw-flex-1
    .gik-form-group:nth-of-type(1)
      @apply tw-mb-2
      flex-basis: 100%
    .gik-form-group:nth-of-type(2)
      @apply tw-mr-2

  @screen xs
    +e(inline-fields)
      .gik-form-group:nth-of-type(1)
        @apply tw-mb-0 tw-mr-2
        flex-basis: 0
        flex-grow: 2

  @screen sm
    +e(inline-fields)
      @apply tw-mb-4 #{!important}
      .gik-form-group:nth-of-type(1), .gik-form-group:nth-of-type(2)
        @apply tw-mr-3

