$blockName: prefix(app-header-sign-in-button)

.#{$blockName}
  &__btn-signin
    @apply tw-px-1 tw-block tw-font-heading tw-leading-none tw-pr-0
    text-decoration: none !important
    --color: theme('colors.neutral.900')
    --color-hover: theme('colors.black')
    > div
      @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-tight tw-text-neutral-900

  +breakpoint(sm)
    & .#{$blockName}__btn-signin > span
      @apply tw-text-sm

  +breakpoint(md)
    & .#{$blockName}__btn-signin > span
      @apply tw-text-base
