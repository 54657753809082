+b(calendar-event-type-select-list)
  @apply tw-flex tw-flex-wrap tw-grid tw-gap-3

  @screen sm
    @apply tw-grid-cols-2

  +e(select-wrapper)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

  .gik-calendar-event-type--announcement
    grid-column: 1/-1


