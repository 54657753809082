+b(upgrade-page-reminder)
  @apply tw-flex tw-items-stretch
  &__image-container
    @apply tw-relative tw-my-0 tw-mx-auto tw-overflow-hidden tw-flex-shrink-0
    width: 71px
    svg
      @apply tw-absolute
      top: 50%
      transform: translate(0, -50%)
  &__content
    @apply tw-flex tw-flex-col tw-flex-1
    h1
      @apply tw-font-bold tw-text-base tw-leading-snug tw-p-0 tw-m-0
    p
      @apply tw-font-normal tw-text-base tw-leading-snug tw-p-0 tw-m-0

