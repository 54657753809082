@use 'sass:math'
+b(modal-button-footer)
  z-index: 200
  // Temporary fix for claim conflict resolution which requires a right-aligned modal footer
  // which is portalled into the claim modal footer
  +b(modal-button-footer)
    @apply tw-p-0

  @apply tw-p-4 tw-bg-white tw-flex tw-items-center tw-justify-between tw-w-full
  $button-spacing: 10px

  > section
    > * + *
      @apply tw-ml-2

  .gik-button
    &:not(:first-of-type)
      margin-left: math.div($button-spacing, 2)

    &:not(:last-of-type)
      margin-right: math.div($button-spacing, 2)

  button.button-v2
    padding: 10px 20px
    margin: 0px
    min-width: 160px
    @screen md-down
      min-width: 100px

    &:not(:first-of-type)
      margin-left:  math.div($button-spacing, 2)

    &:not(:last-of-type)
      margin-right:  math.div($button-spacing, 2)

    @screen md-down
      flex-grow: 1

    span
      font-size: 20px
      line-height: 26px

      i
        top: 1px
        position: relative
        margin-right: -4px
        margin-left: -4px

  +m(custom)
    @apply tw-p-0

  +m(centered-buttons)
    @apply tw-justify-center

    // buttons in the footer should be a different height from normal "base" sized buttons (desktop only)
  // see https://www.figma.com/file/aGhcKx0IGZQuGKqltTGRpD/Internal-Checkout?node-id=137%3A814
  @screen md
    &--small-buttons-desktop
      height: 54px

    &--small-buttons-desktop .gik-button,
    &--small-buttons-desktop .button-v2
      @apply tw-h-8

    &--small-buttons-desktop .gik-button--circle
      @apply tw-h-8 tw-w-8


    &--small-buttons-desktop .button-v2
      @apply tw-text-base
      > span
        font-size: 1rem !important

    &--small-buttons-desktop &--custom
      @apply tw-h-auto

  &.button-footer

    background-color: white
    padding: 10px 20px 25px
    display: flex
    justify-content: center
    align-items: center
    $button-spacing: 10px
    +b(button)
      &:not(:first-of-type)
        margin-left: math.div($button-spacing, 2)

      &:not(:last-of-type)
        margin-right: math.div($button-spacing, 2)

    button.button-v2
      padding: 10px 20px
      margin: 0px
      min-width: 160px
      @screen md-down
        min-width: 100px

      &:not(:first-of-type)
        margin-left: math.div($button-spacing, 2)

      &:not(:last-of-type)
        margin-right: math.div($button-spacing, 2)

      @screen md-down
        flex-grow: 1

      span
        font-size: 20px
        line-height: 26px

        i
          top: 1px
          position: relative
          margin-right: -4px
          margin-left: -4px
