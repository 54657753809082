+b(group-widget-header-preview)
  $-align-space: 20px

  width: 163px
  @apply tw-absolute tw-mx-auto
  @apply tw-z-50
  top: 130px
  right: 16px
  max-width: calc(50% - .25rem)

  @screen lg
    left: calc(50% - 100px)
    right: auto
    top: 210px
    width: 200px
    //z-index: 10

  .gik-inkind-group-widget__main
    min-height: 300px

  &__inner
    @apply tw-relative
    & > *
      left: $-align-space
      @apply tw-absolute tw-shadow-base tw-rounded-t-md tw-bg-white tw-w-full

  .gik-inkind-group-page__section-content
      @apply tw-rounded-t-md

