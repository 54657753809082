+b(related-products-section)
  @apply tw-text-center tw-p-2 tw-pb-8
  @screen md-down
    @apply tw-px-0

  +e(title)
    @apply tw-text-center tw-mt-4 tw-mb-10

    // FIXME: an overwrite like this should not be needed...
    &.gik-heading-new--heading-2
      @apply tw-normal-case tw-text-3xl

  +e(disable-button)
    @apply tw-ml-4
