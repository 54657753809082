$blockName: prefix(product-carousel)

.#{$blockName}
  padding-top: 20px
  > .title
    margin: 60px 0px 10px
    text-align: center
    @apply tw-font-body
    font-size: 30px
    font-weight: 500
    line-height: 35px
    @screen md-down
      font-size: 24px
      line-height: 28px
      margin: 48px 0px 8px

  .slick-track
    padding: 0
    .slick-slide
      & > div
        @apply tw-mx-2

  .slick-arrow:before
    content: none

  .slick-next,
  .slick-prev
    top: 34%
    border-radius: 50%
    padding: 0px
    width: 40px
    height: 40px
    filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.5))

  .slick-prev
    left: -70px
    z-index: 1
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDAgNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuOSI+PHBhdGggZD0ibTIwIDBjLTExLjA1IDAtMjAgOC45NTQtMjAgMjAgMCAxMS4wNSA4Ljk1NCAyMCAyMCAyMCAxMS4wNSAwIDIwLTguOTU0IDIwLTIwIDAtMTEuMDUtOC45NTQtMjAtMjAtMjBtNS4wNTYgOS45ODFjLjcyNS43MTUuNzI1IDEuODc0IDAgMi41ODhsLTcuOTY2IDcuODUzIDcuOTY2IDcuODUzYy43MjUuNzE1LjcyNSAxLjg3NCAwIDIuNTg5LS43MjUuNzE1LTEuOTAxLjcxNS0yLjYyNiAwbC05LjI3OS05LjE0N2MtLjcyNS0uNzE1LS43MjUtMS44NzQgMC0yLjU4OWw5LjI3OS05LjE0N2MuMzYyLS4zNTguODM4LS41MzYgMS4zMTMtLjUzNi40NzUgMCAuOTUuMTc5IDEuMzEzLjUzNiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=)
    @screen md-down
      left: 15px

  .slick-next
    right: -50px
    background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDAgNDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBvcGFjaXR5PSIuOSI+PHBhdGggZD0ibTIwIDQwYzExLjA1IDAgMjAtOC45NTQgMjAtMjAgMC0xMS4wNS04Ljk1NC0yMC0yMC0yMC0xMS4wNSAwLTIwIDguOTU0LTIwIDIwIDAgMTEuMDUgOC45NTQgMjAgMjAgMjBtLTUuMDU2LTguOTgxYy0uNzI1LS43MTUtLjcyNS0xLjg3NCAwLTIuNTg4bDcuOTY2LTcuODUzLTcuOTY2LTcuODUzYy0uNzI1LS43MTUtLjcyNS0xLjg3NCAwLTIuNTg5LjcyNS0uNzE1IDEuOTAxLS43MTUgMi42MjYgMGw5LjI3OSA5LjE0N2MuNzI1LjcxNS43MjUgMS44NzQgMCAyLjU4OWwtOS4yNzkgOS4xNDdjLS4zNjIuMzU4LS44MzguNTM2LTEuMzEzLjUzNi0uNDc1IDAtLjk1LS4xNzktMS4zMTMtLjUzNiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=)
    @screen md-down
      right: 15px

  .slick-next:hover,
  .slick-prev:hover
    opacity: 0.8

  .slick-slide
    margin-top: 20px
    margin-bottom: 20px

  .slick-dots
    text-align: center
