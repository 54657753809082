+b(inkind-page-content)
  // used to hide the bottom shadow from the header
  @apply tw-relative tw-bg-white
  @apply tw-pt-6 tw-pb-0

  // fix the tablet view
  @screen md
    .gik-container
      max-width: 960px

  @screen xl
    .gik-container
      max-width: 1140px

  @apply tw-px-4

  @screen sm
    @apply tw-px-8

  @screen md
    @apply tw-px-4

  @screen lg
    @apply tw-px-0

  +e(content-container)
    @apply tw-mb-12

  +e(page-content-aside)
    > div
      @apply tw-flex tw-flex-col tw-gap-4

  .gik-stream-posts
    @apply tw-px-0
    @screen md-down
      .gik-container
        @apply tw-px-0

  &__main
    @apply tw-flex tw-flex-col

  &__calendar-container
    @apply tw-flex tw-flex-col tw-flex-1

  +b(inkind-calendar-section)
    @apply tw-flex-1

  +e(comments)
    @apply tw-mx-auto tw-mt-16 tw-mb-4
    max-width: 980px

  +e(layout-aside)
    > aside
      @apply tw-flex tw-flex-col tw-items-stretch tw-justify-start tw-mt-8
      align-content: stretch
      // NOTE: white background is required to avoid page tour highlighted area grey background in Chrome
      // .gik-inkind-page-content has tw-bg-white

      @screen md
        @apply tw-mt-0

      // fix the tablet view
      @screen md
        width: 250px
      @screen lg
        width: var(--aside-width)

    > main
      // fixes slick-slider width, important
      min-width: 0
      // add layoutaside gutter
      @screen md
        @apply tw-pr-8

// TODO: remove after migrating to use getStream comment system
#disqus_thread
  position: relative

#disqus_thread:after
  content: ""
  display: block
  height: 55px
  width: 100%
  position: absolute
  bottom: 0
  background: white
