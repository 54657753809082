$blockName: inkind-user-update-card
+b($blockName)
  @apply tw-h-24 tw-shadow-lg tw-rounded
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  +e(title)
    display: -webkit-box
    -webkit-line-clamp: 2
    text-overflow: ellipsis
    overflow: hidden
    -webkit-box-orient: vertical
    max-height: 48px
    padding-bottom: 3px

    p
      @apply tw-m-0 tw-font-heading tw-font-medium tw-text-xl tw-leading-none tw-text-neutral-800
      padding-bottom: 0.12em

  +e(no-update-title)
    @apply  tw-font-normal tw-text-lg tw-leading-tight tw-text-neutral-700
  +e(subtitle)

  +e(content)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch

  +e(thumbnail-wrapper)
    @apply tw-relative
    & > *
      @apply tw-h-24 tw-w-24 tw-rounded
      border-bottom-right-radius: 0
      border-top-right-radius: 0
    & > img
      @apply tw-absolute tw-top-0 tw-object-cover

  +e(card-thumbnail-skeleton)
  +e(card-thumbnail)

  +e(text-wrapper)
    @apply tw-mx-3

  +e(date)
    @apply tw-font-normal tw-text-sm tw-leading-snug tw-text-lgray-other

  +m(no-thumbnail,text-wrapper)
    @apply tw-ml-5

  +m(no-thumbnail, thumbnail-wrapper)
    @apply tw-hidden

  +m(placeholder)
    @apply tw-bg-neutral-100 tw-shadow-none

  +m(organiser)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-cursor-default tw-shadow-none tw-bg-neutral-200
    &:before
      @apply tw-hidden

  #{m(logged-in)}.#{prefix($blockName) + '--placeholder'}, #{m(add-button)}
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +m(hover)
    &:not(.#{prefix($blockName + '--placeholder')})
      @apply tw-cursor-pointer
      @include gik-focus-shadow(0, 1rem)
