$blockName: '#{$gik-prefix}testimonials-carousel';

.#{$blockName}-card {
  @apply tw-mx-auto tw-py-4;
  min-width: 235px;
  max-width: 700px;

  &__quote {
    @apply tw-font-heading tw-pb-6 tw-text-2xl;
    line-height: 28px;
    color: theme('colors.white');
    text-align: center;
  }

  &__person-row {
    display: flex;
  }

  &__person {
    @apply tw-flex tw-pb-3;
    margin: auto;
    max-width: 245px;
    align-items: center;
  }

  &__info {
    @apply tw-pl-2;
  }

  &__name {
    @apply tw-text-base;
    color: theme('colors.white');
  }

  &__title {
    @apply tw-text-sm;
    font-style: italic;
    color: theme('colors.white');
  }

  &__location {
    @apply tw-text-sm;
    font-style: italic;
    color: theme('colors.white');
  }

  @screen md {
    width: 600px;

    &__quote {
      font-size: 30px;
      line-height: 33px;
    }
  }

  @screen lg {
    width: 740px;

    &__quote {
      font-size: 30px;
      line-height: 33px;
    }
  }
}
