$blockName: '#{$gik-prefix}features-nav';

.#{$blockName} {
  --height: 47px;
  @apply tw-relative tw-block tw-px-2 tw-text-center;
  height: var(--height);

  > ul {
    @apply tw-inline-flex tw-mx-auto tw-justify-center tw-h-full tw-shadow-lg tw-rounded-full;

    margin-top: calc(var(--height) / -2);
    > li {
      @apply tw-flex-shrink-0;
    }
  }

  &__icon {
    @apply tw-relative;
    bottom: -4px;
  }

  @screen xs {
    &__link {
      @apply tw-px-6;
    }
  }

  @screen sm {
    --height: 60px;
  }
}

.gik-features-nav-item {
  @apply tw-text-center;

  img {
    @apply tw-mx-auto;
    height: 25px;
  }

  &--primary {
    @apply tw-text-primary-500;
  }
  &--danger {
    @apply tw-text-danger-500;
  }
  &--secondary {
    @apply tw-text-secondary-500;
  }
  &--success {
    @apply tw-text-success-500;
  }
  &--warn {
    @apply tw-text-warn-700;
  }

  &__link {
    @apply tw-bg-white tw-shadow-xs tw-block;
    @extend .gik-no-tap-effect;
    @apply tw-p-2 tw-px-4 tw-text-center tw-cursor-pointer;
    height: var(--height);
    transition: background-color 200ms ease-out;

    &:hover {
      @apply tw-bg-neutral-50;
    }

    &--inactive {
      @apply tw-text-neutral-500;
      img {
        filter: grayscale(1);
      }
      // the wishlist icon needs opacity to make it a lighter grayscale that matches the other icons
      .#{$gik-prefix}features-nav__wishlist-icon {
        opacity: 0.6;
      }
    }
  }

  &__label {
    @apply tw-font-bold tw-text-xs tw-leading-none tw-uppercase tw-hidden;
  }

  &:first-child > &__link {
    @apply tw-rounded-l-full tw-pl-5;
  }
  &:last-child > &__link {
    @apply tw-rounded-r-full tw-pr-5;
  }

  @screen xs {
    &:first-child > &__link {
      @apply tw-pr-5;
      padding-left: 1.75rem; // needs very specific padding
    }
    &:last-child > &__link {
      @apply tw-pl-5 tw-pr-6;
    }
  }

  @screen sm {
    &__link {
      @apply tw-px-6;
    }
    &__label {
      @apply tw-inline-block;
    }
    &:first-child > &__link {
      @apply tw-pl-10 tw-pr-6;
      padding-left: 2.25rem; // needs very specific padding
    }
    &:last-child > &__link {
      @apply tw-pl-6 tw-pr-8;
    }
  }
}
