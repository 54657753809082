.#{$gik-prefix}grid {
  $blockName: &;

  @apply tw-grid tw-gap-4;

  &--5-cols {
    &#{$blockName}--fixed {
      @apply tw-grid-cols-5 #{!important};
    }

    @apply tw-outline-none;

    &:focus {
      @apply tw-outline-none;
    }

    @screen xs {
      @apply tw-grid-cols-2;
    }

    @screen md {
      @apply tw-grid-cols-3;
    }

    @screen lg {
      @apply tw-grid-cols-4;
    }

    @screen xl {
      @apply tw-grid-cols-5;
    }
  }

  &--4-cols {
    &#{$blockName}--fixed {
      @apply tw-grid-cols-4 #{!important};
    }

    @screen xs {
      @apply tw-grid-cols-2;
    }

    @screen md {
      @apply tw-grid-cols-3;
    }

    @screen lg {
      @apply tw-grid-cols-4;
    }
  }

  &--3-cols {
    &#{$blockName}--fixed {
      @apply tw-grid-cols-3 #{!important};
    }

    @screen xs {
      @apply tw-grid-cols-2;
    }

    @screen lg {
      @apply tw-grid-cols-3;
    }
  }

  &--2-cols {
    &#{$blockName}--fixed {
      @apply tw-grid-cols-2 #{!important};
    }

    @apply tw-grid-cols-2;
  }

  &--1-cols {
    &#{$blockName}--fixed {
      @apply tw-grid-cols-1 #{!important};
    }

    @screen xs {
      @apply tw-grid-cols-1;
    }

    @screen lg {
      @apply tw-grid-cols-1;
    }
  }
}

.#{$gik-prefix}grid-albatross {
  @apply tw--m-2 tw-flex tw-flex-wrap;
  > * {
    @apply tw-m-2;
    flex-basis: calc(calc(800px - 100%) * 999);
    flex-shrink: 1;

    @screen xs {
      // 50% width by default (mobile)
      max-width: calc(50% - 1rem);
    }

    @screen md {
      min-width: 230px;
      max-width: unset;
      flex-basis: 230px;
    }
  }
}
