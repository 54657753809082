.gik-hidden-xs {
  @screen sm-down {
    display: none !important;
  }
}
.gik-hidden-xs-down {
  @screen xs-down {
    display: none !important;
  }
}
.gik-hidden-sm {
  @screen sm {
    display: none !important;
  }
}
.gik-hidden-sm-down {
  @screen sm-down {
    display: none !important;
  }
}
.gik-hidden-md-down {
  @screen md-down {
    display: none !important;
  }
}
.gik-hidden-md {
  @screen md {
    display: none !important;
  }
}
.gik-hidden-lg {
  @screen lg {
    display: none !important;
  }
}
.gik-hidden-lg-down {
  @screen lg-down {
    display: none !important;
  }
}
.gik-hidden-xl {
  @screen xl {
    display: none !important;
  }
}
.gik-hidden-xl-down {
  @screen xl-down {
    display: none !important;
  }
}
