// TODO: update this to use tailwind ring styling
@mixin gik-focus-shadow($gap: 4px, $radius: 0.45rem) {
  outline: none;
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    z-index: -1;
    top: -$gap;
    left: -$gap;
    right: -$gap;
    bottom: -$gap;

    // ticket: https://www.notion.so/a1995c1d6b6245eaa9c23fab816ba04d?v=d229c31257f24d6189b9c89cb3fa0a8e&p=9f066d63d893476f943fce2fa34846a3
    border-radius: 0.45rem;
    border: 2px solid theme('colors.info.300');
  }
}
