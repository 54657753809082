@use 'sass:math'
$blockName: line-clamp
$range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10

@mixin line-clamp-line($lines)
  &#{b($blockName)}--lines-#{$lines}
    +b($blockName)
      +e(text-wrapper)
        +lineClamp($lines)

  &#{b($blockName)}--lines-#{$lines}#{b($blockName)}--fade-out
    +b($blockName)
      +e(text-wrapper)
        @if ($lines > 1)
          $fade: 100 - (math.div((2 * 100), $lines))
          mask-image: linear-gradient(to bottom, black #{$fade + '%'}, transparent 100%)

@mixin line-clamp-lines
  @each $line in $range
    +line-clamp-line($line)

+b($blockName)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start tw-relative

  +m(block)
    @apply tw-block

  +m(horizontal)
    @apply tw-flex-row tw-items-end

  +e(button)
    @apply tw--ml-3

  +m(clamped)
    @screen screen
      +line-clamp-lines()

  +e(more-button)
    @apply tw-block
    @screen print
      @apply tw-hidden

  +e(text-wrapper)
    @apply tw-whitespace-pre-wrap tw-flex-1

  +e(compact-button-wrapper)
    @apply tw-absolute
    left: 50%
    width: 100px
    bottom: -18px
    transform: translateX(-50px)

  +e(compact-button)
    @apply tw-drop-shadow-md tw-font-bold tw-text-xs tw-leading-none tw-text-neutral-700 tw-px-2
    height: 22px
    svg
      margin: 0
      width: 1.25rem


