.gik-flex-break {
  flex-basis: 100%;
  height: 0;
}

.gik-placeholder-background {
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #efefef 10px, #efefef 40px);
}

@mixin placeholder-fill($name: 'placeholder', $height: 500px) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;

  &:before {
    content: $name;
  }

  @apply tw-font-heading tw-text-2xl gik-placeholder-background;
}

.#{$gik-prefix}flex-grid {
  @apply tw-flex tw-flex-wrap tw-gap-2;
  > * {
    @apply tw-flex-shrink-0;
  }
}
