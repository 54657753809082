+b(privacy-settings-section-skeleton)

  // section component has padding
  @apply tw-px-5 tw-py-6

  +e(title)
    @apply tw-mt-4

  +e(title description)
    @apply tw-mb-4 tw-text-center

  +e(cards)
    @apply tw-flex tw-flex-wrap tw-justify-center tw-mb-6

  +e(card)
    --height: 77px
    --width: auto
    flex-basis: 100%
    @apply tw-mb-3
    > div
      @apply tw-flex tw-items-center tw-p-4

  @screen sm
    +e(cards)
      @apply tw-max-w-3xl tw-mx-auto

    +e(card)
      flex-basis: calc(50% - 1rem)
      max-width: 270px

    +e(card)
      &:nth-child(1), &:nth-child(3)
        @apply tw-mr-4

  +e(icon)
    --height: 2rem
    --width: 2rem
    @apply tw-mr-4 tw-flex-shrink-0

  +e(switch)
    @apply tw-ml-auto

  +e(second-section)
    @apply tw-mx-auto tw-max-w-md

  +e(box)
    --height: 1rem
    --width: 1rem
    @apply tw-inline-block tw-mr-2

  +e(label)
    @apply tw-inline-block

  +e(text)
    @apply tw-ml-4 tw-mb-4

  +e(checkbox-group-2)
    @apply tw-ml-4


