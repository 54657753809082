+b(contact)
  +e(tiles)
    & > *
      @apply tw-mx-auto tw-mb-24
    @screen md-down
      @apply tw-mt-8

  +b(container)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
      align-content: stretch

    & > *:first-child
      @apply tw-mr-8

    & > *:last-child
      @apply tw-ml-8

  +e(form)
    @apply tw-flex-1

