$tablePrefix: 'gik-sticky-table';

.#{$gik-prefix}competitors-comparison-table {
  @apply tw-mx-auto tw-text-sm;

  &__gik-logo {
    @apply tw-ml-auto tw-font-bold tw-text-lg tw-whitespace-nowrap tw-mr-3;
    margin-bottom: 9px;
    width: 80px;
  }

  max-width: 1600px;
  &__firstCol {
    @apply tw-flex tw-items-center tw-pl-2;
    > main {
      @apply tw-flex-1 tw-mr-6;
      width: 110px;
    }
    > aside {
      @apply tw-pr-10;
      width: 75px;
      @screen md {
        width: 80px;
      }
    }

    max-width: unset;
  }

  .#{$tablePrefix}__td-inner {
    @apply tw-my-6 tw-mx-4;
  }
  .gik-sticky-table__td--giveinkind,
  .gik-sticky-table__td--description {
    .gik-sticky-table__td-inner {
      @apply tw-mx-0;
      margin-left: -2px;
    }
  }

  .#{$tablePrefix}__td {
    @apply tw-text-center tw-text-neutral-900 tw-break-words tw-break-normal tw-whitespace-normal;
    @apply tw-bg-white;
    &--description {
      @apply tw-text-left tw-font-bold;
    }
    svg {
      width: 28px;
      height: 28px;

      &.checkmark {
        @apply tw-text-neutral-600;
      }
      &.gik-checkmark {
        @apply tw-text-success-500;
        width: 34px;
        height: 34px;
        margin-left: 10px;
      }

      @screen md {
        width: 34px;
        height: 34px;

        &.gik-checkmark {
          width: 44px;
          height: 44px;
          margin-left: 10px;
        }
      }
    }
  }

  // NOTE: this does not well supported accross all browser yet (because it's a table)
  // .#{$tablePrefix}__thead,
  // .#{$tablePrefix}__tfoot {
  //   position: sticky;
  //   z-index: 1;
  //   width: fit-content;
  // }

  --desktop-header-height .#{$tablePrefix}__thead {
    top: 0;
  }

  .#{$tablePrefix}__th-inner {
    @apply tw-my-2 tw-mx-2;
    margin-top: theme('app.mobileHeaderHeight');
    @screen md {
      margin-top: theme('app.desktopHeaderHeight');
    }
  }

  .#{$tablePrefix}__th {
    @apply tw-bg-white tw-text-center tw-top-0 tw-pt-3;
    position: sticky;
    svg {
      @apply tw-ml-auto;
    }
  }

  .#{$tablePrefix}__tr {
    .#{$tablePrefix}__td {
      @apply tw-bg-white;

      &:first-child {
        @apply tw-rounded-l;
      }
      &:last-child {
        @apply tw-rounded-r;
      }
    }

    &:nth-child(odd) {
      .#{$tablePrefix}__td {
        @apply tw-bg-neutral-100;
      }
    }
  }

  thead {
    th {
      @apply tw-font-normal tw-break-normal;
    }
  }

  tbody {
    tr {
      &:nth-child(odd) {
        @apply tw-bg-neutral-50 tw-rounded;
      }
    }
  }

  .gik-sticky-table__td {
    @apply tw-flex-shrink-0;
    min-width: 80px;
  }

  .gik-sticky-table__td--description {
    width: 180px;
  }

  @screen sm {
    .gik-sticky-table__td--description {
      width: 240px;
    }
    &__firstCol {
      width: 240px;

      > main {
        @apply tw-mr-12 tw-pl-4;
      }
    }
  }

  @screen md {
    @apply tw-text-base;

    .gik-sticky-table__td {
      max-width: unset;
    }

    .gik-sticky-table__td--description {
      width: 320px;
      min-width: unset;
      max-width: unset;
    }
    &__firstCol {
      width: 320px;
    }
  }
}
