+b(alternate-phone-number-modal-content)

  @screen md
    max-width: 283px !important

  // integrate title in the header
  .gik-modal__header
    @apply tw-font-heading tw-text-2xl tw-font-semibold tw-text-neutral-900 tw-text-center
    @apply tw-px-4 tw-mt-6

  .content
    @apply tw-p-4 tw-pb-2

  +e(description)
    @apply tw-font-body tw-text-base tw-text-neutral-900 tw-leading-tight tw-text-center
    @apply tw-mt-0 tw-mb-4

  .gik-form__actions
    @apply tw-mt-0


