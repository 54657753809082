.gik-interstitial__close .gik-fonticon {
  @apply tw-text-white tw-cursor-pointer tw-z-10;
}

.#{$gik-prefix}external-product-interstitial {
  @apply tw-text-white;
}

.#{$gik-prefix}external-product-interstitial-content {
  &__lead {
    @apply tw-text-center;

    > h1 {
      @apply tw-tracking-normal tw-font-heading tw-font-semibold tw-text-5xl tw-leading-none tw-text-white tw-text-center tw-px-4 tw-mb-6 #{!important};
      @screen md {
        @apply tw-mb-4 #{!important};
      }
    }
    > p {
      @apply tw-font-normal tw-text-lg tw-leading-snug tw-text-white tw-text-center;
    }

    > a:first-of-type {
      @apply tw-no-underline tw-mt-2;
      @screen sm-down {
        @apply tw-w-full;
        .gik-button {
          @apply tw-w-full;
          height: 50px;
        }
      }
      @screen xs-down {
        .gik-button {
          @apply tw-text-sm;
        }
      }
    }
  }
  &__recipient-btn {
    @apply tw-font-bold tw-text-sm tw-leading-none tw-text-white tw-underline tw-mt-6 tw-block;
    @screen sm {
      @apply tw-hidden;
    }
  }
  &__not-working {
    @apply tw-bg-primary-600 tw-flex tw-items-center tw-justify-center tw-mt-6 tw-mb-8 tw-rounded tw-p-4 tw-w-full tw-text-left;
    @screen sm {
      @apply tw-mt-10;
    }
    border-radius: 1rem;
    div:first-of-type {
      @apply tw-text-white;
      p:first-of-type {
        @apply tw-font-bold tw-text-base tw-leading-tight tw-text-primary-50;
      }
      p:last-of-type {
        @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-primary-100;
      }
    }
    > .gik-button {
      @apply tw-ml-6;
      svg {
        @apply tw-text-primary-700;
      }
    }
  }
  &__helpful-info {
    @apply tw-flex tw-flex-col tw-items-center tw-w-full tw-mb-8 tw-text-left;
    > h2 {
      @apply tw-tracking-normal tw-font-heading tw-font-semibold tw-text-2xl tw-leading-none tw-text-white tw-text-center #{!important};
    }
    > div:first-of-type {
      @apply tw-flex tw-w-full tw-justify-between tw-flex-wrap;
    }
  }
  &__email-msg {
    @apply tw-bg-primary-600 tw-py-1 tw-px-4 tw-text-primary-100 tw-rounded-full tw-italic tw-font-normal tw-text-sm;
  }
}

.external-product-interstitial-copy-info-card {
  @apply tw-flex tw-flex-col tw-bg-primary-50 tw-rounded tw-w-full tw-py-5 tw-px-4 tw-relative tw-mb-5;
  @screen sm {
    @apply tw-w-64;
  }
  min-height: 8rem;
  > header {
    @apply tw-flex tw-items-center;
    .gik-svg-icon {
      @apply tw-mr-2 tw-text-black;
      margin-top: 2px;
    }
    span {
      @apply tw-font-bold tw-text-lg tw-leading-tight tw-text-neutral-900 tw-pt-1;
    }
  }
  > main {
    @apply tw-flex tw-flex-col tw-flex-1 tw-items-start tw-justify-center tw-mt-2 tw-leading-tight;
    span {
      @apply tw-font-normal tw-text-sm tw-text-neutral-900 tw-pr-8;
      &:first-of-type {
        @apply tw-font-bold;
        @screen xs {
          @apply tw-pr-0;
        }
      }
    }
  }
  .external-product-interstitial-copy-info-card-button {
    @apply tw-absolute tw-bottom-0 tw-right-0 tw-mr-2 tw-mb-2;
  }
}

.#{$gik-prefix}external-product-interstitial-carousel {
  @apply tw-max-w-full tw-text-lg tw-text-center;

  .gik-checkbox {
    @apply tw-mt-4 tw-mb-6;
  }

  .slick-slide {
    > div > div {
      @apply tw-mx-4 tw-outline-none;
    }
  }
}
