$blockName: '#{$gik-prefix}marketing-nav-hero';

.#{$blockName} {
  @apply tw-p-8 tw-pb-16 tw-flex tw-items-center tw-justify-center tw-min-h-40 tw-text-white;

  .gik-marketing-nav {
    @apply tw-mx-auto;
    width: 100%;
    max-width: 550px;
  }
}
