+b(inkind-page-settings-skeleton)

  +m(is-not-modal)
    min-height: calc(100% - 56px) // full height - footer height

  @apply tw-flex tw-flex-col tw-items-center
  @apply tw-pt-8 tw-px-2

  @screen md
    @apply tw-px-8

  +e(description-1 description-2 description-3)
    @apply tw-mb-6

  +e(input)
    --height: 2.5rem
    --width: 100%
    max-width: 530px

  +e(card)
    --height: 273px
    --width: 100%
    max-width: 279px
    @apply tw-mb-6
    > div
      @apply tw-flex tw-flex-col tw-justify-between tw-items-center tw-p-8

  +e(icon)
    --height: 4.4rem
    --width: 4.4rem
    @apply tw-mb-4 tw-flex-shrink-0

  +e(card-text)
    @apply tw-mb-4 tw-flex tw-flex-col tw-items-center

  +e(switch)
    @apply tw-mx-auto

  @screen md
    +e(card)
      --height: 117px
      max-width: 530px
      @apply tw-mb-6
      > div
        @apply tw-flex-row tw-justify-start tw-p-4

    +e(icon)
      @apply tw-mr-4 tw-mb-0

    +e(card-text)
      @apply tw-mb-0 tw-items-start

    +e(switch)
      @apply tw-mr-0


