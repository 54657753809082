+b(contact-page-organizers-for-address)
  @apply tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-3 tw-px-4
  @apply tw-bg-neutral-100 tw-rounded
  width: 218px

  +m(interstitial)
    @apply tw-bg-transparent

  +m(interstitial centered)
    @apply tw-mx-auto

  +e(description)
    @apply tw-text-sm tw-font-bold tw-leading-none tw-text-neutral-900 tw-text-center tw-mt-0 tw-mb-2

  +e(button)
    @apply tw-font-body tw-font-bold
