$blockName: prefix(app-header-nav-mobile)

.#{$blockName}
  @apply tw-flex tw-items-center tw-mr-2
  > *
    @apply tw-flex-shrink-0

  > * + *
    @apply tw-ml-2

  +breakpoint(md)
    @apply tw-hidden
