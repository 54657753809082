+b(crm-navigation)

  +e(item)
    @apply tw-text-xl tw-font-semibold tw-px-3 tw-py-4 tw-flex tw-items-center
    @apply tw-font-heading tw-bg-white tw-rounded tw-text-neutral-900

  +e(icon)
    @apply tw-mr-3

  .gik-svg-icon--supporters
    @apply tw-text-info-500

  &__item + &__item
    @apply tw-mt-2

  &__label
    @apply tw-flex-1
