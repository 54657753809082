+b(checkout-launcher)
  &__shipping-not-complete

    @screen md
      max-width: 500px !important

    &.gik-modal--system .gik-modal__toolbar
      @apply tw-flex-col-reverse tw-gap-2
      & > *
        flex: initial
      * + *
        @apply tw-ml-0
