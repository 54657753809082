+b(base-image-picker)
  +e(image-upload-dialog-content)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

+b(image-picker)
  +e(image)
    @apply tw-w-64 tw-h-auto tw-mt-4

+b(thumbnail-image-picker)
  +e(thumbnail-button)
    @apply tw-rounded tw-w-20 tw-h-20
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-bg-center tw-bg-no-repeat tw-bg-cover
    @apply tw-bg-neutral-600
