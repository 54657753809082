.#{$gik-prefix}product-tile-toolbar {
  &__icon-heart {
    &:hover {
      @apply tw-text-danger-500;
    }
  }
  &__icon-heart-saved {
    @apply tw-text-danger-500;
  }

  &__icon-plus-btn {
    display: block;
    &:hover {
      @apply tw-text-primary-500;
    }
  }

  &__icon-plus {
    position: relative;
    top: -3px;
  }

  &__icon-trash {
    &:hover {
      @apply tw-text-danger-500;
    }
  }
}
