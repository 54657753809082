+b(product-actions)
  @apply tw-flex tw-gap-4

  &__button-wishlist
    --bg: theme('colors.secondary.50')
    --bg-hover: theme('colors.secondary.50')
    --color: theme('colors.secondary.600')
  &__button-save
    --bg: theme('colors.danger.50')
    --bg-hover: theme('colors.danger.50')
    --color: theme('colors.danger.600')
  // &__button-buy
  //   --bg: theme('colors.primary.500')
  //   --color: theme('colors.white')
  &__zip-codes
    @apply tw-bg-neutral-50 tw-inline-block tw-p-1 tw-rounded tw-border-2 tw-border-neutral-100 tw-mt-6 tw-text-lg tw-leading-6 tw-text-neutral-800
    span
      @apply tw-font-bold


+b(product-actions-skeleton)
  @apply tw-flex tw-gap-4
