$blockname: 'gik-scroll-navbar-item'

@mixin scroll-navbar-item-variant($name)

  .#{$blockname}--active.#{$blockname}#{'--variant-'+ $name} &__label
    color: theme('colors.#{$name}.800')
  .#{$blockname}--active.#{$blockname}#{'--variant-'+ $name} &__icon
    color: theme('colors.#{$name}.500')
  .#{$blockname}--active.#{$blockname}#{'--variant-'+ $name} &__content
    border-color: theme('colors.#{$name}.500')



+b(scroll-navbar-item)
  @apply  tw-flex-grow

  +e(link)
    @apply tw-inline-block tw-w-full tw-h-full

  +e(wrapper)
    @apply tw-flex tw-items-stretch tw-h-full

    &:hover
      @apply tw-bg-neutral-50


  +e(spacing)
    @apply tw-h-full
    flex-grow: 0

  &__icon
    @apply tw-text-neutral-700

  &__label
    @apply tw-font-ui tw-text-base tw-font-semibold tw-leading-tight tw-ml-2 tw-hidden
    @apply tw-text-neutral-900

  &__content
    @apply tw-flex tw-items-center tw-justify-center tw-h-full tw-border-b-2 tw-flex-grow
    @apply tw-border-transparent

  &--active &__content
    @apply tw-border-neutral-500

  // &--variant-danger &--active
  //   @apply tw-border-danger-800
  // &--variant-primary &--active
  //   @apply tw-border-primary-800
  // &--variant-purple &--active
  //   @apply tw-border-purple-800
  // &--variant-secondary &--active
  //   @apply tw-border-secondary-800


  // spacings on mobile
  &:not(:first-child)
    > a > div:first-child
      flex-basis: 10%

  &:not(:last-child)
    > a > div:last-child
      flex-basis: 10%



  @screen sm

    +e(spacing)
      flex-grow: 1
      flex-basis: auto !important

    +e(label)
      @apply tw-block




  +scroll-navbar-item-variant(success)
  +scroll-navbar-item-variant(danger)
  +scroll-navbar-item-variant(primary)
  +scroll-navbar-item-variant(secondary)
  +scroll-navbar-item-variant(purple)
