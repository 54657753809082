@import '../ProductPreview/ProductPreview'
@import '../ProductDetails/ProductDetails'
@import '../ProductActions/ProductActions'

// ---------- Product Section

+b(product-section)
  @apply tw-flex tw-mb-4 tw-flex-col tw-text-left

  &__preview-note
    @apply tw-text-sm tw-text-neutral-600

  &__preview-wrapper
    @apply tw-mb-4 tw-max-w-full tw-mt-2
    @screen md
      width: 425px

  &__section-title
    @apply tw-font-bold tw-text-base tw-mt-2 tw-text-neutral-700


  .gik-product-preview,
  .gik-product-preview-skeleton
    margin: 0 auto
    @apply tw-mb-2
    // max-width: 400px
    @screen md
      @apply tw-mr-6

  .gik-product-details,
  .gik-product-details-skeleton
    @apply tw-mb-4
    @screen md
      @apply tw-mb-6

  .gik-product-actions
    @screen md
      @apply tw-mt-4

  .gik-product-description-skeleton
    @apply tw-mt-8

  &__header-nav
    @apply tw-mb-4 tw--mt-4

  &__header
    @apply tw-mb-6 tw-mx-auto tw-max-w-full
    @screen md
      max-width: 800px
      @apply tw-flex tw-justify-center tw-items-center

  &__header-content
    @apply tw-mt-4
    @screen md
      @apply tw-mt-0
      width: 300px


  &__main
    @apply tw-mx-auto
    max-width: 672px

  &__note
    @apply tw-text-neutral-600 tw-text-base

  +b(product-preview)
    @apply tw-mb-4
    cursor: auto

  @screen md-down
    +b(carousel)
      .slick-slide
        > div
          width: 100%
          > div
            width: 100%
            height: 0
            padding-bottom: 100%
