+b(recipient-info-section)

  // override, this section has smaller padding-x
  @apply tw-px-3 #{!important}

  // reset default fieldset styles
  & fieldset > * + *
    @apply tw-mt-0

  +b(fieldset)
    @apply tw-mx-auto



