// ----- Headings
body
  h1
    @extend .gik-heading
    @apply tw-text-6xl tw-mt-4 tw-mb-4

  h2
    @extend .gik-heading
    @apply tw-text-4xl tw-mt-3 tw-mb-4

  h3
    @extend .gik-heading
    @apply tw-text-2xl tw-mt-4 tw-mb-4

  h4
    @extend .gik-heading
    @apply tw-text-xl tw-mt-2 tw-mb-2


  h5
    @extend .gik-heading
    @apply tw-text-lg tw-mt-2 tw-mb-2

  h6
    @extend .gik-heading
    @apply tw-mt-2 tw-mb-2
