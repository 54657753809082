+b(featured-inkinds)
  @apply tw-relative

  &__item
    width: 200px

  .slick-slide > div
    @apply tw-flex tw-gap-4

  .slick-slide
    @apply tw-p-4

  .slick-dots
    bottom: -16px

  &__carousel
    @apply tw-mb-4

  &__loading
    @apply tw-flex tw-items-center tw-justify-center
    @apply tw-absolute tw-z-10 tw-w-full
    background: rgba(247, 249, 249,.5)
    height: 100%


  // min-height to keep allow the loading spinner to have the same height as the cards that will appear
  min-height: 160px
  @screen md
    min-height: 344px
  @screen lg
    min-height: 424px
  @screen xl
    min-height: 484px
