+b(calendar-event-form)

  .gik-form-group--sendEmailToPageFollowers
    @apply tw-bg-neutral-50 tw-p-2 tw-mt-4 tw-text-center tw-rounded

  +b(calendar-services-picker)
    @apply tw-mb-2
    @screen md
      @apply tw-mb-0

  +b(calendar-event-type-picker)
    @apply tw-mb-2
    @screen md
      @apply tw-mb-0

  +e(event-type-section)
    @screen md
      @apply tw-flex
      > *
        @apply tw-flex-1
      > * + *
        @apply tw-ml-4

  +e(section1)
    @apply tw-flex tw-justify-between tw-items-center
    @screen md
      @apply tw-mb-0

  +e(section-number)
    @apply tw-flex

  +e(request-type-wrapper)
    @apply tw-flex tw-align-middle tw-text-neutral-900 tw-font-bold tw-text-2xl tw-leading-tight tw-items-center

  +e(request-type-icon)
    @apply tw-mr-2

  +e(request-type-name)
    @apply tw-whitespace-nowrap
    color: var(--color)

  +b(form-group--typeId)
    @apply tw-flex-1 tw-mb-3
    @screen md
      max-width: 220px
      @apply tw-mb-0

    +b(form-group--careType)
    width: 140px

  // +e(giftcards-drawer)
    // +b(giftcards-editor)
    //   @apply tw-h-full tw-flex tw-flex-col



  +b(form-group--petCareTypeId)
    @apply tw-border-l tw-border-neutral-300 tw-ml-2 tw-pl-4
    +b(select)
      width: 180px

  +b(form-group--numberRequired)
    +b(input__wrapper)
      width: 60px

  +b(form-group--allowGiftCards)
    @apply tw-mt-6 tw-mb-6 tw-text-center

  +b(gift-card-picker)
    @apply tw-mb-6

  .gik-select--disabled.gik-select-time
    .gik-select__value-container
      @apply tw-invisible

+b(calendar-edit-event-form)
  +e(edit-choice)
    @apply tw-p-4 tw-text-center tw-whitespace-pre-wrap
    hr
      @apply tw-border-none tw-mb-6 tw-mt-6
      border-top: 2px dashed #e1ecec


// .gik-notification-event-claimed
