.#{$gik-prefix}circle {
  @apply tw-bg-neutral-500 tw-inline-flex tw-items-center tw-justify-center tw-p-0;
  border-radius: 50%;

  &--primary {
    @apply tw-bg-primary-500 tw-text-white;
  }
  &--secondary {
    @apply tw-bg-secondary-500 tw-text-white;
  }
  &--default {
    @apply tw-bg-neutral-500 tw-text-black;
  }
}

//   .#{$gik-prefix}circle {
//     &--xs {
//       @apply tw-text-xs tw-w-20p tw-h-20p;
//     }
//     &--sm {
//       @apply tw-text-sm tw-w-30p tw-h-30p;
//     }
//     &--base {
//       @apply tw-text-base tw-w-40p tw-h-40p;
//     }
//     &--lg {
//       @apply tw-text-lg tw-w-50p tw-h-50p;
//     }
//     &--xl {
//       @apply tw-text-xl tw-w-60p tw-h-60p;
//     }
//     &--2xl {
//       @apply tw-text-2xl tw-w-70p tw-h-70p;
//     }
//   }
