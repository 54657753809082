+b(taxonomy-page)
  +b(by-situation-section)
    @apply tw-mb-4
  +b(by-recipient-section)
    @apply tw-mb-16
  +b(by-gift-type-section)
    @apply tw-mb-4

  &__section-taxonomy-results
    @apply tw-mt-8 tw-mb-16

  &__section-nav
    @apply tw-mt-6 tw-mb-2
    @screen md
      @apply tw-mb-6

  &__box-lead

    @screen md
      @apply tw-flex tw-items-center tw-mx-auto
      max-width: 700px

  &__box-lead-content
    @apply tw-flex-1 tw-text-center
    @screen md
      @apply tw-text-left

  &__box-lead-actions
    @apply tw-mx-auto tw-text-center

    @screen md
      @apply tw-mx-12

  &__related-articles
    @apply tw-mt-24

  &__taxonomy-results-title
    @apply tw-block tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-justify-center tw-my-8
    margin-left: 10px

  +b(carousel)
    @screen md-down
      @apply tw-mb-16
