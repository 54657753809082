+b(feature-tile)
  +e(link)
    @apply tw-flex tw-flex-row

  &--primary &__link
    @apply tw-text-primary-500

  &--danger &__link
    @apply tw-text-danger-500

  &--secondary &__link
    @apply tw-text-secondary-500

  &--success &__link
    @apply tw-text-success-500

  &--warn &__link
    @apply tw-text-warn-500


  +e(learn-more-copy)
    @apply tw-mx-2 tw-font-body tw-font-bold tw-text-base
    margin-top: 2px

  +e(learn-more-icon)
    @apply tw-h-6

  +m(reversed, link)
    @apply tw-justify-end

  +m(reversed, separator)
    @apply tw-ml-auto

  +e(separator)
    max-width: 80px

  @screen md-down
    +e(separator)
      @apply tw-mx-auto

    +m(reversed, link)
      @apply tw-justify-center

    +e(link)
      @apply tw-flex tw-justify-center
      @apply tw-flex-row #{!important}

    +e(learn-more-copy)
      @apply tw-mx-1

