@use 'sass:math';
$image-height: 160px;
$image-container-height: math.div($image-height, 2);
$border-length: 1px;
$margin-length: 14px;
$card-width: 280px;

$blockName: #{$gik-prefix}user-settings;

.#{$blockName} {
  @apply tw-flex tw-flex-col;

  &__confirm-dialog {
    width: 320px;
    height: 220px;
  }

  &__full-name {
    @apply tw-font-heading tw-text-base tw-font-semibold tw-text-neutral-800 tw-mb-2 tw-mt-1 tw-leading-normal;
  }

  &__email-address,
  &__city-state {
    @apply tw-font-body tw-text-base tw-text-neutral-600 tw-leading-tight;
  }

  &__filler {
    @apply tw-flex-1;
  }

  &__info {
    @apply tw-flex tw-flex-col tw-gap-2;
  }

  &__avatar-button {
    &.gik-button--circle {
      padding: 0;
      height: 34px;
      width: 34px;
      svg {
        @apply tw-text-black;
      }
    }
  }

  &__full-name,
  &__email-address {
    padding-left: 1em;
    padding-right: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__card-wrapper {
    @apply tw-relative tw-mb-8;
    height: 520px;
  }

  &__avatar-container {
    height: $image-container-height;
    margin-bottom: 1em;

    .loading-spinner {
      position: absolute;
      left: 0;
      right: 0;
      top: 72px;
    }

    &--editable {
      & > svg {
        cursor: pointer;
        position: absolute;
        top: 75px;
        left: 0;
        right: 0;
        color: white;
        margin-left: auto;
        margin-right: auto;
        width: 30px;
        height: auto;
      }

      .#{$blockName}__avatar {
        filter: brightness(0.5) grayscale(0.2);
        cursor: pointer;

        &:hover {
          filter: brightness(0.4) grayscale(0.2);
        }
      }
    }
  }

  &__form {
    @apply tw-flex tw-flex-1 tw-flex-col;
  }

  &__fields {
    @apply tw-flex-1 tw-gap-2 tw-flex tw-flex-col;
  }

  &__actions {
    @apply tw-flex tw-gap-2 tw-mt-4;
    > * {
      @apply tw-flex-1;
    }
  }

  &__avatar {
    @apply tw-mx-auto tw-cursor-pointer;
    bottom: $image-height - $image-container-height;
    position: relative;
  }

  &__city-state {
    margin-bottom: 2em;
  }

  &__edit-button,
  &__password-button,
  &__logout-button,
  &__delete-button {
    @apply tw-font-heading tw-text-xl tw-text-neutral-800 tw-font-medium;
    cursor: pointer;
    text-transform: uppercase;
    padding: {
      top: 10px;
      bottom: 10px;
    }
  }

  &__edit-button,
  &__password-button,
  &__logout-button {
    border-bottom: 2px dotted #dae3e3;
  }

  &__logout-button {
    color: $warning-color;
  }

  &__delete-button {
    @apply tw-bg-neutral-100 tw-text-neutral-600;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  &__back {
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
  }

  &__base {
    @apply tw-flex tw-flex-col;
    flex: 1;

    border: $border-length solid $border-color !important;
    box-shadow: 0px 0px 0px 1px rgba(59, 65, 66, 0.15);
    border-radius: 15px !important;
    min-width: $card-width !important;
    width: $card-width !important;
    align-content: center;
    justify-content: flex-start;
    text-align: center;
    background-color: white;

    margin-top: calc(#{$image-height - $image-container-height - $border-length} + #{$margin-length});
    margin-left: $margin-length;
    margin-right: $margin-length;
    margin-bottom: $margin-length;
    transition: visibility 0.7s, opacity 0.7s linear;

    height: 435px;

    .full-name-typography {
      height: 30px;
    }
  }

  &__billing-empty {
    @apply tw-bg-neutral-100 tw-mt-0 tw-flex tw-items-center tw-justify-center tw-font-bold tw-cursor-pointer;
    // NOTE: not a standard border-radius
    border-radius: 8px;
    height: 68px;
  }

  &__add-address-btn {
    @apply tw-mr-2;
  }

  &__login-provider-icon {
    &,
    & path {
      @apply tw-fill-current;
    }
  }
}

@screen md-down {
  .#{$blockName} {
    &--loading {
      --width: 94%;
    }
  }
}

.#{$blockName}-skeleton {
  --background-color: #f3f6f6;
  --height: 433px;
  --width: 300px;
  height: var(--height);
  margin: 93px 14px 0px;

  @screen md-down {
    width: 95%;
  }

  text-align: center;

  &,
  & > div {
    border-radius: 15px !important;
  }

  & > div {
    @apply tw-pl-4;
  }

  .#{$gik-prefix}circle-bone {
    --background-color: #f3f6f6;
    margin-left: auto;
    margin-right: auto;

    width: 160px;
    height: 160px;
    bottom: 80px;
    margin-bottom: -80px;
    position: relative;
  }

  .#{$blockName}__buttons {
    top: 250px !important;

    & > * {
      @apply tw-my-4;
    }
  }
}
