+b(start-inkind-card)
  @apply tw-p-8 tw-font-normal tw-text-lg tw-leading-normal tw-shadow-2xl tw-rounded-lg tw-mx-auto
  max-width: 41rem

  &__content
    @apply tw-text-center

  &__title
    @apply tw-font-bold tw-text-xl tw-leading-none tw-block tw-mb-6


