+b(landing-page-hero-section)
  +b(hero-card)
    aside
      @screen md
        margin: -4rem 0

  +e(header)
    @apply tw-p-0
    +b(container)
      @apply tw-py-8 tw-px-4
      @screen md
        @apply tw-py-16
