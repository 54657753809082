+b(partner-claim-success)
  @apply tw-text-center tw-p-4

  +b(contact-page-organizers-for-address)
    @apply tw-mx-auto tw-mt-4 tw-mb-4

  +e(check)
    @apply tw-bg-success-500 tw-text-white tw-flex
    @apply tw-items-center tw-justify-center tw-text-3xl tw-mx-auto tw-mb-4
    width: 55px
    height: 55px
    border-radius: 50%

  .gik-add-to-calendar-button
    @apply tw-mb-4


  +e(note-muted)
    @apply tw-text-neutral-600

  +e(date-inline)
    @apply tw-mb-1 tw-font-medium tw-mb-2

    +m(highlight)
      @apply tw-text-danger-500

  +e(date)
    @apply tw-mb-1 tw-font-bold tw-mb-2 tw-text-xl

    +m(highlight)
      @apply tw-text-danger-500

  +e(title)
    @apply tw-text-3xl tw-font-medium tw-leading-none tw-mb-4 tw-block

  +e(order-later)
    @apply tw--mt-2

  +e(order-now)
    @apply tw-mt-5 tw-mx-auto tw-mb-0
    max-width: 379px

