$blockName: prefix(article-page)

.#{$blockName}
  &__side-column
    @screen md
      flex: 0 0 164px
    &--left
      @screen lg-down
        flex: 0 0 0

  &__main
    @screen md
      @apply tw-mx-8

  &__article-body
    +b(container)
      @screen md
        @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
        align-content: stretch
      @screen md-down
        @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-py-0
        align-content: stretch

  &__terms
    @apply tw-font-medium tw-text-xs tw-leading-snug

  &__author-link
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full

  &__columns-3
    @screen md-down
      display: none

  +b(separator)
    max-width: 3rem
