$blockName: prefix(app-header-nav-mobile-drawer)

.#{$blockName}
  &__nav-mobile-trigger
    @apply tw-flex tw-items-center tw-p-4 tw-pr-3
    margin-top: 3px
    &:hover
      @apply tw-text-black

    +breakpoint(md)
      @apply tw-hidden
