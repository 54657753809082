+b(organizers-list-item)
  @apply tw-flex
  height: 57px

  &:not(:last-child)
    border-bottom: 1px solid theme('colors.neutral.400')

  +e(info)
    @apply tw-flex tw-flex-grow tw-items-center tw-flex-shrink-0

  +e(info-name)
    @apply tw-flex tw-flex-col tw-justify-center

  +e(name)
    @apply tw-font-body tw-text-base tw-text-neutral-900 tw-leading-tight
    // email can be long, show elipsis
    @apply tw-whitespace-nowrap tw-overflow-hidden
    max-width: calc(100vw - 120px) //subtract avatar, trash icon and paddings
    text-overflow: ellipsis

  // non standard tw color in Figma
  +e(indicator invited resend-link)
    @apply tw-font-body tw-text-xs tw-text-neutral-700 tw-font-bold tw-leading-none tw-uppercase

  +e(invited-label)
    @apply tw-font-normal

  +e(resend-link)
    &::before
      @apply tw-border-none

  +e(action-cb-1)
    @apply tw-flex tw-items-center tw-justify-start
    flex-basis: 35px

  +e(action-cb-2)
    @apply tw-flex tw-items-center tw-justify-center
    flex-basis: 35px

  +e(action-trash)
    @apply tw-flex tw-items-center tw-justify-end
    flex-basis: 35px

  @screen xs
    +e(action-cb-1 action-cb-2 action-trash)
      flex-basis: 50px

  +e(avatar)
    @apply tw-mr-2

  +e(icon)
    @apply tw-text-danger-500 tw-cursor-pointer
    +m(disabled)
      @apply tw-opacity-50 tw-cursor-not-allowed

+b(wallet-organizers-list-item)
  @apply tw-flex
  height: 57px

  +e(info)
    @apply tw-flex tw-flex-grow tw-items-center tw-flex-shrink-0

  +e(info-name)
    @apply tw-flex tw-flex-col tw-justify-center

  +e(name)
    @apply tw-font-body tw-text-base tw-text-neutral-900 tw-leading-tight
    // email can be long, show elipsis
    @apply tw-whitespace-nowrap tw-overflow-hidden
    max-width: calc(100vw - 120px) //subtract avatar, trash icon and paddings
    text-overflow: ellipsis

  // non standard tw color in Figma
  +e(indicator invited resend-link)
    @apply tw-font-body tw-text-xs tw-text-neutral-700 tw-font-bold tw-leading-none tw-uppercase

  +e(invited-label)
    @apply tw-font-normal

  +e(resend-link)
    &::before
      @apply tw-border-none

  +e(action-cb-1)
    @apply tw-flex tw-items-center tw-justify-start tw-mr-0
    flex-basis: 30px



  +e(avatar)
    @apply tw-mr-2

  +e(icon)
    @apply tw-text-danger-500 tw-cursor-pointer
    +m(disabled)
      @apply tw-opacity-50 tw-cursor-not-allowed
