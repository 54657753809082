$blockName: prefix(saved-items-empty)

.#{$blockName}

  &__preview
    @apply tw-h-72 tw-bg-neutral-100
    @apply tw-font-normal tw-text-base tw-leading-snug tw-text-neutral-700
    border-radius: $border-radius
    margin-bottom: 10px
    align-items: center
    justify-content: center
    display: flex
    padding-left: 2em
    padding-right: 2em
    text-align: center

  &__instructions svg
    color: theme('colors.heart')
    font-size: 1.25rem

  &--empty
    margin-left: 0
    margin-right: 0

  &--small
    .#{$blockName}__preview
      @apply tw-h-24
