.#{$gik-prefix}page-rows {
  max-width: 100%;

  @screen md {
    max-height: calc(80vh - 134px);
    overflow-y: auto;
  }

  .gik-page-row--organizing {
    .gik-avatar {
      @apply tw-border-2 tw-border-secondary-500;
    }
  }
}

.#{$gik-prefix}page-rows-skeleton {
  * {
    box-sizing: border-box;
  }

  &__text {
    overflow: hidden;
  }
  &__item {
    @apply tw-flex tw-items-center tw-overflow-hidden tw-px-3 tw-py-2;
  }
  &__image.gik-frame-bone {
    @apply tw-mr-2 tw-inline-block tw-flex-shrink-0;
    --width: 40px;
    --height: 40px;
  }
}
