.#{$gik-prefix}wishlist-rows-skeleton {
  * {
    box-sizing: border-box;
  }
  > *:not(:first-child) {
    @apply tw-mt-2;
  }
  &__item {
    @apply tw-flex tw-overflow-hidden;
    max-height: 100px;
  }
  &__image {
    @apply tw-mr-4 tw-inline-block tw-flex-shrink-0;
    --width: 100px;
    --height: 100px;
  }
  &__text {
    @apply tw-mt-2;
  }
}

.#{$gik-prefix}wishlist-rows {
  @apply tw-flex tw-flex-col tw-gap-2;

  > *:not(:first-child) {
    @apply tw-mt-2;
  }

  .gik-popover__reference-element {
    @apply tw-flex;
  }

  .gik-popover__reference-element,
  .gik-product-tile {
    @apply tw-w-full;
  }

  .gik-product-tile--row {
    > main {
      @apply tw-py-0;
    }
  }

  &__product-title {
    @apply tw-text-lg;
  }
}
