// modify modal header
.gik-wallet-page-redemption-modal .gik-modal__header
  @apply tw-px-0
// modify modal content
.gik-wallet-page-redemption-modal.modals.modal-v2.gik-modal--padded > .gik-modal__content-wrapper > .content
  @apply tw-px-3 tw-py-4 tw-min-h-[100%] tw-h-[100%]

.gik-wallet-page-redemption-modal .gik-product-price
  @apply tw-text-center

.gik-wallet-page-redemption-modal .gik-accordion-item__main > div
  @apply tw-p-0

.gik-wallet-page-redemption-modal .gik-accordion-item__main a
  @apply tw-no-underline

// .gik-wallet-page-redemption-modal .gik-modal__header
  // @apply tw-px-3 tw-pt-[17px] tw-pb-4
.gik-wallet-page-redemption-modal .gik-modal__header .gik-modal-header
  @apply tw-px-3 tw-pt-[17px] tw-pb-4

  // Transition to smaller header on modal scroll
.gik-wallet-page-redemption-modal.gik-modal--top-indicator .gik-modal__header .image__wrapper
  @apply tw-basis-[93px]

  img
    @apply tw-w-[93px] tw-h-[58px]

.gik-wallet-page-redemption-modal.gik-modal--top-indicator .gift-value-header__wrapper
  @apply tw-items-start

.gik-wallet-page-redemption-modal .redemption-flow__gift-value.gik-input--disabled
  @apply tw-opacity-100 tw-bg-neutral-300
  .gik-input__input
    @apply tw-text-2xl tw-font-bold tw-text-neutral-900 tw-opacity-100 tw-bg-neutral-300
  .gik-input__prepend
    @apply tw-p-0 tw-opacity-100

.gik-wallet-page-redemption-modal .redemption-flow__gift-value.redemption-flow__gift-value--has-value.gik-input--disabled .gik-input__input
  @apply tw-bg-white

.gik-wallet-page-redemption-modal .redemption-flow__gift-value.redemption-flow__gift-value--has-value.redemption-flow__gift-value--has-warning.gik-input--disabled .gik-input__input-wrapper
  @apply tw-border-2 tw-border-warn-500

.gik-wallet-page-redemption-modal .redemption-flow__gift-value.redemption-flow__gift-value--has-value.redemption-flow__gift-value--has-error.gik-input--disabled
  .gik-input__input
    @apply tw-text-danger-500
  .gik-input__input-wrapper
    @apply tw-border-2 tw-border-danger-500

.gik-wallet-page-redemption-modal
  .gik-arrow-button__prev.disabled, .gik-arrow-button__next.disabled
    @apply tw-hidden
  .gik-arrow-button__prev, .gik-arrow-button__next
    @apply tw-z-0
