
+b(calendar-event-type-tile)
  @apply tw-text-center tw-bg-white tw-shadow-sm tw-rounded tw-p-2 tw-border-3 tw-border-transparent tw-select-none

  +e(image)
    @apply tw-mx-auto tw-mb-2
    height: 40px
    @screen md
      height: 40px

  +m(vertical, image)
      @apply tw-mx-auto tw-mb-2
      height: 64px
      @screen md
        height: 64px

  +e(label)
    @apply tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-justify-center tw-min-h-8 tw--mx-3 tw-text-xl
    color: var(--color)

  +m(horizontal)
    @apply tw-flex tw-text-left tw-items-center

  +m(horizontal, image)
    @apply tw-mx-0 tw-mr-6 tw-mb-0

  +m(selected)
    @apply tw-border-primary-500 tw-border-3


+b(calendar-event-type-theme)
  +m(meal-dinner)
    --color: theme('colors.primary.600')
    --border-color: theme('colors.primary.500')
  +m(meal-lunch)
    --color: theme('colors.danger.700')
    --border-color: theme('colors.danger.500')
  +m(meal-breakfast)
    --color: theme('colors.secondary.700')
    --border-color: theme('colors.secondary.500')
  +m(pet-care)
    --color: theme('colors.warn.700')
    --border-color: theme('colors.warn.500')
  +m(groceries)
    --color: theme('colors.success.700')
    --border-color: theme('colors.success.500')
  +m(child-care)
    --color: theme('colors.info.700')
    --border-color: theme('colors.info.500')
  +m(adult-senior-care)
    --color: theme('colors.purple.700')
    --border-color: theme('colors.purple.500')
  +m(home-services)
    --color: theme('colors.pink.700')
    --border-color: theme('colors.pink.700')
  +m(transportation)
    --color: theme('colors.darkOrange.700')
    --border-color: theme('colors.darkOrange.700')
  +m(other)
    --color: theme('colors.darkPurple.700')
    --border-color: theme('colors.darkPurple.700')


