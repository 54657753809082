+b(create-page-navigation)
  @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch tw-p-3 tw-relative
  @screen md-down
    // move buttons above iOS black bar
    padding-bottom: 30px
  align-content: stretch
  box-shadow: 0px 1px 2px rgba(59, 65, 66, 0.07), 0px -5px 10px -2px rgba(59, 65, 66, 0.1), 0px -3px 5px -1px rgba(59, 65, 66, 0.1)

  +e(left-group right-group)
    +b(button)
      @apply tw-mx-1

  +e(btn-back)
    @apply tw-pl-0

  +e(btn-next)
    @apply tw-pr-0
    > * + *
      @apply tw-m-0




  +e(wrapper)
    @apply tw-relative
    +b(carousel)
      @apply tw-absolute tw-w-full
      height: 30px
      top: -37px
      .slick-list
        @apply tw-hidden
      .slick-dots
        bottom: -37px

+b(create-page-navigation__wrapper--has-dots)
  .gik-create-page-navigation
    margin-top: 30px
