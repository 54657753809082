+b(inkind-user-updates-timeline-modal)
  +b(inkind-user-update-timeline-card)
    @apply tw-mb-40
    main
      @apply tw-flex-1
      max-width: 80%

  &__load-more
    @apply tw-text-center

