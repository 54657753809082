
+b(product-details)

  &--type-fixed &__vendor
    @apply tw-ml-5
    &--external
      @apply tw-ml-0

  &__title
    h1
      @apply tw-text-3xl tw-pb-4 tw-my-0 tw-font-normal tw-font-heading tw-font-bold
    &--external h1
      @apply tw-pb-0

  &__vendor,
  &__tc-disclaimer
    @apply tw-text-base tw-leading-none tw-font-body tw-text-neutral-600 tw-font-normal tw-mt-1


  &__variations > *
    @apply tw-inline-block tw-pr-2

  &__price

    .gik-form-group__control
      @apply tw-mb-0

    .gik-form__actions:empty
      @apply tw-m-0

    &--external
      visibility: hidden
      width: 0
      height: 0

  &__button-save--small
    display: none !important

  .productType-title
    @apply tw-text-sm tw-leading-5

  .productType-description
    @apply tw-text-base tw-text-neutral-800
    margin-top: 2px

  .gik-form-group--select-list
    @apply tw-mt-0

  @screen md-down
    &__button-save--small
      display: flex !important

    &__button-save--large
      display: none !important

    &__button-save--no-buy-button
      margin-right: 15px

  &__anonymous
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > :last-child
      @apply tw-ml-1

+b(product-details-skeleton)
  &__title
    @apply tw-mb-8 tw-gap-y-4

    .gik-word-bone__bone
      @apply tw-h-6 tw-mb-8
  &__price .gik-word-bone__bone
    @apply tw-h-6

.gik-product-type-select
  @apply tw-flex tw-flex-row tw-items-center tw-justify-center
  gap: 2px
  .gik-select-list__item
    @apply tw-flex-1
    &:first-child .gik-button
      @apply tw-rounded-r-none
    &:last-child .gik-button
      @apply tw-rounded-l-none
  .gik-button
    @apply tw-w-full



