+b(inkind-group-page)
  $blockName: &
  @apply tw-pb-12

  &__toolbar
    @apply tw-mb-8
    @apply tw-flex tw-justify-between tw-items-center

  &__title
    @apply tw-font-heading tw-text-2xl tw-font-semibold tw-uppercase

  &__description
    max-width: 640px
    @apply tw-mt-4 tw-mb-12 tw-mx-auto

  &__accept-message
    @apply tw-text-center tw-pt-4 tw-pb-0
  &__section-content
    @apply tw-py-2 tw-bg-white

  &__footer
    @apply tw-font-semibold tw-text-xl tw-block tw-text-center
    > span
      @apply tw-block tw-mb-2

  .gik-input-search
    .gik-input__input-wrapper
      @apply tw-border-none
    --height: 40px

  &--muted
    @apply tw-relative
    &::after
      content: ''
      @apply tw-absolute tw-inset-0 tw-bg-white tw-opacity-70 tw-z-40

  &--muted &__section-header
    @apply tw-relative tw-z-50

  // &__section-header
  //   > .gik-container
  //     @screen md-only
  //       @apply tw-max-w-none

  +b(inkind-group-header)
    @apply tw-absolute tw-z-10 tw-inset-0

  +b(inkind-group-pages-grid)
    @apply tw-grid tw-gap-4 tw-text-center tw-mb-8

  +breakpoint(xs)
    +b(inkind-group-pages-grid)
      @apply tw-grid-cols-2

    #{$blockName}__search
      max-width: 150px

  +breakpoint(sm)
    +b(inkind-group-pages-grid)
      @apply tw-flex tw-flex-wrap
      @apply tw-gap-4

      +b(inkind-page-card-base)
        width: 206px

  +breakpoint(md)
    +b(inkind-group-pages-grid)
      +b(inkind-page-card-base)
        width: 229px

    #{$blockName}__section-content
      @apply tw-py-4

    #{$blockName}__search
      max-width: unset

  +breakpoint(lg)
    +b(inkind-group-pages-grid)
      +b(inkind-page-card-base)
        width: 228px

  +breakpoint(xl)
    +b(inkind-group-pages-grid)
      +b(inkind-page-card-base)
        width: 273px
