+b(privacy-step)
  @apply tw-px-0 #{!important}

  // override text styles from page settings
  .gik-section-title-and-description__title
    @apply tw-font-body tw-text-xl tw-font-bold tw-text-neutral-900 tw-leading-none

  .gik-section-title-and-description__description
    @apply tw-font-body tw-text-base tw-text-neutral-900 tw-leading-normal

  // override vertical spacings mobile
  .gik-section-title-and-description
    @apply tw-pt-2 tw-pb-6

  .gik-privacy-settings-section__cards
    @apply tw-mb-3

  .gik-privacy-settings-section__show-page-label, .gik-privacy-settings-section__feature-page-label, .gik-privacy-settings-section__include-search-label
    @apply tw-mb-1

  .gik-privacy-settings-section__separator
    @apply tw-my-4

  // override spacings desktop
  @screen sm
    .gik-section-title-and-description__description
      @apply tw-mb-4 tw-max-w-xs

    .gik-privacy-settings-section__cards
      @apply tw-mb-5
      .gik-privacy-settings-card:nth-of-type(1), .gik-privacy-settings-card:nth-of-type(3)
        @apply tw-mr-2
