+b(create-page-split-layout)
  height: 100%
  position: relative

  & > main
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > *
      max-width: 280px

  @screen md-down
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch

    & > main
      @apply tw-pb-4
      padding-top: CALC(38px + 2rem) // placeholder space for step pills
    & > aside
      @apply tw-flex-1

  @screen md
    @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
    align-content: stretch

    & > main
      @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
      @apply tw-px-4
      min-height: 492px - 42px

    & > aside > *
      @apply tw-py-6 tw-px-4
      min-height: 492px

    & > *
      flex: 0 0 50%
      max-width: 50%
