+b(calendar-days-list)
  @apply tw-h-full tw-overflow-auto
  @screen print
      @apply tw-overflow-visible

  > ul


    > li
      border-bottom: 1px dotted #ddd
      padding: 10px
      // @screen print
      //   @apply tw-p-0

  +e(footer-actions)
    @apply tw-text-center tw-pb-6 tw-mt-6

    +b(button)
      @apply tw-rounded-full tw-shadow-base
      // no spacing between contents
      > * + *
        @apply tw-ml-0

    > * + *
      @apply tw-ml-20

  +e(loading)
    @apply tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center
    background: rgba(255,255,255, .7)
  // slight padding adjustments needed to center the text+chevron on the fully rounded button
  +e(btn-next)
    @apply tw-pl-4
  +e(btn-prev)
    @apply tw-pr-4

  // revalidation loading overlay
  // +m(loading)
  //   &::before
  //     content: ''
  //     @apply tw-absolute tw-inset-0 tw-bg-white tw-opacity-50

  +e(loading-overlay)
    @apply tw-absolute tw-inset-0 tw-bg-white tw-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-10

  +e(error)
    @apply tw-flex tw-justify-center tw-items-center tw-flex-col tw-text-center tw-p-8
    +b(fonticon)
      font-size: 135px
    p
      @apply tw-mb-8 tw-p-4

  +e(error-details)
    @apply tw-mt-8 tw-whitespace-pre-wrap tw-text-secondary-500


+b(calendar-day)

  +m(clickable)
    @apply tw-cursor-pointer

  > ul
    @apply tw-w-full
    > li:not(:first-child)
      @apply tw-mt-3

  +e(date)
    @apply tw-flex tw-flex-shrink-0 tw-items-start tw-font-heading tw-whitespace-nowrap tw-uppercase
    @apply tw-text-neutral-800 tw-text-xl tw-leading-5 tw-font-semibold tw-mb-4 tw-select-none

  +m(empty)
    @apply tw-flex tw-flex-1
    +b(calendar-day__header)
      +b(calendar-day__date)
        @apply tw-text-neutral-600 tw-py-2 tw-mb-0

  +e(no-requests)
    @apply tw-flex tw-justify-end tw-text-neutral-500 tw-text-sm tw-flex-1 tw-content-center tw-items-center

  +e(list)
    @apply tw-w-full
    // @screen print
    //   @apply tw-inline-block

  +e(date-day-name)
    @apply tw-text-base tw-leading-none
    // @screen print
    //   @apply tw-text-base tw-font-normal

  +e(date-month)
    @apply tw-text-sm tw-text-right tw-leading-none
    // @screen print
    //   @apply tw-text-base tw-text-left

  +e(date-day)
    @apply tw-text-4xl tw-ml-1 tw-leading-none
    margin-top: -4px
    // @screen print
    //   font-size: 26px

  +e(empty)
    @apply tw-flex-1 tw-flex tw-justify-center tw-text-neutral-500 tw-uppercase tw-font-heading
    min-height: 60px

  +e(in-view)
    @apply tw-w-full
    min-height: 64px
    &:not(:first-child)
      @apply tw-mt-3


