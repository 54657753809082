+b(app-header-nav-bar)
  @apply tw-z-appNav tw-bg-white

  &--muted
    @apply tw-relative
    &::after
      content: ''
      @apply tw-absolute tw-inset-0 tw-bg-neutral-50 tw-opacity-70 tw-z-50 tw-rounded-t-md

  > .gik-container
    @apply tw-flex tw-flex-1
    height: var(--mobile-header-height)

  +breakpoint(sm)
    > .gik-container
      height: var(--desktop-header-height)

  +breakpoint(md)
    > .gik-container
      @apply tw-w-full
      max-width: unset

  +breakpoint(lg)
    > .gik-container
      max-width: 960px

  +breakpoint(xl)
    > .gik-container
      max-width: 1140px
