+b(select)
  @apply tw-relative

  +e(tabindex-and-keyboard-trigger)
    @apply tw-absolute tw-pointer-events-none #{!important}
    //@apply tw-inset-0
    top: 12px
    bottom: 12px
    left: 12px
    right: 36px
    z-index: -1
    &:-internal-autofill-previewed
      z-index: initial

  +e(autocomplete)
    @apply tw-absolute
    top: -9999px
    left: -9999px

  +e(options-container)
    max-height: 15vh
    overflow-y: auto
    @screen md
      max-height: 25vh

  +e(menu-item)
    +m(selected)
      @apply tw-bg-neutral-500 #{!important}
      +b(popover-menu-item)
        +e(label)
          @apply tw-text-white
    +m(hover suggested)
      @apply tw-bg-neutral-200 #{!important}
      +b(popover-menu-item)
        +e(label)
          @apply tw-text-neutral-800

  +e(footer-actions)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full tw-gap-4

  +e(select-trigger)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full
    text-decoration: none !important
    +b(button)
      +e(content)
        @apply tw-text-black tw-font-normal tw-font-body
        text-transform: initial

  +m(open)
    +b(button)
      @apply tw-outline-none
      @apply tw-ring-2 tw-ring-offset-2

  +m(autofilled)
    +b(button)
      background-color: #dbedff !important

  &--has-error #{b(button)}
    @apply tw-border-danger-500 tw-text-danger-500
    border-width: 2px
    padding: 3px 7px

  &--has-warning #{b(button)}
    @apply tw-border-warn-500

  &--has-success #{b(button)}
    @apply tw-border-success-500

  +b(popover)
    +e(reference-element)
      @apply tw-w-full
