/**
 * A list that is displayed much like a datalist where there is a label
 * in the left column and a value in the right column
 */
.gik-datalist {
  @apply tw-border-b tw-border-neutral-500;

  > li {
    @apply tw-border-t tw-border-neutral-500 tw-pt-3 tw-pb-2 tw-px-3;

    &.gik-datalist__muted {
      @apply tw-border-neutral-200;
    }

    > div {
      @apply tw-flex tw-flex-wrap;
      > * {
        @apply tw-flex-1;
      }
    }

    > label,
    > * > label {
      @apply tw-inline-block;
      min-width: 120px;
      width: 50%;
      @apply tw-flex-1;
      @apply tw-text-base tw-leading-relaxed tw-text-neutral-900;
    }
  }

  &__item {
    &--highlight {
      background: #ecf6f6;
    }
  }

  &__help {
    @extend .gik-text-muted;
    @apply tw-text-sm tw-block;
  }

  &__muted {
    @apply tw-border-neutral-400;
  }
}

/**
 * A list that is displayed much like a single column table
 */
.gik-tablelist {
  @apply tw-border tw-border-neutral-500;

  > li {
    @apply tw-pt-3 tw-pb-2 tw-px-3;

    &:not(:first-child) {
      @apply tw-border-t tw-border-neutral-500;
    }

    &.gik-datalist__muted {
      @apply tw-border-neutral-200;
    }

    > label,
    > * > label {
      @apply tw-inline-block tw-mr-2;
    }
  }

  &__help {
    @apply tw-text-sm tw-block;
    @extend .gik-text-muted;
  }

  &__muted {
    @apply tw-border-neutral-400;
  }
}

.gik-ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.gik-ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

.gik-list-circle {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
