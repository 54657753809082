$blockName: prefix(blog-nav)

.#{$blockName}
  @apply tw-bg-white

  @screen md
    min-height: var(--desktop-blog-header-height)
  @screen md-down
    min-height: var(--mobile-blog-header-height)

  &__title
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-neutral-800
    @screen md-down
      @apply tw-text-xl tw-ml-4
  &__container
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch tw-flex-1
    align-content: stretch

  &__menu
    > * + *
      @apply tw-ml-6
    +b(menu-bar)
      +e(title)
        @apply tw-font-body tw-font-normal tw-text-base tw-leading-none tw-text-neutral-800
    &--mobile
      @apply tw-mr-4

  &__menu
    @screen md
      @apply tw-flex
    @screen md-down
      @apply tw-hidden
    &--mobile
      @screen md
        @apply tw-hidden
      @screen md-down
        @apply tw-flex

  &__preview-mode-container
    @apply tw-text-danger-500

  &--preview-mode
    .#{$blockName}__title
      @apply tw-my-0

  .gik-menu-item
    border-bottom: 1px solid rgba(239, 243, 243)
    margin-bottom: -1px
    +e(label)
      @apply tw-pl-4
  .gik-menu-item, .gik-menu-category-title
    +e(label)
      @apply tw-font-body tw-font-normal tw-text-base

  .gik-input__input-wrapper
    @apply tw-bg-white
