$blockName: #{$gik-prefix}button-bone;

.#{$blockName} {
  @apply tw-cursor-default tw-border-transparent;
  --gradient-angle: 90deg;
  --color-primary: theme('colors.neutral.200');
  --color-accent: theme('colors.neutral.50');
  --bone-animation-speed: 90s;

  background: $bone-gradient;
  background-size: 200px;
  background-repeat: revert;
  animation: #{$gik-prefix}bone-animation var(--bone-animation-speed) linear infinite;

  .gik-word-bone__bone {
    animation: none;
    background: none;
  }
}
