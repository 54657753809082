+b(contact-tile)
  @apply tw-flex tw-flex-col tw-items-center
  max-width: 335px

  +e(title)
    @apply tw-text-center tw-font-heading tw-uppercase tw-font-medium tw-text-2xl tw-leading-7 tw-text-neutral-800

  +e(content)
    @apply tw-text-center tw-text-neutral-600 tw-text-base tw-font-normal tw-font-body

  +b(button)
    @apply tw-mt-3
