@import '../RecipientInfoStep/RecipientInfoAddressForm'


+b(name-your-page-step)
  @apply tw-text-center tw-p-0
  @apply tw-h-full tw-px-4
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  .gik-create-section-title
    @apply tw-text-white

  @screen md
    min-height: 370px

  +e(ideasBtn)
    @apply tw-mt-4 tw-font-normal

  form#CreateNameYourPageStepForm
    @apply tw-flex tw-flex-1
    & > div:first-child
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
      align-content: stretch
      @apply tw-flex-1
      & > *:first-child
        @apply tw-flex-1

  +e(name-your-page-section)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch
    @apply tw--mx-4 tw-py-8

    > div
      max-width: 350px

  +e(page-name-description)
    @apply tw-text-base tw-leading-tight tw-pb-2
    @screen md
      @apply tw-mx-2

  +e(recipient-address)
    @apply tw--mx-4 tw-py-4
    .gik-checkbox
      @include gik-recipient-info-address-form
      +e(label)
        & > div > span:first-of-type
          @apply tw-font-normal tw-leading-none
        & > div > span:nth-of-type(2)
          @apply tw-font-normal tw-text-sm tw-leading-none tw-text-neutral-700

  +b(form)
    @apply tw-z-20
    +e(actions)
      display: none

  & > .gik-container
    @apply tw-flex tw-flex-1

  +b(container)
    z-index: unset

