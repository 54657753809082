+b(calendar-event-type-tile-announcement)
  @apply tw-border-2 tw-border-transparent tw-select-none tw-shadow-sm tw-rounded tw-relative tw-p-2
  @apply tw-border-secondary-500

  > header
    @apply tw-flex-1 tw-flex tw-items-center tw-justify-center tw-rounded-l tw-text-center tw-mb-2
    @screen sm
      @apply tw-text-left tw-mb-0

  > main
    @apply tw-flex-1
    > div
      @apply tw-border-3 tw-border-transparent tw-rounded tw-bg-white tw-shadow-sm
      @apply tw-p-1 tw-flex
    @screen sm
      @apply tw-ml-2

  +m(vertical, image)
      @apply tw-mx-auto tw-mb-2
      height: 64px
      @screen md
        height: 64px

  +e(title)
    @apply tw-block tw-text-xl tw-font-bold
    margin-bottom: -6px

  +e(description)
    @apply tw-text-neutral-600 tw-text-xs

  +e(boxtitle)
    @apply tw-text-white tw-bg-secondary-500 tw-px-2 tw-absolute tw-top-0 tw-left-0 tw-text-xs tw-rounded-br tw-font-bold
    > span
      @apply tw-relative
      top: -1px
      left: -2px

  +e(label)
    @apply tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-justify-center tw-min-h-8 tw-ml-2 tw-text-xl
    @screen sm
       @apply tw--mx-3

  +m(horizontal)
    @screen sm
      @apply tw-flex tw-text-left tw-items-center

  +m(horizontal, image)
    @screen sm
      @apply tw-mx-0 tw-mr-6 tw-mb-0

  +m(selected)
    > main > div
      @apply tw-border-primary-500 tw-border-3

  @screen sm
    @apply tw-flex
