+b(login-form-section)
  @apply tw-text-base tw-text-neutral-700 tw-pt-8
  font-size: 18px

  +e(title)
    @apply tw-mt-6 tw-mb-4 tw-text-center tw-font-bold tw-block

  .external-login-form-wrapper
    @apply tw-mb-4

+b(login-modal)
  &.gik-modal.modal-v2
    .content
      @screen md-down
        @apply tw-px-4
    +b(modal-button-footer)
      @apply tw-px-6 tw-pb-6 tw-pt-2
