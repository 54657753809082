+b(thank-you-form)


  .gik-thank-you-background-picker, .gik-form-group--preview
    max-width: 390px
    @apply tw-mx-auto

    @screen md
      max-width: none

  .gik-form-group__field-extra
    // NOTE: single use styling
    @apply tw-text-sm tw-text-neutral-800 tw-mt-1

  +b(thank-you-card)
    .gik-input__input-wrapper
      @apply tw-bg-none tw-border-0
    .gik-input__input
      @apply tw-bg-transparent tw-border-0 tw-text-white tw-font-bold tw-p-0
    .gik-textarea__wrapper
      @apply tw-bg-none tw-border-0
    .gik-textarea__textarea
      @apply tw-bg-transparent tw-border-0 tw-text-white tw-font-bold tw-p-0
      line-height: 120% //22px
    .gik-form-group__error, .gik-form-error
      @apply tw-bg-neutral-400 tw-p-1 tw-rounded-md
      --tw-bg-opacity: .7

    .gik-form-group--signature
      .gik-input__input
        @apply tw-text-right


  .gik-form-group--preview
    @apply tw-pb-2
    @screen md
      @apply tw-px-6

    .gik-form-group__label-wrapper
      @apply tw-px-6
      @screen md
        @apply tw-px-0

  &__bg-picker--disabled
    @apply tw-opacity-50 tw-pointer-events-none

  &__message-header
    @apply tw-mb-2 tw-select-none

  &__message-footer
    @apply tw-mt-2 tw-text-right tw-select-none

  &__bg-picker
    @apply tw-mx-2 tw-relative
    // .gik-button--premium-locked

  &--locked &__bg-picker
    .gik-color-swatch:not(.gik-color-swatch--active)
      @apply tw-opacity-30
    .gik-thank-you-background-picker
      @apply tw-pointer-events-none

    .gik-button--premium-locked .gik-svg-icon
      width: 1rem
      height: 1rem


  &__input
    @screen md
      @apply tw-flex
  &__form
    @apply tw-flex-1
    // @screen md
      // min-width: 300px

  &__actions
    @apply tw-flex tw-justify-between tw-flex-1
  &__upgrade
    @apply tw-absolute tw-inset-0 tw-text-center tw-z-10 tw-pt-3
    margin-left: 57px

.modals.modal-v2.gik-thank-you-form-modal
  @screen md
    width: 700px
