+b(inkind-user-updates)
  @apply tw-mb-4

  +e(cards-wrapper)
    @apply tw-relative tw--mx-2

  +e(navigation)
    @apply tw-absolute
    top: 35px
    $horizontal-align: 30px

    +m(prev)
      left: -$horizontal-align
    +m(next)
      right: -$horizontal-align

  +e(header-container)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(cards)

    .slick-list
      @apply tw-overflow-hidden tw-pb-4

    .slick-slide
      +b(inkind-user-update-card)
        @apply tw-m-2

      & > div
        @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
        align-content: stretch

  +e(user-update-card-skeleton)
    @apply tw-h-24 tw-rounded tw-w-full tw-my-4

  +e(sign-in-button-container no-updates-button-container)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-absolute tw-inset-0 tw-m-auto
    @apply tw-pb-4
    z-index: theme('zIndex.above')

  +e(title-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-ml-2
    @apply tw-min-h-10
    & > *:first-child
      @apply tw-mr-2

  +e(skeleton-wrapper)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
    align-content: stretch
    @apply tw-mx-2
    & > *
      &:first-child
        @apply tw-mr-2
      &:last-child
        @apply tw-ml-2

  +e(see-all-updates)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-h-full
    +b(button)
      @apply tw-h-full

  +b(arrow-button)
    +e(next prev)
      top: calc(50% - 18px)
    +e(next)
      right: -5px
    +e(prev)
      left: -5px
