+b(signup-form)
  @apply tw-text-left

  +e(buttons)
    @apply tw-text-center tw-w-full

  .gik-button.gik-signup-form__btnSubmit
    @apply tw-text-xl
    @screen md
      height: $ui-height

  +e(fullNameFields)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch
    & > *
      @apply tw-flex-1

  +e(tos)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-center tw-mt-4 tw-italic

  .gik-form-group--checkbox
    @apply tw-mt-2

.gik-signup-form__buttons
  .gik-signup-form__btnSubmit
    @screen md
      height: $ui-height
