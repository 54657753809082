+b(stream-post-background-picker)
  @apply tw-relative
  height: 32px
  width: 32px

  +b(color-swatch)
    @apply tw-cursor-pointer
    width: 32px
    height: 32px

  &__bg-picker
    @apply tw-w-full tw-transition-opacity tw-opacity-0 tw-absolute tw-top-0 tw-left-0 tw-flex tw-flex-shrink-0 tw-justify-between tw-pointer-events-none
    z-index: 1

  .gik-stream-background-picker-button
    @apply tw-absolute tw-top-0 tw-left-0 tw-z-10
    width: 32px !important
    height: 32px !important
    font-size: 1.3rem !important

  &--open &__bg-picker
    @apply tw-opacity-100 tw-absolute tw-top-0 tw-left-0 tw-pointer-events-auto

  &--open &__btn-toggle
    @apply tw-opacity-0 tw-absolute tw-top-0 tw-left-0 tw-z-0 tw-pointer-events-none


.gik-base-attach-button
  width: 24px !important
  height: 24px !important
  font-size: 1rem !important
  @apply tw-border-0 tw-transition-opacity tw-opacity-100 tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-relative tw-select-none


.gik-stream-background-picker-button
  @extend .gik-base-attach-button
  background: conic-gradient(from 180deg at 50% 50%, #41CBCE 0deg, #FFD368 90deg, #9F57C4 180deg, #EF5C84 270deg, #41CBCE 360deg)
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.51)
