+b(benefit-tile)
  @apply tw-flex tw-flex-col tw-items-center tw-mb-12 tw-text-neutral-900
  max-width: 280px

  +e(icon)
    @apply tw-mb-6 tw-w-12

  +e(title)
    @apply tw-text-xl tw-font-bold tw-text-center tw-tracking-normal

  +e(copy)
    @apply tw-text-center tw-text-base tw-leading-tight

  @screen md-down
    max-width: 180px
