+b(page-feature-card)
  --border-color: theme('colors.transparent')
  @apply tw-shadow-xl tw-rounded-md tw-bg-white tw-w-full
  @apply tw-transition-all tw-ease-in-out tw-duration-200
  border: 2px solid var(--border-color)
  max-width: 598px

  +e(main)
    @apply tw-flex tw-flex-row
    position: relative

  +e(secondary-content)
    @apply tw-bg-neutral-50 tw-rounded-b-md
    margin: -2px

    +m(no-secondary-content-background)
      background: none

  +e(copy-container)
    @apply tw-flex tw-flex-1 tw-flex-col tw-justify-center

  +e(title)
    @apply tw-font-heading tw-text-xl tw-text-neutral-900 tw-leading-tight tw-text-left
    // TODO !important can be removed once in full SPA
    @apply tw-font-semibold #{!important}

  +e(description)
    @apply tw-font-body tw-text-base tw-text-neutral-900 tw-leading-tight tw-text-left
    max-width: 288px

    +m(has-asterisk)
      &:after
        content: '*'

  +e(checkbox)
    @apply tw-absolute
    top: 1rem
    right: 1rem

  +e(icon)
    @apply tw-m-6 tw-mr-4
    width: 80px
    height: 80px
    &, & *
      fill: theme('colors.primary.500')

  +e(notes)
    @apply tw-font-normal tw-text-xs tw-leading-none tw-text-neutral-700
    @apply tw-absolute tw-bottom-0
    right: 0.5rem // tw-right-2 doesnt exist?

    &:before
      content: '*'

  +m(disabled)
    @apply tw-bg-neutral-50
    box-shadow: none // no tw-shadow-none ?
    --border-color: theme('colors.neutral.200')

  +m(disabled, icon)
    &, & *
      fill: theme('colors.neutral.500')

  @screen md-down
    max-width: 260px
    +e(icon)
      @apply tw-m-0 tw-mb-2

    +e(main)
      @apply tw-flex tw-flex-col tw-box-border tw-items-center tw-content-center tw-justify-start tw-text-center tw-max-w-full
      @apply tw-p-10

    +e(title description)
      @apply tw-text-center

    +e(copy-container)
      max-width: 200px

    +e(notes)
      @apply tw-inset-x-0

    +e(description)
      @apply tw-text-sm tw-leading-normal
