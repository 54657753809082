+b(inkind-page-information)
  @apply tw-mb-8
  +e(story-wrapper special-notes-wrapper)
    +b(animated-div-bone)
      @apply tw-w-full

  +e(title-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @apply tw-min-h-8
    & > *:first-child
      @apply tw-mr-2

  +e(edit-inkind-page-information)
    @screen md
      min-width: 600px

  +e(intro special-notes-content)
    p
      @apply tw-whitespace-pre-wrap
      & > a
        @apply tw-text-primary-600
        &:hover
          @apply tw-underline

    +m(empty)
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-rounded
      @apply tw-bg-neutral-100
      @apply tw-mt-4

      p
        @apply  tw-font-normal tw-text-sm tw-leading-snug tw-text-neutral-700 tw-text-center tw-mx-auto
        width: 24rem
        @screen md-down
          @apply tw-p-6

  +e(intro)
    +m(empty)
      min-height: 18.75rem // no tailwind preset

  +e(special-notes-content)
    +m(empty)
      min-height: 5rem // no tailwind preset
