
+b(select-list)
  @apply tw-p-0
  > *
    @apply tw-cursor-pointer tw-outline-none
    &:focus
      @apply tw-outline-none

  +m(flex)
    @apply tw-flex

