+b(color-handle)
  @apply tw-cursor-pointer

  svg
    filter: drop-shadow( 0px 0px 1px rgba(0, 0, 0, 0.3))

  &--active svg
    filter: drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.6))
    path
      stroke: white
      stroke-width: 3


