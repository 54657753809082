$blockName: '#{$gik-prefix}notification';

@mixin notification-variants($name, $paletteName) {
  &--#{$name} {
    @apply tw-text-white tw-bg-#{$paletteName}-900;

    .gik-text-muted {
      @apply tw-text-#{$paletteName}-500;
    }

    > svg {
      @apply tw-text-#{$paletteName}-400;
    }
  }
}

.#{$blockName} {
  @apply tw-py-1 tw-pl-1 tw-pr-1 tw-leading-tight tw-inline-flex tw-whitespace-nowrap tw-items-center tw-align-middle tw-rounded-2xl;

  min-height: $ui-height;
  // padding-top: 2px;

  // space between contents
  > * + * {
    @apply tw-ml-1;
  }

  // ---------- Elements

  &__prepend {
    @apply tw-ml-1;
    @screen md {
      @apply tw-inline-block;
    }
  }

  &__append {
    @apply tw-mr-2;
    @screen md {
      @apply tw-inline-block;
    }
  }

  &__content-wrapper {
    @apply tw-ml-1;
    @screen md {
      @apply tw-flex tw-items-center;
    }
  }
  &__content {
    @apply tw-block tw-items-center tw-whitespace-normal tw-pl-1 tw-pr-1 tw-align-middle;

    @screen md {
      @apply tw-mb-2;
    }

    > header {
      @apply tw-font-heading tw-font-bold;
    }

    @screen md {
      @apply tw-mb-0;
    }
  }

  &__close {
    @apply tw-inline-block tw-cursor-pointer tw-text-lg tw-ml-2 tw-mr-1;
    opacity: 75%;
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .gik-fonticon {
    margin-bottom: 2px;
  }

  > img,
  > a > img {
    border-radius: 24px;
  }

  // ---------- Modifiers

  @include notification-variants('default', 'neutral');
  @include notification-variants('secondary', 'secondary');
  @include notification-variants('warning', 'warn');
  @include notification-variants('success', 'success');
  @include notification-variants('info', 'info');
  @include notification-variants('purple', 'purple');

  &--error {
    @apply tw-text-white;
    background: #6c112a;

    .gik-text-muted {
      @apply tw-text-neutral-500;
    }

    > svg {
      @apply tw-text-white;
    }
  }

  &--primary {
    @apply tw-text-white tw-bg-primary-600;

    .gik-text-muted {
      @apply tw-text-neutral-500;
    }

    > svg {
      @apply tw-text-white;
    }
  }

  &--premium-unlocked {
    /* TODO: move bold/leading-none to be a notification option */
    @apply tw-text-secondary-600 tw-bg-white tw-shadow-lg tw-font-bold tw-text-base tw-leading-none;

    > svg {
      @apply tw-text-secondary-600;
    }
  }

  &--premium-locked {
    /* TODO: move bold/leading-none to be a notification option */
    @apply tw-text-white tw-font-bold tw-text-base tw-leading-none;
    @include gradient-warm();

    > svg {
      @apply tw-text-white;
    }
  }

  &--lightblue {
    @apply tw-text-white tw-bg-info-700;

    .gik-text-muted {
      @apply tw-text-neutral-500;
    }

    > svg {
      @apply tw-text-white;
    }
  }

  &--purple {
    @apply tw-text-white tw-bg-purple-500;

    .gik-text-muted {
      @apply tw-text-neutral-500;
    }

    > svg {
      @apply tw-text-white;
    }
  }

  &--green {
    @apply tw-text-white tw-bg-success-700;

    .gik-text-muted {
      @apply tw-text-neutral-500;
    }

    > svg {
      @apply tw-text-white;
    }
  }

  &--click-through {
    @apply tw-cursor-pointer;
    a {
      @apply tw-no-underline tw-flex tw-items-center;
      color: inherit;
    }
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs;
  }

  &--size-sm {
    @apply tw-text-sm;
  }

  &--size-base {
    @apply tw-text-base;
  }

  &--size-lg {
    @apply tw-text-lg;
  }

  &--size-xl {
    @apply tw-text-xl;
  }
}
