.react-joyride__overlay
  background-color: rgba(255, 255, 255, 0.7) !important

+b(joyride-tooltip)
  @apply tw-bg-white tw-shadow-2xl tw-p-4 tw-rounded
  min-width: 200px
  max-width: min(400px, 80vw)

  +e(btn-skip)
    svg
      width: 18px
      height: 18px

  +e(actions)
    @apply tw-flex tw-justify-between tw-items-end

    +b(button)
      @apply tw-shadow-none
      +e(content)
        @apply tw-text-neutral-700

  +e(actions-right)
    +b(button)
        @apply tw-shadow-base
    > * + *
      @apply tw-ml-2

  +e(check-icon)
    @apply tw-text-white

  +e(btn-close)
    +m(flashing)
      animation: tour-btn-flashing 2s ease infinite

  // theme('colors.primary.500'), #41cbce
  // need to use it with rgba because of alpha 0.5
  @keyframes tour-btn-flashing
    0%
      box-shadow: 0 0 0 0rem rgba(65, 203, 206, 0.5)
    50%
      box-shadow: 0 0 0 1rem rgba(65, 203, 206, 0.5)
    100%
      box-shadow: 0 0 0 0rem rgba(65, 203, 206, 0.5)

