+b(inkind-page-settings-modal)

  // same fix for z-index for Intercom like in Create
  +e(body-modal-open)
    +b(dialogs)
      @apply tw-z-belowIntercom #{!important}

  // NOTE: fix for blurry fonts in Chrome caused by translate(-50%, -50%)
  @screen md
    transform: translateZ(0) translate(-50%, -50%) !important
    will-change: contents

  // override text-center from modals content
  // maybe dialog modifiers can handle this
  .content
    @apply tw-text-left tw-p-0 #{!important}

  // modify modal header, integrate scroll navbar
  .gik-modal__header
    @apply tw-px-0

  .gik-modal-header
    @apply tw-p-0 tw-h-auto #{!important}
    +b(button)
      // make the button absolute so it doesn't push down the header height
      @apply tw-absolute

  .gik-modal-header__title-row
    @apply tw-p-4 tw-mb-0 tw-shadow-base tw-z-above tw-relative #{!important}

  .more-content-indicator.top
    box-shadow: none !important

  @screen md
    min-width: 740px
    max-height: min(95vh, 700px) !important

  // reset default dialog footer padding
  .gik-modal__toolbar
    @apply tw-p-0
