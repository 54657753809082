+b(inkind-page)
  +e(header)
    @apply tw-py-4

  +e(header-inner)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
    align-content: stretch

  +e(profile-picture)
    height: 220px
    width: 220px
    margin-right: 2rem
    @apply tw-bg-primary-200

  +e(info-container)
    @apply tw-flex tw-flex-1 tw-flex-col tw-justify-between tw-items-start tw-content-start

  +e(controls)
    @apply tw-flex tw-flex-col tw-justify-between tw-items-end tw-content-end

  +e(right-sidebar)
    //@apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    //align-content: stretch

    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
    flex: 0 0 218px

    +b(button)
      @apply tw-my-1

  +e(updates)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start
    flex: 1
    @apply tw-pr-4

    +b(separator)
      width: 100%

  +e(content)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
    align-content: stretch

  +e(section-title)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full

    +b(button)
      @apply tw-ml-4

  +b(inkind-calendar)
    @apply tw--mx-4
    @screen sm
      @apply tw--mx-8
    @screen md
      @apply tw-mx-0


  +b(stream-posts)
    @apply tw-mt-12
    @apply tw--mx-4

    @screen md
      @apply tw-mx-0 tw-px-0

  +e(care-calendar-wrapper)
    @apply tw-flex-1 tw-pr-4

  +e(care-calendar)
    @apply tw-my-2 tw-shadow-base tw-rounded

  +e(care-calendar-header)
    height: 40px
    @apply tw-bg-primary-500

  +e(care-calendar-body)
    height: 560px
    background-color: rgba(225, 236, 236, 0.9)

  +e(update-cards)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start
    width: 100%

  +e(update-card)
    height: 100px
    @apply tw-shadow-base tw-flex-1 tw-rounded-lg tw-my-4 tw-mr-2

  +e(wishlist-card article-card)
    flex: 0 0 100px
    @apply tw-shadow-base tw-rounded-lg tw-my-2

  +e(related-products)
    @apply  tw-pt-5 tw-pb-8
    +b(carousel)
      @apply tw-pb-0

    +b(product-tile)
      @apply tw-mb-16
      min-height: 338px

  +b(inkind-supporters-button)
    @apply tw-mb-4

