+b(devtools)
  @apply tw-fixed tw-inset-x-0 tw-bottom-0 tw-z-devtools

  @screen md-down
    @apply tw-w-full

  &__container
    @apply tw-w-full tw-fixed tw-right-0

    @screen md
      @apply tw-w-auto tw-text-left tw-max-h-screen

  &__inner
    @apply tw-max-h-screen
    transition: transform 200ms ease-in-out
    transform: translate3d(0,-32px, 0)

  &--open &__inner
    transform: translate3d(0,-100%, 0)

  &__header
    @apply tw-flex tw-items-end tw-justify-items-end tw-z-below tw-relative
    width: 52px
    margin-left: auto

  &__main
    @apply tw-bg-neutral-300 tw-rounded-tl tw-overflow-auto
    @apply tw-relative
    z-index: 1

    // max-height: calc(100vh - 26px)

    @screen md-down
      @apply tw-w-full
    @screen md
      min-width: 400px
      // min-height: 200px

  &__logo
    @apply tw-inline-flex tw-items-center tw-justify-center
    @apply tw-p-1 tw-bg-neutral-300 tw-ml-auto tw-rounded-t tw-mr-2
    @apply tw-cursor-pointer

    height: 34px
    width: 34px
    svg
      @apply tw-text-danger-500

  &__nav
    @apply tw-text-left tw-mb-2
  &__nav-item
    @apply tw-p-2 tw-cursor-pointer
    &:hover
      @apply tw-bg-neutral-300

  .gik-tabbed-view__tab-content
    @apply tw-p-0  tw-overflow-auto
    max-height: calc(100vh - 56px)

  .gik-tabbed-view__tabs-section
    @apply tw-border-b tw-border-neutral-500

    .gik-tabbed-view__tab
      @apply tw-px-2 tw-py-1 tw-overflow-auto
