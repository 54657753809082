$blockName: '#{$gik-prefix}situations-overview-grid';

.#{$blockName} {
  justify-content: center;

  .gik-situation-tile {
    width: 200px;
    @apply tw-mb-6;
  }

  &__feature_title {
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-mb-8;
    @screen md {
      @apply tw-mb-8;
    }
  }

  @screen md-down {
    .gik-situation-tile {
      width: 160px;
    }
  }
  @screen sm-down {
    .gik-situation-tile {
      width: 119px;
    }
  }
}
