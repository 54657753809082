$blockName: '#{$gik-prefix}avatar';

@mixin avatar-variants($name, $paletteName) {
  &--#{$name} {
    --color: theme('colors.white');
    --bg: theme('colors.#{$paletteName}.500');
  }

  color: var(--color) !important; // TODO: Remove `!important` after fixing MVC styles
  background-color: var(--bg);
}

@mixin avatar-size-xs {
  @apply tw-text-xs tw-px-2;
  height: 20px;
  width: 20px;
}

@mixin avatar-size-sm {
  @apply tw-text-sm tw-px-2;
  height: 30px;
  width: 30px;
}

@mixin avatar-size-lg {
  @apply tw-text-lg tw-px-3;
  height: 50px;
  width: 50px;
}

@mixin avatar-size-xl {
  @apply tw-text-xl tw-px-6;
  height: 60px;
  width: 60px;
}

@mixin avatar-size-2xl {
  @apply tw-text-xl tw-px-6;
  height: 120px;
  width: 120px;
}

@mixin avatar-size-3xl {
  @apply tw-text-xl tw-px-6;
  height: 160px;
  width: 160px;
}

@mixin avatar-size-4xl {
  @apply tw-text-xl tw-px-6;
  height: 220px;
  width: 220px;
}

.#{$blockName} {
  --bg: transparent;
  --color: #fff;

  @apply tw-text-base tw-px-3;
  height: 40px;
  @apply tw-inline-flex tw-items-center tw-justify-center;
  background-size: cover;
  background-position: center;

  background-color: white; // start with a fallback

  &--block {
    // @apply tw-flex;
  }

  &--impersonating {
    @apply tw-border-3 tw-border-danger-500;
  }

  &--loading {
    background-image: none !important;
  }

  .gik-circle-bone {
    --width: 100%;
    --height: 100%;
    @apply tw-flex-grow-0 tw-flex-shrink-0;
  }

  @include avatar-variants('default', 'neutral');
  @include avatar-variants('primary', 'primary');
  @include avatar-variants('secondary', 'secondary');

  // contextual variants
  @include avatar-variants('danger', 'danger');
  @include avatar-variants('warning', 'warn');
  @include avatar-variants('success', 'success');

  // ----- Sizes
  &--size-xs {
    @include avatar-size-xs();
  }

  &--size-sm {
    @include avatar-size-sm();
  }

  &--size-lg {
    @include avatar-size-lg();
  }

  &--size-xl {
    @include avatar-size-xl();
  }

  &--size-2xl {
    @include avatar-size-2xl();
  }

  &--size-3xl {
    @include avatar-size-3xl();
  }

  &--size-4xl {
    @include avatar-size-4xl();
  }

  // ----- Shapes
  &--squared {
    @apply tw-rounded-none;
    &:before {
      @apply tw-rounded-none;
    }
  }

  &--rounded {
    @apply tw-rounded;
    &:before {
      @apply tw-rounded;
    }
  }

  &--pill {
    @apply tw-rounded-full;
    &:before {
      @apply tw-rounded-full;
    }
  }

  &--show-border {
    border: 2px solid var(--bg);
  }

  &__toolbar {
    @apply tw-absolute;
    right: 0;
    bottom: 0;
  }

  &--circle {
    @apply tw-inline-flex tw-items-center tw-justify-center tw-p-0;
    border-radius: 50%;

    &:before {
      border-radius: 50%;
    }

    &.#{$blockName}--size-xs {
      width: 20px;
      height: 20px;
      @apply tw-text-xs;
    }

    &.#{$blockName}--size-sm {
      width: 30px;
      height: 30px;
      @apply tw-text-base;
    }

    &.#{$blockName}--size-base {
      width: 40px;
      height: 40px;
      @apply tw-text-2xl;
    }

    &.#{$blockName}--size-lg {
      width: 50px;
      height: 50px;
      @apply tw-text-3xl;
    }

    &.#{$blockName}--size-xl {
      width: 60px;
      height: 60px;
      @apply tw-text-4xl;
    }
  }
}

@include b(author-avatar) {
  &.#{$blockName}.#{$blockName}--size-base {
    @screen md {
      @include avatar-size-3xl();
    }
    @screen md-down {
      @include avatar-size-2xl();
    }
  }
}
