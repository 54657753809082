/**
 * This injects Tailwind's base styles and any base styles registered by
 * plugins.
 */
@tailwind base;

/**
  * This injects Tailwind's component classes and any component classes
  * registered by plugins.
  */
// @tailwind components;

/**
  * This injects Tailwind's utility classes and any utility classes registered
  * by plugins.
  */
@tailwind utilities;

/**
  * Use this directive to control where Tailwind injects the responsive
  * variations of each utility.
  *
  * If omitted, Tailwind will append these classes to the very end of
  * your stylesheet by default.
  */
@tailwind screens;
