$blockName: '#{$gik-prefix}accordion';

.#{$blockName} {
  > hr {
    @apply tw-m-2 tw-border-neutral-900;
    opacity: 0.08;
  }

  > .#{$blockName}-item--open + hr {
    @apply tw-mt-0;
  }

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  &--dividers {
    .#{$blockName}-item:not(:last-child) {
      @apply tw-border-b tw-border-neutral-100;
    }
  }
}

.#{$blockName}-item {
  &__header {
    @apply tw-flex tw-py-4 tw-cursor-pointer tw-text-neutral-900 tw-select-none;
    @extend .gik-no-tap-effect;
  }
  &__header--no-padding {
    @apply tw-p-0;
  }

  &__main {
    @apply tw-overflow-hidden tw-h-0;
    > div {
      @apply tw-p-2 tw-font-normal tw-text-lg tw-leading-normal;
    }

    a {
      @apply tw-underline;
    }

    transition: height 0.3s ease-out;
  }

  &__title {
    @apply tw-flex-1 tw-font-body tw-font-semibold tw-text-lg tw-leading-tight tw-text-neutral-900 tw-mr-4 tw-block tw-my-0;
    a {
      @apply tw-font-semibold tw-underline;
    }
  }

  &__toggle-icon {
    @apply tw-relative tw-flex tw-items-center;
    top: -3px;
    > svg {
      transition: transform 0.3s ease-out;
    }
  }

  &--open &__toggle-icon {
    > svg {
      transform: rotate(180deg);
    }
  }
}
