$blockName: prefix(app-header-nav)

.#{$blockName}
  @apply tw-hidden tw-flex-1 tw-justify-end
  > li
    @apply tw-flex tw-items-center tw-flex-shrink-0

  > li + li
    @apply tw-ml-2


  &__nav-search
    @apply tw-flex-1 tw-justify-end tw-block
    > a
      @apply tw-mr-2 tw-z-10 tw-relative

  &__btn-start
    @apply tw-mr-2 tw-flex-shrink-0

  &__user-avatar-container
    .gik-popover__reference-element
      height: 100%

  &--search-expanded &__nav-search
    @apply tw-mr-4

  .gik-popover__reference-element
    display: inline-flex

  .gik-menu__item
    @apply tw-flex tw-items-center
    min-height: 37px

  .gik-menu-bar
    @apply tw-mr-2

  .gik-avatar
    @apply tw-cursor-pointer

  .item-dropdown .gik-popover__reference-element
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900 tw-px-2
    margin-top: 5px
    > div
      @apply tw-flex tw-align-middle

    svg
      @apply tw-ml-2 tw-text-neutral-600
      margin-top: -2px

  +breakpoint(md)
    @apply tw-flex
    .#{$gik-prefix}input-search
      @apply tw-hidden

    & .#{$blockName}--search-expanded .#{$gik-prefix}input-search
      @apply tw-block
      width: 100%

    & > li + li
      @apply tw-ml-0

    & .#{$blockName}__btn-start > span
      @apply tw-text-sm

    & .#{$blockName}__nav-search
      @apply tw-mr-2
      > a
        @apply tw-mr-4

    & .#{$blockName}__btn-start > span
      @apply tw-text-base


  +breakpoint(lg)
    .gik-menu-bar
      @apply tw-ml-1

    .gik-user-avatar-menu__avatar-wrapper
        @apply tw-pr-0

  +breakpoint(lg-down)
    @apply tw-mr-2

