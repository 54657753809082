+b(share-inkind-buttons)
  @apply tw-flex tw-gap-2 tw-flex-wrap
  min-height: 240px
  > *
    @apply tw-flex-1


  .sb-link
    @apply tw-flex-1 tw-flex
    > *
      @apply tw-flex-1



  &__btn-more-options
    --color: theme('colors.neutral.900')
    --bg: theme('colors.neutral.300')
    --bg-hover: theme('colors.neutral.400')
    svg path
      stroke: theme('colors.neutral.900')

  &--layout-4-email
    > *
      flex: 1 1 calc(33% - 2rem)

  &--layout-4-more
    > *
      flex: 1 1 calc(50% - 1rem)

  &--layout-5
    > *
      flex: 1 1 calc(50% - 1rem)

  &__btn-email-invitations
    flex: 100%

  // &--native-share
  //   > *
  //     min-width: 140px
