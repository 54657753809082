+b(taxonomy-nav)
  @apply tw-overflow-hidden tw-relative tw-text-center

  +b(grid)
    @apply tw-inline-flex tw-justify-start tw-gap-1 tw-gap-y-2


    > *
      @apply tw-flex-shrink-0

    @screen sm
      @apply tw-gap-2 tw-gap-y-4

  &__item
    @apply tw-border-3 tw-border-transparent
    > span
      @apply tw-ml-2
  &__item--selected
    @apply tw-border-primary-500 tw-bg-transparent

  &--type-situation
    +b(image)
      height: 24px
      width: 24px
      @apply tw-mr-2

  &--type-recipient,
  &--type-category
    +b(badge)
      @apply tw-pl-1
    +b(image)
      height: 30px
      width: 30px
      border-radius: 50%
      @apply tw-mr-2

  +b(horizontal-nav)

    height: 58px

    // @screen sm
    //   @apply tw-mx-10

  +b(arrow-button)
    &__next, &__prev
      top: calc(50% - 14px - 11px)

+b(taxonomy-nav-skeleton)
  @apply tw-flex tw-gap-1 tw-gap-y-2 tw-overflow-hidden
  @screen sm
      @apply tw-gap-2 tw-gap-y-4
