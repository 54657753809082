+b(featured-articles-section)
  +b(layout-aside)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
      align-content: stretch
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
      align-content: stretch
      +b(articles-carousel)
        @apply tw-mb-8

    @screen md
      & > main, & > aside
        flex: 0 0 50%
      &  > main
        max-width: 50%
      & > main
        @apply tw-pr-8
      & > aside
        @apply tw-pl-8

        // TODO: make this a prop to control the thumbnail's size in the component
        &__image-wrapper
          @screen md
            flex: 0 0 140px
            height: 140px

      +b(articles-grid)
        &__title
          @apply tw-mt-0
