+b(order-form-skeleton)
  > header
    @apply tw-flex
    +b(frame-bone)
      @apply tw-w-64 tw-h-48 tw-mr-4
    > aside
      @apply tw-w-full
  +e(dropdown)
      @apply tw-w-full tw-h-8 tw-mr-4 tw-mt-4 #{!important}
  +e(user-actions)
    @apply tw-flex tw-mt-8 tw-mb-4
    +b(frame-bone)
      @apply tw-flex-1 tw-h-8
      &:last-of-type
        @apply tw-ml-4

