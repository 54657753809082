.#{$gik-prefix}wishlist-grid-skeleton {
  &__image {
    @apply tw-mb-4 tw-w-full tw-p-0;
    padding-bottom: 100%;
  }
}

.#{$gik-prefix}wishlist-grid {
  &__grid-footer {
    @apply tw-mt-2;
  }

  @screen md {
    .gik-product-tile--col {
      transition: transform 0.3s ease-in;
    }
    .gik-product-tile--col:hover {
      transform: translateY(-4px);
    }
  }

  &__product-title {
    @apply tw-text-lg;
  }
}
