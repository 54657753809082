@use 'sass:list'
@use 'sass:string'

// TODO: would be great to get those lists directly from tailwind.
$upward-breakpoints: xs sm md lg xl
$downward-breakpoints: xs-down sm-down md-down lg-down xl-down
$rule-name: breakpoint

/// @see breakpointBem
@mixin breakpoint($name)
  +m(#{$rule-name}-auto)
    +-screen-selector($name)
      @content

  /// ie. md needs to inherit from lg-down
  +-extend-to-higher-breakpoints($name)
    @content

  /// ie. md needs to inherit from sm
  +-extend-to-lower-breakpoints($name, ($upward-breakpoints list.length($upward-breakpoints), $downward-breakpoints 1))
    @content

  +m(#{$rule-name}-#{$name})
    @content

@mixin -extend-to-lower-breakpoints($name, $list)
  @each $entry in $list
    $list: list.nth($entry, 1)
    $last-index: list.nth($entry, 2)
    $start-index: list.index($list, $name)
    @if ($start-index != null)
      +-loop-through($name, $list, $start-index, $last-index)
        @content

@mixin -extend-to-higher-breakpoints($name)
  $down-index: string.index($name, "-down")
  @if($down-index != null)
    $compare: string.slice($name, 1, $down-index - 1)
    $compare-index: list.index($upward-breakpoints, $compare)
    +-loop-through($compare, $upward-breakpoints, $compare-index, 1)
      @content

  @else
    $compare: $name + "-down"
    $compare-index: list.index($downward-breakpoints, $compare)
    $last-index: list.length($downward-breakpoints)
    +-loop-through($compare, $downward-breakpoints, $compare-index, $last-index)
      @content

@mixin -loop-through($name, $list, $start-index, $last-index)
  @for $i from $start-index through $last-index
    $point: list.nth($list, $i)
    @if($point != $name)
      +m(#{$rule-name}-#{$point})
        @content

@mixin -screen-selector($name)
  @each $breakpoint in list.join($upward-breakpoints, $downward-breakpoints)
    @if ($name == $breakpoint)
      @screen #{$breakpoint}
        @content
