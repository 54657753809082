+b(announcements-carousel)

  .slick-slide
    @apply tw-p-2
    @screen md
      @apply tw-p-3
    > div
      @apply tw-flex

  &__content-stack
    @apply tw-items-center

  +b(keep-proportions)
    @apply tw-flex-shrink-0
    width: 90px

  &__title
    +lineClamp(3)
    @apply tw-font-bold tw-text-xl tw-leading-tight
    @screen md
      @apply tw-text-xl tw-leading-tight

  &__content
    +lineClamp(3)
    @apply tw-font-normal tw-text-base tw-leading-5


  &__image
    @apply tw-rounded



