.#{$gik-prefix}press-bar {
  padding: 20px;

  &__title {
    @apply tw-text-2xl;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    color: theme('colors.white');
  }

  &__logos {
    @apply tw-flex tw-flex-wrap;
    justify-content: space-evenly;
  }

  &__logo {
    @apply tw-flex-grow;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__SVG {
    transform: scale(0.8);
    width: 100%;
  }

  &__people {
    position: relative;
    top: 4px;
  }
}

@screen xs-down {
  .#{$gik-prefix}press-bar {
    &__logos {
      flex-direction: column;
    }
    &__logo {
      flex-basis: 100%;
    }
  }
}

@screen xs {
  .#{$gik-prefix}press-bar {
    &__logo {
      flex-basis: 50%;
    }
  }
}

@screen sm {
  .#{$gik-prefix}press-bar {
    &__logo {
      flex-basis: 33.3%;
    }
  }
}

@screen md {
  .#{$gik-prefix}press-bar {
    &__title {
      font-size: 30px;
    }

    &__logo {
      flex-basis: 25%;
    }

    &__SVG {
      transform: scale(1);
      width: 150px;
    }
  }
}

@screen lg {
  .#{$gik-prefix}press-bar {
    &__SVG {
      width: 200px;
    }
  }
}

@screen xl {
  .#{$gik-prefix}press-bar {
    &__SVG {
      width: 240px;
    }
  }
}
