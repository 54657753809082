+b(right-sidebar-container)
  +m(skeleton)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch

    +b(button-bone)
      @apply tw-my-1

    +b(frame-bone)
      @apply tw-w-full tw-h-40 tw-mt-1
