$blockName: #{$gik-prefix}claimed-item-card;

.#{$blockName} {
  height: 70px;
  color: #46656c;
  border-radius: $border-radius;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background-color: white;

  padding: 6px;
  padding-left: 15px;
  padding-right: 15px;

  &__hover-wrapper {
    margin-bottom: 10px;

    .gik-hover-animation__shadow {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      border: 1px solid transparent;
    }
  }

  &:hover {
    color: #46656c;
  }

  &__first-section {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__flex-separator {
    visibility: hidden;
  }

  &__dates,
  &__title-description-container {
    overflow: hidden;

    & > * {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &__dates {
    min-width: 130px;
  }

  &__date,
  &__title {
    @apply tw-font-body tw-text-xl tw-text-neutral-800 tw-font-bold;
  }

  //&__date {
  //mobile:

  //@apply tw-font-body tw-text-base tw-text-neutral-800 tw-font-bold;
  //}

  &__title-description-container {
    margin-left: 3em;
    margin-right: 1em;
  }

  &__time,
  &__description {
    @apply tw-font-body tw-text-base tw-text-neutral-700;
  }

  &__description {
    min-height: 18px;
    max-width: 220px;
  }

  &__info {
    margin-left: auto;
    color: #35a7a9;
  }
}

@screen md-down {
  .#{$blockName} {
    height: 92px;

    &__first-section {
      flex-direction: column;
      align-items: flex-start;
      align-self: flex-start;
    }

    &__dates {
      display: flex;
      flex-direction: row;
    }

    &__date {
      margin-right: 6px;
      @apply tw-font-body tw-text-base tw-text-neutral-800 tw-font-bold;
    }

    &__flex-separator {
      visibility: visible;

      &:before {
        content: '|';
      }
    }

    &__time {
      margin-left: 6px;
    }

    &__title-description-container {
      margin-left: 0;
    }
  }
}
