+b(give-situation-tile)
  --size: 175px



  @apply tw-text-center
  > div
    @apply tw-flex tw-flex-col tw-rounded-md tw-w-full
    width: var(--size)
    height: var(--size)

  &--primary > div
    @apply tw-bg-primary-50

  &--secondary > div
    @apply tw-bg-secondary-50

  &--danger > div
    @apply tw-bg-danger-50

  &--success > div
    @apply tw-bg-success-50

  &--warning > div
    @apply tw-bg-warn-50

  &__box
    @apply tw-rounded-md tw-flex-1 tw-flex-shrink-0 tw-p-2 tw-text-center
    @apply tw-flex tw-flex-col

  &__image
    @apply tw-m-2 tw-mx-auto tw-flex-1
    height: 85px
    @screen sm
      @apply tw-m-4
      height: 85px
    @screen md
      @apply tw-m-4 tw-mt-4
      height: 85px

  &__title
    @apply tw-block tw-font-bold tw-text-base tw-leading-none
    @apply tw-flex tw-items-center tw-justify-center
    height: 38px

  .gik-hover-animation__shadow
    @apply tw-rounded-md

+b(give-situation-tile-skeleton)
  @screen sm
    width: 175px
    height: 175px

  &__image.gik-frame-bone
    --width: 100%
    --height: 100%

  > main
    @apply tw-pt-2
