$blockName: #{$gik-prefix}claimed-item-modal-content;
$modalBlockName: #{$gik-prefix}claimed-item-modal;

.#{$modalBlockName} {
  &,
  & .content {
    @apply tw-p-0 #{!important};
  }
}

.#{$blockName} {
  &__pages {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      display: flex;
      flex-direction: column;
      height: 100%;

      h2 {
        margin: {
          top: 0;
          bottom: 4px;
        }
      }

      h3 {
        margin-bottom: 16px;
      }

      padding: {
        left: 20px;
        right: 20px;
        bottom: 20px;
      }
    }
  }

  &__address-not-displayed {
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full;
    background: url($public-root + 'user-profile/img/address-placeholder.svg');
    width: 223px;
    height: 53px;


    @apply tw-font-body tw-text-neutral-800 tw-text-sm tw-font-normal;
    margin-left: auto;
    margin-right: auto;
  }

  &__who-container {
    padding: 5px;
    background-color: #ecf6f6;
    border-radius: 10px;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    min-height: 60px;

    span {
      flex: 1;
    }
  }

  &__page-image {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    margin-right: 15px;
  }

  &__who-more-info-icon {
    margin-right: 10px;
  }

  &__who-name {
    font-weight: bold;
  }

  &__address-container {
    margin-bottom: 1em;
    text-align: center;

    .button-v2.copy {
      margin-top: 10px;
    }
  }

  &__who {
    border-top: 1px solid white;
  }

  div.claimed-modal-gift-card {
    p {
      @apply tw-mt-2;
    }
  }

  &__date-time-container {
    margin-bottom: 1em;

    &,
    & > * {
      align-self: center;
      flex-direction: column;
      display: flex;
    }
  }

  &__when {
    text-align: center;
  }

  &__add-to-calendar {
    width: 178px;
    align-self: center;
  }

  &__who,
  &__when,
  &__notes-section,
  &__what {
    border-bottom: 1px solid #e2e6e6;
    padding-bottom: 1em;
  }

  &__what {
    border-bottom: 0;
  }

  &__copy-reminder {
    font-size: 14px;
    font-style: italic;
  }

  &__notes-section {
    background-color: #fffae6;
    border-top: 1px solid #fffae6;
    border-bottom: 0;
  }

  &__special-notes-section {
    border-top: 1px solid #ef5c84;
    background-color: #ffeff6;
  }

  &__section-title {
    @apply tw-font-body tw-text-neutral-600 tw-text-xs tw-font-medium tw-uppercase tw-mt-5 tw-text-left;
  }

  &__date {
    @apply tw-font-body tw-text-primary-600 tw-text-base tw-font-bold;
  }

  &__time {
    @apply tw-font-body tw-text-primary-600 tw-text-sm tw-font-light;
  }

  &__claim-description {
    @apply tw-font-body tw-text-lg tw-font-bold;
  }

  &__entry-title {
    @apply tw-font-body tw-text-neutral-700 tw-text-base tw-font-normal;
  }

  &__entry-description {
    @apply tw-font-body tw-text-base tw-font-normal;
  }

  &__claim-notes {
    @apply tw-font-body tw-text-neutral-700 tw-text-base tw-font-normal;
  }

  &__page-title {
    @apply tw-font-heading tw-text-primary-600;
  }

  &__notes {
    @apply tw-font-body tw-text-warn-700 tw-text-xs tw-font-medium tw-uppercase tw-mt-5 tw-text-left;
  }

  &__special-notes-title {
    @apply tw-font-body tw-text-danger-500 tw-text-xs tw-font-medium tw-uppercase tw-mt-5 tw-text-left;
  }

  &__entry-label,
  &__claim-label {
    @apply tw-text-neutral-700;
  }

  &__entry-text,
  &__entry-label,
  &__claim-text,
  &__claim-label {
    @apply tw-font-body tw-text-base tw-font-normal tw-mb-1;
  }
}
