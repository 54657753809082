+b(feature-step)
  @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch tw-text-center
  align-content: stretch

  +e(title-wrapper)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full


  +e(title)
    @apply  tw-font-bold tw-text-xl tw-leading-none tw-text-neutral-900 tw-ml-1

  +e(description)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-900 tw-text-center
    span
      @apply tw-text-neutral-700 tw-text-lg

  +e(fine-print)
    @apply tw-font-normal tw-text-sm tw-leading-none tw-text-neutral-600
    @screen md
      position: absolute
      bottom: 0

  +e(form-field)
    +b(form-group)
      +e(control error)
        @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch tw--mx-2
        align-content: stretch
