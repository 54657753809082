+b(list)
  @apply tw-flex tw-gap-1 tw-list-inside tw-text-neutral-900

  +m(none)
    @apply tw-list-none
  +m(disc)
    @apply tw-list-disc
    > li
      @apply tw-list-disc

  +m(decimal)
    @apply tw-list-decimal

  +m(block)
    @apply tw-flex-col

  +m(inline, center)
    @apply tw-inline-flex tw-justify-center
