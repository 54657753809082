+b(testimonials-section)
  @apply tw-bg-neutral-50

  +e(container)
    @apply tw-gap-8
    @screen md
      @apply tw-gap-16

  @screen md
    background: initial

  +e(testimonials)
    @screen md
      @apply tw-flex-wrap
      & > *
        flex: 1 1 30%
