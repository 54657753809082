+b(give-situations-grid)

  @apply tw-text-center

  &__select
    @apply tw-mb-4 tw-mx-auto
    @screen xs
      width: 240px

  &__nav
    @apply tw-flex tw-gap-4 tw-flex-wrap tw-justify-center tw-mb-8
    @screen sm
      @apply tw-gap-8

  &__grid
    @apply tw-grid tw-gap-4 tw-text-center tw-mb-8

    @screen xs
      @apply tw-grid-cols-2

    @screen sm
      @apply tw-flex tw-flex-wrap tw-justify-center
      @apply tw-gap-8


