+b(create-wrapper)
  @apply tw-flex tw-flex-col tw-items-center tw-max-w-full tw-mb-4
  align-content: stretch // no tw-content-stretch ?

  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-mx-auto tw-text-center

  +e(description)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-700 tw-text-center
    max-width: 530px

  +e(feature-card)
    @apply tw-my-4

  @screen md-down
    @apply tw-items-center

    +e(title)
      max-width: 250px
