
+b(sticky-table)
  @apply tw-border-collapse

  &--full-width
    @apply tw-w-full
  &__table--wrapper--full-width
    @apply tw-w-full

  > caption
    @apply tw-text-left tw-font-bold tw-mb-2 tw-text-lg


  &__wrapper
    &--scrollable
      @apply tw-overflow-auto



  &__scroller
    overflow-x: scroll
    overflow-y: visible


  &__th
    @apply tw-text-left


  +e(td)
    @apply tw-overflow-hidden tw-truncate
    // max-width: 200px
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;

  .tbody
    @apply tw-relative


  &--neutral
    @apply tw-border tw-border-neutral-900


  &--neutral &__th
    @apply tw-bg-neutral-300 tw-p-2 tw-text-left tw-whitespace-nowrap
    @apply tw-border tw-border-neutral-900

  &--neutral &__td
    @apply tw-bg-neutral-100 tw-p-2 tw-whitespace-nowrap tw-overflow-hidden
    @apply tw-border tw-border-neutral-900


  &--neutral &__wrapper
    @apply tw-border tw-border-neutral-900


  // *[data-sticky-last-left-td='true'] Placeholder900')
  //

  // *[data-sticky-first-right-td='true']
  //   box-shadow: 0px 0px 1px theme('colors.neutral.900')
  //

  &--sticky
    overflow: scroll
    .thead,
    .tfoot
      position: sticky
      z-index: 1
      width: fit-content


    .thead
      top: 0
      box-shadow: 0px 3px 3px #ccc


    .tfoot
      bottom: 0
      box-shadow: 0px -3px 3px #ccc


    .tbody
      position: relative
      z-index: 0


    [data-sticky-td]
      position: sticky



  &--sticky &--neutral
    [data-sticky-last-left-td='true']
      box-shadow: 1px 0px 1px theme('colors.neutral.900')


    [data-sticky-first-right-td='true']
      box-shadow: 0px 0px 1px theme('colors.neutral.900')



  &--full-width
    @apply tw-w-full



.gik-data-table
  @apply tw-border tw-border-neutral-300

