+b(donations-aside)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  +e(button-container)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(skip-description)
    @apply  tw-font-normal tw-text-xs tw-leading-tight tw-text-center tw-mb-8 tw--ml-1 tw--mr-1

  +b(separator)
    +m(variant-default)
      --border-color: theme('colors.neutral.900')
      --border-opacity: .3
