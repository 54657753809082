@mixin articles-grid-layout-row
  #{b(articles-grid)}__articles
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
  +b(article-tile)
    @apply tw-mx-0

@mixin articles-grid-layout-col

+b(articles-grid)
  $blockName: &
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  &__title
    @apply tw-text-3xl tw-font-semibold tw-text-gray-800 #{!important}

  &__articles
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch tw-gap-6
    align-content: stretch

    @screen md-down
      @apply tw-flex-col

    & > *
      @apply tw-flex-1
      @apply tw-px-0

  &--centered-title
    #{$blockName}__title
      @apply tw-text-center

    &#{$blockName}--skeleton #{$blockName}__title
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  &--layout-row
    +articles-grid-layout-row

  &--layout-auto
    @screen md-down
      +articles-grid-layout-row
