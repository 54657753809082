@import '../__values';

$blockName: 'ad-container';
$ad-block-el-name: #{$blockName}__ad;

$vertical-ad: vertical;
$horizontal-ad: horizontal;

.blog .#{$blockName} /* override blog settings - FIXME: update blog to use BEM */,
.#{$blockName} {
  > p {
    color: map_get($v2-colors, text-secondary);
    font-family: theme('fontFamily.body');
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    margin-bottom: 4px;
    flex-basis: 100%;
  }

  .#{$ad-block-el-name} {
    width: 728px;
    height: 90px;
    background-size: cover;
    @screen md-down {
      width: 300px;
      height: 250px;
    }
  }

  &.#{$blockName}--#{$vertical-ad} .#{$ad-block-el-name} {
    width: 160px;
    height: 600px;
  }

  .#{$ad-block-el-name}--fallback {
    background-image: url($public-root + $ads-fallback-directory + 'desktop_leaderboard.jpg');
    @screen md-down {
      background-image: url($public-root + $ads-fallback-directory + 'mobile_medium_rectangle.jpg');
    }
  }

  &.#{$blockName}--#{$vertical-ad} .#{$ad-block-el-name}--fallback {
    background-image: url($public-root + $ads-fallback-directory + 'desktop_skyscraper.jpg');
  }

  &.#{$blockName}--#{$horizontal-ad} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    margin: 30px 0px 70px;
    @screen md-down {
      width: 100%;
      height: auto;
      margin: 10px 0px 40px;
    }
  }
}
