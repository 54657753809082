
+b(provide-myself-claim-form)
  @apply tw-text-center tw-mx-auto
  +e(private-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-mt-8
    > *
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > :last-child
      @apply tw-ml-1

+b(provide-myself-claim-success)
  @apply tw-text-center tw-text-neutral-900 tw-mt-8

  +b(contact-page-organizers-for-address)
    @apply tw-mx-auto tw-flex-1 tw-w-full

  +e(no-address)
    @apply tw-text-xl tw-text-center tw-mx-auto
    max-width: 320px

  +e(description-muted)
    @apply tw-text-neutral-700 tw-mb-4

  +e(section-address)
    @apply tw-flex-1
  +e(section-email)
    @apply tw-flex-1

  +e(check)
    @apply tw-bg-success-500 tw-text-white tw-flex
    @apply tw-items-center tw-justify-center tw-text-3xl tw-mx-auto tw-mb-4
    border-radius: 50%
    width: 55px
    height: 55px
  +e(title)
    @apply tw-text-3xl tw-font-bold tw-leading-none tw-mb-6 tw-block

  +e(details-address)
    @apply tw-bg-neutral-100 tw-p-4 tw-flex-1 tw-rounded

  +e(details-address-content)
    @apply tw-flex tw-items-center tw-mr-2

  +e(details-email)
    @apply tw-bg-neutral-100 tw-p-4 tw-flex-1 tw-rounded tw-flex tw-flex-col

  +e(details-email-content)
    @apply tw-flex tw-flex-1 tw-items-center tw-mr-2

  +e(details)
    @apply tw-mt-6 tw-text-left tw-flex tw-flex-col

    > * + *
      @apply tw-mt-4

    @screen md
      @apply tw-flex-row
      > * + *
        @apply tw-ml-4 tw-mt-0

  +e(footer-text)
    @apply tw-mt-4 tw-mx-auto
    max-width: 330px


  // +m(provide-myself)
  //   @apply
