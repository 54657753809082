+b(page-settings-scroll-navbar)

  // NOTE: this is a fix to hide box-shadow overflow on div's top side
  @apply tw-relative

  &:before
    @apply tw-absolute tw-w-full tw-bg-white
    content: ""
    height: 7px
    top: -7px







