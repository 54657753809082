+b(post-input)
  $blockName: &

  @apply tw-text-lg tw-h-full tw-flex tw-flex-1

  +b(form)
    @apply tw-flex tw-flex-col tw-justify-between tw-items-stretch tw-flex-1
    align-content: stretch

  +b(form-group)
    @apply tw-w-full

  &__error
    @apply tw-mt-4 tw-text-sm

  &--styled
    .gik-form-group__control
      @apply tw-text-center

  .gik-form-group__error
    @apply tw-mt-4

  textarea
    @apply tw-w-full tw-text-neutral-900
    &::placeholder
      @apply tw-text-neutral-600

  &__text-help
    @apply tw-text-xs tw-text-neutral-700 tw-font-semibold


  +b(input-mentions)
    margin-bottom: -14px

  &--styled .gik-input-mentions
    @apply tw-text-3xl

  &--styled .gik-input-mentions__highlighter
    @apply tw-text-center #{!important}
    margin-left: 1px

  &--styled .gik-input-mentions__control
    min-height: 80px

  &--styled textarea
    @apply tw-text-center

    &::placeholder
      @apply tw-text-neutral-600 tw-font-semibold tw-leading-tight
      @apply tw-font-heading #{!important}


  &--styled &__input
    @apply tw-flex tw-items-center tw-justify-center tw-p-8 tw-rounded
    min-height: 200px
    textarea
      @apply tw-font-semibold tw-leading-tight
      @apply tw-font-heading #{!important}

  &--white &__input
    @apply tw-p-0 tw-block

  &--aqua &__input
    @apply tw-bg-primary-200
  &--grey &__input
    @apply tw-bg-neutral-200
  &--red &__input
    @apply tw-bg-danger-200
  &--orange &__input
    @apply tw-bg-secondary-200
  &--green &__input
    @apply tw-bg-success-200
  &--blue &__input
    @apply tw-bg-info-200
  &--purple &__input
    @apply tw-bg-purple-200
  &--yellow &__input
    @apply tw-bg-warn-200

  &__input
    @apply tw-pb-4 tw-flex-1
    min-height: 30px

  &__toolbar
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
    align-content: stretch

    &--bg-picker-open
      #{$blockName}__attachment-picker
        display: none

      +b(stream-post-background-picker)
        flex: 0 0 100%

  &__attachments
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
    & >  *:nth-child(2)
      @apply tw-mt-8

  .gik-input-mentions__suggestions
    @apply tw-text-base tw-text-left tw-text-neutral-900
  .gik-input-mentions__user
    @apply tw-text-neutral-900

  .gik-input-mentions__suggestions__item
    &:first-child
      @apply tw-rounded-t
    &:last-child
      @apply tw-rounded-b

  > .gik-button
    @apply tw-flex-shrink-0
