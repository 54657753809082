.#{$gik-prefix}inkind-list-item {
  @apply tw-flex tw-py-1 tw-items-center;

  > :first-child {
    @apply tw-mr-2;
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
  }
  > span {
    @apply tw-text-neutral-900 tw-text-base tw-truncate;
  }
}
