.ReactModal__Content.modals.modal-v2.alert-dialog {
  height: 221px;
  width: 400px;
}

.ReactModal__Content.confirm-dialog,
.ReactModal__Content.alert-dialog,
.ReactModal__Content.share-modal {
  h3.dialog-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;

    text-align: center;
    margin-top: 38px;
    margin-bottom: 0;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;

    color: theme('colors.neutral.800');
    font-family: theme('fontFamily.body');
    padding: 0px 11px;

    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;

    align-content: center;
    justify-content: center;
  }

  section.buttons-area {
    display: flex;
    flex-direction: row;
    align-self: stretch;
    justify-content: center;

    .gik-button {
      margin: 14px 3px;
      width: 120px;
    }

    .button-v2 {
      margin: 14px 3px;
      width: 120px;
    }
  }

  .list-inline.share-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    li:last-child {
      margin-right: -4px;
    }
  }
}
