+b(product-price)
  &__price
    @apply tw-text-5xl tw-text-primary-500 tw-font-heading tw-inline-block tw-font-semibold
  &__price-range
    @apply tw-text-2xl tw-text-primary-500 tw-font-heading tw-inline-block tw-font-semibold
  &__price-plain
    @apply tw-text-base tw-text-neutral-700 tw-font-normal tw-font-body tw--mt-1

  .gik-form-group
    @apply tw-p-0

  &__price-select .gik-select__single-value
    @apply tw-text-neutral-900 tw-font-normal tw-inline-block tw-font-normal

  .gik-input__input
    @apply tw-text-neutral-900 tw-font-normal
    &::placeholder
      @apply tw-text-lg tw-relative


  &__price-select .gik-select__single-value
    @apply tw-text-left


  &--compact
    @apply tw-text-right
    .gik-input__wrapper
      @apply tw-ml-auto

  &__price-select
    min-width: 250px

  &--compact &__price-select
    min-width: 120px

  &--compact .gik-input__wrapper
    width: 120px

  &--compact .gik-select__control
    width: 120px

  &--compact &__price
    @apply tw-text-xl tw-text-neutral-700
    sup
      top: unset

