+b(donations-container)
  $blockName: &

  @apply tw-flex tw-flex-col tw-items-stretch tw-justify-between tw-h-full
  align-content: stretch

  +m(horizontal)
    #{$blockName}__selector, #{b(donation-provider)}
      @apply tw-flex-row

  +m(vertical)
    #{$blockName}__selector, #{b(donation-provider)}
      @apply tw-flex-col

  +e(selector)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
  +e(selection-inner)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-mt-2 tw-gap-2

  +e(selection)
    text-decoration: none !important

    .gik-button__content, .gik-button__append
      margin-left: 4px
    +m(selected)
      &, &:hover
        border: 3px solid #41CBCE !important // aqua
        background: white !important

  +e(check-icon)
    fill: #7BAB52

  +e(content)
    @apply tw-flex tw-flex-row tw-items-stretch tw-justify-between tw-h-full
    align-content: stretch

  +e(separator)
    @apply tw-font-normal tw-text-base tw-leading-none tw-text-neutral-800 tw-font-bold

  +e(footer)
    @apply tw-font-normal tw-text-sm tw-leading-none tw-text-neutral-600 tw-mx-3 tw-my-2 tw-text-right
