$blockName: prefix(author-page)

.#{$blockName}
  &__more-title
    @apply tw-font-medium tw-text-3xl tw-mb-8

  &__author-info-section, &__author-bio-section
    @screen md
      +b(container)
        max-width: 690px

  &__author-bio-section
    @apply tw-font-normal tw-text-base tw-leading-normal
    @screen md
      +b(container)
        @apply tw-pl-48

    p
      @apply tw-m-0
      @screen md-down
        @apply tw-pt-6
