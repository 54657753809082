.#{$gik-prefix}skeleton-error-wrapper {
  @apply tw-relative tw-inline-block tw-w-full;
}

.#{$gik-prefix}skeleton-error {
  @apply tw-absolute tw-top-0 tw-w-full tw-items-center tw-flex tw-justify-center tw-mt-2;
  > div {
    @apply tw-p-1 tw-bg-danger-900 tw-text-white tw-text-sm tw-inline-block tw-rounded-sm;
  }
}
