.#{$gik-prefix}related-articles-rows {
  // space between elements
  > * + * {
    @apply tw-mt-4;
  }
}

.#{$gik-prefix}related-articles-rows-skeleton {
  * {
    box-sizing: border-box;
  }
  > *:not(:first-child) {
    @apply tw-mt-4;
  }
  &__toolbar {
    @apply tw-flex tw-text-neutral-600 tw-mt-2;
    > * + * {
      @apply tw-ml-1;
    }
  }
  &__text {
    max-height: 45px;
    overflow: hidden;
  }
  &__item {
    @apply tw-flex tw-overflow-hidden;
    max-height: 96px;

    main {
      @apply tw-flex tw-items-center;
    }
  }
  &__image.gik-frame-bone {
    @apply tw-mr-2 tw-inline-block tw-flex-shrink-0;
    --width: 96px;
    --height: 96px;
  }
}
