+b(inkind-deactivated-page)
  @apply tw-flex-1
  p
    @apply tw-mb-4
  >.gik-container
    @screen sm
      max-width: 440px
    @screen lg
      max-width: 900px

  &__content
    @apply tw-flex tw-flex-col-reverse
    @screen lg
      @apply tw-flex tw-items-center tw-justify-center tw-flex-row

  &__main
    @apply tw-mb-8
    @screen lg
      @apply tw-mr-8
      @apply tw-flex-1
    h1
      @apply tw-font-semibold

  &__actions
    @apply tw-text-center tw-mt-8

  &__image
    @screen sm-down
      @apply tw-w-full
      height: auto
    @apply tw-flex-shrink-0 tw-mx-auto
    max-width: 600px

