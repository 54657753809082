/*Changes:
  Give-InKind.* -> giveinkind-v2,
  removed all font types except woff,
  added parent class gik-icon-v2 and used & on all children,
  added :before - [class^="icon-v2-"]:before, [class*=" icon-v2-"]:before
  added !important to remove conflict with unparented v1 .icon classes
  renamed icon to icon-v2
*/
@font-face {
  font-family: 'giveinkind-v2';
  src: url($public-root + 'core/fonts/giveinkind-v2.woff?qo43cb');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.gik-icon-v2 {
  display: inline-block;

  &[class^='icon-v2-']:before,
  &[class*=' icon-v2-']:before {
    font-family: 'giveinkind-v2', sans-serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  &.icon-v2-outlookApple:before {
    content: '\e9dd';
  }
  &.icon-v2-outlook:before {
    content: '\e9dc';
  }
  &.icon-v2-add:before {
    content: '\e900';
  }
  &.icon-v2-addFile:before {
    content: '\e901';
  }
  &.icon-v2-addFolder:before {
    content: '\e902';
  }
  &.icon-v2-addGroup:before {
    content: '\e903';
  }
  &.icon-v2-addUser:before {
    content: '\e904';
  }
  &.icon-v2-alert:before {
    content: '\e905';
  }
  &.icon-v2-amazon:before {
    content: '\e906';
  }
  &.icon-v2-android-o:before {
    content: '\e907';
  }
  &.icon-v2-android:before {
    content: '\e908';
  }
  &.icon-v2-apple-o:before {
    content: '\e909';
  }
  &.icon-v2-apple:before {
    content: '\e90a';
  }
  &.icon-v2-arrowDown:before {
    content: '\e90b';
  }
  &.icon-v2-arrowheadDoubleLeft:before {
    content: '\e90c';
  }
  &.icon-v2-arrowheadDoubleRight:before {
    content: '\e90d';
  }
  &.icon-v2-arrowheadDown:before {
    content: '\e90e';
  }
  &.icon-v2-arrowheadLeft:before {
    content: '\e90f';
  }
  &.icon-v2-arrowheadRight:before {
    content: '\e910';
  }
  &.icon-v2-arrowheadUp:before {
    content: '\e911';
  }
  &.icon-v2-arrowLeft:before {
    content: '\e912';
  }
  &.icon-v2-arrowRight:before {
    content: '\e913';
  }
  &.icon-v2-arrowUp:before {
    content: '\e914';
  }
  &.icon-v2-article:before {
    content: '\e915';
  }
  &.icon-v2-attachment:before {
    content: '\e916';
  }
  &.icon-v2-backward:before {
    content: '\e917';
  }
  &.icon-v2-bell:before {
    content: '\e918';
  }
  &.icon-v2-bookMark:before {
    content: '\e919';
  }
  &.icon-v2-bullhorn:before {
    content: '\e91a';
  }
  &.icon-v2-calendar:before {
    content: '\e91b';
  }
  &.icon-v2-calendarLarge:before {
    content: '\e91c';
  }
  &.icon-v2-car:before {
    content: '\e91d';
  }
  &.icon-v2-card:before {
    content: '\e91e';
  }
  &.icon-v2-cart:before {
    content: '\e91f';
  }
  &.icon-v2-chat:before {
    content: '\e920';
  }
  &.icon-v2-check:before {
    content: '\e921';
  }
  &.icon-v2-chevronDown:before {
    content: '\e922';
  }
  &.icon-v2-chevronLeft:before {
    content: '\e923';
  }
  &.icon-v2-chevronRight:before {
    content: '\e924';
  }
  &.icon-v2-chevronUp:before {
    content: '\e925';
  }
  &.icon-v2-circle-add-o:before {
    content: '\e926';
  }
  &.icon-v2-circle-add:before {
    content: '\e927';
  }
  &.icon-v2-circle-alert-o:before {
    content: '\e928';
  }
  &.icon-v2-circle-alert:before {
    content: '\e929';
  }
  &.icon-v2-circle-check-o:before {
    content: '\e92a';
  }
  &.icon-v2-circle-check:before {
    content: '\e92b';
  }
  &.icon-v2-circle-close-o:before {
    content: '\e92c';
  }
  &.icon-v2-circle-close:before {
    content: '\e92d';
  }
  &.icon-v2-circle-down-o:before {
    content: '\e92e';
  }
  &.icon-v2-circle-down:before {
    content: '\e92f';
  }
  &.icon-v2-circle-help-o:before {
    content: '\e930';
  }
  &.icon-v2-circle-help:before {
    content: '\e931';
  }
  &.icon-v2-circle-info-o:before {
    content: '\e932';
  }
  &.icon-v2-circle-info:before {
    content: '\e933';
  }
  &.icon-v2-circle-left-o:before {
    content: '\e934';
  }
  &.icon-v2-circle-left:before {
    content: '\e935';
  }
  &.icon-v2-circle-minus-o:before {
    content: '\e936';
  }
  &.icon-v2-circle-minus:before {
    content: '\e937';
  }
  &.icon-v2-circle-pause-o:before {
    content: '\e938';
  }
  &.icon-v2-circle-pause:before {
    content: '\e939';
  }
  &.icon-v2-circle-right-o:before {
    content: '\e93a';
  }
  &.icon-v2-circle-right:before {
    content: '\e93b';
  }
  &.icon-v2-circle-up-o:before {
    content: '\e93c';
  }
  &.icon-v2-circle-up:before {
    content: '\e93d';
  }
  &.icon-v2-close:before {
    content: '\e93e';
  }
  &.icon-v2-coffee:before {
    content: '\e93f';
  }
  &.icon-v2-compass:before {
    content: '\e940';
  }
  &.icon-v2-contacts:before {
    content: '\e941';
  }
  &.icon-v2-creditcard:before {
    content: '\e942';
  }
  &.icon-v2-cycle:before {
    content: '\e943';
  }
  &.icon-v2-dashboard:before {
    content: '\e944';
  }
  &.icon-v2-desktop:before {
    content: '\e945';
  }
  &.icon-v2-doc:before {
    content: '\e946';
  }
  &.icon-v2-docBlank:before {
    content: '\e947';
  }
  &.icon-v2-doubleChevronLeft:before {
    content: '\e948';
  }
  &.icon-v2-doubleChevronRight:before {
    content: '\e949';
  }
  &.icon-v2-download:before {
    content: '\e94a';
  }
  &.icon-v2-duplicate:before {
    content: '\e94b';
  }
  &.icon-v2-edit:before {
    content: '\e94c';
  }
  &.icon-v2-editWithLine:before {
    content: '\e94d';
  }
  &.icon-v2-end:before {
    content: '\e94e';
  }
  &.icon-v2-excel:before {
    content: '\e94f';
  }
  &.icon-v2-facebook:before {
    content: '\e950';
  }
  &.icon-v2-female:before {
    content: '\e951';
  }
  &.icon-v2-fileError:before {
    content: '\e952';
  }
  &.icon-v2-filter:before {
    content: '\e953';
  }
  &.icon-v2-firstAid:before {
    content: '\e954';
  }
  &.icon-v2-flag:before {
    content: '\e955';
  }
  &.icon-v2-flowers:before {
    content: '\e956';
  }
  &.icon-v2-folder:before {
    content: '\e957';
  }
  &.icon-v2-folderOpen:before {
    content: '\e958';
  }
  &.icon-v2-forward:before {
    content: '\e959';
  }
  &.icon-v2-fundraising:before {
    content: '\e95a';
  }
  &.icon-v2-fundraisingLarge:before {
    content: '\e95b';
  }
  &.icon-v2-game:before {
    content: '\e95c';
  }
  &.icon-v2-gear:before {
    content: '\e95d';
  }
  &.icon-v2-gift:before {
    content: '\e95e';
  }
  &.icon-v2-GIKHeart:before {
    content: '\e95f';
  }
  &.icon-v2-GIKLogo:before {
    content: '\e960';
  }
  &.icon-v2-global:before {
    content: '\e961';
  }
  &.icon-v2-google:before {
    content: '\e962';
  }
  &.icon-v2-hamburgerMenu:before {
    content: '\e963';
  }
  &.icon-v2-heart-o:before {
    content: '\e964';
  }
  &.icon-v2-heart:before {
    content: '\e965';
  }
  &.icon-v2-help:before {
    content: '\e966';
  }
  &.icon-v2-home:before {
    content: '\e967';
  }
  &.icon-v2-hourglass:before {
    content: '\e968';
  }
  &.icon-v2-image:before {
    content: '\e969';
  }
  &.icon-v2-inbox:before {
    content: '\e96a';
  }
  &.icon-v2-info:before {
    content: '\e96b';
  }
  &.icon-v2-insertAbover:before {
    content: '\e96c';
  }
  &.icon-v2-insertBelow:before {
    content: '\e96d';
  }
  &.icon-v2-instagram:before {
    content: '\e96e';
  }
  &.icon-v2-jpg:before {
    content: '\e96f';
  }
  &.icon-v2-key:before {
    content: '\e970';
  }
  &.icon-v2-light:before {
    content: '\e971';
  }
  &.icon-v2-link:before {
    content: '\e972';
  }
  &.icon-v2-location-o:before {
    content: '\e973';
  }
  &.icon-v2-location:before {
    content: '\e974';
  }
  &.icon-v2-lock:before {
    content: '\e975';
  }
  &.icon-v2-logout:before {
    content: '\e976';
  }
  &.icon-v2-loop:before {
    content: '\e977';
  }
  &.icon-v2-mail:before {
    content: '\e978';
  }
  &.icon-v2-male:before {
    content: '\e979';
  }
  &.icon-v2-market:before {
    content: '\e97a';
  }
  &.icon-v2-maximize:before {
    content: '\e97b';
  }
  &.icon-v2-meal:before {
    content: '\e97c';
  }
  &.icon-v2-menu:before {
    content: '\e97d';
  }
  &.icon-v2-microsoft:before {
    content: '\e97e';
  }
  &.icon-v2-minimize:before {
    content: '\e97f';
  }
  &.icon-v2-minus:before {
    content: '\e980';
  }
  &.icon-v2-mobile:before {
    content: '\e981';
  }
  &.icon-v2-more:before {
    content: '\e982';
  }
  &.icon-v2-neg-o:before {
    content: '\e983';
  }
  &.icon-v2-neg:before {
    content: '\e984';
  }
  &.icon-v2-neu-o:before {
    content: '\e985';
  }
  &.icon-v2-neu:before {
    content: '\e986';
  }
  &.icon-v2-pause:before {
    content: '\e987';
  }
  &.icon-v2-pdf:before {
    content: '\e988';
  }
  &.icon-v2-phone:before {
    content: '\e989';
  }
  &.icon-v2-photo-o:before {
    content: '\e98a';
  }
  &.icon-v2-photo:before {
    content: '\e98b';
  }
  &.icon-v2-pin-o:before {
    content: '\e98c';
  }
  &.icon-v2-pin:before {
    content: '\e98d';
  }
  &.icon-v2-pinterest:before {
    content: '\e98e';
  }
  &.icon-v2-play-o:before {
    content: '\e98f';
  }
  &.icon-v2-play:before {
    content: '\e990';
  }
  &.icon-v2-popOut:before {
    content: '\e991';
  }
  &.icon-v2-pos-o:before {
    content: '\e992';
  }
  &.icon-v2-pos:before {
    content: '\e993';
  }
  &.icon-v2-power:before {
    content: '\e994';
  }
  &.icon-v2-ppt:before {
    content: '\e995';
  }
  &.icon-v2-profile:before {
    content: '\e996';
  }
  &.icon-v2-prompt:before {
    content: '\e997';
  }
  &.icon-v2-refine:before {
    content: '\e998';
  }
  &.icon-v2-refresh:before {
    content: '\e999';
  }
  &.icon-v2-removeGroup:before {
    content: '\e99a';
  }
  &.icon-v2-removeUser:before {
    content: '\e99b';
  }
  &.icon-v2-report:before {
    content: '\e99c';
  }
  &.icon-v2-rocket:before {
    content: '\e99d';
  }
  &.icon-v2-rss:before {
    content: '\e99e';
  }
  &.icon-v2-save:before {
    content: '\e99f';
  }
  &.icon-v2-search:before {
    content: '\e9a0';
  }
  &.icon-v2-server:before {
    content: '\e9a1';
  }
  &.icon-v2-shake:before {
    content: '\e9a2';
  }
  &.icon-v2-share:before {
    content: '\e9a3';
  }
  &.icon-v2-someSelected:before {
    content: '\e9a4';
  }
  &.icon-v2-square-add-o:before {
    content: '\e9a5';
  }
  &.icon-v2-square-add:before {
    content: '\e9a6';
  }
  &.icon-v2-square-check-o:before {
    content: '\e9a7';
  }
  &.icon-v2-square-check:before {
    content: '\e9a8';
  }
  &.icon-v2-square-close-o:before {
    content: '\e9a9';
  }
  &.icon-v2-square-close:before {
    content: '\e9aa';
  }
  &.icon-v2-square-down-o:before {
    content: '\e9ab';
  }
  &.icon-v2-square-down:before {
    content: '\e9ac';
  }
  &.icon-v2-square-left-o:before {
    content: '\e9ad';
  }
  &.icon-v2-square-left:before {
    content: '\e9ae';
  }
  &.icon-v2-square-minus-o:before {
    content: '\e9af';
  }
  &.icon-v2-square-minus:before {
    content: '\e9b0';
  }
  &.icon-v2-square-right-o:before {
    content: '\e9b1';
  }
  &.icon-v2-square-right:before {
    content: '\e9b2';
  }
  &.icon-v2-star-o:before {
    content: '\e9b3';
  }
  &.icon-v2-star:before {
    content: '\e9b4';
  }
  &.icon-v2-start:before {
    content: '\e9b5';
  }
  &.icon-v2-subcategory:before {
    content: '\e9b6';
  }
  &.icon-v2-support:before {
    content: '\e9b7';
  }
  &.icon-v2-tablet:before {
    content: '\e9b8';
  }
  &.icon-v2-tag-o:before {
    content: '\e9b9';
  }
  &.icon-v2-tag:before {
    content: '\e9ba';
  }
  &.icon-v2-target:before {
    content: '\e9bb';
  }
  &.icon-v2-tee:before {
    content: '\e9bc';
  }
  &.icon-v2-thumbsdown-o:before {
    content: '\e9bd';
  }
  &.icon-v2-thumbsdown:before {
    content: '\e9be';
  }
  &.icon-v2-thumbsup-o:before {
    content: '\e9bf';
  }
  &.icon-v2-thumbsup:before {
    content: '\e9c0';
  }
  &.icon-v2-time-o:before {
    content: '\e9c1';
  }
  &.icon-v2-time:before {
    content: '\e9c2';
  }
  &.icon-v2-toTop:before {
    content: '\e9c3';
  }
  &.icon-v2-trash:before {
    content: '\e9c4';
  }
  &.icon-v2-trophy:before {
    content: '\e9c5';
  }
  &.icon-v2-tv:before {
    content: '\e9c6';
  }
  &.icon-v2-twitter:before {
    content: '\e9c7';
  }
  &.icon-v2-unknownFile:before {
    content: '\e9c8';
  }
  &.icon-v2-unlink:before {
    content: '\e9c9';
  }
  &.icon-v2-unlock:before {
    content: '\e9ca';
  }
  &.icon-v2-upload:before {
    content: '\e9cb';
  }
  &.icon-v2-usb:before {
    content: '\e9cc';
  }
  &.icon-v2-user:before {
    content: '\e9cd';
  }
  &.icon-v2-userGroup:before {
    content: '\e9ce';
  }
  &.icon-v2-vidCam:before {
    content: '\e9cf';
  }
  &.icon-v2-view-o:before {
    content: '\e9d0';
  }
  &.icon-v2-view:before {
    content: '\e9d1';
  }
  &.icon-v2-visitor:before {
    content: '\e9d2';
  }
  &.icon-v2-volume:before {
    content: '\e9d3';
  }
  &.icon-v2-wallet:before {
    content: '\e9d4';
  }
  &.icon-v2-wifi:before {
    content: '\e9d5';
  }
  &.icon-v2-windoze-o:before {
    content: '\e9d6';
  }
  &.icon-v2-windoze:before {
    content: '\e9d7';
  }
  &.icon-v2-wishlist:before {
    content: '\e9d8';
  }
  &.icon-v2-wishlistLarge:before {
    content: '\e9d9';
  }
  &.icon-v2-word:before {
    content: '\e9da';
  }
  &.icon-v2-wrench:before {
    content: '\e9db';
  }
  &.icon-v2-youTube:before {
    content: '\e9de';
  }
  &.icon-v2-facebook2019:before {
    content: '\e9df';
  }
  &.icon-v2-copyToClipboard:before {
    content: '\e9e0';
  }
}
