// ----- Aqua
.#{$gik-prefix}gradient-light-aqua {
  @include gradient-light-aqua();
}
.#{$gik-prefix}gradient-aqua {
  @include gradient-aqua();
}
.#{$gik-prefix}gradient-dark-aqua {
  @include gradient-dark-aqua();
}

// ----- Love

.#{$gik-prefix}gradient-light-love {
  @include gradient-light-love();
}
.#{$gik-prefix}gradient-love {
  @include gradient-love();
}
.#{$gik-prefix}gradient-dark-love {
  @include gradient-dark-love();
}

// ----- Warm

.#{$gik-prefix}gradient-light-warm {
  @include gradient-light-warm();
}
.#{$gik-prefix}gradient-warm {
  @include gradient-warm();
}
.#{$gik-prefix}gradient-dark-warm {
  @include gradient-dark-warm();
}

// ----- Purps

.#{$gik-prefix}gradient-light-purps {
  @include gradient-light-purps();
}
.#{$gik-prefix}gradient-purps {
  @include gradient-purps();
}
.#{$gik-prefix}gradient-dark-purps {
  @include gradient-dark-purps();
}

// ----- Neutral

.#{$gik-prefix}gradient-light-neutral {
  @include gradient-light-neutral();
}
.#{$gik-prefix}gradient-neutral {
  @include gradient-neutral();
}
.#{$gik-prefix}gradient-dark-neutral {
  @include gradient-dark-neutral();
}

// ----- Sage

.#{$gik-prefix}gradient-light-sage {
  @include gradient-light-sage();
}
.#{$gik-prefix}gradient-sage {
  @include gradient-sage();
}
.#{$gik-prefix}gradient-dark-sage {
  @include gradient-dark-sage();
}

// ----- Oters

/* Gradient/GIK Page Header Darkening */
.#{$gik-prefix}gradient-page-header-darkening {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.09) 52.56%, rgba(0, 0, 0, 0.21) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}
