+b(stream-post)
  @apply tw-bg-white tw-shadow-base tw-pt-4 tw-pb-1 tw-rounded-md

  &__empty-comments
    @apply tw-text-center

  &__comments-wrapper
    @apply tw-px-2
    @screen md
      @apply tw-px-4

  &__comments
    @apply tw-text-neutral-700 tw-text-sm tw-flex-1

  &__error
    @apply tw-text-xs tw-ml-4 tw--mt-1 tw-text-danger-500 tw-block tw-mb-2
    a
      @apply tw-underline
      &:hover
        @apply tw-font-bold

  &__btn-more
    @apply tw-shadow-none

  &__more-menu .gik-popover-menu-item__icon
    @apply tw-text-neutral-700

  &__header
    @apply tw-px-2 tw-flex
    @screen md
      @apply tw-px-4

  &__header-main
    @apply tw-flex tw-flex-1 tw-items-center
    > .gik-avatar
      @apply tw-flex-shrink-0 tw-cursor-pointer
      height: 48px
      width: 48px

  &__content-wrapper
    @apply tw-p-2 tw-gap-2
    @screen md
      @apply tw-px-4

    .gik-user-mention
      @apply tw-font-semibold tw-cursor-pointer

    .gik-stream-link
      @apply tw-underline

  &--styled &__content-wrapper
    @apply tw-flex tw-items-center tw-justify-center
    .gik-stream-link
      @apply tw-font-semibold tw-text-primary-500 tw-no-underline


  &--styled &__text-wrapper
    min-height: 300px
    @apply tw-flex-1 tw-flex tw-items-center tw-justify-center tw-rounded
    @apply tw-font-semibold tw-text-2xl tw-leading-tight

  &__date
    @apply tw-text-sm tw-text-neutral-700

  &__text
    @apply tw-py-2 tw-whitespace-pre-wrap tw-flex-1

  &__content
    @apply tw-text-neutral-900 tw-text-lg tw-flex-1 tw-ml-2 tw-leading-none

  &__username
    @apply tw-font-bold tw-text-primary-600 tw-text-base tw-inline-block tw--mb-2

  +b(comment-user-display)
    @apply tw-mb-2

  .gik-stream-reactions__reactions-wrapper
    @apply tw-px-2
    @screen md
      @apply tw-px-4

  .gik-stream-comments__pagination--top
    > .gik-stream-pagination__header
      @apply tw-border-b tw-border-neutral-300 tw-mb-4 tw-pb-2
      @apply tw-px-2
      @screen md
        @apply tw-px-4
    > .gik-stream-pagination__main
      @apply tw-px-2
      @screen md
        @apply tw-px-4
    > .gik-stream-pagination__footer
      @apply tw-px-2
      @screen md
        @apply tw-px-4

  .gik-stream-pagination--empty.gik-stream-comments__pagination--top
    > .gik-stream-pagination__header
      @apply tw-mb-0 tw-border-b-0

  &__actions
    @apply tw-border-t tw-border-b tw-border-neutral-300 tw-py-1 tw-flex tw-mb-4
    > .gik-popover__reference-element
      @apply tw-flex-1
      +b(button)
        @apply tw-w-full

    +b(button)
      @apply tw-shadow-none tw-flex-1 tw-outline-none
      --bg: none
      --bg-hover: none
      --border-size: 0

    > .gik-button
      --color: theme('colors.neutral.600')
      &:hover
        --color: theme('colors.neutral.800')

  &--styled &__text
    @apply tw-p-4 tw-text-center tw-text-neutral-900 tw-text-3xl tw-font-semibold tw-font-heading

    .gik-user-mention
      @apply tw-text-neutral-900 tw-underline

    // a
    //   @apply tw-text-primary-500 tw-font-bold tw-no-underline

  &--empty-text &__text
    @apply tw-text-neutral-700 tw-font-semibold

  &--color-aqua &__text-wrapper
    @apply tw-bg-primary-200

  &--color-grey &__text-wrapper
    @apply tw-bg-neutral-200

  &--color-red &__text-wrapper
    @apply tw-bg-danger-200

  &--color-orange &__text-wrapper
    @apply tw-bg-secondary-200

  &--color-green &__text-wrapper
    @apply tw-bg-success-200

  &--color-blue &__text-wrapper
    @apply tw-bg-info-200

  &--color-purple &__text-wrapper
    @apply tw-bg-purple-200

  &--color-yellow &__text-wrapper
    @apply tw-bg-warn-200

