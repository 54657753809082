+b(calendar-claim-header)
  @apply tw-border-b tw-border-neutral-300 tw-pb-2
  @screen md
    @apply tw-flex tw-justify-between tw-items-center

  +e(icon)
    @apply tw-mr-2 tw-flex-shrink-0

    +b(image)
      height: 40px

  +e(icon--2-lines)
    margin-top: 6px
    +b(image)
      height: 48px


  +e(label-content)
    @apply tw-flex tw-items-center

  +e(label)
    @apply tw-text-2xl

  +e(number-of-people)
    @apply tw-text-neutral-800 tw-font-bold

  +e(date-content)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-end tw-content-end
    @screen md-down
      @apply tw-flex tw-flex-col-reverse tw-justify-end tw-items-start tw-content-start
      +b(button)
        @apply tw-mt-1

  +b(date-time-display)
    @screen md
      @apply tw-text-right

    +e(date)
      @apply tw-font-bold tw-text-neutral-800

    +e(time)
      @apply tw-text-neutral-700

    +e(separator)
      @apply tw-px-1 tw-text-neutral-500 tw-font-bold

  +e(allowedService)
    @apply tw-font-bold tw-italic tw-text-sm tw-leading-3

  +e(custom-title)
    @apply tw-text-sm tw-leading-3

  +e(title)
    @apply tw-text-center tw-text-2xl tw-font-medium
    color: var(--color)

// specific height for header icons based on event type
.gik-calendar-event-type-theme--meal-dinner,
.gik-calendar-event-type-theme--meal-breakfast,
.gik-calendar-event-type-theme--meal-lunch
  .gik-calendar-claim-header__icon
    height: 40px

  .gik-calendar-claim-header__icon--2-lines
    height: 58px
