$blockName: '#{$gik-prefix}how-it-works-page';

.#{$blockName} {
  .#{$gik-prefix}situations-overview-section {
    @apply tw-pb-48;
  }
  .#{$gik-prefix}start-inkind-card {
    @apply tw--mt-24;
  }

  &__lead {
    @apply tw-pt-8;
  }

  &__video {
    @apply tw-pt-16;
    > .gik-container {
      max-width: 790px;
    }
  }

  &__start-inkind {
    @apply tw-pt-0;
  }

  .gik-feature-steps-section {
    @apply tw-mb-8;
  }
}
