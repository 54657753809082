$blockName: #{$gik-prefix}claimed-items;

.#{$blockName}-skeleton {
  --background-color: $empty-color;

  .#{$blockName}__tabs-section .#{$gik-prefix}paragraph-bone {
    margin: 8px 25px -1px;
  }

  .#{$gik-prefix}frame-bone.#{$gik-prefix}claimed-item-card {
    --background-color: #f3f6f6;
    margin-bottom: 10px;
    width: 100%;

    & > div {
      border-radius: 20px;
      padding: 10px 0 0 20px;
    }
  }
}
