+b(gift-card-editor)

  &__preview-wrapper
    @apply tw-bg-white tw-pointer-events-auto

  &__preview-fade
    background: linear-gradient(#ffffff, #ffffff00)
    height: 30px
    @apply tw-pointer-events-none

  @screen md-down-landscape
    +e(sticky)
      position: initial
