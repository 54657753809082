+b(inkind-user-update-timeline-card)
  @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
  align-content: stretch

  +e(title)
    @apply tw-font-heading tw-font-medium tw-text-3xl tw-leading-none tw-text-neutral-900

    & > *
      padding-bottom: 0.117em

  +e(updated-by)
    @apply  tw-font-normal tw-text-base tw-leading-normal tw-text-neutral-700

  +e(attachments-label)
    @apply tw-uppercase tw-font-normal tw-text-xs tw-leading-relaxed tw-text-neutral-700

  +e(attachments-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start

  +e(attachment-wrapper)
    @apply tw-h-20
    @apply tw-relative
    @screen md-down
      @apply tw-h-12


    &:not(:last-child)
      @apply tw-mr-6
      @screen md-down
        @apply tw-mr-4


    & > *
      @apply tw-h-full

    +m(photo)
      @apply tw-w-20
      @screen md-down
        @apply tw-w-12

    +m(video)
      width: 128px
      @screen md-down
        width: 76px

    +m(photo video)
      +b(inkind-user-update-timeline-card)
        +e(attachment)
          width: 100%
          object-fit: cover

  +e(attachment-wrapper)
    @apply tw-cursor-pointer

  +e(attachment)
    &:not([src])
      display: none

  +e(play-icon-wrapper)
    @apply tw-absolute tw-inset-0
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +e(thumbnail-skeleton)
    @apply tw-absolute tw-w-full tw-h-full
    z-index: theme('zIndex.below')

  +e(aside)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-end tw-content-end tw-relative
    @screen md-down
      --gutter: 0

  +e(line)
    @apply tw-absolute
    height: CALC(100% + 10rem + 1.25rem)
    bottom: 3rem
    top: -1.25rem
    left: 23px
    z-index: theme('zIndex.below')

  main
    @apply tw-mt-3

  +e(aside-content)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > *
      @apply tw-mt-3

  +e(date)
    $size: 20
    @apply tw-rounded-full tw-h-#{$size} tw-w-#{$size}
    @apply tw-bg-neutral-200
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +e(week-day year month-and-day)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-800 tw-m-0

  +e(month-and-day)
    @apply tw-font-semibold tw-text-lg

  +e(aside)
    width: unset !important

  +m(deleting)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
    min-height: 170px

  +b(stream-reactions)
    @apply tw-border-t tw-border-neutral-300 tw-mt-4

  .gik-stream-comments__pagination--top
    @apply tw-border-b tw-border-neutral-300

  .gik-stream-pagination--empty
    @apply tw-border-b-0
