+b(other-ways-to-support)
  +e(title)
    @apply tw-font-bold

  +e(gofundme-embed)
    @apply tw-w-full #{!important}
    @screen md
      height: 132px
    @screen md-down
      height: 160px
  +e(amazon-wishlist)
    @apply tw-mb-0 #{!important}

  +e(disclaimer)
    @apply tw-w-full tw-text-center tw-text-xs tw-text-neutral-500 tw-leading-none tw-mt-1 tw-mb-3

  +e(donations)
    @apply tw-flex tw-flex-col tw-gap-2
