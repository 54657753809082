+b(contact-page-organizers)
  +e(content)
    @apply tw-px-4 tw-pt-8 tw-pb-2
  +e(filler)
    @apply tw-flex-1


+b(page-organizer-display)
  +e(contact-line)
    @apply tw-w-full tw-flex tw-flex-col
    main
      @apply tw-w-full tw-flex tw-items-center tw-justify-start
    +b(avatar)
      @apply tw-mr-2 tw-flex-shrink-0 tw-w-12 tw-h-12
  +e(email)
    @apply tw-text-left tw-mt-1
  +e(contact-line-content)
    @apply tw-w-full tw-flex tw-flex-col tw-justify-start tw-items-start
  +e(name)
    @apply tw-font-normal tw-text-lg tw-leading-none tw-text-neutral-900 tw-flex-1
  +e(top)
    @apply tw-w-full tw-flex tw-items-center tw-justify-start tw-gap-2 tw-flex-wrap
    min-width: 150px
  +e(owner)
    @apply tw-font-bold tw-text-sm tw-leading-none tw-text-neutral-600 tw-uppercase
