.modals.gik-modal-checkout {
  .gik-steps {
    @apply tw-mt-1 tw-absolute;
  }

  .button-footer {
    .gik-button {
      min-width: 200px;
    }
  }
}

$checkout-nav-height: 4rem;
.gik-modal-checkout-new {
  > .gik-modal__content-wrapper > .content {
    @apply tw-px-6;
  }
  > .gik-modal-header {
    height: 0;

    .gik-steps {
      margin-top: 18px;
      height: $checkout-nav-height;
    }
  }

  .gik-checkout-form-wrapper {
    margin-top: $checkout-nav-height;

    .gik-form-header {
      @apply tw-pb-2 tw-mb-1 tw-font-semibold;
    }
  }
}

.gik-order-status-popup-wrapper {
  @apply tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-flex tw-items-center tw-justify-center tw-z-above;
  background: rgba(255,255,255,0.7);

  .gik-loading-popup__message{
    @apply tw-capitalize;
  }
}

// .modals.modal-v2.gik-modal-checkout-new {
//   .gik-steps__content-wrapper {
//     @apply tw-pt-10;
//   }
// }

.gik-creditcards {
  @apply tw-inline-block;
  > * {
    height: 20px;
    display: inline-block;
  }
  * + * {
    @apply tw-ml-1;
  }
}

.gik-checkout-form-confirm-cancel-purchase {
  @screen md {
    min-width: 400px;
  }

  &__copy-top {
    @apply tw-font-normal tw-text-base tw-leading-snug;
  }

  &__copy-bottom {
    max-width: 277px;
    @apply tw-font-normal tw-text-sm tw-leading-snug tw-italic tw-mx-auto;
  }

  a {
    @apply tw-text-primary-600;
  }
}

.gik-modal__footer--add-from-calendar {
  @apply tw-hidden;
}
