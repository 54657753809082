+b(user-group-card)
  @apply tw-bg-white tw-rounded-md tw-border-3 tw-border-neutral-400
  @apply tw-flex tw-overflow-hidden
  min-height: 110px

  &__image-wrapper
    width: 110px
    @apply tw-p-4 tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    background: var(--background-image)
    background-size: cover

  &--has-gradient &__image-wrapper
    background: linear-gradient(225deg, var(--gradient-color-1) -0.2%, var(--gradient-color-2) 99.8%)

  &__icon
    @apply tw-cursor-pointer tw-flex-shrink-0

  &__title
    @apply tw-font-heading tw-text-xl tw-font-semibold tw-mb-1
    +lineClamp(2)

  &__location
    @apply tw-text-sm tw-text-neutral-600

  &__content-inner
    @apply tw-py-2 tw-px-3 tw-flex tw-items-center tw-flex-1

  &__content
    @apply tw-flex tw-flex-col tw-flex-1

  &--muted &__content
    @apply tw-opacity-30
