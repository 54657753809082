+b(wishlist-aside)
  +e(suggestions)
    @apply tw-flex tw-flex-wrap tw-flex-row tw-justify-center tw-items-start tw-content-start
    & > *
      flex: 0 0 50%

  +e(wishlist-features)
    @apply tw-my-6 tw-list-disc tw-list-outside
    padding-inline-start: 1rem
    &:first-of-type
      @apply tw-mt-0
    li
      @apply tw-text-base tw-leading-tight tw-mb-3
      &:last-child
        @apply tw-m-0

  +b(gift-card-suggestion)
    +e(thumbnail)
      width: unset
      height: unset

  +b(gift-card-skeleton)
    margin: 4px
    margin-bottom: 30px
    height: 79px
    max-width: 125px
