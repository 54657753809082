+b(page-name-ideas)
  @apply tw-p-4 tw-text-center
  +e(title)
    @apply tw-text-neutral-600 tw-font-bold tw-mb-8 tw-block
    &:not(:first-of-type)
      @apply tw-mt-12
  +e(list)
    @apply tw-text-neutral-900
    > li
      @apply tw-text-2xl tw-cursor-pointer tw-mb-8

  +m(loading)
    @apply tw-pt-12

+b(page-name-ideas-skeleton)
  @apply tw-mt-20 tw-w-full
