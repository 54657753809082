+b(partner-claim-form)
  @apply tw-text-center tw-mx-4

  +e(order-now)
    img
      @apply tw-mx-auto tw-text-neutral-600
  +e(order-now-description)
    @apply tw-mt-6 tw-mb-2

  +e(private-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-mt-8
    > *
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > :last-child
      @apply tw-ml-1
