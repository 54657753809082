+b(gift-card-tile)
  @apply tw-text-center tw-relative tw-select-none

  +e(label)
    @apply tw-font-bold tw-text-xs tw-leading-tight tw-mt-1 tw-text-neutral-700 tw-block

  +e(image)
    @apply tw-border tw-border-neutral-900 tw-bg-white tw-rounded tw-flex tw-items-center tw-justify-center

  +e(inner)
    +m(highlight)
      @apply tw-ring-2 tw-ring-offset-2 tw-rounded-sm

  +b(keep-proportions)
    @apply tw-relative
    &::before
      content: ''
      @apply tw-border-3 tw-border-transparent tw-rounded-sm tw-absolute tw-inset-0

  +m(selected)
    +b(keep-proportions)
      &::before
        @apply tw-border-primary-500

  +e(button)
    @apply tw-rounded-full tw-absolute tw-border tw-border-white
    @apply tw-flex tw-items-center tw-justify-center
    @apply tw-cursor-pointer tw-z-10

    top: -7px
    right: -7px
    width: 22px
    height: 22px

    > svg
      @apply tw-text-white

    +m(delete)
      @apply tw-bg-danger-500

      > svg
        width: 16px

    +m(add)
      @apply tw-bg-success-500

