
+b(yes-no-button-input)
  @apply tw-inline-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +e(button)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none
    @apply tw-px-2 tw-py-3 tw-uppercase tw-rounded-full tw-cursor-pointer

    // no close tw units that keep a decent aspect ratio
    width: 3.3125rem
    height: 2.875rem

    color: var(--text-color)
    background-color: var(--background-color)

    +m(yes-button)
      @apply tw-mr-2
      --text-color: theme('colors.success.600')
      --background-color: theme('colors.neutral.100')

    +m(no-button)
      @apply tw-ml-2
      --text-color: theme('colors.danger.600')
      --background-color: theme('colors.neutral.100')

    +m(selected)
      color: var(--background-color)
      background-color: var(--text-color)
