+b(blog-homepage)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch

  &__section-3
    +b(articles-grid)
      &__articles
        @screen md-down
          @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
          align-content: stretch

  &__section-4-5-wrapper
    +b(container)
      @screen md-down
        @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
        align-content: stretch

      @screen md
        @apply tw-flex tw-gap-6
        & > *
          flex: 0 1 50%

  &__section-wrapper
    @screen md-down
      @apply tw-pb-0
