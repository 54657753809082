// default autofill utility class
.#{$gik-prefix}autofill {
  //@apply tw-bg-info-50 tw-text-black #{!important};

  background-color: #dbedff !important;

  &::first-line {
    font-size: 1rem;
  }
}

// make sure password managers autofill styles also look consistent
input[data-com-onepassword-filled='light'],
select[data-com-onepassword-filled='light'],
input[data-dashlane-autofilled='true'],
select[data-dashlane-autofilled='true'],
input:-internal-autofill-selected,
input:-internal-autofill-previewed,
{
  @extend .gik-autofill;
}

// TODO override other passwords managers' styles (at least LastPass and Dashlane?)
