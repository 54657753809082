
+b(stream-posts)
  @apply tw-bg-neutral-100 tw-py-4 tw-pb-8

  @screen md
    @apply tw-px-4

  .gik-heading-new
    @apply tw-mb-4 tw-px-4

  +b(loading-spinner)
    @apply tw-mt-8

  &__stream-input-wrapper
    @apply tw-bg-white tw-py-2 tw-px-2 tw-rounded-md tw-shadow-base tw-mx-auto tw-mb-4
    @screen md
      @apply tw-px-4 tw-py-4


  > .gik-container
    max-width: 600px

  &__empty
    svg
      width: 70px
      height: 70px
    @apply tw-mt-4 tw-text-center tw-text-lg tw-text-neutral-600 tw-font-bold
    @apply tw-bg-neutral-200 tw-rounded-md tw-p-4 tw-flex tw-items-center tw-justify-center
    min-height: 300px

  +b(stream-post)

    &:not(:first-of-type)
      @apply tw-border-t tw-border-neutral-300 tw-mt-4

  &__post + .gik-stream-pagination__load-next
      @apply tw-mt-4


+b(post-input-modal)
  $blockName: &

  &.modals.modal-v2
    //overflow: visible
    .gik-modal__content-wrapper
      //overflow: visible
      display: flex
      flex-direction: column
    .content
      height: 100%
      display: flex
      flex-direction: column

    @screen md
      max-width: 440px
      height: 500px

  &__footer
    @apply tw-mb-2 tw-m-4 tw-mt-2
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
    @apply tw-gap-4
