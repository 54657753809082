+b(reaction-button)
  &__btn
    --color: theme('colors.neutral.700')
    &:hover
      --color: theme('colors.neutral.900')

    svg path
      // color: var(--color)
      fill: transparent
      stroke: var(--color)

    .gik-svg-icon--prayers,
    .gik-svg-icon--strong
      path
        stroke: transparent
        fill: var(--color)

    &--selected-love
      --color: theme('colors.danger.600')
      &:hover
        --color: theme('colors.danger.700')
    &--selected-like
      --color: theme('colors.primary.600')
      &:hover
        --color: theme('colors.primary.700')
    &--selected-dislike
      --color: theme('colors.danger.600')
      &:hover
        --color: theme('colors.danger.700')
    &--selected-haha
      --color: theme('colors.warn.600')
      &:hover
        --color: theme('colors.warn.700')
    &--selected-strong
      --color: theme('colors.success.600')
      &:hover
        --color: theme('colors.success.700')
    &--selected-prayers
      --color: theme('colors.primary.600')
      &:hover
        --color: theme('colors.primary.700')
    &--selected-sad
      --color: theme('colors.info.600')
      &:hover
        --color: theme('colors.info.700')
    &--selected-angry
      --color: theme('colors.red')
      &:hover
        --color: theme('colors.red')
    &--selected-celebrate
      --color: theme('colors.secondary.600')
      &:hover
        --color: theme('colors.secondary.700')
    &--selected-sick
      --color: theme('colors.success.600')
      &:hover
        --color: theme('colors.success.700')
