$blockName: '#{$gik-prefix}situations-overview-section';

.#{$blockName} {
  @apply tw-text-center;
  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-5xl tw-leading-none tw-mb-16;
  }
  &__lead {
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-neutral-800 tw-mt-4;
  }
}
