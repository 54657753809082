@mixin type($typeName)
  +m($typeName)
    @apply tw-bg-#{ $typeName }-50

  +m($typeName, step-number)
    @apply tw-bg-#{ $typeName }-500


+b(step-card)
  @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start
  @apply tw-rounded tw-p-2

  +e(step-number)
    @apply tw-rounded-full tw-mr-2 tw-text-white
    @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    // does not exist? -> @apply tw-w-7 tw-h-7
    width: 1.75rem
    height: 1.75rem

  +e(content)
    @apply tw-flex-1
    @apply  tw-font-normal tw-text-base tw-leading-tight

  +type(primary)
  +type(danger)
  +type(success)
  +type(secondary)
