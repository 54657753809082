+b(homepage-badge)
  --badge-size: 105px
  --icon-size: 40px

  backdrop-filter: blur(10px)
  background: rgba(255, 255, 255, 0.8)

  @apply tw-shadow-lg tw-rounded-full tw-flex tw-flex-col tw-items-center tw-justify-center
  width: var(--badge-size)
  height: var(--badge-size)

  +e(icon)
    width: var(--icon-size)

  +e(title)
    @apply tw-font-ui tw-text-base tw-font-semibold tw-tracking-normal tw-text-neutral-800

  @screen md-down
    --badge-size: 75px
    --icon-size: 30px

    +e(title)
      @apply tw-text-xs

  @screen xs-down
    --badge-size: 70px
    --icon-size: 28px

    +e(title)
      @apply tw-text-xs

