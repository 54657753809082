+b(calendar-day__list)
  +b(calendar-announcement)

+b(calendar-event)
  --color: theme('colors.black')
  @apply tw-rounded tw-shadow-sm tw-flex-1 tw-flex tw-overflow-hidden tw-bg-white tw-items-center




  // @screen print
  //   @apply tw-shadow-none
  //   padding: 6px
  //   border-bottom: 1px dotted #ddd
  //   &:last-child
  //     border-bottom: none
  //   > *
  //     @apply tw-align-middle

  +m(clickable)
    @apply tw-cursor-pointer

  +e(providedBy-giftcard)
    @apply tw-overflow-hidden
    text-overflow: ellipsis
    @screen md
      @apply tw-block

  &:not(.gik-calendar-event--announcement)

    @apply tw-whitespace-nowrap tw-p-2 tw-pr-0
    @apply tw-border-l-4
    border-color: var(--border-color)

    @screen md
      min-width: 90px
    // @screen print
    //   @apply tw-bg-transparent tw-p-0
    //   min-width: 150px

  +e(content-main)
    @apply tw-flex-1 tw-text-neutral-600 tw-overflow-hidden

    @screen md
      @apply tw-p-2 tw-flex tw-items-center

    // @screen print
    //   @apply tw-ml-3 tw-p-0 tw-flex-1
    //   > *
    //     @apply tw-inline-block

    p
      @apply tw-m-0

  +e(content-wrapper)
    @apply tw-flex tw-flex-col-reverse tw-flex-1 tw-overflow-hidden
    @screen md
      @apply tw-flex-row

  +e(label)
    @apply tw-mr-4 tw-mb-1 tw-overflow-hidden
    text-overflow: ellipsis

  +e(content)
    @apply tw-leading-tight tw-flex tw-items-center tw-overflow-hidden



  +e(number-of-people)
    @apply tw-align-bottom tw-whitespace-nowrap tw-text-neutral-800 tw-font-bold

  +e(startTime)
    @screen md
      @apply tw-block

  +e(icon)
    @apply tw-mr-2 tw-mb-1
    width: 20px
    height: 20px

    @screen md
      width: 24px
      height: 24px

  +e(content-title)
     @apply tw-flex tw-items-center tw-flex-shrink-0 tw-overflow-hidden tw-max-w-full

  +e(actions)
    @apply tw-flex tw-items-center tw-flex-shrink-0
    // @screen print
    //   @apply tw-py-0

  +e(date-mobile)
    @apply tw--mt-2 tw-mb-2
    +b(date-time-display)
      @apply tw-text-neutral-700 tw-text-sm

  +e(box-giftcard)
    @apply tw-hidden tw-font-bold tw-text-neutral-700 tw-text-sm tw-leading-tight tw-overflow-hidden
    text-overflow: ellipsis

    @screen md
      @apply tw-inline-block

  +e(box-all-day)
    @apply tw-font-bold tw-text-neutral-700 tw-text-sm tw-leading-tight

  +e(box-all-day-wrapper)
    @screen md
      @apply tw-flex tw-items-center tw-justify-center tw-h-full

  +e(custom-title)
    @apply tw-text-sm

  +e(title)
    @apply tw-font-heading tw-font-bold tw-align-middle tw-text-xl tw-whitespace-nowrap
    color: var(--color)

  +e(content-header)

    @screen md
      @apply tw-flex tw-items-center tw-justify-center
      min-width: 80px

    +b(date-time-display)
      @apply tw-text-neutral-700 tw-text-sm tw-hidden
      @screen md
        @apply tw-text-center tw-flex tw-justify-center

      min-width: 80px

  +m(announcement)
    @apply tw-border tw-border-primary-500 tw-bg-primary-50 tw-p-1 tw-px-0
    .gik-calendar-event__content-header
      @apply tw-mr-1 tw-flex tw-items-center tw-justify-center
      @screen md
        min-width: 100px

      svg
        @apply tw-text-primary-500

    .gik-calendar-event__content-main
      @apply tw-block tw-p-0

    .gik-calendar-event__title
      @apply tw-block tw-text-lg

  +e(announcement-description)
    @apply tw-m-0 tw-text-neutral-900

  +b(avatar)
    width: 20px
    height: 20px
    @apply tw-align-bottom tw-ml-1

  +e(claim-actions)
    @apply tw-pr-2

  +e(providedBy)
    @apply tw-inline-block tw-align-bottom tw-text-sm tw-overflow-hidden
    @apply tw-overflow-hidden
    text-overflow: ellipsis

  +e(providedBy-description)
    @apply tw-overflow-hidden
    text-overflow: ellipsis


  +e(providedBy-from)
    @apply tw-font-bold tw-overflow-hidden
    text-overflow: ellipsis

  +e(name-link)
    @apply tw-leading-none tw-overflow-hidden
    text-overflow: ellipsis
    font-size: 14px

  +e(giftcard)
    @apply tw-h-full tw-mr-2

    +b(keep-proportions)
      width: 56px

  +e(claimed)
    @apply tw-h-full tw-flex tw-items-center tw-justify-center
    min-width: 50px
    svg
      @apply tw-text-success-600
      width: 36px
      height: 36px
    @screen md
      min-width: 72px


  +e(thumbnail)
    @apply tw-rounded
