
+b(inkind-calendar-empty-view)
  @apply tw-text-center tw-p-6 tw-pb-0 tw-items-center tw-text-neutral-800 tw-overflow-hidden tw-relative tw-flex tw-flex-1 tw-pb-4
  // min-height: $calendar-min-height
  background: rgba(225, 236, 236, 0.9)

  > div
    @apply tw-text-center tw-relative tw-flex-1

  // center image for anonymous users
  img
    @apply tw-mx-auto

  // center lottie animation for organizers
  +e(animation)
    @apply tw-mx-auto
    width: 265px
    margin-bottom: -1rem

  +e(leadText)
    @apply tw-text-2xl tw-font-bold tw-leading-tight tw-mx-auto
    max-width: 430px

    mark
      @apply tw-text-secondary-500 tw-bg-transparent

  +e(organizer)
    @apply tw-cursor-pointer

  +e(footer)
    @apply tw-italic tw-text-neutral-700 tw-w-full tw-text-center tw-pt-2

  // &__wrapper

  .gik-button--default-link
    --color: theme('colors.neutral.700')
    --color-hover: theme('colors.neutral.700')

  &__content
    max-width: 320px
    @apply tw-mx-auto

  &__title
    @apply tw-text-2xl tw-font-bold tw-leading-tight tw-whitespace-pre-wrap tw-text-neutral-900 tw-mt-0

  &__subtitle
    @apply tw-whitespace-pre-wrap tw-text-neutral-700 tw-mb-8

  &__logo-upcoming
    @apply tw-mb-6
    svg
      @apply tw-mx-auto

  &__logo svg
    @apply tw-mx-auto

  // TODO: confirm with Bruno if position relative on gik-animation is really needed
  .gik-animation
    @apply tw-static

