$blockName: '#{$gik-prefix}situation-tile';

.#{$blockName} {
  @apply tw-text-center;
  &__image {
    @apply tw-mx-auto tw-h-16;
  }
  &__title {
    @apply tw-text-lg tw-leading-none tw-block tw-mt-4;
  }
}
