+b(perfectgift-faceplate-selector)
  // the aspect ratio for faceplates is: 1.56:1 (14:9)
  --width: calc(640px/3.6)
  --height: calc(410px/3.6)

  &__grid
    @apply tw-grid tw-gap-4 tw-text-center tw-mb-8

    @screen xs
      @apply tw-grid-cols-2

    @screen sm
      @apply tw-flex tw-flex-wrap
      @apply tw-gap-8

  &__grid-tile--selected
    @apply tw-border-primary-500 tw-border-2 tw-rounded-sm

  &__grid-tile
    @apply tw-rounded-sm
    cursor: pointer
    width: var(--width)
    box-shadow: grey 0px 1px 11px -5px

    img
      @apply tw-rounded-sm

