@import './base/vars'
@import './base/colors'
@import './fonts/presets'

@import './mixins/gradients'
@import './mixins/scrollbars'
@import './mixins/bem'
@import './mixins/breakpoint'
@import './mixins/focus-shadow'
@import './mixins/lineClamp'

// ----- helpers used in other files
@import './helpers/circular'
@import './helpers/selection'
@import './helpers/disabled'
@import './helpers/blur'
@import './helpers/gradients'
@import './helpers/placeholder'
@import './helpers/autofill'
@import './helpers/image-as-background'
@import './helpers/box-border'

// effects/animations
@import './effects/barberpole'
@import './effects/skeleton-animation'




// @layer utilities
// ----- Text colors

.gik-text-error
  @apply tw-text-danger-500

.gik-text-muted
  @apply tw-text-neutral-700

.gik-heading
  @apply tw-block tw-font-heading tw-break-words

.gik-h1
  @extend .gik-heading
  @apply tw-text-6xl tw-mt-4 tw-mb-4

.gik-h2
  @extend .gik-heading
  @apply tw-text-4xl tw-mt-3 tw-mb-4

.gik-h3
  @extend .gik-heading
  @apply tw-text-2xl tw-mt-4 tw-mb-4

.gik-h4
  @extend .gik-heading
  @apply tw-text-xl tw-mt-2 tw-mb-2

.gik-h5
  @extend .gik-heading
  @apply tw-text-lg tw-mt-2 tw-mb-2

.gik-h6
  @extend .gik-heading
  @apply tw-mt-2 tw-mb-2


