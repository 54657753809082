.#{$gik-prefix}article-author-link {
  display: flex;
  justify-content: flex-start;
  .author {
    color: map_get($blog-colors, text-secondary);
    @apply tw-font-body;
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
  }
}
