+b(page-settings-upgrade-section)
  @apply tw-bg-neutral-50 tw-rounded-t-md tw-p-6
  > div
    +gradient-warm()
    @apply tw-rounded-md tw-relative tw-overflow-hidden tw-p-6 tw-shadow-2xl

  +b(popover__reference-element)
      @apply tw-w-full

  +m(free-premium-page-upgrades)
    > div
      background: linear-gradient(256.49deg, #406D05 0%, #55832E 33.5%, #55832E 68.23%, #225311 100%)
