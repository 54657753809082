@use "sass:math"

+b(search-drawer)
  +e(close)
    position: absolute
    top: 0.75rem
    right: 14px
    @screen md
      position: initial
      @apply tw-ml-auto

  +e(drawer)
    --animation-speed: 0.25s
    @apply tw-sticky tw-top-0 tw-h-0 tw-z-belowUnbounce
    transform: translateY(-125px)
    transition: var(--animation-speed) ease-in-out
    +m(open)
      transform: translateY(0)

    +b(nav-bar)
      +e(container)
        @apply tw-flex-1
        @screen md-down
          @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-mx-3 tw-mb-3
          align-content: stretch

    .gik-popover__reference-element, .gik-search-drawer__drawer-content, .gik-search-input
      width: 100%
      @screen md
        width: initial

  +e(results)
    @apply tw-z-dialogs tw-flex-col
    @apply tw-flex
    top: 0
    left: 0
    right: 0
    position: sticky
    padding-top: 104px
    height: 100vh
    margin-bottom: -100vh
    @screen md
      padding-top: 70px
    +m(has-query)
      max-height: 100vh
      background: white

  +e(overflow-container)
    @apply tw-flex-1 tw-flex tw-flex-col
    overflow-y: auto

  +e(drawer-content)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full

  +e(bar)
    @apply tw-z-appNav tw-bg-white

    @screen md
      min-height: var(--desktop-header-height)
    @screen md-down
      min-height: var(--mobile-header-height)

    > .gik-container
      @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start
      @screen md
        @apply tw-flex-row tw-items-center tw-content-center

  +e(title)
    @apply tw-font-bold tw-text-base tw-leading-none tw-text-neutral-900 tw-m-0

  +e(popover)
    @apply tw-pt-1
    width: 552px

  &__results-section, &__recent-pages-section
    .slick-slide > div:not(last-child)
      margin-right: 14px
    +b(inkind-page-card)
      width: 140px

  +e(search-title)
    @apply tw-font-bold tw-text-xl tw-leading-none tw-text-neutral-900
    @screen md
      @apply tw-mr-4

  +e(no-results-section)
    @apply tw-flex-1
    +b(container)
      @apply tw-flex tw-h-full tw-flex-1

  +b(nav-bar)
    +e(container)
      @screen md-down
        @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-mx-3 tw-mb-3
        align-content: stretch

  .more-content-indicator
    display: none
