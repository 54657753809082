+b(checkout-promo-code)
  @apply tw-bg-neutral-50 tw-rounded tw-p-3

  &__title
    @apply tw-font-bold tw-block

  &__form
    @apply tw-flex tw-items-center

  &__error
    @apply tw-text-left tw-text-danger-500 tw-text-base

  &__code-prefix
    @apply tw-font-bold tw-text-neutral-600 tw-text-xl

  .gik-input__input
    @apply tw-text-xl tw-uppercase
  .gik-input__input::placeholder
    @apply tw-text-xl
  .gik-input__wrapper
    @apply tw-inline-flex tw-mr-2
    width: 110px

