.#{$gik-prefix}drawer {
  --z-index: theme('zIndex.drawer');
  --z-index-backdrop: theme('zIndex.drawerBackdrop');
  @apply tw-flex tw-flex-col;

  &--neutral &__box {
    @apply tw-bg-neutral-50 tw-text-neutral-800;
  }
  &--neutral &__header {
    @apply tw-bg-white;
  }
  &--neutral &__footer {
    @apply tw-bg-white;
  }

  &--fixed {
    .#{$gik-prefix}drawer__box {
      position: fixed;
    }
    .#{$gik-prefix}drawer-backdrop {
      position: fixed;
    }
  }

  &--padded &__content {
    @apply tw-py-2 tw-px-4;
  }

  &__footer {
    @apply tw-text-center tw-py-2;
  }

  &__box {
    @apply tw-absolute tw-bg-white tw-flex tw-flex-col;
    z-index: var(--z-index);
  }

  &--shadow &__box {
    @apply tw-shadow-xl;
  }

  &__title {
    @apply tw-p-4 tw-font-semibold tw-text-xl tw-leading-none tw-font-heading;
  }

  &__close {
    @apply tw-absolute tw-top-0 tw-right-0 tw-cursor-pointer tw-z-50 tw-pt-3;
    // same padding as the modal header close button
    padding-right: 14px;
  }

  &__content {
    @apply tw-overflow-auto tw-flex-1 tw-h-full;
  }

  &--left &__box {
    top: 0;
    left: 0;
    height: 100%;
    width: 240px;
    transform: translate3d(-100%, 0, 0);
  }

  &--right &__box {
    top: 0;
    right: 0;
    height: 100%;
    width: 240px;
    transform: translate3d(100%, 0, 0);
  }

  &--top &__box {
    top: 0;
    left: 0;
    // height: 200px;
    width: 100%;
    transform: translate3d(0, -100%, 0);
  }

  &--bottom &__box {
    bottom: 0;
    left: 0;
    // height: 200px;
    width: 100%;
    transform: translate3d(0, 100%, 0);
  }

  &__backdrop {
    @apply tw-absolute tw-inset-0 tw-overflow-hidden;
    background: rgba(255, 255, 255, 0.8);
    z-index: var(--z-index-backdrop);
  }

  .more-content-indicator {
    height: 1px;
    width: 100%;
    z-index: 100;
    $more-content-shadow-color: rgba(59, 65, 66, 0.3);
    opacity: 0;
    &.visible {
      opacity: 1;
    }
    &.top {
      // @apply tw-shadow-base;
      box-shadow: 0 2px 4px 1px $more-content-shadow-color;
    }
    &.bottom {
      // @apply tw-shadow-base;
      box-shadow: 0 -3px 4px 1px $more-content-shadow-color;
      margin-bottom: -1px;
    }
    transition: opacity var(--animation-speed) ease-in-out;
  }
}

@include b(drawer-actions) {
  @apply tw-p-2 tw-flex tw-justify-between;

  @include m(centered) {
    @apply tw-justify-center;
  }
}
