+b(gift-card-suggestion)
  @apply tw-relative
  padding: 4px
  @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(product-name)
    @apply tw-font-bold tw-text-xs tw-leading-none tw-pt-1 tw-text-center

  +e(thumbnail)
    width: 90px
    height: 56px
    @apply tw-rounded

  +e(dismiss-button)
    @apply tw-absolute tw-top-0 tw-right-0

  +b(button)
    width: 18px
    height: 18px

