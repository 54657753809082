+b(inkind-calendar)
  $blockName: &
  @apply tw-shadow-base tw-rounded-b tw-flex tw-flex-col
  max-height: var(--aside-height)

  @screen print
    @apply tw-shadow-none
    max-height: none

  > main
    @apply tw-relative tw-flex tw-flex-col
    flex: 1 1 550px
    &:not(#{$blockName}--no-height-constraint main)
      height: 550px
    @screen print
      height: auto

.modals.modal-v2.gik-claim-modal
  @screen md
    width: 700px

.modals.modal-v2.gik-calendar-modal
  @screen md
    width: 700px

  .gik-inkind-calendar-event-form
    @apply tw-px-4 tw-pt-2

  // .gik-modal-button-footer
  //   @apply tw-block tw-h-auto

  //   > * + *
  //     @apply tw-mt-2

  //   .gik-button
  //     &:not(:last-of-type)
  //       @apply tw-mx-0
  //     &:not(:first-of-type)
  //       @apply tw-mx-0

#root-portal
  @apply tw-absolute tw-inset-0 tw-z-popovers
  bottom: unset
  .react-datepicker-popper
    top: -7px !important


+b(form-group)
  &__label.gik-calendar-form-label
    @apply tw-block tw-font-bold tw-text-xl tw-leading-none tw-text-center tw-text-neutral-900 tw-mb-4
