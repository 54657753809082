+b(calendar-announcement-select-list)
  @apply tw-flex tw-flex-wrap

  > * + *
    @apply tw-ml-2
    @screen sm
      @apply tw-ml-3

  .gik-select-list__item--selected
    @apply tw-flex-1

  @screen sm
    .gik-select-list__item
      @apply tw-flex-1


  +e(select-wrapper)
    @screen sm
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen sm-down
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch
