$blockName: prefix(article-component-credits)

.#{$blockName}
  &__caption,
  &__credit
    @apply tw-text-neutral-800
    @apply tw-font-body
    font-size: 12px
    line-height: 14px

  &__caption
    @screen md
      float: left

  &__credit
    @screen md
      float: right

    @apply tw-text-neutral-600
    font-style: italic
    a
      @apply tw-text-neutral-600
      text-decoration: underline
