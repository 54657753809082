$blockName: '#{$gik-prefix}page-header';

.#{$blockName} {
  @apply tw-flex tw-items-center tw-justify-center tw-min-h-40 tw-text-center tw-font-normal tw-text-lg tw-leading-normal tw-overflow-hidden tw-py-8;

  h1,
  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-5xl tw-leading-none;
  }

  &--default {
    @apply tw-bg-neutral-500;
  }
}
