+b(user-profile)
  background-color: theme('colors.neutral.50')

  .backdrop-v2
    --animation-speed: 0

  &__wrapper
    @apply tw-pb-20 tw-flex tw-flex-row tw-flex-1
    @apply tw-mx-auto tw-w-full
    max-width: 1200px

  +b(layout-aside)
    @apply tw-w-full
    --gutter: 1rem
    @screen lg
      --gutter: 2rem

  +b(user-groups-section)
    @apply tw-mb-8

  .inkind-icon
    @apply tw-mr-4

  > aside
    @apply tw-flex tw-flex-col
    width: 328px
    min-width: 328px

  > main
    @apply tw-flex tw-flex-1 tw-flex-col tw-mt-12 tw-pr-4
    margin-right: 1m

  @screen md-down
    &__left-panel,
    &__right-panel
      @apply tw-hidden

    &__mobile-panel
      @apply tw-flex tw-flex-col
