$blockName: prefix(base-article-tile)

@mixin base-layout-row
    @apply tw-p-0
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch

    .#{$blockName}
      &__title
        @include lineClamp(2)
        //min-height: unset

      &__image-wrapper
        flex: 0 0 96px
        height: 96px

    & > main
      @apply tw-mt-0 tw-ml-2 #{!important}
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

.#{$blockName}
  @apply tw-font-medium tw-text-base tw-leading-tight tw-relative

  @screen md
    @apply tw-text-base

    .#{$blockName}__title
      @apply tw-text-2xl

  @screen md-down
    @apply tw-text-base

    .#{$blockName}__title
      @apply tw-text-lg

  &__remove-button
    @apply tw-absolute tw-top-0 tw-right-0

  &--layout-row
    +base-layout-row

  &--layout-auto
    @screen md-down
      +base-layout-row
      +b(keep-proportions)
        +e(wrapper)
          padding-bottom: 100% !important

  &__title
    @apply tw-font-heading tw-font-semibold tw-leading-tight tw-text-neutral-900
    // clamp to 2 lines
    // should be at least 2 lines
    @include lineClamp(2)
    // this is to make titles align in a grid
    //min-height: 60px

  &--no-clamping
    .#{$blockName}__title
      +unclamp()

  &:hover &__title
    @apply tw-text-primary-600

  &__category
    @apply tw-font-bold tw-text-sm tw-leading-none tw-text-primary-600
    :hover
      @apply tw-text-primary-700 tw-underline

    text-transform: capitalize

  &__image
    @apply tw-rounded-md tw-w-full
    background-size: cover !important
    background-position: center
    height: 80px
    @screen md
      width: 250px
      height: 180px

  &__description
    @apply tw-mt-0 tw-mb-3 tw-font-normal tw-text-base tw-leading-normal tw-text-neutral-900

    // clamp to 3 lines
    @include lineClamp(3, 1.6)

  > main
    @apply tw-mt-2

  &--no-link
    &:hover .#{$blockName}__title
      color: unset

  &--no-image
    .#{$blockName}__image
      @include gradient-aqua()

@mixin hero-variant($name)
  &--variant-#{$name}
    main
      @apply tw-bg-#{$name}-500
      transition: background-color 0.5s

    &:hover
      main
        @apply tw-bg-#{$name}-600

    .#{$blockName}__category,
    .#{$blockName}__title
      @apply tw-text-white #{!important}

@mixin hero-layout-row
  @apply tw-flex-row

  .#{$blockName}__image
    @apply tw-rounded-r-none tw-rounded-l-md
  .#{$blockName}__title
    @apply tw-mb-0
  main
    @apply tw-ml-0 #{!important}
    @apply tw-rounded-b-none
    @apply tw-rounded-r-md

@mixin hero-layout-col
  @apply tw-flex-col

+b(article-hero)
  @apply tw-flex tw-flex-1

  .#{$blockName}__image-wrapper
    flex: 0 0 50%
    height: unset

  .#{$blockName}__image
    @apply tw-rounded-b-none


  &.#{$blockName}--layout-row
    +hero-layout-row

  &.#{$blockName}--layout-column
    +hero-layout-col

  &.#{$blockName}--layout-auto
    @screen md-down
      +hero-layout-col
      +b(keep-proportions)
        +e(wrapper)
          padding-bottom: 72% !important
      a.#{$blockName}__title
        @apply tw-mb-0
      main
        @apply tw-ml-0 #{!important}
    @screen md
      +hero-layout-row
      & > main
        @apply tw-mt-0 #{!important}
        @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
        align-content: stretch
      +b(keep-proportions)
        +e(wrapper)
          padding-bottom: 100% !important

  main
    @apply tw-px-12 tw-rounded-b-md tw-mt-0 tw-py-4

  .#{$blockName}__title
    @apply tw-text-5xl tw-mb-6

  @screen lg-down
    .#{$blockName}__title
      @apply tw-text-3xl

  @screen md-down
    .#{$blockName}__title
      @apply tw-text-lg

  +hero-variant(primary)
  +hero-variant(secondary)

@mixin header-layout-row
  @apply tw-flex-row
  main
    @apply tw-px-12

@mixin header-layout-col
  @apply tw-flex-col

+b(article-header)
  @apply tw-flex-1 tw-flex

  .#{$blockName}__image-wrapper
    flex: 0 0 50%
    height: unset

  &.#{$blockName}--layout-row
    +header-layout-row

  &.#{$blockName}--layout-column
    +header-layout-col

  &.#{$blockName}--layout-auto
    @screen md-down
      +header-layout-col
      +b(keep-proportions)
        +e(wrapper)
          padding-bottom: 72% !important
      a.#{$blockName}__title
        @apply tw-mb-0
      main
        @apply tw-ml-0 #{!important}
    @screen md
      +header-layout-row
      & > main
        @apply tw-mt-0 #{!important}
        @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
        align-content: stretch


  main
    @apply tw-px-0

    @screen md-down
      @apply tw-mt-0 tw-py-4

  .#{$blockName}__title
    @apply tw-text-5xl tw-mb-0

  @screen md-down
    .#{$blockName}__title
      @apply tw-text-3xl

+b(article-carousel-tile)
  @apply tw-px-2 tw--mx-2

  @screen md
    @apply tw-text-base

    .#{$blockName}__title
      @apply tw-text-5xl

  @screen md-down
    @apply tw-text-base

    .#{$blockName}__title
      @apply tw-text-lg

.#{$gik-prefix}author-link
  @apply tw-flex tw-justify-start tw-text-neutral-700 tw-items-center tw-leading-none tw-mt-1

  .favourite-button-wrapper
    @apply tw-flex tw-items-center

  .favourite-button
    @apply tw-cursor-pointer

  .author
    @apply tw-font-body tw-font-bold tw-text-xs tw-leading-normal
    @include lineClamp(2, 1.5)

  > * + *
    @apply tw-ml-1

  time
    @apply tw-font-normal tw-text-sm tw-leading-tight

@mixin skeleton-layout-row
  @apply tw-flex tw-flex-row tw-justify-start tw-items-center
  align-content: stretch

  .gik-keep-proportions
    flex: 0 0 96px
    height: 96px
    @apply tw-mr-2

.#{$gik-prefix}article-tile-skeleton
  &__image-wrapper
    @apply tw-w-full

  &__image.gik-frame-bone
    --width: 100%
    --height: 100%

  &__description
    .gik-word-bone
      height: 0.5rem

    .gik-word-bone__bone
      height: 0.5rem

  > main
    @apply tw-pt-2

  &--layout-row
    +skeleton-layout-row


  &--layout-auto
    @screen md-down
      +skeleton-layout-row

  &--hero
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center
    align-content: stretch
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
      align-content: stretch


    .gik-keep-proportions
      @screen md
        flex: 0 0 480px
        height: 480px
        @apply tw-mr-12
