+b(event-services-select-list)

  &.gik-grid
    @apply tw-gap-3

  @screen md-down
    @apply tw-h-full tw-flex tw-flex-col

    +b(event-services-tile)
      @apply tw-flex-1 tw-h-full

    +m(hasSelection)
      +b(event-services-tile)
        @apply tw-flex-none

    +b(select-list__item--selected)
      @apply tw-flex-1
      +b(event-services-tile)
        @apply tw-flex-1 tw-h-auto

  > * + *
    @apply tw-mt-2

  +m(horizontal)
    @apply tw-flex tw-flex-row

    @screen xs-down
     @apply tw-flex-col

    > * + *
      @apply tw-ml-2 tw-mt-0

  @screen md
    +b(select-list__item)
      @apply tw-flex-1

    +m(vertical)
      +b(event-services-tile)
        height: 220px

  @apply tw-h-full
  +m(hasSelection)
    @apply tw-h-auto tw-w-full

  +e(select-wrapper)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch
