+b(horizontal-nav)
  @apply tw-overflow-hidden tw-inline-block tw-relative tw-w-full


  &__scrollbars
    height: 58px

  &--fade-sides &__content-wrapper
    mask: linear-gradient(to right, transparent 0%, #fff 3% 97%, transparent 100%), linear-gradient(to left, transparent 0%, #fff 3% 97%, transparent 100%)

  &__content-wrapper
    @apply tw-overflow-hidden tw-flex

  &--arrows
    padding: 0 30px

  &--arrows &__content-wrapper
    padding: 0 5px

  &--scrollbar &__content-wrapper
    @apply tw-overflow-x-auto

  @screen sm-down
    &__arrow-prev,
    &__arrow-next
      @apply tw-hidden
    &--arrows
      padding: 0
    &--arrows &__content-wrapper
      padding: 0

  // +b(grid)
    // @apply tw-px-4

