$blockName: prefix(articles-carousel)

.#{$blockName}
  .gik-arrow-button__prev--size-lg
    left: -.5rem
  .gik-arrow-button__next--size-lg
    right: -.5rem
  .gik-arrow-button__next--size-lg, .gik-arrow-button__prev--size-lg
    @screen md-down
      top: CALC(50% - 55px - 23px)

    @screen md
      top: 80px

    @screen lg
      top: 123px

    @screen xl
      top: 156px

  +b(article-tile)
    @screen md
      @apply tw-mx-5
