.#{$gik-prefix}wishlist-empty {
  @apply tw-rounded tw-text-center tw-font-normal tw-flex tw-flex-col tw-justify-center tw-items-center;
  background-color: rgba(225, 236, 236, 0.73);
  box-shadow: 0 0 10px 0 rgba(70, 101, 108, 0.25);

  > svg {
    @apply tw-mx-auto tw-text-primary-500;
  }

  &__title {
    @apply tw-mt-2 tw-text-lg tw-block tw-font-light tw-text-primary-600;
  }

  &__plus-icon {
    @apply tw-text-primary-500;
    // exact positioning needed here for the + icon to lign up with the surrounding quotes
    margin: -5px;
    margin-top: -9px;
  }
}
