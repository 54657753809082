+b(perfectgift-faceplate-uploader)

  &__content-wrapper
    @apply tw-flex tw-gap-8
    > *
      @apply tw-flex-1

  &__title
    @apply tw-block tw-text-2xl tw-text-neutral-700 tw-mb-4

  &__title-success
    @apply tw-block tw-text-success-500

  &__list-success
    > li
      @apply tw-flex tw-items-start
      svg
        @apply tw-inline-block tw-text-success-500 tw-mr-2
        margin-top: 3px

  &__list-error
    > li
      @apply tw-flex tw-items-start
      svg
        @apply tw-inline-block tw-text-danger-500 tw-mr-2
        margin-top: 3px


  &__title-error
    @apply tw-block tw-text-danger-500

  &__toolbar
    @apply tw-text-center tw-mt-4


  .gik-image-cropper__image
    // @apply tw-w-64 tw-h-48
    width: 400px
    height: 262px


.modals.modal-v2.perfectgift-photo-uploader
  width: 840px
