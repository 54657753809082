+b(care-calendar-aside)
  +e(title)
    @apply  tw-font-bold tw-text-base tw-leading-tight

  +b(step-card)
    @apply tw-mt-2 tw-mb-4
    &:nth-child(2)
      @apply tw-mb-2


