+b(creatable-select)
  &--default-solid &__control
    @apply tw-bg-neutral-100 tw-border-neutral-100 tw-shadow-none
    &:hover
      @apply tw-bg-neutral-100 tw-border-neutral-100

  &--focused &__control
    // @apply tw-ring-2
    box-shadow: rgb(95, 147, 243) 0 0 0 0, rgb(95, 147, 243) 0 0 0 2px, rgba(0, 0, 0, 0) 0 0 0 0

  &__placeholder
    @apply tw-select-none tw-pointer-events-none tw-text-neutral-700

  &__value-container
    position: static !important
    min-height: 30px
    @apply tw-overflow-visible
    & :last-child
      @apply tw-flex-1 tw-relative

  // change the input field so it's full width and floats above the placeholder text
  &__input
    display: block !important
    input
      @apply tw-block tw-absolute tw-z-10 tw-inset-0
      width: 100% !important
      height: 40px
      top: -17px


.gik-form-group--error
  +b(creatable-select)
    .gik-creatable-select__control
      @apply tw-border-danger-500
