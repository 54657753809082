+b(gift-box-option)
  @apply tw-flex tw-flex-col
  +e(title)
    @apply tw-font-bold tw-text-2xl tw-leading-none tw-text-neutral-900
  +e(title prepend append)
    span
      @apply tw-text-primary-600
  +e(prepend)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-primary-900
  +e(title prepend)
    @apply tw-inline tw-align-middle
  +e(price)
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-tight tw-text-neutral-900
  +e(footer)
    @apply tw-flex tw-justify-center tw-items-center
    > *
      @apply tw-mx-1
  +m(deluxe, footer)
    @screen md-down
      @apply tw-flex-col tw-m-4 tw-ml-1
      max-width: 55%
  +m(deluxe, title)
    @apply tw-text-xl tw-text-warn-900 tw-text-left
  +m(deluxe, title-wrapper)
    @apply tw-mb-2
  +m(deluxe, append)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-warn-900 tw-text-left tw-my-0
    @screen md
      @apply tw-text-center
  +m(deluxe, price)
    @apply tw-text-warn-900 tw-my-3
