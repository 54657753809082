
+b(forgot-password-section)
  @apply tw-text-base tw-text-neutral-800

  +e(title)
     @apply tw-mb-4 tw-text-center

+b(forgot-password-modal)
  &.gik-modal.modal-v2
    .content
      @apply tw-px-8

    +b(modal-button-footer)
      @apply tw-px-6 tw-pb-6 tw-pt-2
