$border-color: theme('colors.neutral.300');
$shadow-color: theme('colors.neutral.400');
$warning-color: theme('colors.danger.600');

// TODO: use tailwind values for the colors and box shadow

$empty-color: #e7f1f2;
$box-shadow: 0 2px 6px 0 rgba(70, 101, 108, 0.28), 0 7px 33px 0 rgba(70, 101, 108, 0.26);
$border-radius: theme('borderRadius.md');

$main-color: #46656c;
$accent-color: #8fb3b4;
$color-accent-2: #70888d;
$color-accent-3: #35a7a9;
