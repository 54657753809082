

+b(inkind-email-invitations-modal-content)

  &__title-wrapper
    @apply tw-align-middle

  &__title-icon
    @apply tw-text-neutral-800

  .gik-separator--direction-horizontal
    @apply tw-mt-6 tw-mb-2

  &__title
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-text-neutral-900 tw-text-left
    margin-top: 2px

  &__description
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-800 tw-text-left tw-mb-6

  &__btn-preview
    --color: tw-text-neutral-700


  &__buttons-left
    @apply tw-flex-1

  &__buttons
    @apply tw-px-2 tw-py-4 tw-flex tw-flex-1

  &__btn-import
    --color: theme('colors.primary.600')
    @apply tw-shadow-base tw-font-semibold tw-pl-2
    svg
      width: 20px
      height: 20px

  &__email-buttons
    @apply tw-justify-between tw-my-4

  &__footer
    @apply tw-text-center tw-text-sm
    strong
      @apply tw-text-neutral-700

  .gik-creatable-select__indicators
    @apply tw-items-start

  &__limit-section
    @apply tw-text-center

  &__limit-title
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-mt-2 tw-mb-4 tw-block

.modals.modal-v2.gik-modal.gik-invitation-limit-modal
  width: 300px
