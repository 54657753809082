@mixin gradient-green-blue($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #00b7b0 0%, #41cbce 19%, #41cbce 78%, #2fc3e4 100%);
}

// TODO: look at reducing the duplication between the different gradients
// ----- Aqua

@mixin gradient-light-aqua($angle: 224.8deg) {
  background-image: linear-gradient(#{$angle}, #cdf6f3 -0.09%, #d9f5f5 32.59%, #d9f5f5 69.08%, #d6f3fa 100%);
}
@mixin gradient-aqua($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #2fc3e4 0%, #41cbce 33.3%, #41cbce 64.48%, #03d0c4 100%);
}
@mixin gradient-dark-aqua($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #0b4856 0%, #215e5c 33.3%, #215e5c 64.48%, #2e6e55 100%);
}

// ----- Love

@mixin gradient-light-love($angle: 225deg) {
  background-image: linear-gradient(#{$angle}, #ffdfdb -0.4%, #fcdee6 34.79%, #fcdee6 66.49%, #f7d9e5 100.27%);
}
@mixin gradient-love($angle: 225.26deg) {
  background-image: linear-gradient(#{$angle}, #ff5e49 -0.18%, #ef5c84 33.07%, #ef5c84 66.47%, #d53f7e 100.03%);
}
@mixin gradient-dark-love($angle: 225.26deg) {
  background-image: linear-gradient(#{$angle}, #a83522 -0.18%, #802d36 33.07%, #802d36 66.47%, #670d26 100.03%);
}

// ----- Warm

@mixin gradient-light-warm($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #fae1d3 0.27%, #fde7d8 31.8%, #fde7d8 68.69%, #ffeecc 100.02%);
}
@mixin gradient-warm($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #e66924 0.27%, #f5893d 31.8%, #f5893d 68.69%, #ffa800 100.02%);
}
@mixin gradient-dark-warm($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #5e360e 0.27%, #834408 31.8%, #834408 68.69%, #a95820 100.02%);
}

// ----- Purps

@mixin gradient-light-purps($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #e1dcf0 0.11%, #eae2f3 33.72%, #eae2f3 66.42%, #f4e6f8 99.86%);
}
@mixin gradient-purps($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #694eb5 0.11%, #956cc3 33.72%, #956cc3 66.42%, #c880de 99.86%);
}
@mixin gradient-dark-purps($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #321a5d 0.11%, #5e2d79 33.72%, #5e2d79 66.42%, #8d25a2 99.86%);
}

// ----- Neutral

@mixin gradient-light-neutral($angle: 45.26deg) {
  background-image: linear-gradient(#{$angle}, #e7e9eb -0.05%, #ebecee 33.72%, #ebecee 66.08%, #f1f2f5 100.16%);
}
@mixin gradient-neutral($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #7f8b8d -0.05%, #9eabad 33.72%, #9eabad 66.08%, #c7cfd0 100.16%);
}
@mixin gradient-dark-neutral($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #3b4142 -0.05%, #4c5759 33.72%, #4c5759 66.08%, #7c8788 100.16%);
}

// ----- Sage

@mixin gradient-light-sage($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #e0ece7 0.26%, #e4efea 33.34%, #e4efea 69.71%, #ebf7ee 100%);
}
@mixin gradient-sage($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #639e89 0.26%, #7aaf95 33.34%, #7aaf95 69.71%, #9bdaac 100%);
}
@mixin gradient-dark-sage($angle: 45deg) {
  background-image: linear-gradient(#{$angle}, #1a4329 0.26%, #345742 33.34%, #345742 69.71%, #53876d 100%);
}

// ----- Oters

/* Gradient/GIK Page Header Darkening */
.#{$gik-prefix}gradient-page-header-darkening {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0.09) 52.56%, rgba(0, 0, 0, 0.21) 100%);
  transform: matrix(1, 0, 0, -1, 0, 0);
}
