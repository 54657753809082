$blockName: #{$gik-prefix}user-pages-list;

.gik-create-button-card {
  @apply tw-flex tw-items-center tw-justify-center;
  background-color: $empty-color;
  border-radius: $border-radius;

  &__empty-placeholder-card {
    @apply tw-pointer-events-none tw-opacity-0;
  }

  &__button-container {
    @apply tw-absolute;
  }
}

.#{$blockName} {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  &__header-text {
    @apply tw-font-heading tw-text-xl tw-font-medium tw-mb-4;
  }

  &__about-text {
    @apply tw-font-body tw-text-base;
  }

  &__header {
    display: flex;
    align-items: baseline;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0.5em;

    a {
      margin-left: 1em;
    }
  }

  &__list-container {
    @apply tw-flex tw-flex-row tw-text-center tw-flex-1;
  }

  &__list {
    @apply tw-grid tw-grid-cols-3 tw-gap-4 tw-pb-1 tw-w-full;
  }

  @screen lg-down {
    &__list {
      @apply tw-gap-4 tw-grid-cols-2;
    }
  }

  @screen md-down {
    @apply tw-mx-4 tw-mt-12;

    &__list-container {
      @apply tw-flex-col;
    }

    &__list {
      @apply tw-gap-4 tw-grid-cols-2;
    }

    &__create-button-container {
      background-color: transparent !important;
      border-radius: 0 !important;
      width: 100%;
      height: auto !important;
      margin-right: 0;
    }
  }
}

.#{$blockName}-skeleton {
  .#{$gik-prefix}frame-bone.#{$gik-prefix}user-page-card {
    --background-color: #f3f6f6;

    margin-right: 1em;
    margin-bottom: 1em;

    & > div {
      border-radius: 20px;
    }
  }

  .#{$gik-prefix}circle-bone {
    --background-color: #f3f6f6;
    margin-top: -34px;
    height: 70px;
    margin-bottom: 32px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
  }

  @screen md-down {
    .#{$gik-prefix}frame-bone.#{$gik-prefix}user-page-card {
      width: 100%;
      margin-right: 0;
    }
  }
}
