+b(partner-info-box)
  @apply tw-bg-neutral-100 tw-py-4 tw-px-2 tw-text-center tw-rounded-md tw-leading-tight

  +e(order-box)
    @apply tw-flex tw-items-center
    > *
      @apply tw-flex-1
    > * + *
      @apply tw-pl-4
    > main
      @apply tw-border-l tw-border-neutral-300 tw-font-bold

  +e(ordered-image)
    img
      @apply tw-mx-auto

  +e(ordered-through)
    @apply tw-text-xs tw-font-bold tw-text-neutral-700 tw-leading-3 tw-block tw-text-center tw-whitespace-nowrap tw-mb-2
