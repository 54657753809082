+b(situation-tile-alt)
  @apply tw-text-center tw-bg-white tw-shadow-xs tw-rounded-md tw-p-4 tw-border-4 tw-border-transparent tw-select-none tw-text-neutral-900

  +e(image)
    height: 62px
    @apply tw-mx-0 tw-mr-6 tw-mb-0

  &--vertical &__image
      @apply tw-mx-auto tw-mb-2
      height: 64px

  +e(label)
    @apply tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-justify-center tw-min-h-8 tw--mx-3

  +m(horizontal)
    @apply tw-flex tw-text-left tw-items-center

  &--horizontal &__image
    @apply tw-mx-0 tw-mr-6 tw-mb-0

  +m(selected)
    @apply tw-border-primary-500
