$blockName: '#{$gik-prefix}preview-section';

.#{$blockName} {
  @apply tw-pt-8 tw-cursor-pointer;

  &__overlay {
    @apply tw-text-white tw-p-4 tw-absolute tw-bottom-0 tw-right-0 tw-shadow-2xl tw-rounded-md;
    min-width: 200px;
    max-width: 350px;
  }

  &__content {
    @apply tw-h-full tw-relative tw-overflow-hidden;
  }
}
