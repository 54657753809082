@keyframes barberpole {
  100% {
    background-position: -56px 100%;
  }
}

.effect-barberpole {
  background-image: repeating-linear-gradient(
    -45deg,
    var(--bg),
    var(--bg) 10px,
    var(--bg-alt) 10px,
    var(--bg-alt) 20px
  );
  background-size: calc(100% + 56px) 100%;
  animation: barberpole 2s linear infinite;
}
