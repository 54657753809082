@mixin loading-linear-variant($name, $color, $containerColor, $radius)
  +m($name, container)
    background-color: $containerColor
    border-radius: $radius

  +m($name, bar)
    background-color: $color
    border-radius: $radius


@mixin loading-linear-size($name, $size)
  +m($name, container)
    @apply tw-h-#{$size}

  +m($name, bar)
    @apply tw-h-#{$size}

+b(loading-linear)

  +e(container)
    @apply tw-w-full tw-relative
    overflow: hidden

  +loading-linear-size(xs, 1)
  +loading-linear-size(sm, 2)
  +loading-linear-size(base, 4)
  +loading-linear-size(lg, 5)
  +loading-linear-size(xl, 6)
  +loading-linear-size(2xl, 10)
  +loading-linear-size(3xl, 12)

  +loading-linear-variant(primary, theme('colors.primary.500'), theme('colors.neutral.300'), 9999px)
  +loading-linear-variant(secondary, theme('colors.secondary.500'), theme('colors.neutral.300'), 9999px)
  +loading-linear-variant(default, theme('colors.neutral.500'), theme('colors.neutral.300'), 9999px)

  +m(determinate, bar)
    transition: width .3s ease-in-out
    margin-right: auto

  +m(indeterminate, bar)
    position: absolute
    left: 0
    top: 0
    bottom: 0
    width: 50%
    animation-iteration-count: infinite

    +m(bar1)
      animation-duration: 3s
      animation-name: loading-linear-indeterminate-1

    +m(bar2)
      animation-duration: 2s
      animation-name: loading-linear-indeterminate-2

@keyframes loading-linear-indeterminate-1
  from
    transform: translateX(-100%)

  to
    transform: translateX(200%)

@keyframes loading-linear-indeterminate-2
  from
    transform: translateX(300%)

  to
    transform: translateX(-250%)
