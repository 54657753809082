.#{$gik-prefix}order-summary {
  &__disclaimer {
    @apply tw-text-center tw-mt-4 tw-px-6;
  }

  &__btn-change-address {
    @apply tw-mt-2 tw-text-neutral-900 tw-block;
    &:hover {
      @apply tw-text-neutral-900;
    }
  }

  &__error-container {
    @apply tw-text-center tw-mt-4;
  }

  &__add-more {
    @apply tw-mt-4 tw-mb-6;
  }

  .gik-form-group--messageToRecipient {
    @apply tw-pb-4;
  }

  &__shipping-method {
    @apply tw-flex tw-gap-4 tw-items-center tw-px-3;
    > label {
      @apply tw-inline;
    }
  }

  &__shipping-method--radio {
    @apply tw-block;
    > label {
      @apply tw-mb-4 tw-block;
    }
  }

  .gik-radio__label {
    @apply tw-text-sm tw-text-neutral-700 tw-relative;
    top: 1px;
  }

  &__shipping-method-content {
    @apply tw-flex-1;
  }

  &__add-more-title {
    @apply tw-font-bold tw-text-lg tw-block tw-text-center;
  }

  &__add-more-buttons {
    @apply tw-flex tw-justify-center tw-gap-2 tw-flex-wrap;
  }

  &__subtotal {
    @apply tw-flex tw-justify-between tw-mb-2;
  }

  &__subtotal-label {
    @apply tw-font-bold;
  }

  .gik-datalist {
    @apply tw-mb-2;
    > li {
      @apply tw-border-neutral-300;
    }
  }

  &__field--disabled {
    @apply tw-opacity-50 tw-pointer-events-none;
  }

  &__summary-list {
    @apply tw-border-b tw-border-neutral-500 tw-mb-4;
    > li {
      @apply tw-border-t tw-border-neutral-500 tw-pt-3 tw-pb-2 tw-px-0;

      &.nopadding {
        @apply tw-p-0;
      }

      &:last-child {
        @apply tw-border-b-2;
      }
    }
  }

  &__summary-list--disabled {
    @apply tw-border-neutral-200 tw-opacity-50 tw-pointer-events-none;
  }

  &__summary-list__muted {
    @apply tw-border-neutral-200;
  }

  &__summary-line {
    @apply tw-flex tw-justify-between;
  }

  &__list-content {
    @apply tw-flex tw-justify-between;
  }

  .gik-form-group--subscribeToNewsletter {
    @apply tw-mt-2;
  }

  .gik-checkout-promo-code {
    @apply tw-mt-2;
  }
}
