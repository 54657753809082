$blockName: '#{$gik-prefix}notifications';

.#{$blockName} {
  @apply tw-fixed tw-w-full tw-text-center tw-z-max;

  > ul {
    @apply tw-block tw-absolute tw-px-2 tw-w-full;
    left: 50%;
    transform: translateX(-50%);
    > li {
      @apply tw-mb-2;
      > * {
        @apply tw-inline-flex tw-text-left;
        max-width: 900px;
      }
    }

    @screen md {
      @apply tw-w-auto;
    }
  }

  &--top {
    @apply tw-left-0 tw-mt-2 tw-px-2 tw-self-center;

    // sit under navbar
    top: calc(var(--mobile-header-height) + 10px);
    @screen sm {
      top: calc(var(--desktop-header-height) + 10px);
    }
  }

  &--bottom {
    @apply tw-bottom-0 tw-left-0 tw-mb-2 tw-px-2 tw-self-center;
  }
}
