$blockName: '#{$gik-prefix}benefits-list-grid';

.#{$blockName} {
  @apply tw-grid tw-gap-4;

  @screen xs {
    @apply tw-grid-cols-1 tw-gap-4;
  }

  @screen md {
    @apply tw-grid-cols-2 tw-gap-8;
  }

  @screen lg {
    @apply tw-grid-cols-4;
  }

  &__item-title {
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-none tw-my-3 tw-block;
  }
}
