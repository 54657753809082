$blockName: '#{$gik-prefix}keep-proportions';

.#{$blockName} {
  &__wrapper {
    @apply tw-relative tw-w-full tw-h-0 tw-bg-no-repeat tw-bg-center tw-bg-cover;

    > * {
      @apply tw-absolute tw-h-full tw-w-full tw-left-0 tw-top-0;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
