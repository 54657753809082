+b(homepage-footer)
  +e(copy)
    @apply tw-flex tw-flex-col tw-justify-center tw-tracking-normal
    flex: 0 0 474px

  +e(animation-container)
    @apply tw-flex tw-flex-row tw-items-center tw-content-center tw-justify-center
    +b(animation)
      @apply tw-flex tw-flex-col tw-items-center tw-content-center tw-max-w-full tw-justify-center
      & > div
        height: unset !important

  +e(title)
    @apply tw-text-4xl tw-font-semibold tw-font-heading tw-tracking-normal
    @apply tw-leading-none

  +e(content)
    @apply tw-text-lg tw-font-body tw-leading-normal tw-mt-2 tw-mb-4 tw-tracking-normal

  +b(container)
    @apply tw-flex tw-flex-row

  @screen md

    +e(animation-container)
      flex: 1 1 617px

  @screen md-down
    @apply tw-pb-0 tw-px-0

    +e(animation-container)
      flex: 0 0 100%

    +e(image)
      @apply tw-flex-1

    +e(title)
      max-width: 300px
      align-self: center

    +e(copy)
      @apply tw-text-center tw-mb-6 tw-mx-4
      flex: unset

    +b(container)
      @apply tw-flex-col
