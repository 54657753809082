$blockName: #{$gik-prefix}word-bone;

.#{$blockName} {
  @apply tw-h-4 tw-inline-block tw-align-middle;

  &__bone {
    @apply tw-h-3 tw-w-full tw-rounded-full;
    --gradient-angle: 90deg;
    --color-primary: theme('colors.neutral.200');
    --color-accent: theme('colors.neutral.50');
    --bone-animation-speed: 90s;

    background: $bone-gradient;
    background-size: 200px;
    background-repeat: revert;
    animation: #{$gik-prefix}bone-animation var(--bone-animation-speed) linear infinite;

    --gradient-angle: 45deg;
  }
}
