$blockName: '#{$gik-prefix}layout-aside';

.#{$blockName} {
  --aside-width: 17rem;
  --gutter: 1rem;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  @screen md-down {
    > * + * {
      margin-top: var(--gutter);
    }
  }

  @screen md {
    @apply tw-flex;

    > main {
      @apply tw-flex-1;
    }

    > aside {
      width: var(--aside-width);
    }

    > * + * {
      margin-left: var(--gutter);
    }
  }
}
