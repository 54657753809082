+b(mobile-page-banner-preview)
  @apply tw-shadow-base
  @apply tw-z-50

  position: absolute
  left: 2px
  top: 105px
  width: 182px
  max-width: calc(50% - .25rem)

  @screen lg
    left: auto
    right: 2px
    top: 114px

  .gik-inkind-group-page
    @apply tw-flex tw-flex-col
  .gik-inkind-group-page__section-content
    @apply tw-flex-1
    min-height: 300px

  &, .#{prefix(nav-bar)}
    @apply tw-rounded-t-md
