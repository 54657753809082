$blockName: prefix(code-block)

.#{$blockName}
  @apply tw-relative

  +e(copy-button)
    @apply tw-absolute
    right: 1rem
    top: 0

  &--wrap pre
    @apply tw-whitespace-pre-wrap
