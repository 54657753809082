.#{$gik-prefix}inkind-tile {
  box-shadow: 0 2px 20px 0 rgba(192, 192, 192, 0.5);
  width: 250px;
  height: 340px;
  border-radius: 20px;
  background: theme('colors.white');
  margin: 20px 8px;
  transition: transform 0.2s cubic-bezier(0.45, 0, 0.55, 1), box-shadow 0.2s cubic-bezier(0.45, 0, 0.55, 1);
  overflow: hidden;
  position: relative;

  &--clickable {
    @screen lg {
      cursor: pointer;

      &:hover {
        transform-origin: center top;
        transform: translate3d(0, -10px, 0);
        box-shadow: 0 12px 30px 0 rgba(192, 192, 192, 0.3);
      }
    }
  }

  &__thumbnail {
    height: 193px;
    width: 100%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &--loading {
    background-color: theme('colors.neutral.200');
  }

  &__loading-block {
    padding: 20px;
  }

  &__title {
    @apply tw-font-heading tw-text-2xl;
    color: theme('colors.neutral.900');
    padding: 15px;
  }

  &__location {
    @apply tw-text-sm;
    color: theme('colors.neutral.800');
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
