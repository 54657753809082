+b(create-page-drawer)
  +e(copy)

  +e(loading-container)
    @apply tw-h-full tw-flex tw-justify-center tw-items-center


+b(create-page)
  @screen md-down
    +e(steps)
      &, & > div
        height: 100%
      +b(steps)
        +e(content-wrapper)
          height: 100%

  @screen md-down
    &#{b(create-page)}--step-FEATURES#{b(create-page)}--substep-0
      &.modals.modal-v2 .gik-modal__content-wrapper
        @apply tw-bg-primary-100 #{!important}
        .content
          height: 100%

        main
          @apply tw-bg-neutral-50


    &#{b(create-page)}--step-FEATURES#{b(create-page)}--substep-1
      &.modals.modal-v2 .gik-modal__content-wrapper
        @apply tw-bg-danger-100 #{!important}
        .content
          height: 100%

        main
          @apply tw-bg-neutral-50

    &#{b(create-page)}--step-FEATURES#{b(create-page)}--substep-2
      &.modals.modal-v2 .gik-modal__content-wrapper
        background-image: linear-gradient(45deg, #e0ece7 0.26%, #e4efea 33.34%, #e4efea 69.71%, #ebf7ee 100%)
        .content
          height: 100%

        main
          @apply tw-bg-neutral-50

    &.modals.modal-v2
      > .gik-modal__content-wrapper
        @apply tw-bg-neutral-50
        & > .content
          @apply tw-p-0
          min-height: unset
  &.modals.modal-v2
    > .gik-modal__content-wrapper
      @apply tw-bg-neutral-50
      & > .content
        @apply tw-p-0 tw-h-full

  // full slick slider inside a step
  +b(steps)
    +e(nav)
      @apply tw-pl-2 tw-absolute tw-z-30
    > .slick-slider
      > .slick-list
        margin-bottom: 25px
        > .slick-track
          @apply tw-overflow-auto tw-flex
          > .slick-slide
            @apply tw-flex-1 tw-float-none tw-h-auto
            > *
              @apply tw-h-full
    // @screen md-down
    //   .slick-list
    //     height: 100%
    //     .slick-track
    //       height: 100%



  +e(slider)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch
    height: 100%
    .slick-dots
      position: absolute
      bottom: 0

  +e(thumbnail-prefetcher)
    position: absolute
    left: -999999px
    top: -999999px

  +e(page-step-carousel)
    +m(myself)
      .slick-list
        margin-bottom: 0 !important

  +e(page-carousel-indicator-wrapper)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +b(about-step)
    @apply tw-p-4 tw-pb-0
    padding-top: 3.4rem

  +b(situation-step)
    @apply tw-p-4 tw-pb-0
    padding-top: 3.4rem

  +b(privacy-step)
    @apply tw-p-4 tw-pb-0
    //@apply tw-bg-neutral-50
    padding-top: 3.4rem

  +b(recipient-info-step__recipient-info)
    padding-top: 3.4rem

  //  TODO: Tailwind-ify
  @screen md
    +e(split-layout-float)
      position: absolute
      left: -9999px
      +m(visible)
        left: 0
        right: 50%
        bottom: 64px
        top: 56px
        & > *
          max-width: 268px
          margin-left: auto
          margin-right: auto

    +e(features-carousel)
      @apply tw-flex tw-flex-col tw-justify-between tw-items-stretch
      align-content: stretch
      @apply tw-h-full
      & > div:first-child
        height: 24px

    +e(features-carousel page-carousel)
      +b(indicator)
        @apply tw-mx-auto

  .gik-form-group--addAsPageOrganizer
    @apply tw-text-center


  //@screen md-down
  //  +e(features-split-layout)
  //    main
  //      //min-height: CALC(50% - 38px)
  //      justify-content: center
  //
  //    aside
  //      //min-height: 50%

.modals.modal-v2.gik-modal--add-as-page-organizer-info
  .gik-modal__content-wrapper > .content
    @screen md-down
      @apply tw-px-6

+b(create-page-sheet-page-name-ideas)
  @apply tw-text-center tw-font-bold tw-text-base tw-leading-tight tw-block tw-mt-10 tw-mb-4 tw-px-4
  +e(actions)
    @apply tw-p-2

