
+b(table)
  &--hor-scroll &__table-wrapper
    @apply tw-overflow-x-auto

  +e(table)
    @apply tw-border-collapse

  &--full-width
    @apply tw-w-full
    +b(table__table)
      width: calc(100% - 1px)

  &--full-height
    @apply tw-h-full
    +b(table__table)
      @apply tw-h-full

  +m(flex-layout)
    +b(table__thead)
      @apply tw-flex tw-flex-col
    +b(table__tbody)
      @apply tw-flex tw-flex-col

  &__cell-actions
    @apply tw-inline-flex
    > * + *
      @apply tw-ml-1

  +m(fixed-layout)
    +b(table__table)
      @apply tw-table-fixed

  +m(sticky-header)
    +b(table__thead)
      // @apply tw-sticky tw-top-0 tw-z-10

  > caption
    @apply tw-text-left tw-font-bold tw-mb-2 tw-text-lg

  &__wrapper
    @apply tw-w-full

  +e(custom)
    @apply tw-flex-1 tw-text-left tw-flex-shrink-0 tw-mr-2 tw-pt-1 tw-items-center

  &__global-filter-container
    @apply tw-flex tw-mb-4 tw-flex-1

  &__global-filter
    +b(input)
      // @apply tw-inline-flex
      max-width: 200px


  &--flex-layout &__global-filter
    @apply tw-flex tw-mb-2
    > th
      @apply tw-flex-1 tw-flex

  .row-select-toolbar
    @apply tw-border-none

    // +b(input__input-wrapper)
    //   @apply tw-rounded-none tw-border-none

  +m(scrollable)

    +b(table__thead)
      // @apply tw-overflow-y-scroll tw-overflow-x-hidden
      padding-right: 17px

    +b(table__tbody)
      @apply tw-overflow-y-scroll tw-overflow-x-hidden

  +m(custom-scrollbars)
    +b(table__tbody)
      @apply tw-overflow-y-hidden

    +b(table__thead)
      @apply tw-overflow-y-hidden
      padding-right: 0

  &__td
    @apply tw-whitespace-normal

  &__th
    @apply tw-text-left tw-overflow-hidden

  +e(td-expandable)
    @apply tw-block tw-overflow-hidden tw-border-b tw-border-neutral-300

  +e(td-inner)
    @apply tw-h-full tw-items-center

  +e(thead)
    @apply tw-overflow-y-auto tw-overflow-x-hidden

  &__th-sort
    svg
      height: 20px
      width: 20px

  &__th--unsorted &__th-sort
    @apply tw-opacity-50

  +e(th-inner)
    @apply tw-flex
  +e(th-label)
    @apply tw-flex-1 tw-flex-shrink-0

  +e(expandable-icon)
    @apply tw-text-primary-600
    width: 22px
    height: 22px

  +e(expandable-toggle)
    @apply tw-text-neutral-700

  // ----- truncate

  // truncate auto layout tables


  //&.gik-table--auto-layout.gik-table--truncate.gik-table--auto-layout &__td,
  //&.gik-table--auto-layout.gik-table--truncate.gik-table--auto-layout &__th
    // this allows a table with auto layout to truncate it's text
    // note: it does mess with the auto layout...
      max-width:1px

  &__loader
    @apply tw-absolute tw-inset-0 tw-z-10 tw-flex tw-items-center tw-justify-center

  &__global-filter-inner
    @apply tw-flex tw-gap-2 tw-pt-1 tw-pr-1

  &__th-filter
    @apply tw-cursor-pointer
    > .gik-popover__reference-element svg
      @apply tw-opacity-50

    .gik-popover__popper
      @apply tw-z-10

  &__tr--hidden
    @apply tw-invisible

  &:not(&--use-pagination-height) &__tr--hidden
    @apply tw-hidden

  &--use-pagination-height &__tr--hidden
    @apply tw-invisible

  &__td--hidden
    @apply tw-invisible tw-border-transparent

  &--truncate &__th-label
    @apply tw-truncate

  &--truncate &__th-inner
    @apply tw-overflow-hidden tw-truncate

  &--truncate &__td
    @apply tw-overflow-hidden tw-truncate tw-whitespace-nowrap
  &--truncate &__td-inner
    @apply tw-overflow-hidden tw-truncate

  +e(tbody)
    @apply tw-relative

  &--row-click &__tbody
    tr
      @apply tw-cursor-pointer
      &:hover td
        @apply tw-bg-neutral-200

  &--plain &__row-select-toolbar-top
    @apply tw-mt-2
  &--plain &__row-select-toolbar-bottom
    @apply tw-mt-2

  &--plain
    @apply tw-text-sm

  &--plain &__table
    // @apply tw-border tw-border-neutral-500

  &--plain &__th-label
    @apply tw-text-neutral-700 tw-uppercase

  &--plain &__th
    @apply tw-p-2 tw-text-left tw-whitespace-nowrap
    // @apply tw-border-b tw-border-neutral-300

  &--plain &__tbody &__td
    @apply tw-p-2 tw-whitespace-nowrap tw-overflow-hidden

  &--plain &__tbody &__tr
    @apply tw-border-b tw-border-neutral-300

  &--plain &__tbody
    @apply tw-border-t tw-border-neutral-300

  // &--neutral &__table
    // @apply tw-border tw-border-neutral-500

  &--neutral &__th
    @apply tw-bg-neutral-300 tw-text-left
    @apply tw-border tw-border-neutral-500 tw-align-top

  &--neutral &__td
    @apply tw-bg-neutral-100 tw-p-2  tw-overflow-hidden
    @apply tw-border tw-border-neutral-500 tw-align-top

  &--neutral &__td--center
    @apply tw-text-center tw-align-middle

  &--neutral &__td--hidden
    @apply tw-invisible tw-border-transparent tw-border-l-0

  &--neutral &__tr:nth-child(2n) &__td
    @apply tw-bg-neutral-300

  // *[data-sticky-last-left-td='true'] Placeholder900')
  //

  // *[data-sticky-first-right-td='true']
  //   box-shadow: 0px 0px 1px theme('colors.neutral.900')
  //

  .gik-table-pagination
    @apply tw-mt-2

  +m(sticky)
    +b(table__thead)
      position: sticky
      z-index: 1
      width: fit-content

      top: 0
      box-shadow: 0px 3px 3px #ccc

    +b(table__tbody)
      position: relative
      z-index: 0

    [data-sticky-td]
      position: sticky

  &--sticky &--neutral
    [data-sticky-last-left-td='true']
      box-shadow: 1px 0px 1px theme('colors.neutral.900')


    [data-sticky-first-right-td='true']
      box-shadow: 0px 0px 1px theme('colors.neutral.900')

  &__fetch-error
    @apply tw-text-center tw-text-danger-500
    @apply tw-rounded tw-bg-neutral-50
    @apply tw-p-4 tw-py-8 tw-text-lg tw-font-bold tw-border-b-0

  &__empty
    @apply tw-text-center

  // &--empty .gik-table__tr
  //   @apply tw-hidden

  &__empty,  &__empty-filter
    @apply tw-rounded tw-bg-neutral-50 tw-text-neutral-700 tw-text-center
    @apply tw-p-4 tw-py-8 tw-text-xl tw-font-bold tw-border-b-0

  &--empty &__tbody
    @apply tw-border-b-0

  &--fixed-layout &__empty,  &--fixed-layout &__empty-filter
    @apply tw-flex tw-items-center tw-justify-center

  &--full-width
    @apply tw-w-full


  &--size-sm
    @apply tw-text-sm
  &--size-xs
    @apply tw-text-xs

  &--size-sm &__th
    @apply tw-p-1

  &--size-sm &__td
    @apply tw-p-1

  &--size-xs,
  &--size-sm
    .gik-table-pagination
      @apply tw-p-0 tw-px-1
    .gik-table-pagination__btn-page,
    .gik-table-pagination__btn-prev,
    .gik-table-pagination__btn-next
      width: 26px
      height: 26px
    .gik-table-pagination__btn-dots
      height: 26px

  &--size-xs &__th &__th-label,
  &--size-xs &__th &__th-sort,
  &--size-xs &__th &__th-filter
    @apply tw-p-1

  &--size-xs &__th-sort
    svg
      height: 15px
      width: 15px

  &--size-xs &__td
    @apply tw-p-1


  &__td--center
    @apply tw-text-center tw-align-middle

  &__td--no-pad
    @apply tw-p-0 #{!important}


// .gik-data-table
//   @apply tw-border tw-border-neutral-300

.gik-table-column-filter-trigger
  @apply tw-cursor-pointer

.gik-table-column-filter--date-range
  .gik-hstack
    @apply tw-items-center

.gik-table__td--actions
    @apply tw-pb-0 tw-text-center
    padding-top: 3px
    > .gik-table__td-inner
      > * + *
        @apply tw-ml-1

.gik-table-filter-number-range
  @apply tw-flex tw-items-center tw-whitespace-nowrap
  .gik-input__input
    width: 80px

.gik-table-filter-select
    width: 180px
    .sb-link
      display: block
