.#{$gik-prefix}loading-lines {
  width: 100%;
  height: 60px;
  display: block;
  background-color: theme('colors.neutral.200');
  @include gik-skeleton-loading-animation-background-gradient(#62edf0, 300px);
  position: relative;

  &__mask {
    background: white;
    position: absolute;
    display: block;

    &__vertical {
      &__1 {
        width: 100%;
        height: 10px;
        top: 0px;
      }

      &__2 {
        width: 100%;
        height: 10px;
        top: 20px;
      }

      &__3 {
        width: 100%;
        height: 10px;
        top: 40px;
      }
    }

    &__horizontal {
      &__1 {
        width: 50%;
        height: 10px;
        right: 0px;
        top: 30px;
      }

      &__2 {
        width: 20%;
        height: 10px;
        right: 0px;
        top: 50px;
      }

      &__3 {
        width: 10%;
        height: 10px;
        right: 0px;
        top: 10px;
      }
    }
  }
}
