+b(weekday-selector)

  > * + *
    @apply tw-ml-1

  +m(inline)
    @apply tw-inline-block

  +b(checkbox)
    +e(wrapper)
      width: 30px
      height: 30px

    +e(wrapper)
      border-color: var(--color)
      @apply tw-flex tw-justify-center

    +e(icon)
      @apply tw-text-primary-500 tw-opacity-100 tw-top-auto tw-w-auto tw-h-auto tw-items-center tw-text-center tw-justify-center
      @apply tw-w-full tw-flex

      transform: scale(1)
      font-size: 16px

    +m(checked)
      .gik-checkbox__icon
        @apply tw-text-neutral-100


