.#{$gik-prefix}app-footer {
  @apply tw-pl-8 tw-pr-12 tw-pt-10 tw-text-white tw-text-center tw-relative tw-bg-neutral-900 tw-flex;

  p {
    @apply tw-my-2;
  }

  @screen sm-down {
    @apply tw-px-8;
  }

  @screen lg-down {
    @apply tw-flex-col-reverse tw-items-center;
  }

  @screen lg {
    // large padding bottom is applied to give the intercom button enough space
    @apply tw-pb-12;
  }

  &__links-grid {
    white-space: nowrap;
    @apply tw-grid tw-grid-cols-2;
    @screen sm-down {
      @apply tw-self-start;
    }
    @screen xs-down {
      @apply tw-gap-0;
    }
    @screen md-down {
      @apply tw-gap-4;
    }
    @screen md {
      @apply tw-grid-cols-3 tw-gap-8;
    }
  }

  &__right {
    @screen lg {
      flex: 1;
    }
  }

  &__heading {
    @apply tw-flex tw-flex-col tw-items-end tw-mb-2;
    @screen lg-down {
      @apply tw-items-center tw-mb-12;
    }
    > :first-child {
      @apply tw-mb-6;
    }
  }

  &__fine-print {
    @apply tw-flex tw-flex-col tw-justify-start tw-items-end tw-mt-5;
    @screen lg-down {
      @apply tw-items-center tw-mt-16;
    }

    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-500;
    @screen lg {
      @apply tw-text-right;
    }

    a:hover {
      @apply tw-text-white tw-underline;
    }
  }

  &__grid-link-list {
    @apply tw-flex tw-flex-col tw-items-start;
    > :first-child {
      @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-tight tw-text-primary-400;
    }
    a {
      @apply tw-mb-2;
    }
    /* Allow article links to be longer without affecting width of all columns */
    max-width: 150px;
    overflow: visible;
    &:last-of-type {
      p {
        max-width: 250px;
        @screen sm {
          max-width: 300px;
        }
        @screen md {
          max-width: 250px;
        }
        @screen sm-down {
          max-width: 80vw;
        }
        @screen lg {
          max-width: 280px;
        }
        @screen xl {
          max-width: calc(100vw - 800px);
        }
      }
    }
  }

  &__grid-link {
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-50 tw-z-above;
    p {
      @include singleLineClamp();
      &:hover {
        @apply tw-text-white tw-underline;
      }
    }
  }

  &__social-links {
    @apply tw-flex tw-justify-center tw-items-center;
    > * {
      @apply tw-mx-2;
      @screen xs-down {
        @apply tw-mx-1;
      }
      @screen lg {
        @apply tw-mx-0 tw-ml-4;
      }
    }
  }

  &__social-link {
    @apply tw-p-2 tw-bg-neutral-800 tw-rounded-full tw-flex tw-justify-center tw-items-center;
  }
}

.gik-creatable-select {
  .gik-creatable-select__control {
    @apply tw-items-start tw-min-h-full;
  }
  .gik-creatable-select__value-container {
    @apply tw-min-h-full;
  }
  .gik-creatable-select__placeholder {
    @apply tw-min-h-full;
  }
  .gik-creatable-select__value-container {
    @apply tw-items-start;
  }
  .gik-creatable-select__input {
    @apply tw-items-start tw-align-top;
  }
}
