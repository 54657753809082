$blockName: #{$gik-prefix}saved-item-card;

.#{$blockName} {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-color: $border-color;
  border-radius: $border-radius;
  min-width: 250px;

  &,
  &:hover,
  &:visited {
    color: #899da1;
  }

  &__confirm-dialog {
    width: 320px;
    height: 220px;
  }

  &__hover-wrapper {
    margin-top: 1em;
    margin-bottom: 1em;

    .gik-hover-animation__shadow {
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      border: 1px solid transparent;
    }
  }

  &__picture {
    margin-right: 10px;

    img {
      width: 100px;
      height: 100px;
    }

    &,
    & img {
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }
  }

  &__title {
    align-self: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    flex: 1;
    padding-right: 10px;
    padding-bottom: 2px;

    @apply tw-font-ui tw-text-lg tw-text-neutral-800 tw-tracking-normal tw-leading-5;
  }

  &__remove-button {
    position: absolute;
    right: 0;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    font-size: 17px;

    svg {
      color: #ef5c84;
    }
  }

  &--tc aside {
    width: 100px;
    height: 100px;
    @apply tw-p-4 tw-bg-neutral-50;
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch;
    align-content: stretch;
    margin-right: 10px;
  }
}
