@use 'sass:list';

// if we ever need those colors in more places other than here we can consider moving only the brand ones to tailwind (indexes 2 and 5)
$amazon-shades: #ffebce #fd9d0c #e48d0b #ca7e0a white;
$paypal-shades: #fef2cf #f9bc0f #e0a90e #c7960c #002681;
$venmo-shades: #e4f8ff #0098d2 #0079a9 #005575 white;
$cashapp-shades: #cdcdcd #000000 #313131 #484848 white;
$facebook-shades: #c0d4ff #4267b2 #385692 #2d4677 white;
$twitter-shades: #a6cfea #1da1f2 #1d95df #1983c4 white;

$blockName: 'gik-button';

@mixin button-circle-styling {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-p-0;
  border-radius: 50%;

  &:before {
    border-radius: 50%;
  }

  &.#{$blockName}--size-xs {
    width: 20px;
    height: 20px;
    @apply tw-text-xs;
    .ui-fonticon {
      // padding-left: 2px;
      // padding-top: 2px;
    }
  }
  &.#{$blockName}--size-sm {
    width: 30px;
    height: 30px;
    @apply tw-text-base;
    .ui-fonticon {
      // padding-left: 2px;
      // padding-top: 1px;
    }
  }
  &.#{$blockName}--size-base {
    width: 40px;
    height: 40px;
    @apply tw-text-2xl;
    .ui-fonticon {
      // padding-left: 1px;
      // padding-top: 3px;
    }
  }
  &.#{$blockName}--size-lg {
    width: 50px;
    height: 50px;
    @apply tw-text-3xl;
    .ui-fonticon {
      // padding-right: 0px;
      // padding-top: 5px;
    }
  }
  &.#{$blockName}--size-xl {
    width: 60px;
    height: 60px;
    @apply tw-text-4xl;
    .ui-fonticon {
      // padding-right: 1px;
      // padding-top: 3px;
    }
  }
}

@mixin button-variants($name, $palette) {
  // FIXME: have to declare the variable for all selectors
  // due to CSS scope. Try to find a better way of doing this
  &--#{$name},
  &--#{$name}-outline,
  &--#{$name}-plain,
  &--#{$name}-link,
  &--#{$name}-extra-light,
  &--#{$name}-inverted {
    @if (type-of($palette) == 'list') {
      --shade-100: #{list.nth($palette, 1)};
      --shade-500: #{list.nth($palette, 2)};
      --shade-600: #{list.nth($palette, 3)};
      --shade-700: #{list.nth($palette, 4)};
      --shade-text: #{list.nth($palette, 5)};
      --shade-50: #{list.nth($palette, 1)};
    } @else {
      --shade-50: theme('colors.#{$palette}.50');
      --shade-100: theme('colors.#{$palette}.100');
      --shade-500: theme('colors.#{$palette}.500');
      --shade-600: theme('colors.#{$palette}.600');
      --shade-700: theme('colors.#{$palette}.700');
      --shade-text: theme('colors.white');
    }
  }
  &--#{$name} {
    --color: var(--shade-text);
    --border-color: var(--color);
    --bg: var(--shade-500);
    --bg-alt: var(--shade-600); // used with barberpole effect
    --bg-hover: var(--shade-600);
    --bg-active: var(--shade-700);

    &.#{$blockName}--active {
      --color: var(--shade-100);
      background-color: var(--bg-active);
      border-color: var(--bg-active);
    }

    svg {
      color: var(--shade-text);
    }
  }

  &--#{$name}-outline,
  &--#{$name}-plain,
  &--#{$name}-inverted {
    --color: var(--shade-600);
    --color-hover: var(--shade-700);

    &:not(.#{$blockName}--disabled):not(.#{$blockName}--loading):not(.#{$blockName}--hover):not(.#{$blockName}--active):hover,
    &.#{$blockName}--hover {
      --color: var(--color-hover);
    }
  }

  &--#{$name}-outline {
    --border-color: var(--color);
    --border-color-hover: var(--color-hover);
    border: var(--border-size) solid var(--border-color);
    @apply tw-bg-white;

    &:not(.#{$blockName}--disabled):not(.#{$blockName}--loading):not(.#{$blockName}--hover):not(.#{$blockName}--active):hover,
    &.#{$blockName}--hover {
      @apply tw-bg-white;
      border: var(--border-size) solid var(--border-color-hover);
    }
  }

  &--#{$name}-link-solid,
  &--#{$name}-link {
    @apply tw-rounded-none tw-h-auto tw-border-none tw-cursor-pointer;
    @apply tw-p-0 #{!important};
    vertical-align: inherit;
    text-transform: none;

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;

    > * {
      @apply tw-m-0;
    }

    --color: var(--shade-600);
    --color-hover: var(--shade-600);
  }
  &--#{$name}-link-solid {
    text-decoration: underline;
  }

  &--#{$name}-link {
    &:not(.#{$blockName}--disabled):not(.#{$blockName}--loading):not(.#{$blockName}--hover):not(.#{$blockName}--active):hover,
    &.#{$blockName}--hover {
      text-decoration: var(--hover-decoration);
      --color: var(--color-hover);
    }
  }
}

.gik-button--circular {
  @apply tw-inline-flex tw-items-center tw-justify-center tw-p-0;
  border-radius: 50%;

  &:before {
    border-radius: 50%;
  }

  &.#{$blockName}--size-xs {
    width: 20px;
    height: 20px;
    @apply tw-text-xs;
    .ui-fonticon {
      // padding-left: 2px;
      // padding-top: 2px;
    }
  }
  &.#{$blockName}--size-sm {
    width: 30px;
    height: 30px;
    @apply tw-text-base;
    .ui-fonticon {
      // padding-left: 2px;
      // padding-top: 1px;
    }
  }
  &.#{$blockName}--size-base {
    width: 40px;
    height: 40px;
    @apply tw-text-2xl;
    .ui-fonticon {
      // padding-left: 1px;
      // padding-top: 3px;
    }
  }
  &.#{$blockName}--size-lg {
    width: 50px;
    height: 50px;
    @apply tw-text-3xl;
    .ui-fonticon {
      // padding-right: 0px;
      // padding-top: 5px;
    }
  }
  &.#{$blockName}--size-xl {
    width: 60px;
    height: 60px;
    @apply tw-text-4xl;
    .ui-fonticon {
      // padding-right: 1px;
      // padding-top: 3px;
    }
  }
}

.#{$blockName} {
  --bg: transparent;
  --color: #fff;
  --bg-hover: transparent;
  --bg-active: transparent;
  --border-size: 1px;
  --contentSpacing: 8px;
  --hover-decoration: underline;

  @apply tw-flex-shrink-0 tw-select-none tw-inline-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full;
  @extend .gik-no-tap-effect;
  // ----- Elements

  > * {
    @apply tw-flex-shrink-0 tw-align-middle tw-inline-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full;
  }

  &__content {
    @apply tw-inline-flex tw-leading-none tw-flex-shrink;
  }

  // space between contents
  > * + * {
    margin-left: var(--contentSpacing);
  }

  > .tw-fonticon {
    @apply tw-inline-flex tw-leading-none;
    font-size: 1.2em;
  }

  font-family: theme('fontFamily.ui');

  // ---------- Modifiers

  &--block {
    @apply tw-flex tw-flex-1;
  }

  &--full-width {
    @apply tw-flex tw-w-full;
  }

  &--auto-height {
    @apply tw-h-auto;
  }

  &--uppercase {
    @apply tw-uppercase;
  }

  &--no-focus-styling {
    &:focus:before {
      border: none !important;
    }
  }

  // ----- Variants

  @apply tw-text-white tw-font-semibold tw-rounded-sm tw-cursor-pointer tw-align-middle tw-no-underline tw-text-center tw-outline-none;
  &:before {
    @apply tw-rounded-sm;
  }

  color: var(--color) !important; // TODO: Remove `!important` after fixing MVC styles
  background-color: var(--bg);
  border: var(--border-size) solid var(--bg);

  transition: color 0.2s ease-out, background-color 0.2s ease-out, border-color 0.2s ease-out;

  &:not(.#{$blockName}--disabled):not(.#{$blockName}--loading):not(.#{$blockName}--hover):not(.#{$blockName}--active):hover,
  &.#{$blockName}--hover {
    background-color: var(--bg-hover);
    border-color: var(--bg-hover);
  }

  @include button-variants('default', 'neutral');
  @include button-variants(
    'default-light',
    theme('colors.neutral.200') theme('colors.neutral.100') theme('colors.neutral.300') theme('colors.neutral.400')
      theme('colors.neutral.700')
  );
  @include button-variants(
    'default-extra-dark',
    theme('colors.neutral.800') theme('colors.neutral.700') theme('colors.neutral.900') theme('colors.neutral.400')
      theme('colors.neutral.700')
  );
  @include button-variants('primary', 'primary');
  @include button-variants('primary-light', 'primary');
  @include button-variants('secondary', 'secondary');

  // contextual variants
  @include button-variants('danger', 'danger');
  @include button-variants('warning', 'warn');
  @include button-variants('success', 'success');
  @include button-variants('info', 'info');

  // special variants
  @include button-variants('amazon', $amazon-shades);
  @include button-variants('paypal', $paypal-shades);
  @include button-variants('venmo', $venmo-shades);
  @include button-variants('cashapp', $cashapp-shades);
  @include button-variants('facebook', $facebook-shades);
  @include button-variants('twitter', $twitter-shades);

  $transparent-black: 'transparent-black';
  @include button-variants(
    $transparent-black,
    theme('colors.gray.600') rgba(59, 65, 66, 0.8) theme('colors.gray.600') theme('colors.gray.600')
      theme('colors.white')
  );
  &--#{$transparent-black} {
    border: 1px solid transparent;
  }

  $black-outline: 'black-outline';
  @include button-variants(
    $black-outline,
    theme('colors.black') theme('colors.black') theme('colors.black') theme('colors.black') theme('colors.white')
  );
  &--#{$black-outline} {
    border: 2px solid white;
  }

  $transparent: 'transparent';
  @include button-variants(
    $transparent,
    theme('colors.transparent') theme('colors.transparent') theme('colors.transparent') theme('colors.transparent') theme('colors.black')
  );

  &--icon {
    // just inherit the color from the parent for icons
    --color: inherit;
  }

  // secondary button (orange) needs a darker alt color for the barberpole
  &--secondary {
    --bg-alt: theme('colors.secondary.600');
  }

  &--visa-gradient {
    background: linear-gradient(90deg, #23285f 0%, #25479f 100%);
    border: transparent;
  }

  &--info-gradient {
    background: linear-gradient(45deg, #4e7aea 0%, #5f93f3 30.98%, #5f93f3 68.27%, #3abdf5 100%);
    border: transparent;
  }

  &--aqua-gradient {
    background: linear-gradient(45deg, #2fc3e4 0%, #41cbce 33.3%, #41cbce 64.48%, #03d0c4 100%);
    border: transparent;
  }

  &--danger-gradient {
    background: linear-gradient(225.26deg, #ff5e49 -0.18%, #ef5c84 33.07%, #ef5c84 66.47%, #d53f7e 100.03%);
    border: transparent;
  }

  &--purple-gradient {
    background: linear-gradient(45deg, #694eb5 0.11%, #956cc3 33.72%, #956cc3 66.42%, #c880de 99.86%);
    border: transparent;
  }

  &--success-gradient {
    background: linear-gradient(256.49deg, #b1d682 0%, #8fbb6a 33.33%, #8fbb6a 68.23%, #67ac4f 100%);
    border: transparent;
  }

  // danger button should have a darker shade for the hover and alt colors
  &--danger {
    --bg-hover: theme('colors.danger.900');
    --bg-alt: theme('colors.danger.700');
  }

  &--default-extra-light {
    --bg: theme('colors.neutral.50');
    --bg-hover: theme('colors.neutral.200');
    --bg-active: theme('colors.neutral.400');
    --color: theme('colors.neutral.700');
    --border-color: theme('colors.neutral.200');
  }

  &--white {
    --bg: theme('colors.white');
    --bg-hover: theme('colors.neutral.50');
    --bg-active: theme('colors.neutral.100');
    --color: theme('colors.neutral.900');
    --border-color: theme('colors.black');

    @apply tw-shadow-xl;
  }

  &--pink {
    --bg: theme('colors.pink.700');
    --bg-hover: theme('colors.pink.700');
    --bg-active: theme('colors.pink.700');
    --color: theme('colors.white');
    --border-color: theme('colors.pink.700');
  }

  &--white-outline {
    --bg: theme('colors.white');
    --bg-alt: theme('colors.neutral.100');
    --bg-hover: theme('colors.neutral.50');
    --bg-active: theme('colors.neutral.100');
    --color: theme('colors.neutral.700');
    --border-color: theme('colors.neutral.500');
    --border-color-hover: theme('colors.neutral.500');

    border: var(--border-size) solid var(--border-color);

    &:not(.#{$blockName}--disabled):not(.#{$blockName}--loading):not(.#{$blockName}--hover):not(.#{$blockName}--active):hover,
    &.#{$blockName}--hover {
      border: var(--border-size) solid var(--border-color-hover);
    }
  }

  &--primary-light {
    --bg: theme('colors.primary.200');
    --bg-hover: theme('colors.primary.50');
    --bg-active: theme('colors.primary.300');
    --bg-alt: theme('colors.primary.300');
    --color: theme('colors.primary.700');
    --color-hover: theme('colors.primary.800');
    --border-color: theme('colors.primary.700');

    &-outline,
    &-plain {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }

    &-outline {
      --border-color: theme('colors.primary.700');
    }

    &-link {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }
  }

  &--default-dark {
    --bg: theme('colors.neutral.600');
    --bg-hover: theme('colors.neutral.700');
    --bg-active: theme('colors.neutral.400');
    --bg-alt: theme('colors.neutral.700');
    --border-color: theme('colors.neutral.600');
    --color: theme('colors.white');
    --color-hover: theme('colors.white');

    &-outline {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
      --border-color: theme('colors.primary.700');
    }

    &-plain {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }

    &-link {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }
  }

  &--primary-dark {
    --bg: theme('colors.primary.600');
    --bg-hover: theme('colors.primary.700');
    --bg-active: theme('colors.primary.400');
    --bg-alt: theme('colors.primary.700');
    --border-color: theme('colors.primary.600');
    --color: theme('colors.white');
    --color-hover: theme('colors.white');

    &-outline {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
      --border-color: theme('colors.primary.700');
    }

    &-plain {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }

    &-link {
      --color: theme('colors.primary.700');
      --color-hover: theme('colors.primary.800');
    }
  }

  &--primary-vdark {
    --bg: theme('colors.primary.700');
    --bg-hover: theme('colors.primary.800');
    --bg-active: theme('colors.primary.500');
    --bg-alt: theme('colors.primary.800');
    --border-color: theme('colors.primary.700');
    --color: theme('colors.white');
    --color-hover: theme('colors.white');

    &-outline {
      --color: theme('colors.primary.800');
      --color-hover: theme('colors.primary.900');
      --border-color: theme('colors.primary.800');
    }

    &-plain {
      --color: theme('colors.primary.800');
      --color-hover: theme('colors.primary.900');
    }

    &-link {
      --color: theme('colors.primary.800');
      --color-hover: theme('colors.primary.900');
    }
  }

  &--primary-inverted {
    --bg: theme('colors.white');
    --bg-hover: theme('colors.primary.50');
  }

  &--deluxe-outline {
    --bg: theme('colors.white');
    --bg-hover: theme('colors.warn.50');
    --color: theme('colors.warn.800');
    --color-hover: theme('colors.warn.700');
    --border-color: theme('colors.warn.800');
  }

  &--deluxe {
    --bg: theme('colors.warn.800');
    --bg-hover: theme('colors.warn.900');
    --color: theme('colors.white');
    --color-hover: theme('colors.white');
    --border-color: theme('colors.warn.800');
    --border-color-hover: theme('colors.warn.800');
  }

  &--premium-locked {
    @include gradient-warm();
    border: none;

    --color: theme('colors.white');
    --color-hover: theme('colors.white');
  }

  &--premium-unlocked {
    @apply tw-shadow-sm;
    --bg: theme('colors.white');
    --bg-alt: theme('colors.secondary.500');
    --bg-hover: theme('colors.lgray.50');
    --color: theme('colors.secondary.500');
    --color-hover: theme('colors.secondary.600');
    border: none;
  }

  $focus-modifier: m(focus);
  $no-focus-styles-modifier: m(no-focus-styling);
  $disabled-modifier: m(disabled);

  &--vertical {
    @apply tw-flex-col tw-p-2 tw-h-auto;
  }
  &--vertical .gik-button__content {
    @apply tw-flex-col tw-mt-1 tw-ml-0;
  }

  &--size-base:not(&--custom-icon-size) &--vertical {
    @apply tw-flex-col tw-mt-1 tw-ml-0;
    svg {
      @apply tw-w-6 tw-h-6;
    }
  }

  &--focus,
  &:focus {
    @apply tw-outline-none;
    #{$focus-modifier}:not(#{$no-focus-styles-modifier}):not(#{$disabled-modifier}) {
      @apply tw-ring-2 tw-ring-offset-2;
    }
  }

  #{$focus-modifier}:not(#{$no-focus-styles-modifier}):not(#{$disabled-modifier}) {
    @apply tw-ring-2 tw-ring-offset-2;
  }

  #{$disabled-modifier} {
    @apply tw-cursor-not-allowed #{!important};
    opacity: 0.6;
  }

  &--loading {
    @apply tw-cursor-default;
    @extend .effect-barberpole;
  }

  &__append {
    @apply tw-flex;
  }
  &__prepend {
    @apply tw-flex;
  }

  // ----- Sizes

  @apply tw-text-base tw-px-3;
  height: 40px;

  &--size-xs {
    @apply tw-text-xs tw-px-1;
    height: 20px;
    --border-size: 1px;

    > span.#{$blockName}__content ~ * {
      @apply tw-ml-1;
    }

    > * ~ span.#{$blockName}__content {
      @apply tw-ml-1;
    }
  }

  &--size-sm {
    @apply tw-text-sm tw-px-2;
    height: 30px;
    --border-size: 1px;

    > span.#{$blockName}__content ~ * {
      @apply tw-ml-1;
    }

    > * ~ span.#{$blockName}__content {
      @apply tw-ml-1;
    }
  }

  &--size-lg {
    @apply tw-text-lg tw-px-3;
    height: 50px;
  }

  &--size-xl {
    @apply tw-text-xl tw-px-6;
    height: 60px;
  }

  &--size-xs:not(&--custom-icon-size) {
    svg {
      @apply tw-w-3 tw-h-3;
    }
  }

  &--size-sm:not(&--custom-icon-size) {
    svg {
      @apply tw-w-4 tw-h-4;
    }
  }

  &--size-base:not(&--custom-icon-size) {
    svg {
      @apply tw-w-6 tw-h-6;
    }
  }

  &--size-lg:not(&--custom-icon-size) {
    svg {
      width: 1.875rem;
      height: 1.875rem;
    }
  }

  &--size-xl:not(&--custom-icon-size) {
    svg {
      width: 2.25rem;
      height: 2.25rem;
    }
  }

  // ----- Shapes

  @include m(pill size-base) {
    @apply tw-px-3;
  }

  @include m(pill size-sm) {
    @apply tw-px-2;
  }

  &--squared {
    @apply tw-rounded-none;
    &:focus:not(.gik-button--no-focus-styling):before {
      @apply tw-rounded-none;
    }
  }

  &--rounded {
    @apply tw-rounded;
    &:focus:not(.gik-button--no-focus-styling):before {
      @apply tw-rounded;
    }
  }

  &--pill {
    @apply tw-rounded-full;
    &:focus:not(.gik-button--no-focus-styling):before {
      @apply tw-rounded-full;
    }
  }

  &--circle {
    @include button-circle-styling;
    &:focus:not(.gik-button--no-focus-styling):before {
      border-radius: 100%;
    }
  }

  &--hide-label-mobile {
    @screen md-down {
      .gik-button__content {
        @apply tw-hidden;
      }
      @include button-circle-styling;
    }
  }

  &--wide {
    @apply tw-px-10;
  }

  &--amazon {
    svg {
      path {
        fill: var(--shade-text);
      }
    }
  }
}

.gik-button-tight {
  @apply tw-py-1;
  height: 28px;
}
