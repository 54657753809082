+b(finish-registration)
  +e(instructions)
    @apply tw-text-center tw--mx-4 tw-mb-4 tw-py-8

  +e(title)
    @apply tw-font-heading tw-font-normal tw-text-2xl tw-leading-none tw-mb-2

  +e(subtitle)
    @apply  tw-font-normal tw-text-base tw-leading-tight tw-mx-auto
    max-width: 312px

  +e(page-title)
    @apply tw-font-bold

  +e(sign-up-form)
    @apply tw-mt-2

  +e(finish-signup-button or-via-email)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-start tw-content-start

  +e(or-via-email)
    @apply tw-my-4

  +e(instructions-wrapper)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
    align-content: stretch
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
      align-content: stretch

  +e(instructions-copy)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch
