@keyframes #{$gik-prefix}bone-animation {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -10000px 0;
  }
}

$bone-gradient: linear-gradient(
  var(--gradient-angle),
  var(--color-primary) 30%,
  var(--color-accent) 70%,
  var(--color-primary) 100%
);

.#{$gik-prefix}bone-animated {
  --gradient-angle: 90deg;
  --color-primary: theme('colors.neutral.200');
  --color-accent: theme('colors.neutral.50');
  --bone-animation-speed: 90s;

  background: $bone-gradient;
  background-size: 200px;
  background-repeat: revert;
  animation: #{$gik-prefix}bone-animation var(--bone-animation-speed) linear infinite;
}
