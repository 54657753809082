+b(inkind-cover-upload-controls)
  +e(cancel save drag-hint)
    @apply tw-absolute tw-z-above
    right: 0.5rem
  +e(cancel)
    top: 0.5rem
  +e(save)
    bottom: 0.5rem
  +e(drag-hint)
    @apply tw-text-white tw-flex tw-justify-center tw-items-center
    // TODO: clean up old drag hint styling
    // center the hint in the editor
    width: 180px
    left: 50%
    margin-left: -90px
    border: 1px solid rgba(255,255,255,0.55)
    border-radius: 5px
    background-color: rgba(0,0,0,0.22)
    padding: 8px 10px 8px 9px
    span
      margin-left: 5px
    +m(horizontal)
      svg
        transform: rotate(90deg)

