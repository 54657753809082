.gik-image-as-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.#{$gik-prefix}about {
  font-size: 1rem;

  &__section-title {
    @apply tw-w-full tw-px-4 tw-py-8 tw-text-center tw-font-semibold tw-text-4xl tw-font-heading tw-leading-none;

    &--primary {
      @apply tw-text-primary-500;
    }

    &--pink {
      @apply tw-text-danger-500;
    }

    &--orange {
      @apply tw-text-secondary-600;
    }

    &--purple {
      @apply tw-text-purple-500;
    }

    &--green {
      @apply tw-text-success-500;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &--top {
      background-image: url($public-root + 'about/img/about-head.jpg');
    }
  }

  &__header-text {
    @apply tw-font-semibold tw-text-5xl tw-my-32 tw-text-white tw-font-heading;
  }

  &__basic-copy {
    @apply tw-pt-0 tw-px-8 tw-pb-16 tw-w-full tw-m-auto tw-text-lg tw-leading-8 tw-text-justify tw-text-neutral-900 tw-font-body;
    max-width: calc(640px + 4rem);
  }

  &__parallax-container .react-parallax-content {
    display: flex;
    flex-direction: column;
  }

  &__story {
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(24px);
    border-radius: 32px;
    max-width: 704px;
    align-self: center;
    @apply tw-my-16 tw-mx-5;

    .#{$gik-prefix}about__basic-copy {
      @apply tw-pt-8;
    }
  }

  &__video {
    @apply tw-mx-8;
  }

  &__team-wrapper,
  &__press-wrapper {
    @apply tw-bg-neutral-50;
  }

  &__team {
    max-width: 1024px;
    margin: auto;
    padding: 1em;
    width: 100%;
  }

  &__team-member-tiles {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 230px));
    grid-gap: 2em 1em;
    justify-content: space-evenly;
    width: 100%;
  }

  &__press {
    max-width: 960px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  &__press-tile {
    padding-bottom: 2rem;
    flex-basis: 100%;
    border-bottom: 1px solid theme('colors.neutral.400');

    &:last-of-type {
      border-bottom: 0;
    }
  }

  &__press-quote {
    @apply tw-font-normal tw-text-3xl tw-leading-8 tw-m-auto tw-text-center tw-py-8 tw-px-4 tw-pb-4 tw-text-neutral-900 tw-font-heading;
    max-width: 360px;
    min-width: 280px;
  }

  &__press-dots {
    width: 37px;
    border-bottom: 3px dotted theme('colors.neutral.400');
    border-top: 0;
    margin: 17px auto;
  }

  &__press-logo {
    text-align: center;

    img {
      display: inline-block;
      transform: scale(0.5);
      -webkit-backface-visibility: hidden;
    }
  }

  &__press-link {
    padding: 5rem;
    text-align: center;
    width: 100%;
  }

  &__footer {
    width: 100%;
    @include gradient-green-blue(45deg);
    position: relative;
  }

  &__footer-leaf {
    width: 100%;
    @extend .gik-image-as-background;
    background-size: cover;
    background-image: url($public-root + 'about/img/business-bg-desktop.png');

    &:nth-child(2) {
      display: none;
    }
  }

  &__footer-content {
    @apply tw-text-white;
    padding: 4rem 2rem;
    max-width: 700px;
    margin: auto;
  }

  &__footer-title {
    @apply tw-text-center tw-font-semibold tw-text-5xl tw-font-heading;
  }

  &__footer-body {
    @apply tw-py-8 tw-px-0 tw-text-lg tw-leading-loose tw-text-justify tw-font-body;
  }

  @screen lg {
    .#{$gik-prefix}about {
      &__section-title {
        padding: 4rem 1rem;
      }
    }
  }

  @screen md {
    .#{$gik-prefix}about {
      &__section-title {
        padding: 3rem 1rem;
      }

      &__team-member-tiles {
        grid-template-columns: repeat(auto-fit, 230px);
      }

      &__press-tile {
        flex-basis: 50%;

        &:nth-last-of-type(-n + 2) {
          padding-top: 2rem;
          padding-bottom: 0;
          border-bottom: 0;
        }

        &:nth-of-type(even) {
          border-left: 1px solid theme('colors.neutral.400');
        }
      }
    }
  }

  @screen sm-down {
    .#{$gik-prefix}about {
      &__footer {
        padding-bottom: 660px;
      }

      &__footer-leaf {
        background-image: url($public-root + 'about/img/business-bg-mobile-1.png');
        position: absolute;
        @apply tw-inset-0;
        z-index: 1;
        background-position: top right;
        background-size: contain;

        &:nth-child(2) {
          z-index: 0;
          display: block;
          background-image: url($public-root + 'about/img/business-bg-mobile-2.png');
          background-position: bottom left;
        }
      }
    }
  }
}
