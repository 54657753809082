// image root (this can be removed after the migration).
// TODO: also consider storing our files (e.g. ad fallbacks) in blob storage so
// we don't have to copy them at build time
$public-root: '/';

// ----- GIK packages

@import '@gik/core/styles';
@import '@gik/ui';

@import 'nprogress/nprogress.css';

// ----- Feature styles

@import '@gik/core/pages/NotFound/NotFoundPage';
@import '@gik/core/pages/Legal/Legal';
@import '@gik/core/pages/Legal/LegalSkeleton';
@import '@gik/core/pages/termsOfServiceAndPrivacyPolicyModal';

@import '@gik/core';
@import '@gik/auth';
@import '@gik/analytics';
@import '@gik/about';
@import '@gik/contact';
@import '@gik/homepage';
@import '@gik/marketing';
@import '@gik/checkout';
@import '@gik/shop';
@import '@gik/blog';
@import '@gik/ads';
@import '@gik/wishlist-page';
@import '@gik/calendar';
@import '@gik/inkind-page';
@import '@gik/user-profile';
@import '@gik/create';
@import '@gik/finish-registration';
@import '@gik/crm';
@import '@gik/search';
@import '@gik/stream';
@import '@gik/messaging/thank-you';
@import '@gik/landing-pages';

body #nprogress .bar {
  @apply tw-z-nprogress tw-bg-primary-500;
  height: 4px;
}

//// removes the NextJS14 error toast. probably not a good idea.
//nextjs-portal {
//  display: none;
//}
