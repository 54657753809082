$blockName: '#{$gik-prefix}tips-sample-page';

.#{$blockName} {
  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-text-center;
  }
  &__content {
    @apply tw-mx-auto tw-whitespace-pre-wrap;
    max-width: 700px;
  }
  &__feature_image {
    @apply tw-mx-auto tw-inline-block tw-h-10;
  }
  &__feature_title {
    @apply tw-mt-2;
  }

  &__feature_title_wrapper {
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-center tw-mb-6;
  }

  &__lead {
    @apply tw-pt-8;
  }

  .gik-accordion {
    @apply tw-mt-6;
  }

  &__foliage-mobile {
    background-size: contain;
    background-repeat: no-repeat;
    margin-left: -2rem;

    &--left {
      @screen md-down {
        background-image: url($public-root + 'marketing/sideFoliageL2-2x.png');
      }
      background-position: left;
      height: 146px;
    }
    &--right {
      @screen md-down {
        background-image: url($public-root + 'marketing/sideFoliageR2-2x.png');
      }
      background-position: right;
      height: 195px;
    }

    @screen md {
      @apply tw-hidden;
    }
  }

  &__section-features {
    @apply tw-pt-8;

    > .gik-container {
      position: relative;
      z-index: 1;
    }

    @screen md {
      > .gik-container {
        max-width: 440px;
      }

      &::before {
        content: '';
        width: 150px;
        height: 195px;
        background: url($public-root + 'marketing/sideFoliageR2-2x.png');
        background-size: contain;
        position: absolute;
        top: 180px;
        right: 0;
      }
      &::after {
        content: '';
        width: 150px;
        height: 146px;
        background: url($public-root + 'marketing/sideFoliageL2-2x.png');
        background-size: contain;
        position: absolute;
        bottom: 400px;
        left: 0;
      }
    }

    @screen lg {
      > .gik-container {
        max-width: 540px;
      }

      &::before {
        content: '';
        width: 210px;
        height: 267px;
      }
      &::after {
        content: '';
        width: 230px;
        height: 224px;
      }
    }

    @screen xl {
      > .gik-container {
        max-width: unset;
      }
    }
  }
  .gik-feature-content {
    &:not(:last-child) {
      @apply tw-pb-12 tw-mb-8;
    }
  }

  .gik-start-inkind-card-wrapper {
    @apply tw-pb-16;
  }
}
