+b(recipient-tile)
  --size: 100%
  @screen sm
    --size: 160px

  $animation-duration: 270ms
  @apply tw-text-center
  > div
    @apply tw-flex tw-flex-col tw-w-full tw-bg-neutral-100
    border-radius: 50%
    width: var(--size)
    height: var(--size)
    transition: box-shadow $animation-duration ease-in-out, transform $animation-duration ease-in-out

  &__box
    @apply tw-flex-1 tw-flex-shrink-0 tw-p-2 tw-text-center
    border-radius: 50%

  &__title
    @apply tw-block tw-font-bold tw-text-base tw-leading-none tw-text-neutral-900
    @apply tw-flex tw-items-center tw-justify-center
    height: 42px

  .gik-hover-animation__shadow
    @apply tw-rounded-md

  &:hover > div
    box-shadow: 2px 9px 42px 2px rgba(70, 101, 108, 0.26)
    transform: scale(1.02) translate(0, -2px)

  +b(bg-image)
    @apply tw-bg-cover


+b(recipient-tile-skeleton)

  &__image-wrapper
    @apply tw-w-full

  @screen sm
    width: 160px
    height: 160px

  &__image.gik-frame-bone
    --width: 100%
    --height: 100%

  > main
    @apply tw-pt-4
    .gik-paragraph-bone
      @apply tw-justify-center
