+b(group-widget-editor)
  @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch tw-gap-8
  align-content: stretch

  @screen md
    @apply tw-flex-row

  +e(column)
    @apply tw-flex tw-flex-1 tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch

  +e(links)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch

  +e(header1)
    @apply tw-font-body tw-font-bold tw-text-lg tw-leading-none tw-text-neutral-900 tw-my-1 tw-text-center

  +e(paragraph1)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700 tw-text-center tw-mt-1 tw-mb-0 tw-px-12

  +e(tooltip-container)
    @apply tw-self-center tw-mb-4
    svg
      @apply tw-text-neutral-700

  +e(header2)
    @apply tw-font-body tw-font-bold tw-text-base tw-leading-none tw-text-neutral-800 tw-my-0

  +e(paragraph2)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700

  +e(widget-preview-title)
    @apply tw-font-body tw-font-normal tw-text-sm tw-leading-none tw-text-neutral-800 tw-my-2

  +e(description-section)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch

    +b(form)
      @apply tw-w-full
      +b(switch)
        @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
        align-content: stretch
        +e(label-before)
          @apply tw-font-bold tw-text-base tw-leading-none tw-text-neutral-800

  +e(description-switch-label)
    @apply tw-font-bold tw-text-base tw-leading-none tw-text-neutral-800

  +e(link-add-tile)
    @apply tw-mb-0 #{!important}

  +b(widget-tile-editor)
    @apply tw-mb-2 tw-relative
    min-height: 68px
    +e(spinner-overlay)
      @apply tw-absolute
      left: 12px

  +b(inkind-group-widget)
    @apply tw-pointer-events-none

  .gik-form-group
    @apply tw-items-center tw-flex-1 tw-w-full

  .gik-form-group__label-wrapper
    @apply tw-flex-1
  .gik-form-group__label
    @apply tw-text-lg tw-text-neutral-800 tw-mt-0

  .gik-form-group__content-wrapper
    @apply tw-justify-end tw-flex-none

  &__description-mode-fields
    .gik-form-group--textarea
      @apply tw-flex tw-flex-col tw-justify-start tw-items-end tw-content-end tw-gap-4
      & > div
        @apply tw-w-full

  +b(textarea)
    &__textarea, &__wrapper
      @apply tw-border-white

+b(widget-tile-editor)
  @apply tw-bg-white tw-p-2 tw-rounded
  @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full

+b(widget-tile-add)
  @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-rounded tw-bg-neutral-200
  min-height: 68px
  &__copy
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-gray-400 tw-font-bold tw-ml-2

+b(add-edit-group-link-modal)
  +e(form-footer)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
    align-content: stretch
