+b(external-login-form-wrapper)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch
  @apply tw-gap-2

  &--vertical
    @apply tw-flex tw-flex-row-reverse tw-justify-between tw-items-start tw-content-start
    & > *
      @apply tw-flex-1

+b(google-login-button-inner)
  &__google-container
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    pointer-events: none
    & > div
      pointer-events: auto

// this is google's account picker iframe. `prompt_parent_id` is ignored on mobile so we need to make sure
//  it renders above the login modal
@screen md-down
  #credential_picker_iframe
    @apply tw-z-max #{!important}
