+b(homepage-testimonial)
  @apply tw-flex tw-flex-col tw-text-neutral-700 tw-font-body tw-tracking-normal

  +e(user-info-container)
    @apply tw-flex tw-flex-row tw-mt-4 tw-tracking-normal

  +e(quote)
    @apply tw-italic tw-text-base tw-leading-tight tw-tracking-normal tw-font-body

    &:before
      content: '"'

    &:after
      content: '"'

  +e(name-and-title-container)
    @apply tw-flex tw-flex-col tw-justify-center tw-ml-3 tw-text-base tw-leading-tight tw-tracking-normal

  +e(name)
    @apply tw-font-medium tw-tracking-normal

  +e(avatar)
    img
      width: 65px
      height: 65px

  &--reversed &__user-info-container
    @apply tw-flex-row-reverse

  &--reversed &__name-and-title-container
    @apply tw-mr-3
    margin-left: 0

  @screen md-down
    +e(quote)
      @apply tw-text-center
      max-width: 260px
      margin-left: auto
      margin-right: auto

    +e(user-info-container)
      @apply tw-mx-auto tw-mt-6

    +m(reversed, name)
      @apply tw-text-right

