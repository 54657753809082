+b(sign-in-flow-content)
    &__transition
      //position: relative
      & > *
        //position: absolute
        top: 0
        width: 100%
        & + *
          margin-top: -100%

      &-enter
        $transitionName: &
        opacity: 0

        &#{$transitionName}-active
          opacity: 1
          transition: opacity 200ms ease-in

      &-exit
        $transitionName: &
        opacity: 1

        &#{$transitionName}-active
          opacity: 0
          transition: opacity 200ms ease-in



