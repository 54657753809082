$selected-line-size: 4px

+b(tabbed-view)
  --color: theme('colors.primary.500')

  +e(tabs-section)
    @apply tw-border-b tw-border-neutral-300 tw-flex tw-flex-row

  +e(tab)
    @apply tw-font-bold tw-px-4 tw-py-2 tw-cursor-pointer
    margin-bottom: -1px
    border-bottom: $selected-line-size solid transparent

  .gik-tabbed-view__tab.gik-tabbed-view__tab--selected
    border-bottom: $selected-line-size solid var(--color)
    transition: 100ms ease-in

  .gik-tabbed-view__tab.gik-tabbed-view__tab--selected.selectedWithColor
    color: var(--color)

  +e(tab-content)
    @apply tw-py-4

  @screen md-down
    +e(tab)
      @apply tw-flex-1 tw-m-0 tw-content-center tw-justify-center tw-text-center tw-px-2

  @screen xs-down
    +e(tab)
      @apply tw-text-sm
