+b(rocker-switch)
  @apply tw-flex-shrink-0 tw-inline-block tw-relative tw-select-none

  // SIZE OF SWITCH
  // ==============
  // All sizes are in em - therefore
  // changing the font-size here
  // will change the size of the switch.
  // See .rocker-small below as example.
  font-size: 1em

  --border-x: .1em
  --border-y: .1em

  --border-width: 1em
  --width: 7em
  --height: 4em

  --calc-width: calc((var(--width)/2) - (var(--border-width)/2))
  --calc-height: calc((var(--height)/2) + (var(--border-width)/2))


  font-weight: bold
  text-align: center
  text-transform: uppercase
  color: #888
  width: var(--width)
  height: var(--height)
  overflow: hidden
  border-bottom: 0.5em solid #eee

  &__mid
    display: flex
    align-items: center
    justify-content: center
    padding-top:1em


  &--size-lg
    font-size: 1.5em
    // margin: 1em

  &--size-sm
    font-size: 0.75em
    // margin: 1em

  &::before
    content: ""
    position: absolute
    top: 0.5em
    left: 0
    right: 0
    bottom: 0
    background-color: #999
    border: 0.5em solid #eee
    border-bottom: 0

  &__switch-left,
  &__switch-right
    cursor: pointer
    position: absolute
    display: flex
    align-items: center
    justify-content: center
    height: var(--calc-height)
    width: var(--calc-width)
    transition: 0.2s

    &::before
      content: ""
      position: absolute
      width: 0.4em
      height: 2.45em
      bottom: -0.45em
      background-color: #ccc
      transform: skewY(-65deg)

  &__switch-left
    height: 2.4em
    width: 2.75em
    left: 0.85em
    bottom: 0.4em
    background-color: #ddd
    transform: rotate(15deg) skewX(15deg)
    &::before
      left: -0.4em

  &__switch-right
    right: 0.5em
    bottom: 0
    @apply tw-bg-danger-400 tw-text-white

    &::before
      right: -0.375em
      background-color: transparent
      transform: skewY(65deg)

  input
    opacity: 0
    width: 0
    height: 0

    &:checked + .gik-rocker-switch__switch-left
      @apply tw-bg-success-400 tw-text-white
      bottom: 0px
      left: calc(var(--border-width)/2)
      height: var(--calc-height)
      width: var(--calc-width)
      transform: rotate(0deg) skewX(0deg)

    &:checked + .gik-rocker-switch__switch-left::before
      @apply tw-bg-transparent
      width: 3.0833em

    &:checked + .gik-rocker-switch__switch-left + .gik-rocker-switch__switch-right
      background-color: #ddd
      color: #888
      bottom: calc((var(--border-width)/2) - var(--border-y))
      right: calc((var(--border-width)) - var(--border-x)*2)
      height: calc(var(--calc-height) - var(--border-y))
      width: calc(var(--calc-width) - (var(--border-y)*2))

      transform: rotate(-15deg) skewX(-15deg)


    &:checked + .gik-rocker-switch__switch-left + .gik-rocker-switch__switch-right::before
      background-color: #ccc


    &:focus + .gik-rocker-switch__switch-left
      color: #333


    &:checked:focus + .gik-rocker-switch__switch-left
      @apply tw-text-white


    &:focus + .gik-rocker-switch__switch-left + .gik-rocker-switch__switch-right
      @apply tw-text-white


    &:checked:focus + .gik-rocker-switch__switch-left + .gik-rocker-switch__switch-right
      color: #333
