+b(group-background-picker)
  @apply tw-relative
  &__edit
    @apply tw-absolute tw-bottom-0 tw-left-0 tw-bg-white tw-text-secondary-500 tw-flex tw-items-center tw-justify-center tw-cursor-pointer
    border-radius: 50%
    width: 24px
    height: 24px
    svg
      width: 18px
      height: 18px

  +e(spinner-overlay)
    @apply tw-inset-0 tw-absolute
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  &__content
    @apply tw-relative tw-mx-auto
    border-radius: 50%
    width: 58px
    height: 58px

    +b(avatar)
      width: 58px
      height: 58px

    @screen lg
      width: 50px
      height: 50px
      +b(avatar)
        width: 50px
        height: 50px

  &--active &__content
    //@apply tw-ring-4 tw-ring-white tw-ring-offset-0 tw-ring-offset-white
    //--tw-ring-inset: /*!*/ /*!*/
    box-shadow: rgb(255, 255, 255) 0 0 0 0, rgb(255, 255, 255) 0 0 0 4px, rgba(0, 0, 0, 0) 0 0 0 0

  &__label
    @apply tw-text-neutral-700 tw-text-sm tw-font-bold tw-uppercase tw-mt-2 tw-block

  &__empty
    @apply tw-bg-secondary-500 tw-flex tw-items-center tw-justify-center tw-text-white tw-cursor-pointer tw-mx-auto
    border-radius: 50%
    width: 58px
    height: 58px
    @screen lg
      width: 50px
      height: 50px

  +b(avatar)
    @apply tw-cursor-pointer
  +b(color-swatch)
    @apply tw-cursor-pointer
    --size: 58px
    @screen lg
      --size: 50px

  &--gradient &__empty
    background: linear-gradient(225deg, #E9582B -0.2%, #FF9E5A 51.37%, #FFD76F 99.8%), #35922D
