+b(gift-card-picker)

  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-xl tw-leading-none tw-text-neutral-900
  +e(header)
    @apply tw-flex tw-justify-between tw-items-center
  +e(main)
    @apply tw-mt-4 tw-text-center tw-select-none
  +e(footer)
    @apply tw-text-center tw-mt-4
  +e(icon)
    @apply tw-cursor-pointer tw-text-neutral-600

  +e(empty-tile)
    @apply tw-flex tw-flex-col tw-cursor-pointer
    +b(svg-icon)
      width: 35px
      height: 35px
      @apply tw-text-neutral-700
    > main
      @apply tw-bg-neutral-300 tw-rounded
      +b(keep-proportions)
        +e(wrapper)
          svg
            @apply tw-absolute tw-inset-0 tw-m-auto
    > footer
      @apply tw-text-center tw-font-bold tw-text-xs tw-leading-none tw-mt-1 tw-mb-2 tw-text-neutral-700 tw-invisible


  &__grid
    @apply tw-grid tw-gap-4

    @screen xs
      @apply tw-grid-cols-2

    @screen md
      @apply tw-grid-cols-4

  +e(delete)
    @apply tw-bg-danger-500 tw-rounded-full tw-absolute
    @apply tw-flex tw-items-center tw-justify-center
    @apply tw-cursor-pointer

    top: -7px
    right: -7px
    width: 18px
    height: 18px

    &:hover
      @apply tw-bg-danger-700

    > svg
      @apply tw-text-white
      width: 14px

  +e(drag-item)
    @apply tw-z-modalDrag


  +m(disabled)
    @apply tw-opacity-50 tw-pointer-events-none tw-select-none
