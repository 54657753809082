+b(menu)
  min-width: 140px

  +e(seo-navbar)
    @apply tw-hidden

  &__search-bar
    @apply tw-px-4 tw-py-1

  &__search-bar, &__menu-nav-bar
    @apply tw-bg-neutral-200 tw-flex tw-flex-row
    box-shadow: 0px 0px 6px rgba(59, 65, 66, 0.25)

  &--categorize
    +b(popover)
      +e(arrow)
        --bgColor: theme('colors.neutral.100')

  &--search
    +b(popover)
      +e(arrow)
        --bgColor: theme('colors.neutral.200')
