+b(situations-grid-with-nav)
  @apply tw-text-center
  @screen md
    @apply tw-text-left

  > .gik-situations-nav
      @apply tw-mb-4 tw-mx-auto

  > .gik-select
    @apply tw-mb-4
    width: 200px

