+b(calendar-event-type-picker)
  @apply tw-border tw-border-neutral-300 tw-p-2 tw-flex tw-justify-between tw-items-center tw-align-middle tw-cursor-pointer tw-select-none
  +e(icon)
    @apply tw-inline-block tw-mr-2
    width: 40px
    height: 40px
  +e(title)
    @apply tw-inline-block tw-align-middle tw-font-bold tw-text-lg
    color: var(--color)
  +e(editButton)
    @apply tw-text-sm tw-font-bold tw-text-primary-500 tw-whitespace-nowrap
