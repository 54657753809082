@import '../../__vars';

$blockName: '#{$gik-prefix}team-tile';

.#{$blockName} {
  width: 100%;

  &__name {
    @apply tw-text-base tw-font-bold tw-leading-5 tw-text-center tw-text-neutral-900 tw-font-body;
    padding-top: 5px;
  }

  &__title {
    display: flex;
    @apply tw-text-base tw-font-normal tw-leading-5 tw-text-center tw-text-neutral-900;
  }

  &__title-back {
    opacity: 0;
    max-height: 0px;
    overflow: hidden;
    margin-left: -100%;
  }

  &__title-front,
  &__title-back {
    flex: none;
    width: 100%;
    transition: all $title-change-transition-duration linear;
  }

  $gik-team-tile-perspective: 600px;

  &__photo {
    display: flex;
  }

  &__photo-element {
    @extend .gik-image-as-background;
  }

  &__photo-front,
  &__photo-back {
    border-radius: 20px;
    overflow: hidden;
    flex: none;
    width: 100%;
    transition: all $card-flip-transition-duration cubic-bezier(0.45, 0, 0.55, 1);
    backface-visibility: hidden;
  }

  &--show-front {
    .#{$gik-prefix}team-tile {
      &__photo-front {
        transform: perspective($gik-team-tile-perspective) rotateY(0deg);
      }

      &__photo-back {
        transform: perspective($gik-team-tile-perspective) rotateY(180deg);
        margin-left: -100%;
      }
    }
  }

  &--show-back {
    .#{$gik-prefix}team-tile {
      &__photo-back {
        transform: perspective($gik-team-tile-perspective) rotateY(0deg);
        margin-left: -100%;
      }

      &__photo-front {
        transform: perspective($gik-team-tile-perspective) rotateY(-180deg);
      }

      &__title-back {
        max-height: 300px;
        opacity: 1;
      }

      &__title-front {
        opacity: 0;
      }
    }
  }

  &--flip-enabled {
    &.#{$blockName}--show-front {
      .#{$gik-prefix}team-tile {
        &__photo-front:hover {
          transform: perspective($gik-team-tile-perspective) rotateY(-20deg);
          transition-duration: $card-hover-transition-duration;
        }
      }
    }

    &.#{$blockName}--show-back {
      .#{$gik-prefix}team-tile {
        &__photo-back {
          &:hover {
            transform: perspective($gik-team-tile-perspective) rotateY(-20deg);
            transition-duration: $card-hover-transition-duration;
          }
        }
      }
    }
  }
}
