$blockName: b(user-info-modal)

#{$blockName}
  &.modals.modal-v2.gik-modal--padded > .gik-modal__content-wrapper > .content
    @apply tw-px-6 tw-pb-6

  +b(modal-header)
    @apply tw-p-0 tw-h-6



  &__content
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @screen md
      width: 227px

  &__user-name
    @apply tw-font-heading tw-font-semibold tw-text-xl tw-leading-tight tw-my-3

  &__user-email-address
    @apply tw-font-bold tw-text-base tw-leading-none tw-text-primary-600 tw-my-0

  &__copy-button
    @apply tw-mt-3
