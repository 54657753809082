
+b(inkind-supporters-button)
  @apply tw-py-0
  height: 40px
  +b(badge)
    --color: theme('colors.info.600')
    @apply tw-px-4 tw-font-bold tw-font-body

  +b(svg-icon)
    margin-bottom: 4px

