+b(reaction-picker)
  @apply tw-flex tw-gap-2 tw-flex-wrap
  max-width: 232px

  &--loading
    @apply tw-pointer-events-none

  &--loading &__icon
    @apply tw-opacity-30

  +b(loading-spinner)
    @apply tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center

  &__icon
    @apply tw-cursor-pointer tw-transition-opacity tw-transition-transform tw-flex-shrink-0
    transition-duration: 300ms
    &:hover
      transform: scale(1.3)


    &--selected
      transform: scale(1.3)

      // width: 30px
      // height: 30px

      // @screen md
      //   width: 40px
      //   height: 40px
