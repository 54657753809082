+b(base-color-picker)
  min-width: 316px
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-gap-4
  align-content: stretch
  +e(picker-block-wrapper)
    @apply tw-relative
    +m(saturation)
      @apply tw-h-40
      &, & *
        @apply tw-rounded
    +m(hue)
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-2
      .hue-picker
        width: unset !important
        @apply tw-flex-1
        & > div, & > div > div
          @apply tw-rounded #{!important}

  +e(indicator)
    @apply tw-rounded tw-w-8 tw-h-8

  +e(controls)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full tw-gap-2

  +e(save-button)
    flex: 0 0 6rem



+b(solid-color-picker)
  &__rerender
    height: 281px
