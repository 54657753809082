@mixin zoom-transform($var)
  transform: scaleY(var($var))

  &__container
    transform: scaleX(var($var))
    width: #{calc(calc(1/var($var)) * 100%)}

+b(zoom)
  transform-origin: left top
  --zoom-level: none

  +zoom-transform(--zoom-level)

  &__container
    transform-origin: left center
    & > *
      @apply tw-w-full

  @screen xs
    +zoom-transform(--xs-zoom-level)

  @screen sm
    +zoom-transform(--sm-zoom-level)

  @screen md
    +zoom-transform(--md-zoom-level)

  @screen lg
    +zoom-transform(--lg-zoom-level)

  @screen xl
    +zoom-transform(--xl-zoom-level)
