$blockName: '#{$gik-prefix}marketing-nav';

.#{$blockName} {
  &__item__title {
    @apply tw-mr-4;
  }

  &__item {
    @apply tw-flex tw-cursor-pointer tw-items-center tw-font-heading tw-font-semibold tw-text-xl tw-leading-none;
    @extend .gik-no-tap-effect;

    &:not(:first-child) {
      @apply tw-border-t;
      border-color: rgba(255, 255, 255, 0.5);
    }
    a {
      @apply tw-w-full tw-p-2 tw-py-3 tw-font-bold tw-text-white tw-flex tw-items-center;
      > span {
        @apply tw-flex-1;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    @screen md {
      @apply tw-text-3xl;
    }
  }
}
