+b(crm-layout)
  @apply tw-flex tw-flex-1 tw-flex-row
  min-height: 855px

  > .gik-container
    @apply tw-flex tw-flex-1

  +b(supporters-page-content)
    @apply tw-flex tw-flex-col tw-flex-1
    +b(table)
      @apply tw-flex-1

  +b(layout-aside)
    --aside-width: auto
    width: 100%

  +e(aside-return)
    @apply tw-text-primary-600 tw-text-xl tw-font-semibold tw-px-3 tw-pb-2 tw-py-2
    @apply tw-flex tw-items-center tw-font-heading tw-pr-2 tw-mb-4 tw-cursor-pointer
    margin-top: 2px
    > svg
      @apply tw-mr-2

    @screen md-down
      @apply tw-bg-white tw-rounded tw-shadow-sm

  +e(main)
    @apply tw-flex tw-flex-row tw-flex-1 tw-w-full

    @screen md-down

      .gik-table__custom-header
        @apply tw-bg-white tw-rounded-t-md
      .gik-table__table-wrapper
        @apply tw-bg-white
      .gik-table__footer
        @apply tw-bg-white tw-rounded-b-md

    @screen md
      @apply tw-px-8 tw-bg-white tw-rounded tw-pt-2



  .gik-svg-icon--UserGroupOutline
    @apply tw-text-info-500

  +e(aside)
    @apply tw-py-2 tw-px-2
    @screen md
      @apply tw-mb-4 tw-px-0

  +b(crm-navigation)
    @apply tw-hidden
    @screen md
      @apply tw-block

  &__mobile-header-title
    @apply tw-flex-1

  +e(mobile-header)
      @apply tw-text-2xl tw-font-semibold tw-px-1 tw-flex tw-items-center
      @apply tw-font-heading tw-text-neutral-900

      svg
        @apply tw-mr-3 tw-text-info-500

      @screen md
        @apply tw-hidden tw-px-3


      .gik-badge
        @apply tw-ml-4 tw-rounded
        @screen md
          @apply tw-ml-0


  @screen md
    @apply tw-p-4

    > .gik-container
      @apply tw-flex
    +e(aside)
      @apply tw-mr-4 tw-mb-0
