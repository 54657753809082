+b(calendar-announcement-tile)
  @apply tw-flex tw-border-2 tw-border-transparent tw-select-none tw-shadow-sm tw-rounded tw-relative tw-p-2 tw-py-3 tw-text-center tw-justify-center

  > header
    @apply tw-flex-1 tw-flex tw-items-center tw-justify-center tw-rounded-l


  > main
    @apply tw-flex-1 tw-ml-2
    > div
      @apply tw-border-3 tw-border-transparent tw-rounded tw-bg-white tw-shadow-sm
      @apply tw-py-2 tw-px-4 tw-flex

  +m(information)
    --bg: theme('colors.info.50')
    --color: theme('colors.info.500')
  +m(love)
    // TODO: use to the love pallete
    --bg: theme('colors.danger.50')
    --color: theme('colors.danger.500')
  +m(warn)
    --bg: theme('colors.secondary.50')
    --color: theme('colors.secondary.500')


  +e(icon)
    @apply tw-flex-shrink-0
    height: 32px
    width: 32px
    color: var(--color)

  +m(vertical, icon)
      @apply tw-mx-auto tw-mb-2
      height: 64px
      @screen sm
        height: 64px

  +e(title)
    @apply tw-block tw-text-xl tw-font-bold
    margin-bottom: -6px

  +e(description)
    @apply tw-text-neutral-600 tw-text-xs

  +e(boxtitle)
    @apply tw-text-white tw-bg-secondary-500 tw-px-2 tw-absolute tw-top-0 tw-left-0 tw-text-xs tw-rounded-br tw-font-bold
    > span
      @apply tw-relative
      top: -1px
      left: -2px

  +e(label)
    @apply tw-font-bold tw-hidden
    color: var(--color)
    @screen sm
      @apply tw-block

  +m(horizontal)
    @apply tw-flex tw-text-center tw-items-center

  +m(horizontal, icon)
    @apply tw-ml-0 tw-mr-2
    @screen md-down
      @apply tw-mx-0

  +m(selected)
    @apply tw-flex-1
    background-color: var(--bg)
    border-color: var(--color)

  +m(selected, label)
    @apply tw-block

  +m(selected, icon)
    @screen md-down
      @apply tw-mr-2


