+b(group-invite-members)
  &__title
    @apply tw-font-heading tw-text-2xl tw-text-neutral-900

  +b(avatar)
    @apply tw-cursor-pointer tw-flex-shrink-0
    width: 32px
    height: 32px

  &__invited
    @apply tw-font-bold tw-text-sm tw-text-neutral-700
    > span
      @apply tw-text-neutral-800

  &__member-cell
    @apply tw-items-center tw-whitespace-nowrap tw-flex-shrink-0
  &__member-content
    @apply tw-overflow-ellipsis tw-overflow-hidden

  &__member-name
    @apply tw-font-bold tw-overflow-ellipsis tw-overflow-hidden

  .gik-table__td--lastActive
    @apply tw-text-neutral-700 tw-text-sm

  .gik-table__th--roleId
    @apply tw-text-right

  .gik-table__td--roleId
    @apply tw-text-right
    .gik-select
      width: 140px
      @apply tw-ml-auto
      .gik-select__value-container
        @apply tw-pr-1
      .gik-select__indicator
        @apply tw-pl-1

  .gik-table__td-inner
    @apply tw-w-full

  .gik-table__tr
    @apply tw-flex

  .gik-table__th--fullName
    @apply tw-flex-1

  .gik-table__td--fullName
    @apply tw-overflow-hidden tw-flex-1
    .gik-flex-grid2
      @apply tw-flex-nowrap

.gik-role-selector.gik-select--plain


  .gik-select__menu
    // make the menu anchor to the right
    right: 0
    min-width: 300px
  .gik-select__option
    @apply tw-cursor-pointer tw-flex
    &:not(:last-child)
      @apply tw-border-b tw-border-neutral-300
    span
      @apply tw-font-semibold tw-text-neutral-900 tw-font-ui tw-text-xl
    span.gik-select__help
      @apply tw-font-normal tw-text-sm tw-font-body tw-text-neutral-700

  .gik-select__option--is-selected
    @apply tw-cursor-default
    @apply tw-bg-white #{!important}

  .option-superadmin
    .gik-select__pre
      margin-right: 0.3rem
      margin-left: 0.1rem

  .option-owner
    .gik-select__pre
      svg
        @apply tw-text-warn-600

  .option-admin
    .gik-select__pre
      margin-top: 0px
      svg
        @apply tw-text-danger-500

  .option-member
    .gik-select__pre
      svg
        @apply tw-text-secondary-500

  .option-remove
    .gik-select__pre
      svg
        @apply tw-text-danger-500



  .gik-select__main
    @apply tw-flex-1

  .gik-select__pre
    @apply tw-mr-2 tw-text-neutral-900
    margin-top: 2px
    width: 20px
  .gik-select__post
    @apply tw-text-success-500 tw-flex tw-items-center
    width: 24px

  .gik-select__option--danger
    span
      @apply tw-text-danger-500


