$blockName: #{$gik-prefix}claims-list;

.#{$blockName} {
  list-style-type: none;
  padding: 0;
  margin: 0;

  &--empty > div {
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    border-radius: $border-radius;
    display: flex;
    @apply tw-h-72 tw-bg-neutral-100;
    @apply tw-font-normal tw-text-base tw-leading-snug tw-text-neutral-700;
    @screen md-down {
      @apply tw-mx-4;
    }

    span {
      max-width: 470px;
      text-align: center;
    }
  }

  &__empty-text {
    @apply tw-font-body tw-text-lg tw-text-neutral-800 tw-tracking-normal;
  }
}
