// ----- base styles

@import './utils';
@import './core';

@import './base/print';
@import './fonts/presets'; // Font style presets
@import './helpers';
@import './base/br';
@import './base/lists';
@import './base/colors';
@import './base/typography';
@import './base/error';
@import './base/article';
@import './base/browser';
@import './base/document';
@import './helpers/gradients';

@import './animations/fade';

@import '../components/DevTools';
@import '../components/ErrorBoundary';
@import '../components/InViewport';

@import './utilities/text-shadow';

// ----- FontIcons

@import './fonts/giveinkind-v2';

// ----- Third party styles

// ----- Default values

:root {
  @apply tw-text-neutral-900;
  @apply tw-font-body;
  @apply tw-tracking-normal;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  min-height: 100%;
}

address {
  font-style: normal;
}

// some important stripped out TW classes

.\!tw-flex {
  display: flex !important;
}

.\!md\:tw-flex {
  @screen md {
    @apply tw-flex #{!important};
  }
}

.\!md\:tw-hidden {
  @screen md {
    @apply tw-hidden #{!important};
  }
}

.\!md\:tw-py-20 {
  @screen md {
    @apply tw-py-20 #{!important};
  }
}

.\!md\:tw-pt-20 {
  @screen md {
    @apply tw-pt-20 #{!important};
  }
}

.\!md\:tw-pb-20 {
  @screen md {
    @apply tw-pb-20 #{!important};
  }
}

.\!md\:tw-py-0 {
  @screen md {
    @apply tw-py-0 #{!important};
  }
}

.tw-min-w-xs {
  min-width: 20rem;
}

.md\:tw-min-w-xs {
  @screen md {
    min-width: 20rem;
  }
}

//.md\:tw-leading-none {
//  @screen md {
//    @apply tw-leading-none
//  }
//}
