+b(inkind-calendar-section)
  @apply tw-flex tw-flex-col

  +e(header)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full
    @apply tw-my-4

  +e(buttons-wrapper)
    @apply tw-ml-2
    & > *:not(:last-child)
      @apply tw-mr-2

  &__buttons-wrapper &__add-request-btn
    @apply tw-text-base tw-leading-none

  > div
    @apply tw-flex-1 tw-flex tw-flex-col

  +b(inkind-calendar)
    @apply tw-flex-1

    --aside-height: 590px
