$blockName: #{$gik-prefix}frame-bone;

.#{$blockName} {
  --border-width: 4px;
  --width: 200px;
  --height: 200px;
  --background-color: white;

  width: var(--width);
  height: var(--height);

  position: relative;
  --gradient-angle: 90deg;
  --color-primary: theme('colors.neutral.200');
  --color-accent: theme('colors.neutral.50');
  --bone-animation-speed: 90s;

  background: $bone-gradient;
  background-size: 200px;
  background-repeat: revert;
  animation: #{$gik-prefix}bone-animation var(--bone-animation-speed) linear infinite;

  & > div {
    background: var(--background-color);

    position: absolute;
    top: var(--border-width);
    bottom: var(--border-width);
    left: var(--border-width);
    right: var(--border-width);
  }
}
