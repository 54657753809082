+b(supporters-page-content)
  hr
    @apply tw-border-neutral-300
  +e(note)
    @apply tw-italic tw-text-center tw-text-sm tw-my-0 tw-py-4

    @screen md-down
      @apply tw-bg-white tw-rounded-b-md

    @screen md
      @apply tw-text-base
