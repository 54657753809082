+b(gift-type-grid)
  @apply tw-text-center
  &__grid
    @apply tw-grid tw-gap-4 tw-mb-8

    @screen xs
      @apply tw-grid-cols-2

    @screen sm
      @apply tw-flex tw-flex-wrap tw-justify-center tw-gap-8

  &--skeleton &__grid
     @apply tw-mb-16
