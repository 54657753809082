+b(section-title-and-description)
  @apply tw-py-16 tw-px-5

  +e(title)
    @apply tw-font-heading tw-text-2xl tw-font-semibold tw-text-neutral-900 tw-leading-none tw-mt-0 tw-mb-3 tw-text-center
  +e(description)
    @apply tw-font-body tw-text-base tw-leading-tight tw-font-normal tw-text-neutral-800 tw-mt-0 tw-mb-6 tw-text-center

  +m(blue-background)
    @apply tw-bg-neutral-50 tw-rounded-md

  @screen sm
    @apply tw-px-8
    +e(description)
      @apply tw-mx-auto
      max-width: 25rem


