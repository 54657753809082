+b(gift-card-claim-conflict-resolution)
  &__message
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center
    border-bottom: 1px dotted #ddd
    h1
      @apply tw-font-bold tw-text-3xl tw-leading-none tw-text-neutral-900 tw-p-0 tw-m-0 tw-ml-1 tw-mt-2
    p
      @apply tw-text-center tw-font-normal tw-text-base tw-leading-snug tw-text-neutral-900
      max-width: 417px

  &__title
    @apply tw-flex tw-justify-center tw-items-center

  &__image
    @apply tw-mt-2
    width: 72px
    height: 72px

  ul
    @apply tw-overflow-auto
    > li
      border-bottom: 1px dotted #ddd
      padding: 10px
