.#{$gik-prefix}app-nav-mobile {
  @apply tw-relative tw-flex tw-flex-col;
  height: 100vh;

  &__logo {
    @apply tw-pb-4 tw-pt-3 tw-text-center;
    svg {
      @apply tw-mx-auto;
      height: 36px;
      overflow: visible;
      transform: scale(0.85);
    }
  }

  &__nav {
    > li {
      > a:not(.gik-button) {
        @apply tw-flex tw-items-center;
        @apply tw-py-2 tw-px-3;
        @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900;
        @apply tw-whitespace-nowrap tw-overflow-hidden;
        text-overflow: ellipsis;
        &:hover {
          @apply tw-bg-neutral-200;
        }
      }
      .gik-avatar {
        @apply tw-mr-2;
        width: 35px;
        height: 35px;
      }
    }
  }

  &__start-page {
    @apply tw-mx-auto tw-text-center tw-mt-4;
    > a {
      @apply tw-inline-flex;
    }
  }

  .gik-page-row-item__title {
    @apply tw-text-xl;
  }

  &__avatar {
    > li {
      @apply tw-flex;
      > a {
        @apply tw-py-2 tw-px-3 tw-w-full tw-flex tw-items-center;
        @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900;
        &:hover {
          @apply tw-bg-neutral-200;
        }

        .gik-avatar {
          @apply tw-flex-shrink-0;
          width: 40px;
          height: 40px;
        }

        > span {
          @apply tw-whitespace-nowrap tw-block tw-overflow-hidden tw-py-2 tw-pl-4 tw-text-xl;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__social-links {
    @apply tw-flex tw-flex-wrap tw-items-center tw-mx-auto tw-justify-center tw-my-3 tw-pb-12;
    > * {
      @apply tw-px-2 tw-flex-shrink-0;
    }

    @screen sm {
      > * {
        @apply tw-px-3;
      }
    }
  }

  &__btn-close {
    @apply tw-absolute tw-top-0 tw-left-0 tw-ml-2;
    margin-top: 16px;

    svg {
      @apply tw-text-neutral-900;
    }
    &:hover {
      svg {
        @apply tw-text-black;
      }
    }
  }
  &__right-buttons {
    @apply tw-absolute tw-top-2 tw-right-2 tw-flex tw-gap-2 tw-items-center tw-justify-center;
  }
  &__btn-login {
    @apply tw-font-heading tw-text-base tw-leading-none tw-uppercase;
    > a {
      @apply tw-font-semibold;
    }
  }

  &__connect {
    @apply tw-flex-1 tw-flex tw-justify-center tw-items-end tw-mt-6 tw-min-h-12;
    p {
      @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-tight tw-text-neutral-900;
    }
  }

  .gik-input-search {
    @apply tw-px-2;
  }

  .gik-accordion {
    @apply tw-mt-6;
  }

  .gik-accordion-item__title {
    @apply tw-font-heading;
  }

  .gik-accordion-item__header {
    > span {
      @apply tw-pl-3;
    }
  }

  .gik-page-rows-section {
    @apply tw-mt-4;
  }

  .gik-accordion-item {
    @apply tw-border-b tw-border-neutral-200;

    // &:hover {
    //   @apply tw-bg-neutral-200;
    // }

    div,
    li {
      padding: 0;
    }
    a {
      text-decoration: none;
      @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900 tw-h-full tw-flex tw-items-center;
    }
    > header {
      padding-top: 15px;
      height: 50px;
      > span {
        @apply tw-font-heading tw-block tw-text-xl tw-leading-none tw-text-neutral-900 tw-px-2;
      }
    }

    .gik-accordion-item__toggle-icon {
      @apply tw-mr-3;
    }
  }

  &__pages-divider {
    @apply tw-my-2 tw-border-neutral-200;
  }
}

.#{$gik-prefix}drawer.#{$gik-prefix}app-header-nav-mobile-drawer {
  &.#{$gik-prefix}drawer--left .#{$gik-prefix}drawer__box {
    width: 100%;
  }

  // NOTE: this is here to maintain legacy compatibility
  // The AppNavMobile should make use of a header and content div
  .gik-drawer__box {
    overflow: auto;
  }
  .gik-drawer__content {
    overflow: visible;
    @apply tw-pb-12;
  }

  .gik-drawer__close {
    @apply tw-p-2 tw-left-0 tw-cursor-pointer;
    right: unset;
    top: 9px;
  }
}
