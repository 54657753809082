$blockName: 'gik-color-palette';

.#{$blockName} {
  @apply tw-inline-block;
  width: 180px;

  > header {
    @apply tw-px-2 tw-py-1;
  }

  &__item {
    @apply tw-px-2 tw-cursor-pointer;
    padding-top: 1px;
    padding-bottom: 1px;
  }
}
