+b(features)
  +e(copy-separator)
    @apply tw-text-5xl tw-text-center tw-font-ui tw-font-semibold tw-text-neutral-900
    @apply tw-leading-none tw-tracking-normal
    @apply tw-mt-16 tw-mb-20


  +e(animation)
    max-width: 100%
    width: 330px
    height: 490px

    & > div
      &, & svg
        overflow: visible !important

  .gik-feature-content
    @apply tw-mb-24 tw-justify-center tw-tracking-normal
    justify-content: space-around
    max-width: 800px
    margin-left: auto
    margin-right: auto

    & > * + *
      @apply tw-m-0

    & > main
      flex: 0 0 335px
      align-self: center

  +e(care-calendar)
    margin-top: -38px

    +b(feature-content)
      +e(description)
        max-width: 300px

    +b(homepage-testimonial)
      +e(quote)
        max-width: 290px

  +e(wishlist)
    +b(feature-content)
      +e(title)
        max-width: 335px

      +e(description)
        max-width: 325px

    +b(homepage-testimonial)
      +e(quote)
        max-width: 200px
        margin-left: auto

  +e(updates)
    +b(feature-content)
      +e(description)
        max-width: 280px

    +b(homepage-testimonial)
      +e(quote)
        max-width: 280px
        margin-right: auto

  +e(donations)
    +b(homepage-testimonial)
      +e(quote)
        max-width: 240px
        margin-left: auto



  @screen md
    +b(feature-content)
      +e(title)
        @apply tw-text-5xl

    +e(wishlist updates donations)
      margin-top: -60px

  @screen lg-down
    .gik-page-section
      &::before, &::after
        display: none

  @screen md-down
    +e(animation)
      @apply tw-mx-auto

    +e(copy-separator)
      @apply tw-text-3xl tw-mx-auto tw-mt-12 tw-mb-10
      max-width: 280px

    .gik-feature-content
      @apply tw-mb-0

      +e(title description)
        max-width: 315px

      & > main
        @apply tw-mx-4

  @screen xs-down
    +e(animation)
      height: 400px
