.#{$gik-prefix}user-nav {
  @apply tw-pt-1;
  max-width: 250px;
  max-height: calc(100vh - 100px);

  > li {
    @apply tw-flex;
    > a {
      @apply tw-py-2 tw-px-3 tw-w-full tw-flex tw-items-center;
      @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-text-neutral-900;

      min-height: 50px;

      &:hover {
        @apply tw-bg-neutral-200;
      }
      .gik-avatar {
        @apply tw-mr-2 tw-flex-shrink-0;
        width: 35px;
        height: 35px;
      }

      > span {
        @apply tw-whitespace-nowrap tw-overflow-hidden tw-block tw-py-2;
        text-overflow: ellipsis;
      }
    }
  }

  &__list-header {
    @apply tw-font-bold tw-text-sm tw-leading-none tw-py-2 tw-px-3 tw-block tw-w-full tw-uppercase tw-text-neutral-600;
  }

  @screen sm-down {
    @apply tw-hidden;
  }
}
