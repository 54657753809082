+b(wishlist-card)
  +e(suggestions)
    @apply tw-flex tw-justify-center tw-items-center tw-content-center tw-max-w-full
    min-height: 64px

  +e(suggestions-before suggestions-after)
    @apply tw-text-center tw-font-body

  +e(suggestions-before)
    @apply tw-text-sm

  +e(suggestions-after)
    @apply tw-text-xs tw-text-neutral-700

  +b(gift-card-suggestion)
    @apply tw-mx-1

  +b(gift-card-skeleton)
    margin: calc(0.25rem + 4px)

  @screen md-down
    +e(suggestions)
      @apply tw-flex-wrap tw-mx-auto
      max-width: 300px

    +b(gift-card-suggestion)
      margin: 0.1rem

    +b(gift-card-skeleton)
      margin: calc(0.1rem + 4px)
