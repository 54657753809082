+b(send-a-gift-box-modal)
  // TODO: fox styling specificity of the main modal component
  &.modals.modal-v2.gik-modal--padded > .gik-modal__content-wrapper > .content
      @apply tw-flex tw-flex-col tw-p-0

  img
    @apply tw-absolute
  +e(desktop-bg-top-left)
    @apply tw-z-below tw-top-0 tw-left-0
    width: 80px
  +e(desktop-bg-top-right)
    @apply tw-z-below tw-top-0 tw-right-0
    width: 70px
  +e(desktop-bg-bottom-left)
    @apply tw-z-below tw-left-0
    width: 80px
    bottom: 0px
  +e(desktop-bg-bottom-right)
    @apply tw-z-below tw-bottom-0 tw-right-0
    width: 220px
  +e(mobile-bg-top)
    @apply tw-z-below tw-top-0 tw-left-0 tw-right-0 tw-w-full
  +e(mobile-bg-mid)
    @apply tw-z-below tw-left-0 tw-right-0 tw-w-full
    top: 45%
    @screen xs
      top: 40%
    @media only screen and (min-width: 480px)  // custom breakpoint just for image styling - halfway between xs and sm
      top: 32%
    @screen sm
      @apply tw-hidden #{!important}
  +e(mobile-bg-bottom)
    @apply tw-z-below tw-left-0 tw-right-0 tw-w-full
    bottom: -30px
  +e(deluxe-bg-desktop)
    @apply tw-top-0 tw-left-0 tw-h-full
  +e(deluxe-bg-mobile)
    @apply tw-bottom-0
    height: min(60%, 35vw)
    right: 5%
  +e(content-outer)
    @apply tw-relative
  +e(content)
    @apply tw-text-left tw-px-6 tw-text-center tw-pt-12 tw-pb-20 tw-mx-auto
    max-width: calc(280px + 3rem)
    @screen md
      @apply tw-pt-6 tw-pb-6
      max-width: 500px
  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-primary-600
    @screen md-down
      @apply tw-mx-auto
      max-width: 240px
  +e(page-title)
    @apply tw-font-bold tw-text-xl tw-leading-none tw-text-neutral-900 tw-my-6
    @screen md
      @apply tw-text-2xl
  +e(description description-2)
    @apply tw-font-normal tw-text-base tw-leading-snug tw-text-neutral-900 tw-text-left
    @screen md
      @apply tw-mx-auto
      max-width: 400px
  +e(description-2)
     @apply tw-mb-6
     @screen md
      @apply tw-mx-auto tw-mb-4
  +e(or)
    @apply tw-flex tw-items-center tw-justify-center tw-my-2
    @screen md
      @apply tw-flex-col
    span
      @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-tight tw-text-primary-900
      @screen md
        @apply tw-mx-4 tw-w-4
  +e(or-line)
    @apply tw-bg-neutral-300 tw-w-16 tw-mx-2
    height: 1px
    @screen md
      @apply tw-h-6
      width: 1px
  +e(standard-options)
    @screen md
      @apply tw-flex tw-justify-center tw-items-center
  +e(deluxe)
    @apply tw-bg-warn-200 tw-border-warn-600 tw-rounded-md tw-mt-6 tw-shadow-none tw-px-4 tw-py-2 tw-relative tw-overflow-hidden tw-text-left
    @screen md
      @apply tw-mt-4 tw-mx-4
      padding-left: 120px
