$blockName: '#{$gik-prefix}breadcrumbs';

.#{$blockName} {
  > ul > li {
    @apply tw-align-middle tw-text-xs tw-inline-flex;
    @screen sm {
      @apply tw-text-base tw-inline-block;
    }
  }
  &--trailing-slash {
    > ul > li {
      &:after {
        content: '/';
        @apply tw-mx-1;
      }
    }
  }
  &:not(&--trailing-slash) {
    > ul > li {
      &:not(:last-child):after {
        content: '/';
        @apply tw-mx-1;
      }
    }
  }
}
