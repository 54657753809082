$blockName: '#{$gik-prefix}radio-group';
$radioBlockName: '#{$gik-prefix}radio';

$radio-margin: 20px;

.#{$blockName} {
  // space between blocks
  > * + * {
    @apply tw-my-2;
  }

  // ---------- Modifiers
  &--inline {
    // space between blocks
    > * + * {
      @apply tw-ml-2;
    }

    .#{$radioBlockName} {
      @apply tw-inline-flex;
    }
  }
}
