$blockName: '#{$gik-prefix}layout-aside-eq';

.#{$blockName} {
  --aside-width: 17rem;
  --gutter: 1rem;
  --breakpoint: 40rem;
  --modifier: calc((var(--breakpoint) - 100%) * 999);

  @apply tw-flex tw-flex-wrap tw-flex-1 tw-box-border;
  margin: calc((var(--gutter) / 2) * -1);

  > main {
    @apply tw-flex-1;
  }

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  > * {
    flex-basis: var(--modifier);
    min-width: var(--aside-width);
    margin: calc(var(--gutter) / 2);
  }
}
