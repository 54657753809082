+b(group-preview)
  position: relative
  overflow-y: hidden
  height: 350px

  width: calc(100vw - 55px)
  @apply tw-z-50

  @screen md
    width: auto

  &__desktop-preview-badge
    @apply tw-absolute tw-z-50
    top: 84px
    right: 20px

    @screen sm
      top: 148px
      right: auto
      left: calc(50% - 62px)

    @screen md
      top: 148px
      right: auto
      left: calc(50% - 62px)

    @screen lg
      top: 150px
      left: calc(50% - 251px)

    @screen xl
      top: 150px
      left: calc(50% - 260px)

  &__tile-preview-badge
    @apply tw-absolute
    z-index: 60
    bottom: 90px
    right: 69px
    @screen lg
      right: auto
      bottom: 20px
      left: 0px

  &__widget-preview-badge
    @apply tw-absolute tw-z-50
    top: 161px
    right: 71px
    @screen lg
      right: auto
      top: 245px
      left: calc(50% - 90px)

  &__mobile-preview-badge
    @apply tw-absolute tw-z-50
    top: 193px
    left: 18px
    @screen sm
      top: 213px
      left: 0px

    @screen lg
      left: auto
      top: 225px
      right: 60px

@import DesktopPageBannerPreview
@import GroupPageThumbnailPreview
@import GroupWidgetHeaderPreview
@import MobilePageBannerPreview




