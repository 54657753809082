+b(support-buttons)

  +e(heading-wrapper)
    @apply tw-mb-4

  &--anchor
    @apply tw-block

  &__btn-visa
    @apply tw-mb-2
    height: 62px

  &__buttons
    @apply tw-flex tw-flex-wrap tw-gap-2
    > *
      @apply tw-flex
    +b(button)
      @apply tw-flex-1 tw-font-body tw-text-sm tw-font-bold

  &--odd &__buttons
    > *
      flex: 1 0 30%
      min-width: 85px

  &--even &__buttons
    > *
      flex: 1 0 45%
      min-width: 120px

  +b(button)
    .gik-button__content
      @apply tw-mb-1

  &__h2
    @apply tw-text-2xl
  &__h2--no-uppercase
    @apply tw-normal-case

  .gik-popover__reference-element
    @apply tw-block
