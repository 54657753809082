+b(shipping-form)
  +e(state-zip-section)
    @apply tw-flex-1 tw-flex tw-flex-row tw-gap-2
    @screen md-down
      @apply tw-flex tw-flex-1 tw-flex-row tw-gap-2
      .gik-form-group
        @apply tw-flex-1

  +e(first-name-last-name-section city-state-zip-section)
    @screen md-down
      @apply tw-flex tw-flex-col
      & > div
        @apply tw-m-0

  +e(address-2-field-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-1

  +e(address-2-field-copy)
    @apply tw-text-sm tw-font-normal tw-m-0
