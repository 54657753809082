$blockName: prefix(article-dynamic-loader)

.#{$blockName}
  &__grid
    @screen md
      @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start
      @apply tw--mx-3
      flex-wrap: wrap
      & > *
        flex: 0 1 25%
    @screen md-down
      @apply tw-flex tw-flex-col tw-gap-6

  &__show-more
    @apply tw-mt-6

  +b(article-tile)
    @screen md
      @apply tw-px-3 tw-pb-12

