+b(add-edit-inkind-user-updates-modal)
  min-height: 300px

  +e(date-field)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    label
      @apply tw-mr-2

  +e(button-wrapper)
    @apply tw-mt-4 tw-mb-2
    @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
    align-content: stretch

  +e(attachments)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch

  +e(attachment)
    max-width: 10rem

  +e(add-media-modal)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @screen md
      min-width: 516px
    & > *
      @apply tw-mt-4 tw-flex tw-flex-col
      min-width: 240px
      &:last-child
        @apply tw-mb-4

  +e(confirm-image-modal)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch


  +e(image-container)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +e(image-container video-container)
    @apply tw-mb-4

  +e(image-picker)
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +m(loading)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  @screen md
    min-width: 580px

    +e(confirm-video-modal-wrapper)
      min-width: 650px !important

+b(add-edit-inkind-user-updates-modal-attachment)
  @apply tw-relative tw-m-1 tw-mr-4

  +e(attachment)
    height: 70px
    aspect-ratio: 1
    object-fit: cover

  +e(remove-button)
    @apply tw-absolute
    right: -4px
    top: -4px
