+b(date-time-display)
  @apply tw-text-neutral-900 tw-font-bold

  +e(date)
    @apply tw-whitespace-nowrap

  +e(time)
    @apply tw-whitespace-nowrap

  +e(separator)
    @apply tw-px-1

  +m(split, startTime)
    @apply tw-block

  +m(split, timeSeparator)
    &::after
      content: ' '
