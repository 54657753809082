$blockName: follow-page-cta
+b($blockName)
  @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none tw-inline-block

  +e(hover-copy)
    @apply tw-hidden

  +e(heart-icon)
    @apply tw-opacity-0

  +b(badge)
    transition: all .27s ease-out,  width 2s ease-out
    @apply tw-cursor-pointer tw-shadow-base tw-uppercase
    svg, &
      @apply tw-text-danger-500 #{!important}

  +m(hover, main-copy)
    @apply tw-hidden
  +m(hover, hover-copy)
    @apply tw-block

  +e(icon-container)
    @apply tw-relative tw-w-6 tw-h-6 tw-mr-2

  +e(heart-icon heart-icon-outline)
    @apply tw-absolute
    left: 0
    transition: opacity .27s ease-out

  +m(following)
    +b(badge)
      @apply tw-bg-danger-500
      svg, &
        @apply tw-text-white #{!important}


  +m(hover, heart-icon)
    @apply tw-opacity-100
  +m(hover, heart-icon-outline)
    @apply tw-opacity-0

  +m(following, heart-icon)
    @apply tw-opacity-100
  +m(following, heart-icon-outline)
    @apply tw-opacity-0

#{b($blockName)}:hover#{b($blockName)}--following
  +b($blockName)
    +e(heart-icon)
      @apply tw-opacity-0
    +e(heart-icon-outline)
      @apply tw-opacity-100
