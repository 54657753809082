$blockName: '#{$gik-prefix}svg-icon';

.#{$blockName} {
  @apply tw-inline-flex tw-font-normal tw-not-italic;

  &--notfound {
    @apply tw-text-2xl;
  }

  &__strike-line {
    @apply tw-absolute;

    content: '';
    width: 26px;
    height: 2px;
    @apply tw-bg-danger-500 tw-rounded tw-absolute tw-z-10;
    top: 9px;
    left: -2px;
    transform: rotate(-45deg);
  }

  &--strikeOut {
    @apply tw-opacity-50;
  }

  &__wrapper {
    @apply tw-relative;
  }
}
