@use 'sass:color'

+b(share-inkind-option)
  svg
    @apply tw-text-white


  &__button--facebook
    --bg: #3B5998
    --bg-hover: #2f4779

  &__button--messenger
    --bg: #0078FF
    --bg-hover: #0060cc

  &__button--twitter
    --bg: #1DA1F2
    --bg-hover: #0b83cd
