+b(container)
  @apply tw-mx-auto

  +breakpoint(md)
    max-width: 720px

  +breakpoint(lg)
    max-width: 960px

  +breakpoint(xl)
    max-width: 1140px

  +m(centered)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
