+b(organizers-list)

  +e(header)
    @apply tw-flex tw-items-center
    > div
      @apply tw-font-body tw-text-sm tw-text-neutral-700 tw-font-bold tw-leading-none tw-uppercase
    > :nth-child(1)
      @apply tw-flex-grow tw-flex-shrink-0
    > :nth-child(2) // edit
      flex-basis: 30px
    > :nth-child(3) // email
      @apply tw-ml-1
      flex-basis: 45px
    > :nth-child(4)
      flex-basis: 30px

  @screen xs
    +e(header)
      > :nth-child(2), > :nth-child(3), > :nth-child(4)
        flex-basis: 50px
      > :nth-child(3)
        @apply tw-ml-3 tw--mr-2
