+b(popover-menu)

+b(popover-menu-item)
  @apply tw-p-2 tw-px-4 tw-cursor-pointer tw-flex tw-items-center
  &:hover
    @apply tw-bg-neutral-50

  &__label
    @apply tw-text-neutral-800
    &--fullWidth
      @apply tw-w-full

  &__icon
    @apply tw-mr-1 tw-inline-flex tw-items-center tw-text-neutral-600

  &--danger
    @apply tw-text-danger-500

  &--danger &__icon
    @apply tw-text-danger-500
  &--danger &__label
    @apply tw-text-danger-500
