$blockName: '#{$gik-prefix}feature-steps-grid';

.#{$blockName} {
  @apply tw-grid tw-gap-2;

  @screen xs {
    @apply tw-grid-cols-1 tw-gap-2;
  }

  @screen md {
    @apply tw-grid-cols-3 tw-gap-3;
  }

  @screen lg {
    @apply tw-grid-cols-3 tw-gap-4;
  }

  &__feature_title,
  &__title_copy {
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-mb-8;
    @screen md {
      @apply tw-mb-4;
    }
  }

  &__badge {
    @apply tw-mb-4;
  }
}
