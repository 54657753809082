.#{$gik-prefix}popover-list-item
  @apply tw-flex tw-items-center tw-cursor-pointer
  > *
    @apply tw-flex-shrink-0


  > :first-child
    flex: 1 1 180px

  > :last-child
    @apply tw-text-primary-500

  &__icon
    @apply tw-flex-shrink-0

  &--hover:hover
    @apply tw-bg-neutral-50

  &--danger
    .gik-svg-icon
      @apply tw-text-danger-500


  &--loading
    .loading-spinner
      transform: scale(0.5)


