+b(giftcards-editor)
  @apply tw-flex tw-flex-col tw-h-full

  &--disabled
    @apply tw-opacity-80 tw-pointer-events-none

  +e(selection)
    @apply tw-bg-neutral-50 tw-p-4
    box-shadow: inset 0px 0px 8px 1px rgba(59, 65, 66, 0.2)
  +e(sel-title)
    @apply tw-font-bold tw-leading-none tw-text-neutral-900
  +e(sel-description)
    @apply tw-mt-0 tw-mb-4



  +e(selection)
    @apply tw-text-center tw-relative tw-mb-8 tw-text-neutral-700

  +e(main)
    @apply tw-mt-4 tw-text-center tw-select-none
  +e(footer)
    @apply tw-text-center tw-mt-4
  +e(browser)
    @apply tw-flex-1 tw-flex tw-flex-col

  +e(empty-tile)
    @apply tw-flex tw-flex-col
    > main
      @apply tw-bg-neutral-300 tw-rounded tw-flex-1 tw-w-full tw-min-h-8
    > footer
      @apply tw-text-center tw-font-bold tw-text-xs tw-leading-none tw-mt-1 tw-mb-2 tw-text-neutral-700 tw-invisible


  +e(icon)
    @apply tw-cursor-pointer

  +b(gift-card-tile)
    @apply tw-cursor-pointer

  +e(counter-wrapper)
    @apply tw-flex tw-items-center tw-justify-center

  +e(select)
    @apply tw-mx-auto tw-w-full tw-mb-2 tw-px-4
    max-width: 430px

  +e(counter)
    @apply tw-absolute tw-bg-white tw-px-2 tw-rounded-full tw-shadow-sm
    font-size: 14px
    height: 20px
    bottom: -10px

  +e(drag-item)
    @apply tw-z-modalDrag

  +e(grid-wrapper)
    // @apply tw--m-4
    // min-height: 400px

  +e(grid)
    @apply tw-p-4 tw-flex-1 tw-mt-4
  +e(grid-empty)
    @apply tw-text-center

  +b(grid)
    @apply tw-grid tw-gap-4 tw-flex-1

    @screen xs
      @apply tw-grid-cols-2

    @screen md
      @apply tw-grid-cols-4

  +e(filters)
    @apply tw-py-2 tw-mb-4 tw-text-center tw-justify-center tw-px-4

    +b(input__wrapper)
      @apply tw-mx-auto
      max-width: 430px

    > * + *
      @apply tw-mt-2

    // @screen md
    //   @apply tw-px-0

  // +b(tabbed-nav)
  //   @apply tw-mb-8

  +e(delete)
    @apply tw-bg-danger-500 tw-rounded-full tw-absolute tw-border tw-border-white
    @apply tw-flex tw-items-center tw-justify-center
    @apply tw-cursor-pointer

    top: -7px
    right: -7px
    width: 24px
    height: 24px

    &:hover
      @apply tw-bg-danger-700

    > svg
      @apply tw-text-white
      width: 16px

  +e(add)
    @apply tw-bg-success-500 tw-rounded-full tw-absolute tw-border tw-border-white
    @apply tw-flex tw-items-center tw-justify-center
    @apply tw-cursor-pointer

    top: -7px
    right: -7px
    width: 24px
    height: 24px

    &:hover
      @apply tw-bg-success-700

    > svg
      @apply tw-text-white
      width: 24px

.gik-drawer-giftcard-editor
  +b(loading-spinner)
    @apply tw-mt-8
