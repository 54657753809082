
+b(calendar-claim-event)
  @screen md-down
    @apply tw-h-full tw-flex tw-flex-col
    > main
      @apply tw-flex-1

  > main
    @apply tw-py-4

    > .gik-loading-spinner
      @apply tw-mt-16


  +e(description)
    @apply tw-mt-0 tw-mb-4 tw-text-center tw-text-base tw-font-medium

  +e(content)
    @apply tw-mx-auto

  +b(service-categories-list)
    @apply tw-p-4 tw-px-20

  +e(payment-confirmation)
    @apply tw-p-4

  +b(tabbed-view)
    @apply tw-mt-4

  +b(event-claim-type-select-list)
    @apply tw-mb-4

  +b(partner-info-box)
    @apply tw-mt-4

  +e(giftcard-view)
    @apply tw-text-center tw-mt-8
    img
      @apply tw-mx-auto
      max-width: 300px

  +e(giftcard-view-title)
    @apply tw-text-lg tw-mb-4 tw-block


+b(partner-claim-form)
  @apply tw-mx-auto tw-my-4
  max-width: 400px

+b(claim-failed)
  @apply tw-px-4 tw-text-center tw-text-danger-500
  @screen md
    @apply tw-px-12

+b(calendar-claim-event-modal)
  @screen md-down
    > .gik-modal__content-wrapper
      > .content
        @apply tw-h-full

  +b(steps__nav)
    margin-top: 20px

.gik-tabbed-view__tab-content
  @apply tw-whitespace-pre-wrap
