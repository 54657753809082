+b(homepage-header)
  position: relative

  $spacing-top: 4rem
  margin-bottom: 5rem

  min-height: 550px

  @screen md
    min-height: none

  +e(content-wrapper)
    max-width: 1145px
    margin-left: auto
    margin-right: auto

  +e(copy)
    margin-top: $spacing-top

  &__announcements
    @apply tw-mt-32
    max-width: 430px
    @screen md
      @apply tw-mt-16


  &__search
    @apply tw-mb-4

  &__search-btn
    padding-left: 7px
    --color: theme('colors.neutral.800')
    @apply tw-normal-case tw-font-body tw-font-normal tw-text-neutral-800


  +e(title)
    @apply tw-text-4xl tw-text-neutral-900 tw-tracking-normal tw-leading-none
    @apply tw-font-heading tw-font-semibold
    @apply tw-mt-6 tw-mb-2
    max-width: 455px

    @screen md
      @apply tw-text-6xl

  +e(subtitle)
    @apply tw-font-body tw-font-bold tw-leading-snug tw-tracking-normal
    @apply tw-text-2xl tw-text-neutral-800
    @apply tw-mt-4
    max-width: 410px

  +e(start-page-button)
    @apply tw-shadow-2xl tw-mt-4 tw-mb-4 tw-font-semibold

  +e(animation-container)
    position: absolute
    top: -4rem
    right: 0
    left: 0
    width: 100%
    padding-left: 90px
    height: 760px
    max-width: 1440px
    margin: auto
    z-index: theme('zIndex.below')
    +b(animation)
      @apply tw-absolute
      & > div
        &, & svg
          overflow: visible !important

  +e(buttons)
    @apply tw-flex tw-flex-col tw-items-start tw-mt-12

  +e(buttons-copy)
    @apply tw-inline-block
    min-width: 258px

  +e(badges)
    @apply tw-flex tw-flex-wrap tw-flex-row tw-mt-4
    & > *
      @apply tw-mr-4 tw-mb-4
      &:last-child
        @apply tw-mr-0

  @screen md
    +e(copy buttons)
      @apply tw-ml-6

  @screen md-down
    @apply tw-mx-2
    margin-bottom: 0

    +e(animation-container)
      padding: 0
      margin-top: 160px
      height: 455px

    +e(copy)
      margin: 0



    +e(subtitle)
      @apply tw-text-base tw-my-0
      max-width: 260px

    +e(start-page-button)
      @apply tw-my-4

    +e(buttons)
      margin-top: 180px

    +e(badges)
      & > *
        @apply tw-mb-0 tw-mr-1

  @screen xs-down
    +e(badges)
      margin: 0 -15px

  @screen lg
    +e(copy buttons)
      @apply tw-ml-0
