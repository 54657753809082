+b(calendar-service-select-list)

  @apply tw-flex tw-flex-wrap tw-grid tw-gap-3
  // justify-content: space-around

  @screen md
    @apply tw-grid-cols-3
    +b(calendar-service-tile)
      @apply tw-justify-center tw-flex-col tw-text-center
      height: 220px

  +e(select-wrapper)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

