
  +b(data-cards)
    > * + *
      @apply tw-mt-2

  +b(data-card)
    @apply tw-bg-neutral-100 tw-border tw-border-neutral-900 tw-p-4

    +e(th)
      @apply tw-font-bold tw-mr-2
      &::after
        @apply tw-font-bold
        content:':'
