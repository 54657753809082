$blockName: '#{$gik-prefix}sticky';

.#{$blockName} {
  @apply tw-z-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.#{$blockName}-horizontal {
  position: relative;
}
