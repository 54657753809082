$blockName: '#{$gik-prefix}features-page';

.#{$blockName} {
  @screen md {
    .gik-feature-content--reversed &__feature-title {
      @apply tw-justify-end;
    }
  }

  @include b(feature-content) {
    // the descriptions should have a max width
    @include e(description) {
      max-width: 420px;
    }

    @include m(reversed, description) {
      @apply tw-ml-auto;
    }
  }
  .gik-accordion-item ul {
    @apply tw-pl-4;
    list-style-type: disc;
  }

  #updates {
    .gik-feature-content > aside {
      @apply tw-mt-16;
    }
  }
  #fundraising {
    .gik-feature-content > aside {
      @apply tw-mt-24;
    }
  }

  .gik-start-inkind-card {
    transform: translate3d(0, -50%, 0px);
  }

  .gik-accordion-item > main {
    @apply tw-whitespace-pre-wrap;
  }

  &__sticky-nav {
    top: calc(var(--mobile-header-height) + 30px) !important;
    @screen sm {
      top: calc(var(--desktop-header-height) + 40px) !important;
    }
  }

  .gik-testimonials-carousel-section {
    @apply tw-pt-0;
  }

  &__feature-title {
    @apply tw-flex tw-items-center;
    > img {
      @apply tw-mr-4 tw-mb-1;
    }
  }

  #calendar,
  #wishlist,
  #updates,
  #donations,
  #more {
    @include b(container) {
      max-width: 900px;
    }
  }

  .gik-features-page .gik-feature-content--reversed .gik-feature-content__description {
    @apply tw-ml-auto;
  }

  @screen md-down {
    @include e(calendar-example wishlist-example updates-example fundraising-example more-example) {
      width: unset;
      @apply tw-mx-auto;
    }
  }
}
