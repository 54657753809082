@use 'sass:math';
$blockName: '#{$gik-prefix}switch';

$switch-width: 40px;
$switch-height: 20px;
$handle-padding: 2px;

.#{$blockName} {
  --color: black;
  --iconColor: white;
  // ---------- Block
  @apply tw-text-base tw-inline-flex tw-relative tw-align-middle tw-cursor-pointer tw-select-none tw-items-center;

  // space between children
  > * + * {
    margin-left: 8px;
  }

  // ---------- Element

  &__input {
    // place the real switch offscreen
    position: absolute;
    left: -9999px;
  }

  &__handle {
    @apply tw-shadow-sm tw-rounded-full;
    transition: opacity 150ms ease-in, transform 150ms ease-in;
    background-color: white;
    left: 0;
    height: $switch-height - ($handle-padding * 2);
    width: $switch-height - ($handle-padding * 2);
    margin: $handle-padding;
  }

  &__wrapper {
    @apply tw-relative tw-cursor-pointer tw-flex-shrink-0;
    @apply tw-rounded-full tw-align-middle tw-inline-block;
    background-color: var(--color);
    width: $switch-width;
    height: $switch-height;
  }

  &__label,
  &__label-before {
    @apply tw-inline-block tw-align-middle;
  }

  // ---------- Modifiers

  &--loading {
    @apply tw-pointer-events-none tw-opacity-50;
  }
  &--block {
    @apply tw-block;
  }

  &--checked {
    .#{$blockName}__wrapper {
      background-color: var(--checked-color);
    }

    .#{$blockName}__handle {
      transform: translateX(math.div($switch-width, 2));
    }
  }

  // ----- Variants

  &--default {
    --color: theme('colors.danger.500');
    --checked-color: theme('colors.success.500');
  }

  // ----- States

  &--focus &__wrapper {
    @include gik-focus-shadow;
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }

  &--disabled &__wrapper {
    @apply tw-cursor-not-allowed;
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs;
  }

  &--size-sm {
    @apply tw-text-sm;
  }

  &--size-base {
    @apply tw-text-base;
  }

  &--size-lg {
    @apply tw-text-lg;
  }

  &--size-xl {
    @apply tw-text-xl;
  }
}
