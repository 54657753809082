$blockName: '#{$gik-prefix}feature-content';

.#{$blockName} {
  @apply tw-flex tw-flex-col-reverse;

  > main {
    @apply tw-flex-1 tw-font-normal tw-text-base tw-leading-normal;
  }
  > aside {
    @apply tw-text-center;
    > img {
      @apply tw-mx-auto;
    }
  }

  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-tracking-normal;
  }

  &__description {
    @apply tw-font-body tw-font-normal tw-text-lg tw-leading-7 tw-tracking-normal tw-text-neutral-900;
  }

  &--reversed {
    @apply tw-flex-col;
  }
  &--reversed &__description {
    @apply tw-ml-auto;
  }

  &--title-centered &__title {
    @apply tw-text-center;
  }

  &--primary &__title {
    @apply tw-text-primary-500;
  }
  &--danger &__title {
    @apply tw-text-danger-500;
  }
  &--secondary &__title {
    @apply tw-text-secondary-500;
  }
  &--success &__title {
    @apply tw-text-success-500;
  }
  &--warn &__title {
    @apply tw-text-warn-500;
  }
  &--blue &__title {
    @apply tw-text-info-500;
  }

  @screen md {
    @apply tw-flex tw-flex-row;

    > * + * {
      @apply tw-ml-10;
    }

    > aside + main {
      @apply tw-text-right;

      .gik-accordion {
        @apply tw-text-left;
      }
    }
  }
}
