$blockName: #{$gik-prefix}user-pages-list-skeleton;

.#{$blockName} {
  @apply tw-flex tw-flex-wrap;

  .#{$gik-prefix}frame-bone.#{$gik-prefix}user-page-card {
    --background-color: #f3f6f6;

    margin-right: 1em;
    margin-bottom: 1em;

    & > div {
      border-radius: 20px;
    }
  }

  .#{$gik-prefix}circle-bone {
    --background-color: #f3f6f6;
    margin-top: -34px;
    height: 70px;
    margin-bottom: 32px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
  }

  @screen md-down {
    .#{$gik-prefix}frame-bone.#{$gik-prefix}user-page-card {
      width: 100%;
      margin-right: 0;
    }
  }
}
