
body

  .SingleDatePicker_picker__portal
    @apply tw-z-datePicker
    background-color: #ffffffaa // todo: need to usa a tailwind color

  .SingleDatePicker
    @apply tw-block tw-h-full
    > div
      @apply tw-h-full

  .SingleDatePickerInput
    @apply tw-border-0 tw-block tw-h-full


  .DateInput_input
    @apply tw-p-0 tw-text-base tw-block tw-h-full tw-border-0 tw-px-2 tw-py-1 tw-font-normal tw-cursor-pointer
    height: $ui-height - 2px

  .DateInput_input__focused
    @apply tw-border-transparent

  .DateInput
    @apply tw-block tw-h-full
    width: unset



  .CalendarDay__default
    @apply tw-border-0 tw-text-neutral-900
    &:hover
      @apply tw-bg-neutral-100 tw-border-0
      border-radius: 50%



  .CalendarDay__blocked_out_of_range
    @apply tw-text-neutral-400
    &:hover
      @apply tw-border-0


  .CalendarDay__outside
    @apply tw-text-neutral-200

  .CalendarDay__selected
    @apply tw-bg-primary-500 tw-text-white
    border-radius: 50%
    &:hover
      @apply tw-bg-primary-500 tw-text-white tw-border-0

  .CalendarDay__today
    @apply tw-text-primary-500 tw-font-bold
    &:hover
      @apply tw-text-primary-500 tw-border-0



  .CalendarMonth_caption
    padding-bottom: 45px

  .CalendarDay__today.CalendarDay__selected
    @apply tw-text-white
    &:hover
      @apply tw-font-bold tw-border-0 tw-text-white

  .CalendarDay__blocked_calendar
    @apply tw-bg-transparent tw-text-neutral-400
    &:hover
      @apply tw-bg-transparent tw-text-neutral-400

  .DayPicker_weekHeader
    @apply tw-uppercase tw-text-primary-500 tw-font-bold tw-mb-4

  .DayPicker_weekHeader_ul
    @apply tw-text-sm


  .gik-datepicker-btn-prev
    @apply tw-absolute tw-text-primary-500
    left: 22px
    top: 20px
  .gik-datepicker-btn-next
    @apply tw-absolute tw-text-primary-500
    right: 22px
    top: 20px


  +b(input-datepicker)
    @apply tw-border tw-border-neutral-400 tw-p-0 tw-rounded-sm tw-relative

    +e(icon)
      @apply tw-absolute tw-text-neutral-600 tw-cursor-pointer tw-pointer-events-none
      top: 7px
      right: 6px

  .DayPicker_focusRegion
    @apply tw-shadow-base
