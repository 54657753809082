$blockName: '#{$gik-prefix}benefits-page-hero';

.#{$blockName} {
  @apply tw-mt-2;

  @screen md {
    @apply tw-flex;
    // more spacing between elements
    > * + * {
      @apply tw-ml-20;
    }
  }
}

$blockNameItem: '#{$gik-prefix}benefits-page-hero-item';

.#{$blockNameItem} {
  @apply tw-text-center tw-mb-10 tw-mt-4;

  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-none tw-mt-4 tw-block;
  }

  img {
    @apply tw-mx-auto;
  }

  &--danger &__title {
    @apply tw-text-danger-500;
  }
  &--success &__title {
    @apply tw-text-success-500;
  }

  @screen md {
    @apply tw-text-center tw-px-8 tw-flex-1 tw-flex-shrink-0;
  }

  @screen lg {
    > main {
      @apply tw-px-4;
    }
  }

  @screen xl {
    > main {
      @apply tw-px-10;
    }
  }
}
