+b(premium-page-upgrade-thank-you)
  @apply tw-bg-white tw-p-6 tw-px-5 tw-text-secondary-700
  &.has-border
    @apply tw-border-secondary-500 tw-border-2 tw-rounded-md
  +e(premium-benefit)
    @apply tw-flex tw-items-center
    @apply tw-font-normal tw-text-sm tw-leading-tight
    svg
      @apply tw-text-secondary-500 tw-mr-2 tw-flex-shrink-0

  &__premium-benefit--thank-you-messages
    @screen md
      @apply tw-mr-16

  +e(heading)
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none
  +e(subheading)
    @apply tw-font-bold tw-text-sm tw-leading-tight tw-mt-1 tw-mb-2
  +e(footer-message)
    @apply tw-font-bold tw-text-sm tw-leading-tight tw-italic tw-mt-4

+b(premium-page-upgrade-thank-you-modal)
  &.modals.modal-v2
    @apply tw-border-secondary-500 tw-border-2 tw-rounded-md
    +b(modal-header)
      position: absolute
      background: none
