.gik-modal-add-to-checkout__header
  @apply tw-flex tw-justify-between tw-p-2

.gik-modal-add-to-checkout__title
  @apply tw-font-heading tw-text-xl tw-font-semibold

.gik-add-from-calendar-section
  .gik-inkind-calendar
    min-height: 200px
    @screen md
      min-height: 300px

  .gik-steps__nav
    @apply tw-mb-2

.gik-modal-add-to-checkout
  .gik-steps__nav
    @apply tw-mb-2

.gik-modal-add-from-title
  @apply tw-text-2xl tw-font-semibold tw-block tw-font-heading

