$blockName: '#{$gik-prefix}checkbox';

$checkbox-width: 20px;
$checkbox-height: 20px;

@mixin checkbox-variants($name, $paletteName) {
  &--#{$name} {
    --color: theme('colors.#{$paletteName}.500');
  }
}

.#{$blockName} {
  --color: black;
  --iconColor: white;
  // ---------- Block
  @apply tw-text-base tw-inline-flex tw-relative tw-align-middle tw-cursor-pointer tw-select-none;
  @apply tw-items-center;

  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }

  // space between children
  > * + * {
    margin-left: 8px;
  }

  // ---------- Element

  &__input {
    // place the real checkbox offscreen
    position: absolute;
    left: -9999px;
  }

  &__background {
    @apply tw-absolute tw-inset-0 tw-z-0;
    background: var(--color);
    opacity: 0;
    transform: scale(0.2);
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.73);
    transition-duration: 150ms;
  }

  &__icon {
    @apply tw-absolute tw-items-center tw-justify-center tw-inline-block tw-z-10;
    top: 0;
    left: 0;

    color: var(--iconColor);
    transition: opacity 150ms ease-in, transform 150ms ease-in;
    opacity: 0;
    transform: scale(0.2);

    line-height: 27px;

    svg {
      width: 90%;
      height: 90%;
    }
  }

  &__wrapper {
    @apply tw-relative tw-cursor-pointer tw-flex-shrink-0;
    @apply tw-rounded-sm tw-align-middle tw-inline-block tw-border-2 tw-border-neutral-600 tw-bg-white;
    width: $checkbox-width;
    height: $checkbox-height;
  }

  &__label,
  &__label-before {
    @apply tw-inline-block tw-align-middle;
  }

  // ---------- Modifiers

  &--block {
    @apply tw-block;
  }

  &--checked &__icon {
    opacity: 1;
    transform: scale(1);
  }

  &--checked &__background {
    opacity: 1;
    transform: scale(1.05); // add 0.05 to prevent gaps
  }

  &--checked &__wrapper {
    border-color: var(--color);
  }

  &--indeterminate &__icon {
    left: 0;
  }

  // ----- Variants

  @include checkbox-variants('default', 'neutral');
  @include checkbox-variants('primary', 'primary');
  @include checkbox-variants('secondary', 'secondary');
  @include checkbox-variants('has-error', 'danger');
  @include checkbox-variants('has-warning', 'warn');
  @include checkbox-variants('has-success', 'success');

  &--white {
    --color: theme('colors.white');
    --iconColor: theme('colors.black');
  }

  // ----- States

  &--focus &__wrapper {
    @include gik-focus-shadow;
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }

  &--disabled &__wrapper {
    @apply tw-cursor-not-allowed;
  }

  // ----- Sizes

  // xs

  &--size-xs &__wrapper,
  &--size-xs &__icon {
    width: 16px;
    height: 16px;
  }

  &--size-xs &__wrapper {
    border-radius: 2.4px;
  }

  &--size-xs &__icon {
    top: -8px;
  }

  // sm

  &--size-sm &__wrapper,
  &--size-sm &__icon {
    width: 18px;
    height: 18px;
  }

  &--size-sm &__wrapper {
    border-radius: 2.7px;
  }

  &--size-sm &__icon {
    top: -7px;
  }

  // base

  &--size-base &__wrapper,
  &--size-base &__icon {
    width: 20px;
    height: 20px;
  }

  &--size-base &__wrapper {
    border-radius: 3px;
  }

  &--size-base &__icon {
    top: -7px;
  }

  // lg

  &--size-lg &__wrapper,
  &--size-lg &__icon {
    width: 24px;
    height: 24px;
  }

  &--size-lg &__wrapper {
    border-radius: 3.6px;
  }

  &--size-lg &__icon {
    top: -4px;
  }

  // xl

  &--size-xl &__wrapper,
  &--size-xl &__icon {
    width: 30px;
    height: 30px;
  }

  &--size-xl &__wrapper {
    border-radius: 4.5px;
  }

  &--size-xl &__icon {
    top: -2px;
    left: 0px;
  }
}
