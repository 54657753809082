+b(invite-section)

  // reset default form styles
  .gik-form--vertical > * + *
    @apply tw-mt-0

  +e(input)
    @apply tw-max-w-xs tw-mx-auto tw-mb-6

  @screen sm
    +e(input)
      @apply tw-mb-2

  +e(checkbox)
    @apply tw-font-body tw-text-sm tw-text-neutral-900 tw-whitespace-nowrap tw-tracking-normal
    @apply tw-flex tw-flex-col tw-mb-6

    .gik-checkbox--size-base
      @apply tw-text-sm #{!important}

    > div:first-child
      @apply tw-mb-4

  // make labels fit right above 320px by decreasing letter-space
  @screen xs
    +e(checkbox)
      @apply tw-flex-row tw-justify-center tw-tracking-tighter

      > div:first-child
        @apply tw-mb-0 tw-mr-2

  @screen sm
    +e(checkbox)
      @apply tw-tracking-normal

  +e(button)
    @apply tw-text-center tw-mb-6
