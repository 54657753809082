$blockName: 'gik-fonticon';

.#{$blockName} {
  @apply tw-inline-flex tw-font-normal tw-not-italic;

  &--size-xs {
    @apply tw-text-sm;
  }

  &--size-sm {
    @apply tw-text-base;
  }

  &--size-base {
    @apply tw-text-xl;
  }

  &--size-lg {
    @apply tw-text-2xl;
  }

  &--size-xl {
    @apply tw-text-4xl;
  }

  &--size-2xl {
    @apply tw-text-5xl;
  }

  &--notfound {
    @apply tw-text-2xl;
  }

  // this icon isnt well centered
  &.icon-v2-edit {
    margin-left: 3px;
  }
}
