.print,
.print-inline {
  display: none !important;
}

@media print {
  .print {
    display: block !important;
    margin: 0px;
  }

  .print-inline {
    display: inline-block !important;
  }

  .no-print {
    display: none !important;
  }

  @page {
    margin: 2cm;
  }
}
