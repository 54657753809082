+b(inkind-cover-toolbar)
  @apply tw-relative tw-z-above
  // match height of toolbar button due to toolbar being absolute positioned, TODO: find a better way to do this
  height: 40px
  width: 40px

+b(inkind-cover-toolbar-button)
  @apply tw-absolute tw-right-0

  +e(tooltip)
    @apply tw-uppercase tw-font-bold tw-shadow-base

  +b(button)
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.21) #{!important}
    border: none #{!important}
    //&:focus:before
    //  border: none
    &:hover
      border: 2px solid theme('colors.white') #{!important}
  @keyframes fadein-desktop
    0%
      right: 0px
      opacity: 0
    100%
      opacity: 1
  @keyframes fadein-mobile
    0%
      top: 0px
      opacity: 0
    100%
      opacity: 1
  @keyframes fadeout
    0%
      opacity: 1
    100%
      opacity: 0
      right: 0px
      top: 0px
  @for $i from 2 through 10
    &:nth-of-type(#{$i}n)
      z-index: -1
      opacity: 0
      top: #{($i - 1) * 48}px
      animation: fadein-mobile 0.5s ease-out forwards
      animation-delay: #{$i * $i * 0.0025}s
      @screen md
        animation: fadein-desktop 0.5s ease-out forwards
        right: #{($i - 1) * 48}px
        top: 0px
        animation-delay: #{$i * $i * 0.0025}s

  +m(closing)
    pointer-events: none
    @for $i from 2 through 10
      &:nth-of-type(#{$i}n)
        animation: fadeout 0.5s ease-out forwards

  +e(button)
    &.gradient5
      @include gradient-purps()
    &.gradient4
      @include gradient-warm()
    &.gradient3
      @include gradient-sage()
    &.gradient2
      @include gradient-love()
    &.gradient1
      @include gradient-neutral()
    &.gradient0
      @include gradient-aqua()
