@use 'sass:math'

+b(card-carrier-form)
  &__preview-title
    @apply tw-text-xl tw-mt-4 tw-block

+b(card-carrier-editor)
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
  align-content: stretch
  @apply tw-gap-4

  &__preview
    @apply tw-relative

  &__preview-wrapper
    @apply tw-pb-4
    &--greeting-card
      transform: rotate(90deg)

  &__underlay
    @apply tw-absolute tw-inset-0 tw-shadow-lg

  &__custom-fields
    @apply tw-absolute
    left: 324px
    top: 118px
    width: 252px

  &__from-address
    @apply tw-absolute tw-text-sm
    left: 20px
    top: 30px

  &__to-address
    @apply tw-absolute
    left: 50px
    top: 120px
    width: 250px

  &__card-preview
    @apply tw-absolute
    left: 30px
    top: 434px
    zoom: .7

+b(greeting-card-carrier-preview)
  $blockName: &
  $width: 270px
  $height: 349px

  @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-overflow-hidden tw-pb-2

  &__greeting-card
    height: $height
    width: $width * 2
    display: flex
    transform: translateX(math.div(-$width, 2))
    transition: transform 1s
    zoom: .7
    padding-left: 1rem

  &__greeting-card-wrapper
    @apply tw-overflow-hidden tw-flex tw-justify-center
    padding-bottom: 15px


    width: calc(100% + 1rem)
    margin-left: -1rem
    height: 260px

    @screen md
      height: auto
      max-height: 400px


  &__flip-cover, &__cover, &__content-panel-left, &__content-panel-right
    height: $height
    width: $width

  &__flip-cover
    position: relative

  &__cover
    transform: rotateY(180deg)

  &__content-panel-left
    position: absolute
    left: 0
    top: 0
    z-index: 1
    backface-visibility: hidden
    @apply tw-flex tw-flex-col tw-justify-between tw-items-stretch
    align-content: stretch


  &__content-panel-right
    z-index: -1

  &__content-panel-left, &__content-panel-right
    padding: 48px

  &__flip-cover
    transform: rotateY(180deg) perspective(1400px)
    transform-origin: right center
    transform-style: preserve-3d
    transition: transform 1s
    backface-visibility: hidden

  &__flip-cover, &__content-panel-right
    @apply tw-shadow-lg

  &__buttons
    @apply tw-flex tw-flex-row tw-justify-center tw-items-start tw-content-start
    @apply tw-mt-4 tw-gap-2

    .gik-button
      --contentSpacing: 2px


  &--opened #{$blockName}__flip-cover
    transform: rotateY(0deg) perspective(1400px)

  &--opened #{$blockName}__greeting-card
    transform: translateX(0px)

    @screen md
      transform: translateX(0px)


