+b(thank-you-background-picker)
  @apply tw-relative
  height: 50px

  +b(color-swatch)
    @apply tw-cursor-pointer
    width: 32px
    height: 32px

  &__bg-picker
    @apply tw-w-full tw-absolute tw-top-0 tw-left-0 tw-flex tw-flex-shrink-0 tw-justify-between tw-my-3
    z-index: 1

  .gik-stream-background-picker-button
    @apply tw-absolute tw-top-0 tw-left-0 tw-z-10 tw-whitespace-nowrap
    width: 32px !important
    height: 32px !important
    font-size: 1.3rem !important

  .gik-color-swatch
    width: 28px
    height: 28px

  .gik-color-swatch--active
    @apply tw-border-3 tw-box-content
    position: relative
    top: -4px
    width: 32px
    height: 32px

