+b(nav-bar)
  background-blend-mode: hard-light
  box-shadow: 0px 0px 10px rgba(59, 65, 66, 0.25)
  min-height: 2.5rem
  align-items: center
  @apply tw-flex tw-flex-row

  +e(container)
    @apply tw-flex tw-flex-row


  +m(blurred)
    filter: blur(0.5rem)

