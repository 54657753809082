$blockName: '#{$gik-prefix}sample-page-banner';

.#{$blockName} {
  --height: 44px;
  @apply tw-sticky tw-w-full tw-px-2 tw-text-center tw-bg-danger-500 tw-z-banner tw-hidden;

  height: var(--height);
  top: var(--mobile-header-height);
  @screen sm {
    top: var(--desktop-header-height);
  }

  &--visible {
    @apply tw-flex;
  }

  &__container {
    @apply tw-flex tw-items-center;
  }
  &__title {
    @apply tw-font-bold tw-text-lg tw-leading-none tw-uppercase tw-text-white;
  }
  main {
    @apply tw-flex-1 tw-flex tw-justify-around tw-items-center;
    @screen sm {
      @apply tw-justify-center;
    }
  }
}
