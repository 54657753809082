+b(article)
  ol
    display: block
    list-style-type: decimal
    margin-block-start: 1em
    margin-block-end: 1em
    margin-inline-start: 0px
    margin-inline-end: 0px
    padding-inline-start: 20px

  ol ul
    list-style-type: circle
    margin-block-start: 0px
    margin-block-end: 0px

  ul
    display: block
    list-style-type: disc
    margin-block-start: 1em
    margin-block-end: 1em
    margin-inline-start: 0px
    margin-inline-end: 0px
    padding-inline-start: 20px

  @screen sm
    ol, ul
      padding-inline-start: 40px

  // override h1 font-size to h2
  h1
    @apply tw-text-4xl

  // match button link
  a
    @apply tw-text-primary-600

  a:hover
    @apply tw-underline
