+b(stream-pagination)
  @apply tw-flex tw-flex-col tw-gap-0

  &__debug
    @apply tw-text-sm tw-p-2 tw-leading-tight tw-bg-neutral-200 tw-border-2 tw-border-info-500 tw-border-dashed

  // &--debug &__main
  //   @apply tw-border tw-border-danger-500

  &__main
    @apply tw-relative

  &__error
    @apply tw-text-center tw-text-danger-500 tw-text-sm

  &__empty
    @apply tw-text-center

  &__load-next
    @apply tw-text-center tw-mt-2
  &__load-prev
    @apply tw-text-center tw-mb-2

  &__load-next-counter
    @apply tw-ml-2

  .gik-stream-posts__stream-input-wrapper
    @apply tw-flex-1 tw-w-full
