@use 'sass:math'

@mixin separator-size($name, $size)
  +m(size-#{$name})
    --margin-size: #{math.div($size, 4)}rem

@mixin separator-variant($name, $style: $name, $hue: 200)
  +m(variant-#{$name})
    --border-color: theme('colors.#{$style}.#{$hue}')

    @apply tw-border-#{$style}-#{$hue}
    // TODO: make `m` accept a list of modifiers too
    &.gik-separator--with-content
      @apply tw-border-0

      &:before
        @apply tw-mr-2
      &:after
        @apply tw-ml-2

      &:before, &:after
        border-top-width: var(--border-size)
        content: ''
        flex: 1
        border-color: var(--border-color)
        opacity: var(--border-opacity)

      &.gik-separator--vertical
        &:before, &:after
          border-top-width: 0
          border-left-width: var(--border-size)
          @apply tw-mx-0

@mixin separator-type($name, $type)
  +m(type-#{$name})
    border-style: $type

@mixin separator-thickness($name, $size)
  +m(thickness-#{$name})
    --border-size: #{$size}

+b(separator)
  --margin-size: 0
  --border-color: #000
  --border-opacity: 1

  @apply tw-flex
  align-items: center
  text-align: center

  +separator-size(none, 0)
  +separator-size(xs, 1)
  +separator-size(sm, 2)
  +separator-size(base, 4)
  +separator-size(lg, 8)
  +separator-size(xl, 12)
  +separator-size(2xl, 16)
  +separator-size(4xl, 20)

  +separator-variant(default, neutral)
  +separator-variant(neutral-300, neutral, 300)
  +separator-variant(primary)
  +separator-variant(primary-dark, primary, 700)
  +separator-variant(primary-light, primary, 100)
  +separator-variant(secondary)
  +separator-variant(danger)
  +separator-variant(success)

  +separator-type(solid, solid)
  +separator-type(dashed, dashed)
  +separator-type(dotted, dotted)

  +separator-thickness(thin, 1px)
  +separator-thickness(normal, 2px)
  +separator-thickness(double, 4px)
  +separator-thickness(3x, 8px)
  +m(thickness-3x)
    @apply tw-rounded-sm

  +m(direction-vertical)
    @apply tw-flex-col
    border-left-width: var(--border-size)
    margin:
      left: var(--margin-size)
      right: var(--margin-size)

  +m(direction-horizontal)
    @apply tw-flex-row
    border-top-width: var(--border-size)
    margin:
      top: var(--margin-size)
      bottom: var(--margin-size)
