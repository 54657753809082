+b(image-cropper)
  @apply tw-inline-block

  +e(toolbar)
    @apply tw-flex tw-items-center tw-pl-4 tw-pr-1 tw-mt-3
    > *
      @apply tw-flex-shrink-0

  +e(sliderWrapper)
    @apply tw-flex-1

  +e(image)
    @apply tw-relative tw-flex tw-items-center tw-justify-center tw-shadow-base
    @apply tw-w-64 tw-h-64

  &--background-checkerboard &__image
    $dark-color: #222
    $light-color: #ddd
    --size: 40px

    background-color: $light-color
    background-size: var(--size) var(--size)
    background-position: 20px 20px, -20px 0, 0 0, 0 20px
    background-image: linear-gradient(45deg, $dark-color 25%, transparent 25%), linear-gradient(-45deg, $dark-color 25%, transparent 25%), linear-gradient(45deg, transparent 75%, $dark-color 75%), linear-gradient(-45deg, transparent 75%, $dark-color 75%)


  +e(rotateButton)
    @apply tw-ml-6
    --hover-decoration: 'none'

