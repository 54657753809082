.modals.modal-v2.gik-modal.#{$gik-prefix}share-inkind-modal

  > .content
    @apply tw-flex tw-flex-col tw-items-start tw-mt-2


.#{$gik-prefix}share-inkind-modal

  .gik-share-inkind-modal-content--page-0
    @screen md
      max-width: 380px

  .gik-share-inkind-modal-content--page-1
    @screen md
      max-width: 600px

  > .gik-modal__content-wrapper
    > .content
      @apply tw-p-0

  &__content-title
    @apply tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-text-neutral-900 tw-text-left

  &__description
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-neutral-700 tw-text-left tw-mb-8
    max-width: 400px

  &__input
    @apply tw-flex-1

  &__copy-page-link
    @apply tw-text-neutral-700

  &__footer-hstack
    @apply tw-flex-1

  &__footer-wrapper
    @apply tw-pb-2

  .gik-modal-button-footer
    @apply tw-p-0
