+b(modal-header)
  @apply tw-py-4 tw-px-2 tw-w-full tw-flex-shrink-0 tw-relative
  z-index: 200
  min-height: 54px

  @screen md-down-landscape
    +b(steps)
      @apply tw-absolute #{!important}
      top: 12px !important
      left: 160px !important
      +e(nav)
        @apply tw-mt-0 #{!important}

  @screen md
    @apply tw-px-4

  .gik-steps__nav
    @apply tw-mt-2

  +e(title-row)
    @apply tw-flex tw-items-center

  +e(title)
    @apply tw-text-xl tw-font-semibold tw-leading-tight tw-p-0 tw-m-0 tw-text-neutral-900 tw-font-heading tw-pr-8
  +e(title-append)
    @apply tw-ml-2 tw-inline

  +e(close-button)
    // ensure position is higher than the modal header
    @apply tw-text-2xl tw-absolute tw-z-50
    right: 14px
    top: 9px

  +e(back-button)
    @apply tw-flex tw-flex-shrink-0

  +e(close-button back-button)
    @apply tw-text-neutral-800 tw-cursor-pointer

    &:hover
      opacity: 0.8

    span
      @apply tw-text-lg tw-relative
      margin-right: 5px
      top: 1px

    .title
      @apply tw-inline

    &:active,
    .title:active
      @apply tw-text-primary-500


