+b(legal-skeleton)

  @apply tw-text-left

  +e(title-bone)
    @apply tw-mb-4
    &:not(:last-child)
      @apply tw-mr-2

  +e(subtitle)
    @apply tw-mb-6

  +e(paragraph)
    @apply tw-mb-4




