@mixin modal-bottom {
  // on mobile the sytem dialogs should be full width and stick to the bottom of the page
  @apply tw-max-w-none tw-bottom-0 tw-w-full tw-rounded-md tw-rounded-b-none tw-max-h-screen;
  box-shadow:
    0 2px 6px 0 rgba(70, 101, 108, 0.28),
    0 7px 33px 0 rgba(70, 101, 108, 0.26);

  animation: modal-v2-scrollIn-bottom var(--animation-speed) ease-out forwards;
  &.closing {
    animation: modal-v2-scrollOut-bottom var(--animation-speed) ease-in forwards;
  }
}

.ReactModal__Body--open {
  &.ReactModal__Body--no-overflow {
    // note: using overflow instead of overflow-y because iphone has issues with overflow-y
    overflow: hidden;
  }
  position: relative; // fix iOS/safari overflow-y not working
}

.ReactModal__Overlay {
  filter: blur(0);
  transition: filter 600ms ease-out;

  &::after {
    @apply tw-absolute tw-inset-0;
    filter: blur(0.5rem);
    content: '';
  }

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.ReactModal__Overlay:not(.backdrop-disabled) {
  opacity: 0;
  //pointer-events: none;
  background-color: #ffffffaa;
  transition: opacity 0.7s ease-in-out;
}

.ReactModal__Overlay.no-pointer-events {
  pointer-events: none;
  .gik-modal {
    pointer-events: auto;
  }
}

.ReactModal__Overlay--after-open:not(.backdrop-disabled) {
  opacity: 1;
}

.ReactModal__Overlay--before-close,
.ReactModal__Overlay.closing {
  opacity: 0;
}

.modals {
  &.modal-v2 {
    @apply tw-z-modal;
    --animation-speed: 0.5s;
    --width: 658px;

    position: fixed;
    background-color: white;

    display: flex;
    flex-direction: column;
    outline: none;

    &.padding {
      .content {
        @apply tw-py-2 tw-px-2;
      }
      @screen md {
        @apply tw-px-4;
      }
    }

    /* Full page modals */
    @mixin fullscreen-styling {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      animation: modal-v2-scrollIn-fullscreen var(--animation-speed) ease-out forwards;

      &.closing {
        animation: modal-v2-scrollOut-fullscreen var(--animation-speed) ease-in forwards;
      }
    }

    /* Centered modals / dialogs */
    @mixin dialog-styling {
      @apply tw-rounded-md;
      left: 50%;
      top: 50%;
      // right, bottom override fullscreen styling
      right: auto;
      bottom: auto;
      transform: translate(-50%, -50%);
      width: var(--width);
      max-width: 80vw;
      max-height: 95vh;

      overflow: hidden;
      box-shadow:
        0 2px 6px 0 rgba(70, 101, 108, 0.28),
        0 7px 33px 0 rgba(70, 101, 108, 0.26);
      animation: modal-v2-scrollIn var(--animation-speed) ease-out forwards;

      &.closing {
        animation: modal-v2-scrollOut var(--animation-speed) ease-in forwards;
      }
    }

    &:not(.dialog, .gik-modal--system, .gik-modal--wide) {
      @include fullscreen-styling();
    }

    &.dialog {
      @include dialog-styling();
    }

    &.gik-modal--wide.modals.modal-v2:not(.dialog) {
      --width: 100%;
      max-width: 90vw;

      top: 10px;
      left: 50%;

      transform: translate(-50%, 20px);
      animation: none;
    }

    @screen md {
      &:not(.dialog) {
        @include dialog-styling();
      }
    }

    @screen md-down {
      &:not(.gik-modal--system) {
        @apply tw-w-full tw-h-full;
      }
    }

    @screen md-down-landscape {
      &:not(.gik-modal--system) {
        @apply tw-w-full tw-h-full tw-max-h-full tw-max-w-full #{!important};
      }
    }

    > .gik-modal__content-wrapper > .content {
      @apply tw-block tw-text-base tw-leading-normal tw-flex-1 tw-text-neutral-900;
      -webkit-overflow-scrolling: touch;

      &.loading {
        @apply tw-flex tw-w-full tw-h-full tw-justify-center tw-items-center;
      }
    }

    &.gik-modal--padded {
      > .gik-modal__content-wrapper > .content {
        @apply tw-py-2 tw-px-2;
      }

      @screen md {
        > .gik-modal__content-wrapper > .content {
          @apply tw-py-2 tw-px-4;
        }
      }
    }

    > footer {
      @apply tw-px-4 tw-pt-2 tw-pb-4;
    }

    &.gik-modal {
      &--autowidth {
        width: auto;
      }

      &--centered {
        > .gik-modal__content-wrapper > .content {
          @apply tw-text-center;
        }

        .gik-modal-header {
          @apply tw-h-auto;
        }
      }

      &--overlay {
        @apply tw-w-screen tw-h-screen tw-max-w-none tw-max-h-screen tw-rounded-none tw-top-0;
      }

      &--lightbox {
        @apply tw-w-screen tw-h-screen tw-max-w-none tw-max-h-screen tw-bg-transparent;
        > .gik-modal-header {
          @apply tw-bg-transparent;
        }

        .gik-modal-header__close-button {
          @apply tw-flex tw-items-center tw-justify-center tw-static tw-flex-shrink-0 tw-flex-grow-0 tw-w-8 tw-h-8;
          border-radius: 50%;
        }

        .gik-modal__content-wrapper > .content {
          @apply tw-flex tw-flex-grow-0;
          @apply tw-h-full tw-max-w-full tw-mx-auto tw-overflow-visible;
        }
        .slick-dots {
          bottom: 5px;
        }

        .gik-lightbox-content__content {
          max-height: calc(100% - 50px);
          @apply tw-flex tw-flex-col tw-justify-center;

          > .gik-keep-proportions {
            @apply tw-mx-auto;
          }
        }

        .gik-lightbox-content__content--video {
          @apply tw-h-full;
        }

        .gik-lightbox-content .slick-slide img {
          max-width: calc(100% - 30px);
          align-self: center;
        }

        .gik-lightbox-content__caption {
          @apply tw-p-1;
          // bottom: 20px;
        }
      }

      &--lightboxfull {
        @apply tw-rounded-none;

        .gik-modal-header__close-button {
          @apply tw-flex tw-items-center tw-justify-center tw-static tw-flex-shrink-0 tw-flex-grow-0 tw-w-8 tw-h-8;
          border-radius: 50%;
        }

        .gik-modal__content-wrapper {
          @apply tw-overflow-hidden;
        }

        .gik-modal-header {
          @apply tw-absolute tw-right-0 tw-shadow-none tw-w-auto;
        }

        @apply tw-w-screen tw-h-screen tw-max-w-none tw-max-h-screen tw-bg-transparent;
        > .gik-modal-header {
          @apply tw-bg-transparent;
        }

        .gik-modal__content-wrapper > .content {
          @apply tw-flex tw-flex-grow-0 tw-p-0;
          @apply tw-h-full tw-max-w-full tw-mx-auto tw-overflow-visible;
        }

        .slick-dots {
          bottom: 5px;
        }

        .gik-lightbox-content__content {
          max-height: 100%;
          @apply tw-flex tw-flex-col tw-justify-center tw-relative;
        }

        .gik-lightbox-content__caption-wrapper {
          @apply tw-absolute tw-p-1 tw-w-full tw-flex tw-justify-center tw-m-0;
          bottom: 20px;
        }

        .gik-lightbox-content__caption {
        }
      }

      &--loading-spinner {
        @apply tw-w-screen tw-h-screen tw-max-w-none tw-max-h-screen tw-bg-transparent tw-text-white;
        > .gik-modal-header {
          @apply tw-bg-transparent tw-text-white;
        }

        > .gik-modal__content-wrapper > .content {
          @apply tw-h-full tw-max-w-full tw-items-center tw-mx-auto tw-overflow-visible tw-justify-center;
          width: 100%;
        }

        .gik-modal-header__title {
          @apply tw-text-white;
        }
        .gik-modal-header__close-button {
          @apply tw-text-white;
        }
      }

      &--system {
        @screen md-down {
          @include modal-bottom();
        }

        @screen md {
          // system dialogs should have a max width on desktop
          width: 280px;
          &.gik-modal--autowidth {
            width: auto;
          }
        }
      }

      &--system-wide {
        @screen md-down {
          @include modal-bottom();
        }

        @screen md {
          // system dialogs should have a max width on desktop
          width: 350px;
          &.gik-modal--autowidth {
            width: auto;
          }
        }
      }

      &--bottom {
        @include modal-bottom();
        left: initial;
        right: initial;
        bottom: initial;
        top: initial;
        transform: initial;
      }
    }
  }

  /*&.overlay {
    z-index: 2147483100;
  }

  &.portal {
  }*/
}

.gik-modal {
  $blockName: &;
  $more-content-shadow-color: rgba(59, 65, 66, 0.3);
  min-width: 200px;

  &--mode {
    &-dark {
      &#{$blockName}--lightbox {
        @apply tw-text-white;
        > .gik-modal-header {
          @apply tw-text-white;
        }

        .gik-modal-header__title {
          @apply tw-text-white;
        }

        .gik-modal-header__close-button {
          @apply tw-bg-white;

          & * {
            @apply tw-text-neutral-900;
          }
        }
      }

      &#{$blockName}--lightboxfull {
        .gik-modal-header__close-button {
          @apply tw-bg-white tw-ml-2;

          & * {
            @apply tw-text-neutral-900;
          }
        }

        @apply tw-text-white;
        > .gik-modal-header {
          @apply tw-text-white;
        }

        .gik-modal-header__title {
          @apply tw-text-white;
        }

        .gik-lightbox-content__caption {
          color: #000;
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }

    &-bright {
      &#{$blockName}--lightbox {
        @apply tw-text-neutral-900;
        > .gik-modal-header {
          @apply tw-text-neutral-900;
        }

        .gik-modal-header__title {
          @apply tw-text-neutral-900;
        }

        .gik-modal-header__close-button {
          &,
          & * {
            @apply tw-text-neutral-900;
          }
        }

        .gik-lightbox-content__caption {
          @apply tw-text-black;
        }
      }

      &#{$blockName}--lightboxfull {
        .gik-modal-header__close-button {
          & * {
            @apply tw-text-neutral-900;
          }
        }

        @apply tw-text-neutral-900;
        > .gik-modal-header {
          @apply tw-text-neutral-900;
        }

        .gik-modal-header__title {
          @apply tw-text-neutral-900;
        }

        .gik-lightbox-content__caption {
          @apply tw-text-neutral-100;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  &__header,
  &__footer {
    transition: box-shadow var(--animation-speed) ease-in-out;
    @apply tw-z-above;
  }

  &--top-indicator #{$blockName}__header {
    box-shadow: 0 2px 4px 1px $more-content-shadow-color;
  }

  &--bottom-indicator #{$blockName}__footer {
    box-shadow: 0 -3px 4px 1px $more-content-shadow-color;
  }

  &--has-footer {
    @apply tw-pb-0;
  }

  &__header {
    @apply tw-mb-0 tw-px-2 tw-pl-3;
    &--centered {
      @apply tw-text-center tw-pt-6;
    }
  }

  &__toolbar {
    @apply tw-px-4 tw-pt-2 tw-pb-4 tw-text-center;

    // ios should have slightly more padding bottom

    @screen md-down {
      @supports (-webkit-touch-callout: none) {
        /* CSS specific to iOS devices */
        @apply tw-pb-6;
      }
    }

    // space between items
    * + * {
      @apply tw-ml-2;
    }
  }

  .gik-modal-drawer {
    --z-index: theme('zIndex.modalDrawer');
    --z-index-backdrop: theme('zIndex.modalDrawerBackdrop');

    .gik-drawer__box {
      height: calc(100% - 30px);
    }
  }

  &--system &__toolbar {
    // system dialogs should flex each button to use the full available width
    @apply tw-flex;
    > * {
      @apply tw-flex-1;
    }
  }

  @include e(content-wrapper) {
    @apply tw-flex-1 tw-overflow-y-auto;
  }

  @include e(arrow-prev arrow-next) {
    margin-top: -10px;
  }

  @include e(arrow-prev) {
    left: 25px;
  }
  @include e(arrow-next) {
    right: 25px;
  }

  .gik-modal__content-wrapper--has-scroll &__arrow-next {
    right: 40px;
  }
}

.gik-modal-header {
  @apply tw-rounded-t-md;

  &__title--centered {
    @apply tw-text-center tw-font-semibold tw-text-2xl tw-leading-tight tw-w-full tw-pr-0 #{!important};
  }
}

.gik-overlay {
  $blockName: &;

  &--mode {
    &-dark {
      &#{$blockName}--lightbox {
        &.ReactModal__Overlay:not(.backdrop-disabled) {
          background: rgba(0, 0, 0, 0.8);
        }
      }
    }

    &-bright {
      &#{$blockName}--lightbox {
        &.ReactModal__Overlay:not(.backdrop-disabled) {
          background: rgba(255, 255, 255, 0.8);
        }
      }
    }
  }
}

// scroll in/out full page

@keyframes modal-v2-scrollIn-fullscreen {
  0% {
    top: 100%;
  }
  100% {
    top: 0%;
  }
}
@keyframes modal-v2-scrollOut-fullscreen {
  0% {
    top: 0%;
  }
  100% {
    top: 100%;
  }
}

// scroll in/out centered dialogs

@keyframes modal-v2-scrollIn {
  0% {
    top: 65%;
    opacity: 0;
  }
  100% {
    top: 50%;
    opacity: 1;
  }
}

@keyframes modal-v2-scrollOut {
  0% {
    top: 50%;
    opacity: 1;
  }
  100% {
    top: 65%;
    opacity: 0;
  }
}

// scroll in/out from bottom

@keyframes modal-v2-scrollIn-bottom {
  0% {
    bottom: -30%;
    opacity: 0;
  }
  100% {
    bottom: 0%;
    opacity: 1;
  }
}

@keyframes modal-v2-scrollOut-bottom {
  0% {
    bottom: 0%;
    opacity: 1;
  }
  100% {
    bottom: -30%;
    opacity: 0;
  }
}

.gik-embedded-video {
  @apply tw-absolute tw-inset-0;
}

@include b(lightbox-content) {
  &,
  & .slick-slider,
  & .slick-list,
  & .slick-slide,
  & .slick-slide > div,
  &__slide {
    @apply tw-h-full tw-flex-1 tw-max-w-full;
    touch-action: auto !important;
  }

  & .slick-slider {
    @apply tw-pb-0;
    touch-action: auto !important;
  }

  & .slick-track {
    @apply tw-h-full tw-flex-1;
    * {
      touch-action: auto !important;
    }
  }

  & .slick-slide img {
    @apply tw-max-h-full;
  }

  & .slick-slide > div,
  &__slide {
    @apply tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full;
    @apply tw-flex #{!important};
  }

  .slick-dots li button:before {
    @apply tw-text-white #{!important};
  }

  @include e(loading) {
    @apply tw-absolute tw-inset-0;
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full;
    z-index: theme('zIndex.below');
    @screen md-down {
      @apply tw-hidden;
    }
  }

  @include e(caption-wrapper) {
    @apply tw-flex tw-justify-center;
  }

  @include e(caption) {
    @apply tw-font-body tw-text-white tw-text-lg tw-text-center tw-p-1;
    a {
      @apply tw-text-primary-400;
      &:hover {
        @apply tw-text-primary-300;
        text-decoration: underline;
      }
    }
  }
}

@include b(dialogs) {
  @include m(allow-intercom) {
    @apply tw-z-belowIntercom #{!important};
  }
}

// blur entire page without blurring modals
// NOTE: backdrop-filter should be used on the modal backdrop instead when firefox fully supports it
.gik-app-layout {
  transition: filter 300ms ease-out;
}
.gik-blur {
  filter: blur(0.5rem);
  /** {
    @apply tw-select-none;
  }*/
}

@import './ModalHeader';
@import './ModalButtonFooter';
