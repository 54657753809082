+b(billing-form)
  .gik-form-group--saveBillingAddress
    @apply tw-mt-4

  .gik-form-group--plaintext .gik-form-group__content-wrapper
    @apply tw-text-sm
    .gik-form-group__control > div
      @apply tw-mt-0 #{!important}

  +e(address-2-field-wrapper)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-1

  +e(address-2-field-copy)
    @apply tw-text-sm tw-font-normal tw-m-0

  +e(state-zip-section first-name-last-name-section)
    @apply tw-grid tw-grid-cols-2 tw-gap-2 #{!important}
    > *
      @apply tw-ml-0 #{!important}

  +e(first-name-last-name-section)
    @screen md-down
      @apply tw-grid-rows-2 tw-grid-cols-1 #{!important}

  label[for="stripe"]
    @apply tw-mb-2
    @screen md
     @apply tw-inline-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-2

  .gik-form-group--saveAddress
    @apply tw-mb-4

  +e(email-field)
    +m(hidden)
      +b(form-group)
        +e(label-wrapper content-wrapper)
          @apply tw-hidden

