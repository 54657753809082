$blockName: prefix(share-your-story-cta)

.#{$blockName}
  &__title
    @apply tw-font-medium tw-text-base tw-leading-tight

  &__subtitle
    @screen md
      @apply tw-font-medium tw-text-5xl tw-leading-none
    @screen md-down
      @apply tw-font-normal tw-text-4xl tw-leading-snug

  &__content
    @apply tw-font-normal tw-text-lg tw-leading-relaxed

  &__layout
    @screen md
      @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
      align-content: stretch
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  &__main, &__aside
    @screen md
      flex: 0 0 35% #{!important}

  &__main
    @screen md
      @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
      @apply tw-text-center

  &__aside
    @screen md
      @apply tw-flex tw-flex-row tw-justify-end tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full
