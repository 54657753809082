+b(calendar-edit-announcement-form)
  > header
    @apply tw-flex tw-items-center tw-mb-4

  +e(title)
    @apply tw-text-2xl tw-font-bold tw-ml-2


  +e(edit-choice)
    @apply tw-p-4 tw-text-center tw-whitespace-pre-wrap
    hr
      @apply tw-border-none tw-mb-6 tw-mt-6
      border-top: 2px dashed #e1ecec
