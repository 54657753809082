+b(user-groups-section)
  @apply tw-px-4
  @screen md
    @apply tw-px-0

  &__header
    @apply tw-justify-between tw-items-center

  .gik-switch__label-before
    @apply tw-font-bold tw-text-neutral-700

  &__title
    @apply tw-font-heading tw-text-xl tw-font-semibold tw-mb-4 tw-uppercase
  &__description
    @apply tw-mb-4



