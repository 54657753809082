+b(calendar-service-tile)
  @apply tw-text-center tw-bg-white tw-shadow-xs tw-rounded tw-px-2 tw-py-2 tw-border-3 tw-border-transparent tw-select-none tw-relative
  // min-height: 100px

  +e(number-of-people)
    margin-top: 4px
    @apply tw-ml-2

  +e(image)
    height: 64px

  +m(vertical)
      @apply tw-items-center tw-flex

  +m(vertical, image)
      @apply tw-mx-auto tw-mb-2

  +b(checkbox)
    @apply tw-absolute tw-pointer-events-none
    top: 10px
    right: 10px

  +e(label)
    @apply tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-justify-center tw-min-h-8 tw--mx-3 tw-pr-1 tw-text-lg tw-flex tw-items-center

  +m(horizontal)
    @apply tw-flex tw-text-left tw-items-center tw-flex-row

  +m(horizontal, image)
    @apply tw-mx-0 tw-mr-6 tw-mb-0

  +m(selected)
    @apply tw-border-primary-500
