/**
 * GIK Carousel: https://www.figma.com/file/EkdYgBCLNK1HSlE8lYA6Le/Component-InKind?node-id=385%3A0
 */

$slick-font-path: $public-root + 'ui/fonts/'

@import '~slick-carousel/slick/slick'
@import '~slick-carousel/slick/slick-theme'

@mixin slick-dots()
  bottom: 0px
  width: 100%
  @apply tw-relative tw-mx-auto
  margin-bottom: 0

  li
    width: 20px

    button
      &:before
        @apply tw-bg-white tw-opacity-50 tw-rounded-full
        content: ''
        height: 8px
        width: 8px

      &:hover:before
        @apply tw-opacity-100

    &.slick-active button:before
      background: theme('colors.white')
      @apply tw-opacity-100

@mixin carousel-variant($name, $paletteName: $name)
  &--#{$name}
    &.gik-carousel__slider,
    & .gik-carousel__slider
      .slick-dots li.slick-active button:before
        @apply tw-bg-#{$paletteName}-500

      .slick-dots li button:before
        @apply tw-bg-neutral-400
        opacity: 1

+b(carousel)
  @include carousel-variant(primary)
  @include carousel-variant(secondary)
  position: relative

  #gik-carousel__indicator-wrapper
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  &--dots-inside &__slider--has-dots
    .slick-dots
      bottom: 0

  &__slider
    .slick-arrow
      height: auto
      width: auto
      @apply tw-z-10 tw-text-white


    // a slider with dots navigation should have padding at the bottom to account for the extra space
    &--has-dots
      // padding-bottom: 5px

      @include slick-dots()

      .slick-dots
        bottom: -30px

    &--has-dots &--has-arrows
      .slick-dots
        width: calc(100% - 50px)

    &--has-arrows
      // @apply tw-px-8

      // the slick arrows should be at 0 (instead of the default -25px) so we can keep things inside this block
      .slick-next
        right: 0

      .slick-prev
        left: 0

    &--arrows-dark
      .slick-arrow
        @apply tw-text-black
        &:before
          @apply tw-text-black

  &--arrows--primary
    .slick-arrow
      @apply tw-bg-primary-500 tw-text-white tw-flex tw-items-center tw-justify-center
      border-radius: 50%
      width: 28px
      height: 28px
      transform: translate3d(0, -28px, 0)

      &.slick-disabled
        opacity: 0.5

      &:before
        content: ''
        @apply tw-bg-primary-500 tw-text-white

  &--fade .slick-slide:not(.slick-active)
    pointer-events: none

  &--aspect-ratio
    width: 100%
    height: 0
    padding-bottom: var(--aspect-ratio)

    .slick-slider
      height: 0
      padding-bottom: var(--aspect-ratio)

    .slick-list
      height: 0
      padding-bottom: var(--aspect-ratio)

    .slick-track
      @apply tw-flex
      @apply tw-absolute tw-inset-0

    .slick-slide
      @apply tw-flex-shrink-0
      > div
        @apply tw-h-full tw-flex tw-items-center

  +m(transparency-mask)
    .slick-list
      mask-image: linear-gradient(90deg,hsla(0,0%,100%,0),#000 1%,#000 99%,hsla(0,0%,100%,0))
