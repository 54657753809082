+b(featured-inkinds-nav)
  @apply tw-text-center tw-flex tw-flex-wrap

  &__item
    --bg: none
    @apply tw-gap-1 tw-border-3 tw-border-transparent
    &.gik-badge--active
      --bg: none
      @apply tw-border-primary-500
    .gik-image
      @apply tw-w-6 tw-h-6
