+b(benefits)
  @apply tw-flex tw-flex-col tw-items-center

  +b(benefit-tile)
    +e(icon)
      width: 40px
      height: 40px
      &.#{prefix(svg-icon)}
        +m(LinkOutline)
          @apply tw-text-heart

        +m(UserGroupOutline)
          @apply tw-text-purple-600

        +m(EyeOffOutline)
          @apply tw-text-neutral-700

        +m(EatOutline)
          @apply tw-text-red

        +m(CreditCardOutline)
          @apply tw-text-success-500

        +m(GiftOutline)
          @apply tw-text-warn-600

        +m(NewspaperOutline)
          @apply tw-text-neutral-800

        +m(ReminderOutline)
          @apply tw-text-info-600

        +m(ChatAlt2Outline)
          @apply tw-text-secondary-500

        +m(SparklesOutline)
          @apply tw-text-primary-500


  +e(title)
    @apply tw-font-heading tw-text-3xl tw-font-semibold
    @apply tw-mt-0 tw-mb-16 tw-text-center tw-tracking-normal tw-text-neutral-800
    @apply tw-leading-none

  +e(grid)
    @apply tw-flex tw-flex-wrap tw-justify-between
    max-width: MIN(100%, #{$internal-max-width}) // https://css-tricks.com/when-sass-and-new-css-features-collide/

    & > div
      @apply tw-w-full
      &:last-child
        @apply tw-mx-auto

  @screen md-down
    +e(title)
      @apply tw-mx-auto tw-mt-24 tw-mb-20
      max-width: 255px

    +e(grid)
      @apply tw-justify-around

      & > div
        &:last-child
          @apply tw-mx-0

