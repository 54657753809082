+b(group-branding-form)

  +e(branding-row-1)
    @apply tw-flex tw-flex-col tw-gap-8
    @screen lg
      @apply tw-flex-row tw-justify-evenly tw-items-stretch tw-gap-0
      align-content: stretch

  +e(branding-fields-separator)
    @apply tw-hidden
    @screen lg
      height: 200px
      @apply tw-block

  +e(branding-fields-separator2)
    @apply tw-my-8

  +e(group-logo)
    max-width: 289px
    @apply tw-mx-auto tw-relative

  +e(branding-row-title)
    @apply tw-font-body tw-font-bold tw-text-lg tw-leading-none tw-text-neutral-900 tw-m-0 tw-text-center
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(branding-row-instructions)
    @apply tw-mt-6 tw-text-center tw-text-sm
    a
      @apply tw-text-primary-600

  +e(branding-row-subtitle)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700 tw-text-center
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-mb-2 tw-justify-center
    // max-width: 269px

  +e(color-pickers-title)
    @apply tw-font-bold tw-text-sm tw-text-neutral-800 tw-mb-1

  +e(backgound-pickers-text)
    @screen lg
      max-width: 270px

  +e(backgound-pickers)
    min-width: 250px

  +e(color-background-section)
    @apply tw-flex tw-flex-col tw-justify-center tw-text-center tw-gap-8
    @screen lg
      @apply tw-flex tw-flex-row tw-justify-center tw-items-start tw-content-start tw-text-center tw-gap-8 tw-mb-2

  +e(spinner-overlay)
    @apply tw-inset-0 tw-absolute
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +b(color-swatch)
    outline-width: 4px

  .gik-group-background-picker
    min-width: 68px

  &__color-pickers
    @apply tw-gap-4 tw-justify-center tw-mb-6
    @screen lg
      @apply tw-mb-0

  +e(logomark-or-icon-field)
    @apply tw-relative
    +b(form-group)
      +e(content)
        @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
        align-content: stretch
