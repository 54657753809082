.#{$gik-prefix}app-layout {
  @apply tw-flex tw-flex-col;
  min-height: 100%;
  > main {
    @apply tw-flex-1 tw-relative tw-flex tw-flex-col;
    min-height: calc(100vh - var(--mobile-header-height) - 1rem - 351px);
    margin-top: var(--mobile-header-height);
    @screen sm {
      min-height: calc(100vh - var(--desktop-header-height) - 1rem - 331px);
      margin-top: var(--desktop-header-height);
    }
  }
}
