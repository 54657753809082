@use 'sass:math';
$blockName: '#{$gik-prefix}page-section';

@mixin gradient-variant($name, $textColor) {
  &--#{$name} {
    @apply #{$textColor};
    @extend .gik-gradient-#{$name};
  }
  &--light-#{$name} {
    @apply #{$textColor};
    @extend .gik-gradient-light-#{$name};
  }
  &--dark-#{$name} {
    @apply #{$textColor};
    @extend .gik-gradient-dark-#{$name};
  }
}

@mixin decoration(
  $name,
  $left-source-desktop,
  $right-source-desktop: $left-source-desktop,
  $left-source-mobile: $left-source-desktop,
  $right-source-mobile: $right-source-desktop
) {
  @include breakpoint(md) {
    &.#{$blockName}--decoration-#{$name} {
      &.#{$blockName}--decoration-left,
      &.#{$blockName}--decoration-both {
        &::after {
          content: '';
          width: 300px;
          height: 243px;
          background: url(#{$public-root + 'marketing/' + $left-source-desktop});
        }
      }

      &.#{$blockName}--decoration-right,
      &.#{$blockName}--decoration-both {
        &::before {
          content: '';
          width: 272px;
          height: 275px;
          background: url(#{$public-root + 'marketing/' + $right-source-desktop});
        }
      }
    }
  }
  &--decoration-#{$name} {
    &.#{$blockName}--decoration-left,
    &.#{$blockName}--decoration-right,
    &.#{$blockName}--decoration-both {
      &::after,
      &::before {
        mix-blend-mode: luminosity;
        position: absolute;
      }
    }

    &.#{$blockName}--decoration-left,
    &.#{$blockName}--decoration-both {
      &::after {
        content: '';
        width: 88px;
        height: 80px;
        background: url(#{$public-root + 'marketing/' + $left-source-mobile});
        bottom: 0;
        left: 0;
      }
    }

    &.#{$blockName}--decoration-right,
    &.#{$blockName}--decoration-both {
      &::before {
        content: '';
        width: 74px;
        height: 116px;
        background: url(#{$public-root + 'marketing/' + $right-source-mobile});
        top: 0;
        right: 0;
      }
    }
  }
}

@mixin setBackgroundSize($originalWidth, $originalHeight) {
  $width: math.div($originalWidth, 2);
  $height: math.div($originalHeight, 2);

  width: $width;
  height: $height;
  bottom: calc(50% - #{math.div($height, 2)});
  top: calc(50% - #{math.div($height, 2)});
  background-size: $width $height;
}

.#{$blockName} {
  @apply tw-p-4 tw-py-16 tw-font-normal tw-text-lg tw-leading-normal tw-relative;

  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-5xl tw-leading-none;
  }

  &--large-pad {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &--no-pad {
    @apply tw-p-0;
    // no padding still uses 1px padding so margins will be included in the section content
    padding: 1px 0;
  }
  &--no-pad-top {
    @apply tw-pt-0;
  }
  &--no-pad-bottom {
    @apply tw-pb-0;
  }

  &--inset {
    @apply tw-absolute tw-inset-0;
  }

  @include gradient-variant('aqua', 'tw-text-neutral-50');
  @include gradient-variant('warm', 'tw-text-neutral-50');
  @include gradient-variant('love', 'tw-text-neutral-50');
  @include gradient-variant('purps', 'tw-text-neutral-50');
  @include gradient-variant('neutral', 'tw-text-neutral-50');
  @include gradient-variant('sage', 'tw-text-neutral-50');

  &--gradient-darkening {
    &::before {
      content: '';
      @apply tw-z-0 tw-absolute tw-inset-0;
      @extend .gik-gradient-page-header-darkening;
    }
  }

  &--default-pad {
    @apply tw-p-4;
    @include breakpoint(md) {
      @apply tw-p-8;
    }
  }

  > .gik-container {
    @apply tw-relative;
    z-index: 2;
  }

  &--has-background {
    &::before {
      content: '';
      background: var(--background);
      background-position: var(--background-position);
      background-size: var(--background-size);
      background-repeat: no-repeat;
      @apply tw-absolute tw-inset-0;
      z-index: 1;
    }
  }

  @include decoration(
    'foliage',
    'headerPlantBottomLeft-desktop.svg',
    'headerPlantTopRight-desktop.svg',
    'headerPlantBottomLeft-mobile.svg',
    'headerPlantTopRight-mobile.svg'
  );
  @include decoration('foliage-pointy', 'sideFoliageL1-2x.png');
  @include decoration('flowers', 'sideFoliageR1-2x.png');
  @include decoration('foliage-round', 'sideFoliageL2-2x.png');
  @include decoration('foliage-small', 'sideFoliageR2-2x.png');
  // @include decoration('flutter', 'flutter.svg');

  &--decoration-flutter {
    &.#{$blockName}--decoration-right {
      &::after {
        content: '';
        width: 32px;
        height: 47px;
        background: url(#{$public-root + 'marketing/flutter.svg'});
        background-repeat: no-repeat;
        position: absolute;
        top: 20px;
        right: 20px;
        mix-blend-mode: luminosity;
        z-index: 10;
      }
    }
  }

  &--purps {
    @apply tw-text-neutral-50;
    @include gradient-purps();
  }

  &--lightblue {
    @apply tw-text-neutral-900;
    background: #e7f1f2;
  }

  &--lightgray {
    @apply tw-text-neutral-900;
    background: #f7f9f9;
  }

  &--white {
    background: #fff;
  }

  &--wallet {
    background: #fff;
    @screen lg {
      background: #f7f9f9;
    }
  }

  &--solid-neutral {
    @apply tw-text-neutral-900 tw-bg-neutral-50;
  }

  &--solid-neutral-100 {
    @apply tw-text-neutral-900 tw-bg-neutral-100;
  }

  &--with-nav {
    padding-bottom: 4rem !important;
  }

  @include breakpoint(md) {
    &.#{$blockName}--decoration-foliage-pointy.#{$blockName}--decoration-left::after {
      @include setBackgroundSize(399px, 546px);
    }
    &.#{$blockName}--decoration-flowers.#{$blockName}--decoration-right::before {
      @include setBackgroundSize(320px, 699px);
    }
    &.#{$blockName}--decoration-foliage-round.#{$blockName}--decoration-left::after {
      @include setBackgroundSize(460px, 448px);
    }

    &.#{$blockName}--decoration-foliage-small.#{$blockName}--decoration-right::before {
      @include setBackgroundSize(420px, 534px);
    }
  }
}

.#{$gik-prefix}section-lead {
  @apply tw-mx-auto tw-text-center tw-py-4;
  max-width: 33rem;
}
