+b(giftcard-service-form)
  @apply tw-mb-6

  +e(select-list)
    @apply tw-justify-center tw-flex tw-mb-4
    > *
      @apply tw-flex-1
    > * + *
      @apply tw-ml-2
    @screen md
      > *
        max-width: 136px
  //   @apply tw-grid tw-gap-4

  //   @screen xs
  //     @apply tw-grid-cols-2

  //   @screen md
  //     @apply tw-grid-cols-4

  &__private-override
    @apply tw-rounded tw-bg-neutral-100 tw-p-4

  +e(error-empty)
    @apply tw-mt-8

  +e(caption)
    @apply tw-font-bold tw-text-center tw-mt-4 tw-mb-4

  +e(list)
    @apply tw-flex tw-flex-wrap tw-justify-center tw-mb-5

  +e(item)
    @apply tw-relative tw-rounded tw-m-2 tw-bg-center tw-bg-contain tw-bg-no-repeat tw-cursor-pointer
    flex-basis: calc(33.33% - 16px)

    +m(active)

      &::before
        content: " "
        position: absolute
        z-index: -1
        top: -6px
        left: -6px
        right: -6px
        bottom: -6px
        border: 3px solid #41cbce
        border-radius: 8px

  +e(image)
    @apply tw-w-full tw-h-auto tw-rounded

  +e(private-wrapper)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-mt-4 tw-gap-1

