$blockName: prefix(recipe-card)

.#{$blockName}
  margin: 22px 0 40px
  border-radius: 20px
  background-color: #f7f5f1
  padding: 17px 20px

  a
    @apply tw-text-primary-600

  a:hover
    @apply tw-text-primary-700

  &__title
    font-family: Dosis,Roboto,Open Sans,Helvetica Neue,sans-serif
    font-size: 30px
    line-height: 34px

  &__time
    margin: 23px 0

  &__total, &__prep
    padding-top: 9px
    display: inline-block
    padding-right: 19px

    p
      text-align: center

    &>:first-child
      font-size: 18px
      line-height: 20px
      font-weight: 500

    &>:nth-child(2)
      font-size: 20px
      line-height: 20px
      font-weight: 300

  &__total
    border-right: 2px dashed #ced6d6

  &__prep
    padding-left: 19px

  &__ingredients, &__instructions, &__reference
    word-break: break-word
    ul
      @apply tw-ml-4
    li
      list-style-type: circle
    &>:first-child
      font-weight: 700
      font-size: 18px
