$blockName: '#{$gik-prefix}competitors-preview-section';

.#{$blockName} {
  @apply tw-pb-48;

  > .gik-container {
    @apply tw-relative;
    height: 575px;
  }

  .gik-preview-section__content {
    max-height: 500px;
    mask: linear-gradient(to bottom, transparent 0%, #fff 0% 50%, transparent 100%);
  }
}
