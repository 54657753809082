+b(products-grid-section)

  &__title,
  &__title-carousel
    @apply tw-block tw-font-heading tw-font-semibold tw-text-3xl tw-leading-none tw-justify-center tw-flex-1
    margin-left: 0px

  &__title
    @screen md
      text-align: right
    @screen lg
      text-align: left

  &__title-carousel
    text-align: left
    margin-left: 27px

  &__title-section
    @apply tw-mb-8
    @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
    align-content: stretch

  &__actions
    @apply tw-mt-8 tw-text-center

  +b(grid)
    gap: 1rem
    @screen sm
      gap: 1.8rem
