// vertically center the stripe element to fit our standard UI height of 34px
.__PrivateStripeElement {
  transform: translateY(3px);
  min-height: 24px;
}

.gik-input__input.StripeElement {
  height: 38px;
}

.StripeElement {
  width: 100%;
}
