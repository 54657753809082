+b(stream-reactions)

  &__empty-comments
    @apply tw-text-center

  +b(avatar)
    @apply tw-flex-shrink-0

  &__comments
    @apply tw-text-neutral-700 tw-text-sm tw-flex-1

  &__content-wrapper
    @apply tw-flex tw-gap-2 tw-px-4

  &__text
    @apply tw-py-2

  &__content
    @apply tw-text-neutral-900 tw-text-lg tw-flex-1

  &__username
    @apply tw-font-bold tw-text-primary-600 tw-text-base

  +b(comment-user-display)
    @apply tw-mb-2

  &__actions
    @apply tw-border-t tw-border-b tw-border-neutral-300 tw-py-1 tw-flex tw-mb-4
    > .gik-popover__reference-element
      @apply tw-flex-1
      +b(button)
        @apply tw-w-full

    +b(button)
      @apply tw-shadow-none tw-flex-1 tw-outline-none tw-text-lg tw-mx-auto
      --bg: none
      --bg-hover: none
      --border-size: 0

      // svg path
      //   // color: var(--color)
      //   fill: transparent
      //   stroke: var(--color)

    > .gik-button:not(gik-reaction-button__btn)
      --color: theme('colors.neutral.700')
      &:hover
        --color: theme('colors.neutral.800')

  &__reactions
    @apply tw-flex tw-items-center
    svg
      width: 16px
      height: 16px
    .gik-svg-icon--heart
      @apply tw-text-danger-500
    > div
      @apply tw-inline-block

  &__reactions-total
    @apply tw-text-neutral-700 tw-text-xs tw-inline tw-relative
    margin-left: 2px
    top: 1px

  &__reactions-wrapper
    @apply tw-flex tw-items-center

  &--styled &__text
    @apply tw-p-4 tw-text-center tw-flex tw-items-center tw-justify-center tw-rounded tw-my-2 tw-text-neutral-900 tw-text-2xl tw-font-semibold
    min-height: 300px

  &--empty-text &__text
    @apply tw-text-neutral-700 tw-font-semibold

  &--color-red &__text
    @apply tw-bg-danger-200

.gik-user-mention
  @apply tw-text-primary-600 tw-font-normal tw-cursor-pointer
