
+b(reset-password-section)
  @apply tw-text-base

  +e(title)
     @apply tw-mb-4 tw-text-3xl tw-font-semibold tw-text-neutral-900

  &__success-section
    > .gik-container
      @apply tw-text-center

  &__success-title
    @apply tw-mb-8 tw-text-3xl tw-font-semibold tw-text-neutral-900

+b(reset-password-modal)
  &.gik-modal.modal-v2
    .content
      @apply tw-px-8

    +b(modal-button-footer)
      @apply tw-px-6 tw-pb-6 tw-pt-2
