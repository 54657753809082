+b(product-description)
  &__terms,
  &__note,
  &__disclaimer,
  &__product-terms,
  &__brand-terms,
  &__tango-card-disclaimer
    @apply tw-text-neutral-600 tw-text-base

    p:first-of-type
      @apply tw-mt-0


+b(product-description-skeleton)
  .gik-paragraph-bone
      @apply tw-my-8

  &__title
    @apply tw-mb-8
    .gik-word-bone__bone
      @apply tw-h-6
