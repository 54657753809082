
+b(signup-section)
  @apply tw-text-base tw-text-neutral-800
  font-size: 18px

  +b(external-login-form-wrapper)
    @apply tw-mb-4

  +e(title)
    @apply tw-mb-4 tw-text-center

  +e(loginText)
    @apply tw-text-center

  .external-login-form-wrapper
    @apply tw-mb-4
