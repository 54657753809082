@import '../../Carousel/Carousel';
$blockName: '#{$gik-prefix}box-carousel';

.#{$blockName} {
  @apply tw-rounded tw-relative tw-p-4;
  border-radius: 20px;
  box-shadow: 0 2px 45px 0 rgba(0, 0, 0, 0.21);

  > main {
    @apply tw-mx-auto tw-relative tw-z-10;
    padding-bottom: 5px;
  }

  &--contained > main {
    @apply tw-mx-auto;
    max-width: 800px;
  }

  &--fade-sides > main {
    mask: linear-gradient(to right, transparent 0%, #fff 5% 95%, transparent 100%),
      linear-gradient(to left, transparent 0%, #fff 5% 95%, transparent 100%);
  }

  // white dots for some variants
  &.#{$gik-prefix}box--primary,
  &.#{$gik-prefix}box--secondary {
    .slick-dots li button:before {
      @apply tw-text-white;
    }
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .slick-dots {
    @include slick-dots();
    bottom: -5px;
  }
}
