@use "sass:math"

+b(gradient-picker)
  +e(editor)
    @apply tw-mb-6

  +e(preview)
    @apply tw-w-full tw-h-9 tw-rounded
    filter: drop-shadow( 0px 0px 1px rgba(0, 0, 0, 0.4))

  +e(controls)
    @apply tw-relative tw-h-4
    margin-top: 2px
    margin-right: 20px
    margin-left: -10px

  +b(color-handle)
    @apply tw-absolute tw-top-0
