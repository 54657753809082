+b(recipients-grid)
  @apply tw-grid tw-gap-4

  @screen xs
    @apply tw-grid-cols-2

  @screen sm
    @apply tw-flex tw-flex-wrap tw-justify-center
    @apply tw-gap-8 tw-gap-y-8

  &--skeleton
    @screen sm
      row-gap: 4.5rem
