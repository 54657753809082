+b(inkind-group-header)
  @apply tw-h-full tw-flex tw-items-center tw-justify-center tw-flex-shrink-0

  &:before
    --background-position: center

  > .gik-container
    @apply tw-flex tw-items-center tw-justify-center tw-h-full

  &__keep-prop
    min-height: 160px
    > *
      min-height: 160px

  &__actions
    @apply tw-absolute tw-top-0 tw-right-0 tw-mt-2 tw-mr-1 tw-z-20

  &__actions--muted
    @apply tw-pointer-events-none tw-opacity-30

  &__wrapper
    @apply tw-h-full tw-absolute tw-inset-0

  &__logo
    @apply tw-bg-no-repeat tw-bg-center tw-bg-contain tw-z-10 tw-mt-4
    max-width: 80%
    max-height: 60%
    width: 100%
    height: 100%

    +breakpoint(md)
      margin-top: 20px

  &--has-gradient
    background: linear-gradient(225deg, var(--gradient-color-1) -0.2%, var(--gradient-color-2) 99.8%)

  &--muted &__actions
    @apply tw-pointer-events-none tw-opacity-30

  +breakpoint(md)
    &, &:before
      @apply tw-rounded-b-md

