+b(comment)
  @apply tw-flex tw-gap-2 tw-mb-2
  +b(avatar)
    @apply tw-flex-shrink-0 tw-cursor-pointer

  &__more-menu
    +b(popover-menu-item)
      @apply tw-p-0
    svg
      @apply tw-mr-2
    +b(loading-spinner)
      @apply tw-inline-block tw-align-middle tw-mr-0
      --sk-size: 12px
      margin-bottom: 2px

    +b(popover-menu-item)
      @apply tw-p-2

    .gik-popover-menu-item__icon
      @apply tw-text-neutral-700

  &__error
    @apply tw-text-danger-500 tw-text-xs tw-ml-1
    > span
      @apply tw-flex tw-items-center
    a
      @apply tw-underline
      &:hover
        @apply tw-font-semibold

  &__text
    @apply tw-whitespace-pre-wrap

  &__content
    @apply tw-text-neutral-900 tw-bg-neutral-50 tw-p-2 tw-rounded tw-relative
    > p
      @apply tw-m-0

    .gik-stream-link
      @apply tw-underline

  &__content-wrapper
    @apply tw-w-full

  &__username
    @apply tw-font-bold tw-text-primary-600 tw-text-base

  &__reactions-total
    @apply tw-text-neutral-700 tw-text-xs tw-inline tw-relative
    top: 1px
    margin-left: 2px

  &__reactions
    @apply tw-absolute tw-right-0 tw-bg-white tw-shadow-sm tw-rounded-full tw-pl-1 tw-pr-2 tw-flex tw-items-center
    bottom: -14px
    height: 18px
    line-height: 18px

    svg
      width: 16px
      height: 16px

    .gik-svg-icon--heart
      @apply tw-text-danger-500

    > span
        @apply tw-inline-block
        margin-bottom: 2px

  &__btn-react
    --color: theme('colors.neutral.700')
    &--love
      --color: theme('colors.danger.600')
    &--like
      --color: theme('colors.primary.600')
    &--dislike
      --color: theme('colors.danger.600')
    &--haha
      --color: theme('colors.warn.600')
    &--strong
      --color: theme('colors.success.600')
    &--prayers
      --color: theme('colors.primary.600')
    &--sad
      --color: theme('colors.info.600')
    &--angry
      --color: theme('colors.red')
    &--celebrate
      --color: theme('colors.secondary.600')
    &--sick
      --color: theme('colors.success.600')

  &__footer
    @apply tw-inline-flex tw-items-center

  &__actions
    @apply tw-text-xs tw-ml-2
    > li
      @apply tw-inline-block
      &:not(:last-child)::after
        content: '•'
        @apply tw-px-1 tw-text-neutral-800 tw-font-bold tw-no-underline
        &:hover
          @apply tw-no-underline
    .gik-button
      @apply tw-font-semibold
    .gik-button:not(.gik-reaction-button__btn)
      --color: theme('colors.neutral.700')
    .gik-reaction-button__btn--default
      --color: theme('colors.neutral.700')
      // &:hover
      //   --color: theme('colors.neutral.800') !important

  &__date
    @apply tw-text-neutral-700 tw-text-xs

  &__editing-message
    @apply tw-hidden

  &--editing &__editing-message
    @apply tw-block tw-text-xs tw-text-neutral-700 tw--mt-2

  &--editing &__actions,
  &--editing &__date
    @apply tw-hidden

  &--deleting &__footer
    @apply tw-opacity-50 tw-select-none tw-pointer-events-none

  &--highlighted &__content
    @apply tw-bg-primary-50
