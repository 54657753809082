+b(gift-type-tile)
  $animation-duration: 270ms
  @apply tw-text-center
  > div
    @apply tw-flex tw-flex-col-reverse tw-w-full tw-bg-neutral-100 tw-rounded-md tw-overflow-hidden
    width: 100%
    height: 100%
    transition: box-shadow $animation-duration ease-in-out, transform $animation-duration ease-in-out

    @screen sm
      width: 142px

    @screen md
      width: 156px

    @screen lg
      width: 216px

    @screen xl
      width: 261px

  &__box
    @apply tw-rounded-md tw-flex-1 tw-flex-shrink-0 tw-p-2 tw-text-center tw-flex-col-reverse tw-text-center tw-flex
    &:after
      @apply tw-absolute tw-inset-x-0 tw-bottom-0 tw-rounded-b-md
      content: ''
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%)
      height: 85px

  &__title
    @apply tw-block tw-font-bold tw-text-base tw-uppercase tw-text-white tw-relative tw-z-10
    @apply tw-flex tw-items-center tw-justify-center
    height: 42px

  .gik-hover-animation__shadow
    @apply tw-rounded-md

  &:hover > div
    box-shadow: 2px 9px 42px 2px rgba(70, 101, 108, 0.26)
    transform: scale(1.02) translate(0, -2px)

  +b(bg-image)
    @apply tw-bg-cover tw-h-full

+b(gift-type-tile-skeleton)
  &__image-wrapper
    @apply tw-w-full

  @screen sm
    width: 142px

  @screen md
    width: 156px

  @screen lg
    width: 216px

  @screen xl
    width: 261px

  &__image.gik-frame-bone
    --width: 100%
    --height: 100%

  > main
    @apply tw-pt-2
