// both privacy policy and terms of service pages share same styles

+b(legal)
  @apply tw-text-base tw-text-justify

  p
    @apply tw-mt-2 tw-mb-4

  a
    @apply tw-text-primary-600 tw-underline

  a:hover, a:focus
    @apply tw-text-primary-500 tw-no-underline

  ul
    @apply tw-pl-4 tw-list-disc

  ul li
    @apply tw-text-base

  ol
    @apply tw-list-decimal

  ol ul
    @apply tw-list-circle
