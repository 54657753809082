+b(expandable-button)
  +e(button-wrapper)
    @apply tw-relative tw-z-above

  +b(accordion-item)
    +e(main)
      & > div
        @apply tw-p-0

  +b(form-group)
    +m(highlight)
      @apply tw-rounded-t-none
