+b(recipient-address-prompt-modal)

  .gik-form-group__error
    @apply tw-text-left

  &.modals.modal-v2 > .gik-modal__content-wrapper
    @apply tw-flex
    > .content
      @apply tw-flex-1 tw-flex tw-flex-col

  .gik-modal-button-footer
    @apply tw-pl-5 tw-py-2 tw-pr-4 tw-justify-start #{!important}

    .gik-button
      height: 38px !important

  .gik-recipient-address-prompt-form__dont-ask-checkbox
    @apply tw-mr-auto tw-text-sm tw-font-normal tw-text-neutral-800 tw-items-center

  .gik-recipient-address-prompt-form__skip-button
    @apply tw-mr-2

+b(recipient-address-prompt)

  +e(content)
    @apply tw-p-4 tw-pb-2 tw-bg-neutral-100 tw-flex-1

  +e(header)
    @apply tw-px-4 tw-py-4 tw-text-center

  +e(title)
    @apply tw-text-2xl

  +e(icon)
    @apply tw-text-white tw-ml-1
    &:hover
      @apply tw-cursor-pointer

  +e(description)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-mx-auto
    max-width: 30rem

  +e(why-add-dialog skip-dialog)
    .gik-modal-header
      @apply tw-pt-6 tw-pb-2

  +e(dialog-text)
    @apply tw-text-neutral-900 tw-leading-tight tw-font-body tw-text-base tw-font-normal tw-mb-0
    &:first-child
      @apply tw-mt-0 tw-mb-4
