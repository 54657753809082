$blockName: prefix(general-results)

.#{$blockName}
    &__page-name
      @screen md-down
        @apply tw-text-3xl #{!important}
      @apply  tw-font-medium tw-leading-tight

    @screen md
      &__featured
        +b(base-article-tile)
          &__image-wrapper
            @apply tw-pr-3

    &__featured-header, &__featured-tile
      @apply tw-mb-6

    &__featured-header
      @apply tw-hidden
      @screen md
        @apply tw-flex

    &__featured-tile
      &__title
        @apply tw-mb-0
      @screen md
        @apply tw-hidden


    @screen md-down
      &__featured-wrapper
        @apply tw-pb-0

      +e(article-dynamic-loader)
        @apply tw-pt-6
