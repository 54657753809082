@import ./SignInFlowContent
@import ./SignInFlowStartContent
@import ./SignInFlowLoginContent
@import ./SignInFlowSignUpContent
@import ./SignInFlowOAuthContent
@import ./SigninFlowAccountDeactivated

+b(sign-in-flow-modal)
  @screen md
    .content
      @apply tw-pb-6

+b(sign-in-flow)
  $blockName: &
  @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-gap-6
  align-content: stretch

  &__content-container > *
    @apply tw-px-4

  +breakpoint(md)
    @apply tw-flex tw-flex-row-reverse tw-justify-center tw-items-stretch tw-gap-0
    align-content: stretch

    #{$blockName}__content-container, #{$blockName}__callout-block-container
      @apply tw-flex-1
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch
