+b(calendar-announcement)
  --color: theme('colors.black')
  @apply tw-relative tw-rounded tw-shadow-sm tw-flex-1 tw-flex tw-overflow-hidden tw-bg-white tw-cursor-pointer tw-z-0

  background-color: var(--bg)

  > header
      @apply tw-items-baseline tw-whitespace-nowrap tw-p-2 tw-pr-0 tw-text-center
      @apply tw-border-l-4 tw-flex-col tw-justify-center tw-items-start
      border-color: var(--color)

      @screen md
        @apply tw-items-center
        min-width: 90px

  > main
    @apply tw-p-2 tw-flex-1 tw-overflow-hidden

  +e(icon)
    color: var(--icon-color)
    width: 20px
    height: 20px
    @apply tw-mt-2

    @screen md
      width: 28px
      height: 28px
      @apply tw-mt-0


  +e(title)
    @apply tw-font-bold tw-align-middle tw-text-xl tw-whitespace-nowrap tw-overflow-hidden tw-block
    text-overflow: ellipsis
    color: var(--color)

  +e(btn-more)
    @apply tw-absolute tw-text-right
    right: 12px
    bottom: 6px

  +e(description)
    @apply tw-m-0 tw-text-neutral-900 tw-whitespace-pre-wrap

  +b(line-clamp__text-wrapper)
    @apply tw-mr-16

  +m(information)
    --bg: theme('colors.info.50')
    --color: theme('colors.info.700')
    --icon-color: theme('colors.info.600')
  +m(love)
    // TODO: use to the love pallete
    --bg: theme('colors.danger.50')
    --color: theme('colors.danger.700')
    --icon-color: theme('colors.danger.600')
  +m(warn)
    --bg: theme('colors.secondary.50')
    --color: theme('colors.secondary.700')
    --icon-color: theme('colors.secondary.600')

  +e(box-time)
    @apply tw-font-bold tw-text-neutral-700 tw-text-sm tw-leading-tight tw-flex tw-items-center
    color: var(--color)
    @screen md
      @apply tw-text-center tw-justify-center

  +b(date-time-display)
    @apply tw-text-sm tw--mt-1
    color: var(--color)
    +e(startTime)
      @screen md
        @apply tw-block tw--mb-1
    @screen md
      @apply tw-text-center tw-flex tw-justify-center

    min-width: 80px
