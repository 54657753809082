$blockName: adglare;
$placeholderElName: placeholder;

.#{$blockName} {
  padding: 2px;
  display: inline-block;

  .#{$blockName}__#{$placeholderElName} {
    display: block;
  }
}
