$blockName: '#{$gik-prefix}dialogs';

.#{$blockName} {
  @apply tw-fixed tw-w-full tw-z-modalBackdrop;
  @apply tw-top-0 tw-left-0 tw-mt-2 tw-px-2 tw-self-center;

  > ul {
    @apply tw-block tw-w-full tw-absolute tw-px-2;
    left: 50%;
    transform: translateX(-50%);
    > li {
      @apply tw-mb-2;
      > * {
        @apply tw-inline-flex tw-text-left;
      }
    }
  }

  .backdrop-v2 {
    --animation-speed: 200ms;
  }
  .modal-v2 {
    --animation-speed: 300ms;
  }
}
