/**
Line Clamp (Vertical text overflow ellipsis)

very happy that we don't care that this doesn't work in IE11 ;)
support table: https://caniuse.com/css-line-clamp
css-tricks article: https://css-tricks.com/almanac/properties/l/line-clamp/
*/
@mixin lineClamp($lines: 2, $lineHeight: 0) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;
  overflow: hidden;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;

  @if $lineHeight > 0 {
    max-height: #{($lines * $lineHeight)}rem;
  }
}

@mixin singleLineClamp() {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin unclamp() {
  display: block;
  -webkit-line-clamp: unset;
  text-overflow: unset;
  overflow: visible;
  max-height: unset;
}
