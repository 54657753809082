+b(image-picker-modal)
  @apply tw-text-center tw-mb-2

  +e(content-wrapper)
    @apply tw-flex tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-mb-4
    @screen md
      @apply tw-flex-row
    @screen md-down
      @apply tw-flex-col

  +e(controls)
    @apply tw-mx-16
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-text-center
