+b(menu-item)
  @apply tw-flex tw-items-center

  +e(item-wrapper)
    @apply tw-my-3 tw-mx-5

  +e(label)
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-leading-none
    @apply tw-text-neutral-900 #{!important}

  +e(subtitle)
    @apply tw-text-sm tw-font-normal tw-leading-tight
    @apply tw-text-neutral-700 #{!important}

  +m(hover)
    @apply tw-bg-neutral-200

+b(menu-category-title)
  @apply tw-flex tw-items-center
  @apply tw-bg-neutral-100 tw-cursor-default

  +e(label)
    @apply tw-font-heading tw-font-bold tw-text-lg tw-leading-none tw-my-3 tw-mx-5
    @apply tw-text-neutral-900 #{!important}
