$blockName: prefix(group-page-thumbnail-preview)

.#{$blockName}
  @apply tw-shadow-base tw-rounded-md
  position: absolute
  left: auto
  right: 2px
  bottom: -30px
  width: 217px
  z-index: 60


  @screen lg
    left: 2px
    @apply tw-z-50
    bottom: 10px
