$blockName: #{$gik-prefix}hover-animation;

.#{$blockName} {
  $animation-duration: 270ms;
  position: relative;

  &__shadow {
    transition: box-shadow $animation-duration ease-in-out;
    z-index: 1;
    @apply tw-rounded;

    position: absolute;
    top: 3px;
    bottom: 3px;
    left: 3px;
    right: 3px;
    margin: auto;
    pointer-events: none;
  }

  &__target {
    transition: transform $animation-duration ease-out;
    z-index: 2;
    position: relative;
  }

  &--hover {
    .#{$blockName}__shadow {
      box-shadow: 2px 9px 42px 6px rgba(70, 101, 108, 0.26);
    }

    .#{$blockName}__target {
      transform: scale(1.02) translate(0, -2px);
    }
  }

  &--triggered {
    .#{$blockName}__target {
      transform: scale(1) translate(0, +2px) !important;
    }
  }

  .#{$blockName}--hover.#{$blockName}--triggered {
    .#{$blockName}__shadow {
      box-shadow: 2px 4px 8px 6px rgba(70, 101, 108, 0.28) !important;
    }
  }
}
