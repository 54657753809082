$blockName: '#{$gik-prefix}radio';

$radio-width: 20px;
$radio-height: 20px;
$dot-width: 10px;
$dot-height: 10px;

@mixin radio-variants($name, $paletteName) {
  &--#{$name} {
    @apply tw-text-#{$paletteName}-500;
  }
}

.#{$blockName} {
  // ---------- Block
  @apply tw-flex tw-relative tw-align-middle tw-cursor-pointer tw-select-none tw-items-center;

  // space between children
  > * + * {
    @apply tw-ml-2;
  }

  // ---------- Element

  &__input {
    // place the real radio offscreen
    position: absolute;
    left: -9999px;
  }

  &__background {
    @apply tw-absolute tw-z-0 tw-rounded-full;
    background: currentColor;
    opacity: 0;
    transform: scale(0.2);
    transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.73);
    transition-duration: 150ms;
    // transition: opacity 150ms ease-in, transform 150ms ease-in;

    left: 4px;
    top: 4px;
    width: $radio-width - $dot-width;
    height: $radio-height - $dot-height;
  }

  &__icon {
    @apply tw-absolute tw-inset-0 tw-text-white tw-z-10 tw-flex tw-items-center tw-justify-center;
    transition: opacity 150ms ease-in, transform 150ms ease-in;
    display: block;
    font-size: 18px;
    // margin-left: 1px;
    opacity: 0;
    transform: scale(0.2);
    position: relative;
    top: -2px;
    left: 1px;
  }

  &__wrapper {
    @apply tw-relative tw-cursor-pointer;
    @apply tw-rounded-full tw-align-middle;
    border: 1px solid currentColor;
    width: $radio-width;
    height: $radio-height;
  }

  &__label {
    white-space: pre;
    @apply tw-break-words tw-whitespace-normal;
  }

  &__label,
  &__label-before {
    @apply tw-inline-block tw-align-middle tw-text-black;
  }

  // ---------- Modifiers

  &--inline {
    @apply tw-inline-flex;
  }

  @include radio-variants('default', 'neutral');
  @include radio-variants('primary', 'primary');
  @include radio-variants('secondary', 'secondary');
  @include radio-variants('danger', 'danger');
  @include radio-variants('warning', 'warn');
  @include radio-variants('success', 'success');

  &--checked &__icon,
  &--intermediate &__icon {
    opacity: 1;
    transform: scale(1);
  }

  &--checked &__background,
  &--intermediate &__background {
    opacity: 1;
    transform: scale(1);
  }

  // ----- States

  &--focus &__wrapper {
    @include gik-focus-shadow;
    &:before {
      border-radius: 50%;
    }
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }

  // ----- Sizes

  // xs
  &--size-xs {
    @apply tw-text-xs;
  }

  &--size-xs &__wrapper {
    width: 16px;
    height: 16px;
  }

  &--size-xs &__background {
    width: 8px;
    height: 8px;
    top: 3px;
    left: 3px;
  }

  // sm
  &--size-sm {
    @apply tw-text-sm;
  }

  &--size-sm &__wrapper {
    width: 18px;
    height: 18px;
  }

  &--size-sm &__background {
    width: 9px;
    height: 9px;
  }

  // base
  &--size-base {
    @apply tw-text-base;
  }

  // lg
  &--size-lg {
    @apply tw-text-lg;
  }

  &--size-lg &__wrapper {
    width: 24px;
    height: 24px;
  }

  &--size-lg &__background {
    width: 12px;
    height: 12px;
    top: 5px;
    left: 5px;
  }

  // xl
  &--size-xl {
    @apply tw-text-xl;
  }

  &--size-xl &__wrapper {
    width: 30px;
    height: 30px;
  }

  &--size-xl &__background {
    width: 15px;
    height: 15px;
    top: 6.5px;
    left: 6.5px;
  }
}
