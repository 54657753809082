+b(remote-select)
  min-width: 200px

  &__portal
    display: block !important
    box-shadow: none !important

    &,
    & > *,
    & .gik-remote-select__menu
      z-index: theme('zIndex.max') !important

