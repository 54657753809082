+b(situation-step)
  @apply tw-text-center tw-h-full

  +e(select)
    @apply tw-font-semibold tw-mb-3
    @screen md
      width: 288px

  +b(select-list)
    @apply tw-grid tw-gap-3

    @screen xs
      @apply tw-grid-cols-2

    @screen md
      @apply tw-grid-cols-3

  +e(select-wrapper)
    @screen md
      @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
      align-content: stretch

  +e(tiles-instructions)
    @apply tw-font-bold tw-text-sm tw-leading-none

  +b(grid)
    @apply tw-px-4 tw-py-2
    &:focus
      @apply tw-outline-none
    @screen md
      @apply tw-px-12 tw-py-2

  > p
    @apply tw-text-neutral-700 tw-font-bold

  +e(carousel-wrapper)
    @apply tw-h-full
    padding-bottom: 40px

  +e(form-wrapper)
    @apply tw-h-full

  .slick-slide
    // uncomment this for fixed slide heights with scrollbars
    // height: 310px;
    // overflow-y: auto
    > div > div
      @apply tw-outline-none
