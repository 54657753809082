+b(privacy-settings-section)

  // this section has custom padding-x, override it
  @screen sm
    @apply tw-px-6 #{!important}

    // TODO: maybe this should be handled with variant modifier
    .gik-section-title-and-description__description
      @apply tw-max-w-xs

  +e(checkbox-section)
    @apply tw-text-left

  @screen sm
    +e(checkbox-section)
      @apply tw-mx-auto
      max-width: 29rem

  // checkboxes and desc fonts
  +e(show-page-label feature-page-label include-search-label)
    @apply tw-text-base tw-font-body tw-text-neutral-900 tw-font-bold tw-mb-2
  +e(show-page-description feature-page-description include-search-description)
    @apply tw-font-body tw-text-sm tw-text-neutral-700 tw-font-normal

  // desc indentation
  +e(show-page-description feature-page-description include-search-description)
    @apply tw-my-0
    margin-left: 1.8rem

  +e(feature-page-description include-search-description)
    max-width: 25rem

  +e(feature-page-description)
    @apply tw-mb-4

  +e(checkbox-group)
    @apply tw-ml-5
    +m(disabled)
      @apply tw-opacity-50

  +e(separator-container)
    @apply tw-flex tw-justify-center

  +e(separator)
    @apply tw-my-4 tw-w-full

  // 640px- -> 1 card per row
  +e(cards)
    @apply tw-flex tw-flex-wrap tw-justify-center tw-mb-6
    .gik-privacy-settings-card
      @apply tw-mb-3
      flex-basis: 100%

  // 640px+ -> max 2 cards per row
  @screen sm
    +e(cards)
      @apply tw-max-w-3xl tw-mx-auto
      .gik-privacy-settings-card
        flex-basis: calc(50% - 1rem)
        max-width: 270px

      .gik-privacy-settings-card:nth-of-type(1), .gik-privacy-settings-card:nth-of-type(3)
        @apply tw-mr-4


