+b(inkind-user-groups-grid)

  @screen md
    @apply tw-flex tw-flex-col tw-gap-4

  /*&--empty
   @apply tw-block*/

  &__empty
    @apply tw-bg-neutral-100 tw-rounded-md tw-shadow-lg tw-p-8 tw-flex tw-items-center tw-font-semibold tw-text-neutral-900 tw-justify-center

  +b(badge)
    @apply tw-mr-2 tw-flex-shrink-0

  +b(hover-animation)
    @apply tw-w-full
