$blockName: prefix(create-page-for)

.#{$blockName}
  @apply tw-flex

  +e(select)
    @apply tw-shadow-xl
    width: 240px

  +e(button)
    @apply tw-ml-4 tw-shadow-xl

  @screen md
    @apply tw-flex-row tw-items-center tw-content-center tw-justify-start

  @screen md-down
    @apply tw-flex-col tw-items-center tw-content-center tw-justify-start

    +e(button)
      @apply tw-mt-4
