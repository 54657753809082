+b(stream-comments)

  &__empty-comments
    @apply tw-text-center

  +b(avatar)
    @apply tw-flex-shrink-0


  &__comments
    @apply tw-text-neutral-700 tw-text-sm tw-flex-1

  &__content-wrapper
    @apply tw-flex tw-gap-2 tw-px-4

  &__text
    @apply tw-py-2

  &__content
    @apply tw-text-neutral-900 tw-text-lg tw-flex-1

  &__username
    @apply tw-font-bold tw-text-primary-600 tw-text-base

  +b(comment-user-display)
    @apply tw-mb-2

  &__load-more-error
    @apply tw-text-danger-500 tw-text-sm

  &__actions
    @apply tw-border-t tw-border-b tw-border-neutral-300 tw-py-1 tw-flex tw-mb-4
    > .gik-popover__reference-element
      @apply tw-flex-1
      +b(button)
        @apply tw-w-full

    +b(button)
      @apply tw-shadow-none tw-flex-1 tw-outline-none
      --bg: none
      --bg-hover: none
      --border-size: 0

    > .gik-button
      --color: theme('colors.neutral.600')
      &:hover
        --color: theme('colors.neutral.800')

  &__reactions
    .gik-svg-icon--heart
      @apply tw-text-danger-500

  &__reactions-total
    @apply tw-text-neutral-700 tw-text-xs tw-ml-1 tw-inline

  &__reactions-wrapper
    @apply tw-flex tw-items-center tw-px-4

  &--styled &__text
    @apply tw-p-4 tw-text-center tw-flex tw-items-center tw-justify-center tw-rounded tw-my-2 tw-text-neutral-900 tw-text-2xl tw-font-semibold
    min-height: 300px

  &--empty-text &__text
    @apply tw-text-neutral-700 tw-font-semibold

  &--color-red &__text
    @apply tw-bg-danger-200

  &__btn-show-more
    --color: theme('colors.primary.600')
    height: 28px

  &__btn-show-more-wrapper
    @apply tw-text-center tw-mb-4

  &--no-interaction
    .gik-comment-input-avatar
      @apply tw-select-none tw-cursor-not-allowed
      > *
        @apply tw-pointer-events-none

  .gik-stream-pagination__load-prev
    @apply tw-mb-4
    +b(button)
      --color: theme('colors.primary.500')

  .gik-stream-pagination__load-next
    @apply tw-mb-4
    +b(button)
      --color: theme('colors.primary.500')

