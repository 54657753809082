$blockName: prefix(blog-layout-wrapper)

.#{$blockName}
  --desktop-blog-header-height: 62px
  --mobile-blog-header-height: var(--mobile-header-height)

  & > main
    @screen md
      margin-top: CALC(var(--desktop-header-height) + var(--desktop-blog-header-height))
    @screen md-down
      margin-top: CALC(var(--mobile-header-height) + var(--mobile-blog-header-height))

  .app-header-sticky
    background-color: white
    background-blend-mode: hard-light
    box-shadow: 0px -1px 2px rgba(59, 65, 66, 0.101399), 0px 2px 3px rgba(59, 65, 66, 0.12)

  +b(blog-nav)
    background-color: transparent
    box-shadow: none
