$blockName: '#{$gik-prefix}steps';

@mixin steps-nav-variant($name, $paletteName) {
  &--#{$name} &__item--completed &__content {
    @apply tw-text-#{$paletteName}-600;
  }
  &--#{$name} &__item--active &__content {
    @apply tw-text-#{$paletteName}-600 tw-w-auto;
  }
  &--#{$name} &__item--active &__name {
    width: auto;
    opacity: 1;
    @apply tw-block;
  }
}

.#{$blockName} {
  &__nav {
    @apply tw-text-black tw-flex;

    // space between nav items
    > * + * {
      @apply tw-ml-2;
    }
  }

  // ---------- Elements

  &__item {
    @apply tw-relative tw-inline-flex tw-items-center tw-justify-center;
  }

  &__item--disabled {
    @apply tw-opacity-50 tw-pointer-events-none;
  }

  &__name {
    width: 0;
    opacity: 0;
    text-transform: uppercase;
    transition: width 300ms;
    @apply tw-font-heading tw-font-semibold tw-text-sm;
  }

  &__content {
    @apply tw-inline-flex tw-px-2 tw-py-1 tw-rounded-full tw-font-bold tw-text-sm tw-items-center tw-justify-center;
    @apply tw-text-neutral-600 tw-select-none tw-overflow-hidden;
    @apply tw-bg-white tw-shadow-base;
    width: 30px;
    height: 30px;
  }

  &__content-wrapper {
    @apply tw-hidden;
  }
  &__content-wrapper--active {
    @apply tw-block;
  }

  &__item--active &__content {
    @apply tw-shadow-lg;
    @apply tw-opacity-100 tw-cursor-default;
  }

  &__item--active &__substep-indicator-wrapper {
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full;
    .gik-indicator {
      margin-top: 0px;
    }
  }

  .gik-fonticon {
    @apply tw-text-base;
  }

  &__prepend {
    @apply tw-inline-flex tw-items-center tw-justify-center;
    svg {
      width: 22px;
    }
  }

  &__item--active &__prepend + &__name,
  &__item--active &__prepend + &__substep-indicator-wrapper {
    @apply tw-ml-1;
  }

  &__append {
    @apply tw-inline-flex tw-items-center tw-justify-center;
  }

  &__name + &__item--active &__append,
  &__substep-indicator-wrapper + &__item--active &__append {
    @apply tw-ml-1;
  }

  // ---------- Modifiers

  @include steps-nav-variant('default', 'neutral');
  @include steps-nav-variant('primary', 'primary');
  @include steps-nav-variant('secondary', 'secondary');

  &__item--completed &__content {
    @apply tw-cursor-pointer;
  }

  &__item--completed &__prepend {
    opacity: 0.7;
  }

  &__item--active &__prepend {
    @apply tw-opacity-100;
  }

  &--circle &__item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  // ----- States

  &--focus {
    @include gik-focus-shadow;
  }

  &--hidden {
    display: none;
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }
  &--disabled &__content {
    @apply tw-cursor-not-allowed;
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs;
  }

  &--size-sm {
    @apply tw-text-sm;
  }

  &--size-base {
    @apply tw-text-base;
  }

  &--size-lg {
    @apply tw-text-lg;
  }

  &--size-xl {
    @apply tw-text-xl;
  }
}
