$blockName: '#{$gik-prefix}box';

.#{$blockName} {
  @apply tw-font-normal tw-text-lg tw-leading-normal tw-shadow-2xl tw-relative;

  > header {
    @apply tw-px-4 tw-pt-2 tw-pb-1 tw-font-semibold tw-border-b tw-border-neutral-200;
  }

  > main {
    @apply tw-px-4 tw-py-2;
  }

  &--padded {
    @apply tw-p-8;
  }

  &--rounded-base {
    @apply tw-rounded;
  }
  &--rounded-sm {
    @apply tw-rounded-sm;
  }
  &--rounded-md {
    @apply tw-rounded-md;
  }
  &--rounded-lg {
    @apply tw-rounded-lg;
  }

  &--default {
    @apply tw-bg-neutral-100;
  }

  &--primary {
    @apply tw-text-neutral-50;
    @include gradient-aqua();
  }

  &--secondary {
    @apply tw-text-neutral-50;
    @include gradient-warm();
  }

  &--love {
    @apply tw-text-neutral-50;
    @include gradient-love();
  }

  &--white {
    @apply tw-text-neutral-900 tw-bg-white;
  }

  &--white-blur {
    @apply tw-text-neutral-900 tw-relative tw-shadow-none tw-rounded-md;
    &:before {
      @apply tw-overflow-hidden;
      content: '';
      @apply tw-absolute tw-inset-0 tw-rounded-md;

      box-shadow: 0px -1px 3px 1px rgba(59, 65, 66, 0.07), 0px 5px 10px -3px rgba(59, 65, 66, 0.07),
        0px 10px 17px -3px rgba(59, 65, 66, 0.1);

      // https://www.figma.com/file/AOLkkX93J6dRL18v4SLLRZ/%F0%9F%8F%9A-Home-Page?node-id=1%3A3
      // NOTE: Figma has the background opacity set to .7 but .5 seems to achieve
      /// a more similar result when viewed in the browser
      background: rgba(255, 255, 255, 0.85);
      // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      // NOTE: Figma has this set to 20px but in results in too much blur in the browser
      // 10 px achieves a more similar results as figma
      // backdrop-filter: blur(10px);
      // }
    }
  }

  &--decoration-foliage-small-green {
    @apply tw-overflow-hidden;

    &::before {
      content: '';
      width: 86px;
      height: 109px;
      background: url($public-root + 'marketing/sideFoliageR2-2x.png');
      background-size: contain;
      position: absolute;
      opacity: 0.5;
      top: -22px;
      right: -28px;
      transform: rotate(-145.73deg);
    }
    &::after {
      content: '';
      width: 80px;
      height: 95px;
      background: url($public-root + 'marketing/sideFoliageR2-2x.png');
      background-size: contain;
      position: absolute;
      opacity: 0.5;
      bottom: -9px;
      left: -4px;
      transform: rotate(22.12deg);
    }

    @screen md {
      &::before {
        content: '';
        width: 103px;
        height: 125px;
        top: -22px;
        right: -16px;
        transform: rotate(-145.73deg);
      }
      &::after {
        content: '';
        width: 98px;
        height: 125px;
        bottom: -9px;
        left: -18px;
        transform: rotate(32.73deg);
      }
    }
  }
}
