+b(feature-checkbox-card)
  /* TODO: find a better way to apply this styling */
  &.gik-features-section__giftbox
    .gik-privacy-settings-card__icon
      @apply tw-text-danger-500
  /* TODO: find a better way to apply this styling */
  &.gik-features-section__suggested-articles
    .gik-privacy-settings-card__icon
      @apply tw-text-info-500
  /* TODO: find a better way to apply this styling */
  &.gik-features-section__suggested-products
    .gik-privacy-settings-card__icon
      @apply tw-text-purple-500

  /* TODO: find a better way to apply this styling */
  &.gik-features-section__carecard
    .gik-privacy-settings-card__icon
      @apply tw-text-success-500

  /* TODO: find a better way to apply this styling */
  &.gik-features-section__messages
    .gik-privacy-settings-card__icon
      @apply tw-text-secondary-500

  @apply tw-w-full tw-py-0
  @apply tw-my-0 #{!important}

  // override PrivacySettingsCard styles
  +b(privacy-settings-card)
    +e(card)
      @apply tw-py-0 tw-px-4 tw-rounded-md tw-bg-transparent
      min-height: 128px
      border: 0

    +e(title)
      @apply tw-mb-2 tw-text-xl

    +e(icon)
      @apply tw-w-10 tw-h-10

    +e(description)
      @apply tw-text-neutral-900 tw-leading-normal
      max-width: 346px

    +e(checkbox)
      @apply tw-self-center

  +m(disabled)
    .gik-privacy-settings-card__icon, .gik-privacy-settings-card__description, .gik-privacy-settings-card__prepend
      @apply tw-opacity-50

  // consistent horizontal spacings with features cards
  @screen md
    .gik-privacy-settings-card__card
      @apply tw-pl-6 tw-pr-12
    .gik-privacy-settings-card__text
      @apply tw-mr-3
    .gik-privacy-settings-card__description
      @apply tw-text-base tw-leading-tight
