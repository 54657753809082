+b(timeline)
  @apply tw-relative

  +e(line)
   @apply tw-bg-neutral-300 tw-absolute tw-top-0 tw-bottom-0
   left: 14px
   bottom: 5px
   width: 1px

  +e(icon)
    @extend .gik-circle
    @apply tw-mr-3 tw-shadow-sm tw-z-10
    background: #fff
    width: 28px
    height: 28px
    svg, img
      width: 20px
      height: 20px
  +e(item)
    @apply tw-flex tw-leading-none tw-mb-3
  +e(item-content)
    @apply tw-mt-1 tw-flex-1
  +e(item-title)
    @apply tw-block
  +e(item-date)
    @apply tw-block tw-text-neutral-700 tw-text-xs
  +e(pending-fulfillment)
    @apply tw-inline-flex tw-items-center
    strong
      @apply tw-text-neutral-700
    svg
      @apply tw-text-neutral-500

  .gik-svg-icon--donation
    @apply tw-text-success-500

  .gik-svg-icon--calendar
    @apply tw-text-primary-500

  .gik-svg-icon--follow, .gik-svg-icon--thank-you
    @apply tw-text-danger-500

  .gik-svg-icon--purchase-tango-card
    @apply tw-text-secondary-500

  .gik-svg-icon--purchase-giftbox
    @apply tw-text-purple-500

  .gik-svg-icon--pending
    @apply tw-text-neutral-500

  .gik-svg-icon--purchase-gik-premium
    @apply tw-text-warn-500

  &__purchase-wrapper
    @apply tw-flex tw-items-center

  &__resend-wrapper
    @apply tw-flex tw-flex-1 tw-justify-end
    svg
      @apply tw-text-white

  &__btn-resend.gik-button
    @apply tw-px-2
    svg
      @apply tw-w-4 tw-h-4

  // &__purchase-text
  //   @apply tw-flex-1


.gik-image-icon-strikeout
  @apply tw-relative

  &::after
    @apply tw-absolute

    content: ''
    width: 26px
    height: 2px
    @apply tw-bg-danger-500 tw-rounded tw-absolute tw-z-10
    top: 9px
    left: -2px
    transform: rotate(-45deg)
  img
    @apply tw-opacity-50
