@use "sass:math"

@mixin indicator-variant($name, $activePalette: $name, $inactivePalette: $activePalette)
  +m($name, step)
    @apply tw-bg-#{ $inactivePalette }-500
    opacity: .5
    +m(active)
      @apply tw-bg-#{ $activePalette }-500
      opacity: 1

@mixin indicator-size($name, $size, $margin: $size)
  +m($name)
    @apply tw-my-#{ $margin } tw-gap-#{math.ceil($size + math.div($size, 2))}
  +m($name, step)
    @apply tw-w-#{ $size } tw-h-#{ $size }

+b(indicator)
  @apply tw-flex tw-flex-row tw-justify-around tw-items-stretch
  align-content: stretch

  +e(step)
    @apply tw-rounded-full

  @include indicator-variant(default, neutral)
  @include indicator-variant(primary)
  @include indicator-variant(secondary)
  @include indicator-variant(new-default, neutral)
  @include indicator-variant(primary-default, primary, neutral)
  @include indicator-variant(secondary-default, secondary, neutral)

  @include indicator-size(xs, 1, 0)
  @include indicator-size(sm, 1)
  @include indicator-size(base, 2)
  @include indicator-size(lg, 3)
  @include indicator-size(xl, 4)
  @include indicator-size(2xl, 6)
  @include indicator-size(3xl, 8)
