$blockName: '#{$gik-prefix}input';

@mixin input-variant($name, $paletteName, $textColor) {
  &--#{$name} &__prepend {
    @apply tw-bg-#{$paletteName}-500;
    color: $textColor;
  }

  // &--#{$name} &__append {
  //   @apply tw-bg-#{$paletteName}-500;
  //   color: $textColor;
  // }
}

.#{$blockName},
.gik-geosuggest {
  --color: theme('colors.black');
  --bg: theme('colors.white');
  --alt-color: theme('colors.black');
  --alt-bg: theme('colors.neutral.500');

  // ---------- Block

  // border
  @apply tw-rounded-sm;
  // colors
  @apply tw-text-white tw-bg-white;
  // other
  @apply tw-flex;

  // ---------- Elements

  &__wrapper,
  .geosuggest__input-wrapper {
    @apply tw-relative tw-rounded-sm tw-flex;
  }

  &__input {
    @apply tw-h-full tw-box-border;
    // colors
    @apply tw-text-neutral-900 tw-bg-white;
    // padding
    @apply tw-px-2 tw-py-1;
    // other
    @apply tw-w-full tw-flex-1 tw-outline-none tw-max-w-full tw-overflow-hidden tw-break-normal;

    &:disabled {
      @apply tw-bg-white tw-cursor-not-allowed;
      opacity: 0.6;
    }

    &:-webkit-autofill {
      @extend .gik-autofill;
    }

    &::placeholder {
      @extend .gik-placeholder;
    }

    transition: width 200ms ease-out;
  }

  &__input-wrapper,
  .geosuggest__input-wrapper {
    // border
    @apply tw-flex tw-flex-1 tw-border tw-border-neutral-400 tw-rounded-sm;
  }

  &--plain &__input-wrapper {
    @apply tw-border-none;
  }

  &__prepend-wrapper {
    @apply tw-flex;
  }
  &__prepend {
    // padding
    @apply tw-px-1 tw-py-1;
    // other
    @apply tw-flex tw-items-center;

    // only the last prepend block should have some extra padding on the right
    &:last-of-type {
      @apply tw-pr-2;
    }
    // only the first prepend block should be rounded and have some extra padding on the left
    &:first-of-type {
      @apply tw-rounded-l-sm tw-pl-2;
    }

    &--currency {
      @apply tw-w-8 tw-justify-center;
    }
  }

  &__append-wrapper {
    @apply tw-flex;
  }
  &__append {
    // padding
    @apply tw-px-1 tw-py-1;
    // other
    @apply tw-flex tw-items-center tw-bg-transparent;

    // only the last append block should be rounded and have some extra padding on the right
    &:last-of-type {
      @apply tw-rounded-r-sm tw-pr-2;
    }
    // only the first append block should have some extra padding on the right
    &:first-of-type {
      @apply tw-pl-2;
    }

    &--currency {
      @apply tw-w-8 tw-justify-center;
    }
  }

  &__clear {
    @apply tw-absolute tw-inset-0 tw-left-auto tw-cursor-pointer;
    @apply tw-flex tw-items-center tw-pr-3 tw-text-neutral-700 tw-hidden;
  }

  &__max-length {
    @extend .gik-text-muted;
    @apply tw-absolute tw-opacity-0;
    transition: opacity 300ms ease-out;
    right: 10px;
    top: 2px;
    text-align: right;
    font-size: 0.8em;
  }

  &--max-length-visible &__max-length {
    @apply tw-opacity-100;
  }

  // maxlength error should turn the maxLength indicator to red
  &__max-length--warning,
  &__max-length--error {
    @apply tw-text-danger-500;
  }

  // ---------- Modifiers

  &--collapsable {
    @apply tw-inline-flex tw-cursor-pointer;
  }

  &--collapsable &__input {
    @apply tw-w-0 tw-max-w-0 tw-p-0 tw-hidden;
  }

  &--hidden {
    @apply tw-hidden;
  }

  // if max length is used there should be more padding on the right
  // to prevent the text from overlowing with the maxLength indicator
  &--has-max-length &__input {
    padding-right: 60px;
  }

  &--max-length-centered &__max-length {
    bottom: unset;
    top: 50%;
    right: 10px;
    height: 20px;
    transform: translateY(-10px);
  }

  // an input that comes after prepend block should not have borders on the left
  // &--has-prepend &__input {
  //   @apply tw-rounded-l-none tw-border-l-0;
  // }

  // // an input that comes before an append block should not have borders on the right
  // &--has-append &__input {
  //   @apply tw-rounded-r-none tw-border-r-0;
  // }

  // input should have a danger border if it has an error
  &--has-error &__input {
    @apply tw-border-danger-500 tw-text-danger-500;
    border-width: 2px;
    padding: 3px 7px;
  }

  // input should have a warning border if it has a warning
  &--has-warning &__input {
    @apply tw-border-warn-500;
  }

  // input should have a warning border if it has a warning
  &--has-success &__input {
    @apply tw-border-success-500;
  }

  // ----- Variants

  @include input-variant('default', 'neutral', theme('colors.black'));
  @include input-variant('primary', 'primary', theme('colors.white'));
  @include input-variant('secondary', 'secondary', theme('colors.white'));

  &--lightgrey &__input-wrapper {
    @apply tw-bg-neutral-100 tw-border-none;
  }
  &--lightgrey &__input {
    @apply tw-bg-transparent;
  }
  &--lightgrey &__icon {
    @apply tw-text-neutral-700;
  }

  &--verylightgrey &__input-wrapper {
    @apply tw-bg-neutral-100 tw-border-none;
  }
  &--verylightgrey &__input {
    @apply tw-bg-transparent;
  }

  &--default-solid &__input {
    --bg: theme('colors.neutral.100');
    @apply tw-bg-transparent;
  }

  &--default-solid &__input-wrapper {
    @apply tw-bg-neutral-100;

    @apply tw-border-neutral-100;
  }

  &--transparent &__input {
    @apply tw-border-neutral-100;
  }

  // ----- States

  &--focus {
    @apply tw-ring-2 tw-ring-offset-2;
  }

  &--has-value {
    .gik-input__clear {
      @apply tw-flex;
    }
  }

  &--disabled {
    @apply tw-cursor-not-allowed;
    opacity: 0.6;
  }

  // ----- Shapes

  &--pill &__input-wrapper {
    @apply tw-rounded-full;
  }
  &--pill:not(&--has-prepend):not(&--has-append) &__input {
    @apply tw-rounded-full;
  }
  &--pill &__prepend {
    @apply tw-rounded-l-full;
  }
  &--pill &__append {
    @apply tw-rounded-r-full;
  }

  &--pill &__append-wrapper {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  &--pill &__prepend-wrapper {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  &--pill {
    border-radius: 100rem;
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs;
  }
  &--size-xs &__input::placeholder {
    @apply tw-text-xs;
  }
  &--size-xs &__append {
    padding: 0.3rem;
  }

  &--size-sm {
    @apply tw-text-sm;
  }
  &--size-sm &__input::placeholder {
    @apply tw-text-sm;
  }
  &--size-sm &__append {
    padding: 0.3rem;
  }

  &--size-base {
    @apply tw-text-base;
    height: $ui-height;
  }
  &--size-base &__input::placeholder {
    @apply tw-text-base;
  }
  &--size-base &__append {
    padding: 0.5rem;
  }

  &--size-lg {
    @apply tw-text-lg;
  }
  &--size-lg &__input::placeholder {
    @apply tw-text-lg;
  }
  &--size-lg &__append {
    padding: 0.6rem;
  }

  &--size-xl {
    @apply tw-text-xl;
  }
  &--size-xl &__input::placeholder {
    @apply tw-text-xl;
  }
  &--size-xl &__append {
    padding: 0.7rem;
  }

  &--size-2xl {
    @apply tw-text-2xl;
  }
  &--size-2xl &__input::placeholder {
    @apply tw-text-2xl;
  }
  &--size-2xl &__append {
    padding: 0.8rem;
  }

  // ----- Formats

  // prepend and append blocks for the currency input should have a min width and centered content
  &-currency &__prepend,
  &-currency &__append {
    @apply tw-min-w-8 tw-justify-center;
  }
}

// disable native browser up/down buttons for type="number" input fields
// works for Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// force a numeric keyboard input on mobile for currency inputs
.gik-input-currency .gik-input__input {
  -wap-input-format: 'N';
}
