$blockName: '#{$gik-prefix}inkind-uses-card';

.#{$blockName} {
  --width: theme('width.56');
  --height: theme('width.56');

  @apply tw-relative tw-cursor-pointer tw-rounded-md;

  // full-width image with squared aspect ratio
  width: 100%;
  padding-bottom: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__overlay {
    @apply tw-absolute tw-inset-0 tw-rounded-md tw-bg-neutral-900 tw-text-neutral-50 tw-text-center tw-flex tw-flex-col;
    @apply tw-px-6 tw-py-4;
    --bg-opacity: 0.9;
    opacity: 0;
    transition: opacity 300ms ease-out;

    @screen md {
      @apply tw-px-8 tw-py-4;
    }

    > svg {
      @apply tw-mx-auto tw-flex-shrink-0;
    }
  }

  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-tight tw-block tw-mb-4;
    @include lineClamp(2);

    @screen xl {
      @apply tw-text-3xl tw-mb-6;
    }
  }

  &__description {
    @apply tw-font-normal tw-text-base tw-leading-normal;
    @include lineClamp(3);
  }

  &__overlay-content {
    @apply tw-flex-1;
  }

  &--display-empty {
    @apply tw-bg-neutral-100;
  }

  &--hover &__overlay,
  &:hover &__overlay {
    opacity: 1;
  }
}
