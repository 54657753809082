+b(inkind-group-widget)
  @apply tw-shadow-xl tw-rounded-md tw-bg-white

  &__header
    @apply tw-rounded-t-md tw-p-3 tw-shadow-base
    background-color: var(--background)
    background-image: var(--background-image)
    background-size: cover
    background-repeat: no-repeat
    background-position: center

  &--has-gradient &__header
    background: linear-gradient(225deg, var(--gradient-color-1) -0.2%, var(--gradient-color-2) 99.8%)

  &__logo
    height: 36px

  &__description
    font-size: 0.95rem

  &__main
    @apply tw-p-4 tw-pt-3
    p
      margin: 0

  &__disabled-warning
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-danger-500 tw-text-center
    @apply tw-mx-auto #{!important}
    max-width: 240px

  &--muted &__main
    @apply tw-opacity-30

  +b(inkind-group-widget-grid)
    @apply tw-gap-1 tw-mt-2

+b(inkind-group-widget-tile)
  @apply tw-flex tw-items-center tw-text-base

  &__title
    @apply tw-text-base

  &__image
    width: 48px
    height: 48px

  &__header
    @apply tw-mr-2
    height: 48px

  &__content
    @apply tw-font-bold tw-text-sm

  &__link-preview
    @apply tw-font-normal tw-text-base tw-leading-none tw-text-gray-400
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    max-width: 215px
