+b(user-consent-modal)
  +b(modal__content-wrapper)
    .content
      @apply tw-pt-0 #{!important}

  +b(modal-header)
    @apply tw-py-2
    &__title
      @apply tw-text-lg #{!important}
      @screen md
        @apply tw-text-left #{!important}

  &__wrapper
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

    @screen md
      @apply tw-gap-8
      @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
      align-content: stretch

  &__copy
    @apply tw-flex-1 tw-text-sm
    @screen md
      @apply tw-text-left

  &__buttons
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-gap-2 tw-w-full

    @screen md-down
      +b(button)
        @apply tw-flex-1

    @screen md
      @apply tw-flex tw-flex-col-reverse tw-justify-center tw-items-stretch
      align-content: stretch
      flex-basis: 300px
