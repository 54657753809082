+b(inkind-page-privacy-badge)
  @apply tw-cursor-pointer
  +e(settings)
    @apply tw-ml-2

+b(inkind-page-privacy-badges)
  @apply tw-flex tw-flex-row tw-justify-end tw-items-stretch tw-mr-10
  @screen md
    @apply tw-mb-2
  align-content: stretch
  +b(circle-bone)
    width: 40px
    height: 40px
    margin-left: 0.75rem
