$blockName: '#{$gik-prefix}why-support-matters-section';

.#{$blockName} {
  &__title {
    @apply tw-text-center tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-text-purple-600 tw-mb-16;
  }
  &__description {
    @apply tw-mx-auto tw-font-normal tw-text-lg tw-leading-loose tw-text-justify;
    max-width: 545px;
  }

  @screen sm {
    background-image: url($public-root + 'marketing/benefits/why-BG.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 524px;
  }
}
