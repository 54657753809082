+b(arrow-button)
  +e(next prev)
    @apply tw-text-neutral-700 tw-bg-white tw-items-center tw-justify-center tw-shadow-base
    @apply tw-absolute tw-z-10 tw-select-none tw-flex
    border-radius: 50%

    top: calc(50% - 18px)
    width: 28px
    height: 28px

    transition: background-color 0.2s ease-out

    > svg
      @apply tw-overflow-visible

    &:not(.disabled)
      @apply tw-cursor-pointer

    &:hover:not(.disabled)
      @apply tw-bg-neutral-300

    &.disabled
      opacity: 0.5

    &--size-lg
      width: 46px
      height: 46px
      top: calc(50% - 23px)
  +e(next)
    @apply tw-right-0
    > svg
      margin-right: -2px
  +e(prev)
    @apply tw-left-0
    > svg
      margin-right: 2px
