+b(thank-you-card)
  // min-height: 530px
  width: 340px
  @apply tw-text-neutral-900

  &::before
    content: ''
    @apply tw-absolute tw-inset-0 tw-bg-no-repeat
    background-image: url($public-root + 'messaging/thank-you/leaf-left.svg')
    width: 214px
    height: 300px
    mix-blend-mode: color-dodge

  &::after
    content: ''
    @apply tw-absolute tw-inset-0 tw-left-auto tw-bg-no-repeat
    background-image: url($public-root + 'messaging/thank-you/leaf-right.svg')
    top:23px
    width: 214px
    height: 300px
    mix-blend-mode: color-dodge

  &__header
    height: 262px


  &__main
    @apply tw-px-7 tw-font-bold tw-text-lg tw-whitespace-pre-wrap tw-pb-14
    line-height: 120% // 22px

  &--variant-aqua
    background: #0EB5DA
    @apply tw-text-white

  &--variant-aqua &__header
    background: linear-gradient(0deg, #0EB5DA 0%, #41CBCE 33.3%, #41CBCE 64.48%, #03D0C4 100%)

  &--variant-grey
    background: #86909A
    @apply tw-text-white

  &--variant-grey &__header
    background: linear-gradient(0.49deg, #86909A 0.84%, #9B9EA9 34.25%, #9B9EA9 66.27%, #B8C0CE 100%)

  &--variant-yellow
    background: #EAA51F
    @apply tw-text-white

  &--variant-yellow &__header
    background: linear-gradient(0deg, #EAA51F 0%, #FFC700 45.31%, #FFFA85 100%)

  &--variant-pink
    background: #d53f7e
    @apply tw-text-white

  &--variant-pink &__header
    @apply tw-pointer-events-none tw-select-none
    background: linear-gradient(0deg, #D53F7E 0.19%, #EF5C84 33.36%, #EF5C84 66.7%, #FF5E49 100.19%)

  &--variant-orange
    background: #E66924
    @apply tw-text-white

  &--variant-orange &__header
    @apply tw-pointer-events-none tw-select-none
    background: linear-gradient(0.05deg, #E66924 0.08%, #F5893D 31.67%, #F5893D 68.62%, #FFA800 100%)

  &--variant-green
    background: #67AC4F
    @apply tw-text-white

  &--variant-green &__header
    @apply tw-pointer-events-none tw-select-none
    background: linear-gradient(180deg, #B1D682 0.05%, #8FBB6A 33.39%, #8FBB6A 68.28%, #67AC4F 100.05%)

  &--variant-blue
    background: #4E7AEA
    @apply tw-text-white

  &--variant-blue &__header
    @apply tw-pointer-events-none tw-select-none
    background: linear-gradient(0deg, #4E7AEA 0%, #5F93F3 30.98%, #5F93F3 68.27%, #3ABDF5 100%)

  &--variant-purple
    background: #694EB5
    @apply tw-text-white

  &--variant-purple &__header
    @apply tw-pointer-events-none tw-select-none
    background: linear-gradient(359.56deg, #694EB5 0%, #956CC3 33.44%, #956CC3 65.97%, #C880DE 99.24%)

  &.gik-box--rounded-md &__header
    @apply tw-rounded-t-md
