+b(invite-group-members-form)
  &__main
    @apply tw-flex-1

  &__value-container
    min-height: 48px
    @screen md
      min-height: unset

  .gik-select
    width: 110px
