+b(give-footer)


  min-height: 300px


  @screen md-down
    --background-position: 50% 100%
    --background-size: 575px


  @screen md
    --background-position: calc(50% + 340px) 0%
    --background-size: 831px
    &.gik-page-section--decoration-flutter.gik-page-section--decoration-right::after
      left: auto
      right: 20px

  @screen lg
    --background-position: calc(50% + 220px) 0%

  @screen xl
    --background-position: calc(50% + 140px) 0%
    &.gik-page-section--decoration-flutter.gik-page-section--decoration-right::after
      left: calc(50% + 520px)



  @screen md
    min-height: 354px

  +e(copy)
    @apply tw-text-left tw-pt-12
    max-width: 432px


  +e(image)
    left: -191px
    position: relative
    z-index: -1

  +e(title)
    @apply tw-text-4xl tw-font-semibold tw-font-heading tw-tracking-normal
    @apply tw-leading-none

  +e(content)
    @apply tw-text-lg tw-font-body tw-leading-normal tw-mt-2 tw-mb-4 tw-tracking-normal
    text-align: center

  @screen md

    +e(animation-container)
      flex: 1 1 617px

  @screen md-down
    @apply tw-px-0
    padding-bottom: 50px

    +e(content)
      padding-bottom: 170px
      @apply tw-px-4

    +e(title)
      max-width: 300px
      @apply tw-mx-auto

    +e(copy)
      @apply tw-text-center tw-mb-6 tw-mx-auto
      flex: unset

    +b(container)
      @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
      background-image: url(#{$public-root + 'shop/create-cta/desktop/create-cta.png'})
