$blockName: '#{$gik-prefix}common-questions-page';

.#{$blockName} {
  .gik-accordion {
    @apply tw-mx-auto;
    max-width: 41rem;
  }
  .gik-accordion-item {
    @apply tw-whitespace-pre-line;
  }
}
