+b(situations-nav)
  @apply tw-overflow-y-hidden tw-relative

  +b(grid)
    @apply tw-flex tw-flex-wrap tw-justify-start tw-justify-center
    @apply tw-gap-1 tw-gap-y-2

    > *
      @apply tw-flex-shrink-0

    @screen sm
      @apply tw-gap-2 tw-gap-y-4

  &__item
    @apply tw-border-3 tw-border-transparent
    @apply tw-uppercase tw-font-heading tw-text-base tw-font-semibold
    --color: theme('colors.neutral.800')

  &__item--selected
    @apply tw-border-primary-500

  &__item--all
    --bg: theme('colors.neutral.50')
  &__item--primary
    --bg: theme('colors.primary.50')
  &__item--secondary
    --bg: theme('colors.secondary.50')
  &__item--danger
    --bg: theme('colors.danger.50')
  &__item--success
    --bg: theme('colors.success.50')
  &__item--warning
    --bg: theme('colors.warn.50')

  +b(image)
    @apply tw-w-6 tw-h-6 tw-mr-2

  +b(badge)
    @apply tw-cursor-pointer
  +b(horizontal-nav)
    @apply tw-mx-10
    height: 58px

  +b(arrow-button)
    &__next, &__prev
      top: calc(50% - 14px - 11px)
