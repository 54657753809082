$blockName: '#{$gik-prefix}form-group';

.#{$blockName} {
  @apply tw-flex tw-items-start tw-text-left;

  // ---------- Elements

  &__label-wrapper {
    @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch;
    align-content: stretch;
  }

  &__label {
    @apply tw-text-right tw-mr-5 tw-text-sm tw-inline-block tw-text-neutral-700 tw-font-bold;
    font-family: theme('fontFamily.body');
    // vertical centering seems to be off, need to adjust a couple of pixels
    margin-top: 10px;
    min-width: 100px;
  }

  &--textarea {
    // disable vertical centering
    @apply tw-items-start;
    .gik-form-group__label {
      // apply a top margin that matches a normal Input component even when
      // the textarea is multiline
      margin-top: 6px;
    }
    flex: 1;
  }

  &--highlight {
    @apply tw-p-2 tw-rounded tw-bg-neutral-100;
  }

  &__content-wrapper {
    @apply tw-flex-1;
  }

  &__help {
    @apply tw-block tw-text-xs tw-text-neutral-600;
  }
  &__field-extra {
    @apply tw-block;
  }

  &__error {
    @apply tw-text-danger-900 tw-text-sm tw-mt-1 tw-italic;
    margin-top: 3px;
  }

  // ---------- Modifiers

  &--variant-new-default &__label {
    @apply tw-font-body tw-font-bold tw-uppercase tw-text-neutral-900 tw-text-base;
  }

  &--vertical {
    @apply tw-block;
    .gik-form-group__label {
      @apply tw-mr-0 tw-mt-0 tw-mb-1 tw-text-left;
      min-width: unset;
    }
  }

  &--center {
    @apply tw-text-center;
  }

  &--optional &__label {
    @apply tw-text-neutral-900;
  }

  &--optional &__optional-indicator {
    @apply tw-text-neutral-700;
  }

  &--inline {
    @apply tw-inline-flex;
    .gik-form-group__label {
      @apply tw-mt-0;
      min-width: unset;
    }
  }

  &--inline &__content-wrapper {
    @apply tw-inline-block;
  }

  &--inline.gik-form-group--vertical {
    @apply tw-inline-block;
  }

  &--hidden {
    @apply tw-hidden;
  }

  @include b(savable-controls) {
    @apply tw-mt-1;
  }

  &--saving {
    @include b(savable-controls) {
      @apply tw-pointer-events-none tw-opacity-50;
    }
  }
}
