$blockName: '#{$gik-prefix}competitors-page';

.#{$blockName} {
  &__lead {
    @apply tw-pt-8;
  }
}

// TODO move this to it's own component
.gik-competitors-comparison-section {
  @apply tw-pt-8 tw-overflow-auto;

  @screen sm-down {
    @apply tw-px-0;
  }
}
