
.gik-text-shadow
  text-shadow: 0 2px 4px rgba(0,0,0,0.10)

.gik-text-shadow-md
  text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08)

.gik-text-shadow-lg
  text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08)

.gik-text-shadow-none
  text-shadow: none
