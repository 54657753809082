.#{$gik-prefix}product-list {
  @apply tw-mb-2 tw-rounded-md tw-p-4 tw-shadow-base;

  > header {
    @apply tw-flex tw-justify-between tw-text-sm tw-mb-2;

    > div {
      @apply tw-whitespace-nowrap tw-text-neutral-700 tw-inline-flex tw-items-center;
      svg {
        @apply tw-inline-block tw-mr-1;
      }
    }
  }

  > main {
    @apply tw-flex-1 tw-min-w-32 tw-flex tw-gap-2 tw-items-center;
  }

  &__error {
    @apply tw-text-danger-500 tw-bg-neutral-100 tw-rounded tw-py-2 tw-px-4 tw-mt-2;
  }

  &__toolbar {
    @apply tw-border-l tw-border-neutral-200;
  }

  &__anon {
    @apply tw-whitespace-nowrap;
    svg {
      @apply tw-mr-2;
    }
  }

  &__date {
    @apply tw-whitespace-nowrap tw-font-bold;
    line-height: 15px;
    > div {
      @apply tw-flex tw-items-center;
      > span {
        margin-top: 2px;
      }
    }
  }

  &__wishlist {
    @apply tw-whitespace-nowrap tw-font-bold tw-flex tw-items-center;
    line-height: 15px;
    > span {
      margin-top: 1px;
    }
  }

  &__date-new {
    // @apply tw-cursor-pointer;
  }

  &__date-new--matchingConflict {
    @apply tw-text-success-500;
  }

  &__date-new--noAvailableDate {
    @apply tw-text-danger-500 tw-line-through;
  }

  &__date-overwritten {
    @apply tw-text-danger-500 tw-mr-1 tw-line-through;
  }

  figure {
    @apply tw-flex tw-items-start tw-justify-center tw-bg-transparent;
    width: 100px;
    // height: 63px;
    img {
      @apply tw-rounded-sm;
    }
  }

  .gik-button {
    @apply tw-px-1;
  }

  &__title {
    @apply tw-block tw-font-bold tw-flex-1;
  }

  &__price {
    @apply tw-font-bold;
  }

  &__empty {
    @apply tw-rounded tw-bg-neutral-100 tw-px-6 tw-py-2 tw-text-neutral-700 tw-font-bold tw-text-center tw-items-center tw-flex tw-justify-center;
    min-height: 103px;
  }

  &__btn-remove svg {
    @apply tw-text-danger-500;
  }

  .gik-contact-page-organizers-for-address--centered {
    @apply tw-mt-4;
  }
}

.#{$gik-prefix}product-list-skeleton {
  &__item {
    @apply tw-flex tw-mb-2;
  }

  &__image {
    @apply tw-mr-4 tw-flex-shrink-0;
    width: 150px;
    height: 95px;
  }
  &__title {
    @apply tw-mb-2;
  }
}
