@import AppHeaderNavBar
@import AppHeaderLogo
@import AppHeaderNav
@import AppHeaderSignInButton
@import AppHeaderNavMobile
@import AppHeaderNavMobileDrawer

.gik-sticky.app-header-sticky
  @apply tw-z-appNav tw-fixed
  transition: margin 300ms ease-in-out
  &--unbounce
    margin-top: 52px !important

