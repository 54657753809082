@use 'sass:list';

@mixin importGoogleWebFont($family, $weights: 400) {
  $_weights: '';

  @for $i from 1 through length($weights) {
    $weight: nth($weights, $i);

    @if ($i == length($weights)) {
      $_weights: $_weights + $weight;
    } @else {
      $_weights: $_weights + $weight + ';';
    }
  }

  @import url('https://fonts.googleapis.com/css2?family=' + $family + ':wght@' + $_weights + '&display=swap');
}

@include importGoogleWebFont(Dosis, 200 300 400 500 600 700 800);
@include importGoogleWebFont(Roboto, 200 300 400 500 600 700 800);

.text-rendering {
  text-rendering: geometricPrecision;
}

@mixin print-preset($name, $family, $weight, $size) {
  .#{$gik-prefix}font-#{$name}-#{$size} {
    text-rendering: geometricPrecision;
    @apply #{$family};
    font-weight: $weight;
    font-size: $size;
    line-height: $size + 3px;
  }
}

@include print-preset('heading', 'tw-font-heading', 300, 75px); // h1
@include print-preset('heading', 'tw-font-heading', 400, 60px); // h2
@include print-preset('heading', 'tw-font-heading', 400, 50px); // h3
@include print-preset('heading', 'tw-font-heading', 400, 45px); // h4
@include print-preset('heading', 'tw-font-heading', 500, 35px); // h5
@include print-preset('heading', 'tw-font-heading', 500, 25px); // h6

@include print-preset('sub-heading', 'tw-font-body', 500, 75px);
@include print-preset('sub-heading', 'tw-font-body', 500, 60px);
@include print-preset('sub-heading', 'tw-font-body', 500, 50px);
@include print-preset('sub-heading', 'tw-font-body', 600, 45px);
@include print-preset('sub-heading', 'tw-font-body', 600, 35px);
@include print-preset('sub-heading', 'tw-font-body', 600, 25px);

@include print-preset('body', 'tw-font-body', 300, 24px);
@include print-preset('body', 'tw-font-body', 300, 20px);
@include print-preset('body', 'tw-font-body', 400, 18px);
@include print-preset('body', 'tw-font-body', 400, 16px);
@include print-preset('body', 'tw-font-body', 400, 14px);
@include print-preset('body', 'tw-font-body', 400, 12px);

@include print-preset('body-bold', 'tw-font-body', 600, 24px);
@include print-preset('body-bold', 'tw-font-body', 600, 20px);
@include print-preset('body-bold', 'tw-font-body', 600, 18px);
@include print-preset('body-bold', 'tw-font-body', 600, 16px);
@include print-preset('body-bold', 'tw-font-body', 600, 14px);
@include print-preset('body-bold', 'tw-font-body', 600, 12px);
