@mixin spinner-variant($name, $color)
  +m($name)
    .sk-bounce .sk-bounce-dot
      background-color: $color

+b(loading-spinner)

  +spinner-variant(primary, theme('colors.primary.500'))
  +spinner-variant(secondary, theme('colors.secondary.500'))
  +spinner-variant(default, white)

  +m(center)
    @apply tw-text-center tw-mx-auto

    // display the spinner as an inline-block so it can be centered
    > div
      @apply tw-inline-block
