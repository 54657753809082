+b(perfectgift-faceplate-preview)

  @apply tw-rounded-md tw-relative
  width: $productPageWidth
  height: 256px
  box-shadow: grey 0px 1px 11px -5px

  // background: url(https://res.cloudinary.com/gift-card-granny/image/upload/c_scale,f_auto,w_400/v1/PG/corp_card_designs/eu8epjw8egoxwod3male);
  background-size: 418px
  background-position-x: -7px
  background-position-y: -4px

  img
    @apply tw-rounded-md
    @apply tw-max-w-full
    min-width: $productPageWidth

  &__overlay
    @apply tw-inset-0 tw-absolute tw-z-10

  &__month-overlay

    @apply tw-inset-0 tw-absolute tw-flex tw-leading-tight
    @apply tw-uppercase tw-text-white
    left: 198px
    top: 176px

    letter-spacing: 1px
    text-shadow: rgb(0 0 0) 0px 0px 2px
    transform: scale(1, 1.3)
    font-size: 13px

    text-shadow: 0px 0px 1px #000, 0px 0px 1px #000, 0px 0px 1px #000, 0px 0px 1px #000

  &__custom-overlay

    @apply tw-inset-0 tw-absolute tw-flex
    @apply tw-uppercase tw-text-white tw-font-body
    left: 40px
    top: 203px
    line-height: 1.1


    letter-spacing: 1px
    text-shadow: rgb(0 0 0) 0px 0px 2px
    transform: scale(1, 1.3)
    font-size: 19px

    text-shadow: 0px 0px 1px #000, 0px 0px 1px #000, 0px 0px 1px #000, 0px 0px 1px #000

    @supports (-webkit-touch-callout: none)
      -webkit-text-size-adjust: 100%
      font-size: 14px !important

//+b(modal)
//  +b(perfectgift-faceplate-preview)
//    width: $productModalWidth
//    height: 179.2px
//
//    img
//      min-width: $productModalWidth
