
+b(calendar-bar)
  @apply tw-flex tw-items-center tw-bg-primary-500 tw-text-neutral-100 tw-rounded-t tw-overflow-hidden
  height: 40px // specifically set to 40px to contain the height

  +e(title)
    @apply tw-flex-1 tw-text-center tw-font-heading tw-uppercase tw-font-semibold tw-text-white

  +b(button)
    // no spacing between contents
    > * + *
      @apply tw-ml-0

    &.gik-button--primary
      --bg: theme('colors.primary.600')
      --bg-alt: theme('colors.primary.700')
      --bg-hover: theme('colors.primary.700')
      --bg-active: theme('colors.primary.800')
      --border-color: theme('colors.primary.600')

      &:first-of-type
        @apply tw-rounded-tl

      &:last-of-type
        @apply tw-rounded-tr

