$blockName: prefix(author-info)

.#{$blockName}
  @apply tw-flex tw-flex-row tw-justify-start tw-items-stretch
  align-content: stretch

  &__about
    @screen md
      @apply tw-pl-8
    @screen md-down
      @apply tw-pl-4

    @apply tw-flex tw-flex-col tw-justify-center tw-items-start tw-content-start

  &__about-title
    @apply tw-font-normal tw-text-base tw-leading-normal tw-text-primary-600 tw-m-0 tw-p-0

  &__author-name
    @apply tw-font-normal tw-leading-tight tw-m-0 tw-p-0 #{!important}
    @screen md
      @apply tw-text-4xl #{!important}
    @screen md-down
      @apply tw-text-2xl #{!important}

  &__author-role
    @apply tw-font-normal tw-text-xs tw-leading-loose tw-text-lgray-other tw-m-0 tw-p-0

  &__avatar-container
    @screen md
      @apply tw-max-h-40
    @screen md-down
      max-height: 7.5rem
