+b(express-checkout)
  $blockName: &

  +e(credit-cards-container)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-gap-2
    .gik-creditcards > *
      height: 28px

  +e(formgroup-title)
    @apply tw-text-center tw-text-base tw-font-bold

  +e(accordion-control)
    @apply tw-mt-2
    +b(button)
      +e(content)
        transition: transform .2s ease
    +m(expanded)
      +b(button)
        +e(content)
          transform: rotate(180deg)

  +e(separator-wrapper)
    @apply tw-mx-auto
    max-width: 160px

  +e(billing-form-group)
    @apply tw-cursor-pointer
    +m(expanded)
      @apply tw-cursor-default

  +m(no-express-checkout)
    #{$blockName}__separator-wrapper, #express-checkout-form-group
      display: none !important

apple-pay-modal
  @apply tw-z-max
