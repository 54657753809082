+b(supporters)
  @apply tw-block

  +e(page-organizer)
    @apply tw-text-secondary-600 tw-block tw-uppercase tw-truncate tw-overflow-hidden
  +e(supporter-name)
    @apply tw-block tw-text-base tw-truncate tw-overflow-hidden
  +e(supporter-content)
    @apply tw-overflow-hidden
  +e(supporter-cell)
    @apply tw-flex tw-items-center tw-font-bold tw-leading-none tw-truncate tw-overflow-hidden
    +b(avatar)
      @apply tw-mr-2 tw-flex-shrink-0 tw-cursor-pointer

  +e(invitee-email)
    @apply tw-block tw-text-base tw-truncate tw-overflow-hidden
  +e(invitee-content)
    @apply tw-overflow-hidden

  +e(invitee-cell)
    @apply tw-flex tw-items-center tw-font-bold tw-leading-none tw-truncate tw-overflow-hidden
    +b(avatar)
      @apply tw-mr-2 tw-flex-shrink-0

  &__global-filter-container
    @apply tw-flex
  &__global-filter-inner
    @apply tw-flex tw-gap-2


  &__header
    @apply tw-px-2
    @screen md
      @apply tw-px-0

  &__custom
    @apply tw-flex-1

  &__invitee-status
    @apply tw-text-neutral-700 tw-font-bold tw-uppercase

  &__invitee-date
    @apply tw-text-neutral-700 tw-font-normal

  &__invite-popover-menu
    @apply tw-text-neutral-800

  &__invite-popover-menu .gik-popover-menu-item__label
    @apply tw-font-ui tw-text-xl tw-font-semibold

  +e(empty-support)
    @apply tw-mb-2

  +e(empty)
    @apply tw-text-center tw-font-normal tw-leading-tight tw-text-base tw-mx-auto
    max-width: 360px

    p
      @apply tw-mt-0
  +e(empty-title)
    @apply tw-font-bold tw-text-xl
  +e(empty-share)
    @apply tw-mt-4
  +e(empty-image)
    @apply tw-mx-auto tw-mb-8

  +e(empty-filter)
    @apply tw-mb-3 tw-text-center

  +e(email)
    @apply tw-mb-3 tw-inline-block tw-flex tw-gap-2
    margin-left: -6px
    padding-left: 10px
    +b(copy-button)
      @apply tw-m-0 tw-px-2
      svg
        @apply tw-text-neutral-600 tw-w-4 tw-h-4

  &__email  &__btn-send-thanks
      @apply tw-m-0 tw-px-2
      --color: theme('colors.danger.500')
      svg
        @apply tw-w-4 tw-h-4
        path
          fill: theme('colors.danger.500')

  &__btn-remove-all-invitees
    svg
      @apply tw-text-danger-500

  .gik-tabbed-nav__item
    @screen md-down
      @apply tw-flex-1

  .gik-tabbed-nav__item--selected
    border-bottom-width: 5px

  .gik-horizontal-nav__scroll-container
    @screen md-down
      @apply tw-flex-1

  &__tab-invited
    @apply tw-hidden
    --color: theme('colors.neutral.900')
    --line-color: theme('colors.secondary.500')
  &__tab-invited &__icon
    color: theme('colors.secondary.500')
    width: 16px
    height: 14px
  &__tab-invited svg
    @apply tw-inline-block

  &--has-invites &__tab-invited
    @apply tw-block

  &__tab-following
    --color: theme('colors.neutral.900')
    --line-color: theme('colors.info.500')
  &__tab-following &__icon
    color: theme('colors.info.500')

  &__tab-following svg
    @apply tw-inline-block

  &__nav-item
    @apply tw-flex tw-items-center tw-gap-2 tw-text-base tw-justify-center

  &__nav
    @apply tw-border-b tw-flex

  +e(btn-invite)
    svg
      width: 16px
      height: 14px

  +e(btn-export)
    @apply tw-whitespace-nowrap

  +e(email-link)
    @apply tw-text-primary-600 tw-font-bold tw-mr-2
    &:hover
      @apply tw-text-primary-500

  +e(details-hitbox)
    @apply tw-absolute tw-inset-0

  +e(email-icon)
    @apply tw-mr-3 tw-text-neutral-600
    width: 20px
    height: 20px

  +e(details-wrapper)
    @apply tw-flex tw-items-center

  +e(history)
    @apply tw-pt-1
    padding-left: 11px

  // .gik-table__td-expanded
  //   @apply tw-mt-3
  //   margin-left: 11px

  &.gik-table--empty
    @apply tw-flex tw-flex-col



  &__btn-hstack
    @apply tw-h-full tw-items-center


  .gik-table__empty,
  .gik-table__empty-filter
    @apply tw-flex tw-flex-1 tw-flex-col

  .gik-table__table
    @apply tw-flex tw-flex-1 tw-flex-col
    tfoot
      @apply tw-flex tw-flex-1
      > tr
        @apply tw-flex tw-flex-1
        td
          @apply tw-flex-1

  &.gik-table--empty .gik-table__table
    @apply tw-flex-none

  .gik-table__tbody
    @apply tw-flex-1 tw-flex tw-flex-col

  .gik-table__tr
    @apply tw-relative

  .gik-table__td--supportGiven,
  .gik-table__th--supportGiven
    @apply tw-hidden

    // make the support icons float above the expand hitbox so the tooltips work
    > .gik-table__td-inner > span
      @apply tw-relative tw-z-10

    @screen md
      @apply tw-block


  .gik-table__th--expander
    @apply tw-text-right

  .gik-table__td--expander,
  .gik-table__td--supportGiven
    .gik-svg-icon--info-icon
      @apply tw-text-info-500
      margin-right: 3px
    .gik-table__td-inner
      @apply tw-flex tw-items-center tw-justify-end

  .gik-svg-icon--donation
    @apply tw-text-success-500

  .gik-svg-icon--calendar
    @apply tw-text-primary-500

  .gik-svg-icon--follow,
  .gik-svg-icon--unfollow,
  .gik-svg-icon--thankYou
    @apply tw-text-danger-500

  .gik-svg-icon--giftCard
    @apply tw-text-secondary-500

  .gik-svg-icon--giftBox
    @apply tw-text-purple-500

  .gik-svg-icon--pending
    @apply tw-text-neutral-500

  .gik-svg-icon--premium
    @apply tw-text-warn-500



  +e(help-icon)
    @apply tw-shadow-sm tw-z-10 tw-flex tw-items-center tw-justify-center
    border-radius: 50%
    &:not(:last-of-type)
      @apply tw--mr-1

    background: #fff
    width: 28px
    height: 28px
    svg
      width: 20px
      height: 20px

    @screen md
      &:not(:last-of-type)
        @apply tw-mr-1
