+b(inkind-page-header)
  @apply tw-w-full tw-flex tw-justify-center tw-items-center

  @screen md
    height: 254px

  +m(mod, el)

  &__bg-image-layer
    @apply tw-absolute tw-inset-0 tw-pointer-events-none tw-z-below tw-hidden
    background-color: rgba(59, 65, 66, 0.12)
    &--visible
      @apply tw-block

  +e(content)
    @apply tw-py-5 tw-flex tw-items-center tw-justify-center
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
      align-content: stretch

  +e(inner)
    $inner-width: 1140px
    $margin: 34px
    @apply tw-h-full tw-relative tw-max-w-full tw-rounded-b-md tw-overflow-hidden
    width: $inner-width + (2 * $margin)

  +e(main)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-start
    @screen md
      @apply tw-flex-row tw-items-start

  +e(desktop-layout)
    @apply tw-flex tw-w-full
    > section
      @apply tw-flex-1

    > aside
      @apply tw-flex tw-flex-col tw-justify-between tw-items-stretch
      align-content: stretch
  h1
    @apply tw-m-0 tw-p-0

  +e(avatar-wrapper)
    @apply tw-relative

  +e(change-inkind-photo)
    @apply tw-absolute
    top: .5rem
    left: .5rem

  +e(header-info)
    @apply tw-ml-8 tw-h-full tw-w-full
    @apply tw-flex tw-flex-col tw-justify-between tw-items-stretch
    align-content: stretch
    @screen md-down
      @apply tw-mx-8 tw-mt-3

  +e(info-wrapper)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start
    @screen md
      @apply tw-block
    @screen md-down
      @apply tw-flex tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(page-title)
    @apply tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-text-white

    @screen md-down
      @apply tw-text-center

  +e(page-location)
    @apply tw-font-bold tw-text-lg tw-leading-none
    @apply tw-text-white tw-opacity-90

  +e(organizers-section)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @screen md
      @apply tw-mb-2
    @screen md-down
      @apply tw-justify-center tw-mt-6 tw-mb-8

  +e(organizers-label)
    @apply tw-font-bold tw-text-base tw-leading-none
    @apply tw-text-white tw-uppercase tw-mr-2

  +e(organizer-avatar)
    @apply tw-cursor-pointer
    &:not(:first-of-type)
      @apply tw--ml-4

  +e(organizer-info)
    @apply tw-flex tw-flex-row tw-justify-between tw-items-center tw-content-center tw-max-w-full

  +e(organizer-info-data)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    & > *
      @apply tw-mr-2

  +e(organizer-page-owner-flag)
    @apply tw-font-normal tw-text-xs  tw-text-lgray-other tw-uppercase

  +e(organizer-name)
    @apply tw-font-medium tw-text-lg tw-leading-none

  +e(main privacy)
    +m(transparent-preview)
      @apply tw-opacity-25
      pointer-events: none !important
      user-select: none !important

  +e(privacy)
    @apply tw-flex


    @screen md
      @apply tw-float-right tw-absolute
      bottom: 1.5rem
      right: 0rem

    +b(inkind-page-privacy-badge)
      @apply tw-ml-3
      @screen md-down
        &:first-child
          @apply tw-ml-0

  &--authenticated &__settings
    @screen md-down
      @apply tw-flex tw-flex-row tw-justify-between

  +e(settings)
    @apply tw-flex tw-flex-row tw-justify-end tw-items-center tw-content-center tw-mx-2 tw-gap-2 tw-mb-4 tw-justify-end tw-max-w-full


    @screen md
      @apply tw-float-right tw-max-w-none


  +e(privacy)
    @screen md-down
      @apply tw-flex tw-flex-row tw-justify-center tw-items-start tw-content-start

  +e(cover-gradient cover-gradient-prev)
    &.gradient5
      @include gradient-purps()
    &.gradient4
      @include gradient-warm()
    &.gradient3
      @include gradient-sage()
    &.gradient2
      @include gradient-love()
    &.gradient1
      @include gradient-neutral()
    &.gradient0
      @include gradient-aqua()
  +e(cover-gradient-prev)
    @apply tw-opacity-0
    +m(changing)
      opacity: 1.0
      @keyframes cover-gradient-fade-out
        0%
          opacity: 1.0
        100%
          opacity: 0.0
      animation: cover-gradient-fade-out 1.0s ease-out forwards
  +e(cover-photo)
    background-position: center
    background-size: cover
  +e(cover-photo-editor)
    @apply tw-overflow-hidden tw-opacity-0
    +m(open)
      @apply tw-opacity-100
    img
      transform-origin: 0 0

  +e(cover-gradient cover-gradient-prev cover-darkening cover-photo-editor cover-photo)
    @apply tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-below

  +e(cover-darkening)
    background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.14) 56.14%, rgba(0,0,0,0.25) 100%)

  +e(inkind-thumbnail)
    @apply tw-rounded-md tw-cursor-pointer
    width: 212px
    height: 212px
    border: 5px solid #FFFFFF
