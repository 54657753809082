@use 'sass:math';
$blockName: #{$gik-prefix}flippable-card;

$animation-speed: 0.7s;
$doubleSpeed: math.div($animation-speed, 2);

.#{$blockName} {
  perspective: 600px;
  display: flex;
  flex: 1;

  &__wrapper {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    flex: 1;

    position: relative;
    transition: transform $animation-speed;
    transform-style: preserve-3d;
  }

  &__card-face {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    min-width: 0;

    width: 100%;
    position: absolute;

    height: 100%;
    backface-visibility: hidden;

    transition: backface-visibility $doubleSpeed, visibility $doubleSpeed, opacity $doubleSpeed linear;

    &--front {
      visibility: visible;
      opacity: 1;
      // flex: 1;
    }

    &--back {
      transform: rotateY(180deg);

      visibility: hidden;
      opacity: 0;
      // flex: 0;
    }
  }

  &--flipped {
    .#{$blockName}__wrapper {
      transform: rotateY(180deg);
    }

    .#{$blockName}__card-face {
      &--front {
        visibility: hidden;
        opacity: 0;
        // flex: 0;
      }

      &--back {
        visibility: visible;
        opacity: 1;
        // flex: 1;
      }
    }
  }
}
