+b(search-results-page)
  @apply tw-flex

  & > .gik-page-section
    @apply tw-flex tw-flex-1
    & > .gik-container
      @apply tw-flex tw-flex-1 tw-flex-col

  &__lead
    @apply tw-pt-8

  &__title
    @apply tw-font-bold tw-font-body tw-text-lg tw-leading-none tw-text-neutral-900 tw-mb-9

  &__show-more
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-my-8
    +b(button)
      @screen md
        width: 300px

  +b(article-tile)
    @apply tw-px-0

  +b(product-link-card)
    width: unset

+b(no-results)
  @apply tw-bg-neutral-100
  @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full
  @apply tw-flex-1
  @apply tw-rounded

  +e(icon)
    @apply tw-text-neutral-600
    @apply tw-w-8 tw-h-8 tw-mb-1

  +e(title)
    @apply tw-font-bold tw-text-lg tw-leading-none tw-text-neutral-800 tw-mx-4
    text-align: center

  +e(subtitle)
    @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700 tw-italic tw-mx-4
    max-width: 190px
    text-align: center

  +e(note)
    @apply tw-text-sm tw-leading-tight tw-text-neutral-700 tw-italic tw-mx-4
    text-align: center
    position: absolute
    bottom: 0
    @apply tw-mb-4


+b(search-results-loading-indicator)
  @apply tw-my-28
  +b(loading-linear)
    @apply tw-w-48
