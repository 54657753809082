+b(finish-step)
  min-height: 300px

  +e(signup-title)
    @apply tw-font-bold tw-text-xl tw-leading-none tw-text-center

  +e(signup-description)
    @apply tw-font-normal tw-text-base tw-leading-tight tw-text-center tw-my-3

  +e(external-login-separator)
    @apply tw-font-bold tw-text-lg tw-leading-none tw-text-center tw-mb-4 tw-mt-8

  +e(switch-address)
    @apply tw-mb-3

  +e(signup-form-wrapper)
    @apply tw-flex tw-flex-col tw-justify-center tw-items-stretch
    align-content: stretch
    @screen md
      @apply tw-bg-primary-100
    @screen md-down
      @apply tw-p-4

  +e(login-title)
    @apply tw-font-bold tw-leading-none tw-text-xl tw-text-center

  +e(external-login-email-address login-email-address)
    @apply tw-leading-none tw-text-base tw-text-center tw-mt-3

  +e(login-email-address)
    @apply tw-font-bold

  +e(login-switch-address)
    @apply tw-my-3

  +e(loading)
    @apply tw-py-32

  +e(change-email-content)
    +b(form)
      +e(actions)
        display: none

  +e(change-email-description)
    @apply tw-font-normal tw-text-base tw-leading-snug tw-mb-2

  +e(change-email-buttons)
    @apply tw-flex tw-flex-row tw-justify-center tw-items-stretch
    align-content: stretch
    &, & > *
      @apply tw-flex-1

  +m(login)
    padding-top: 38px
    @apply tw-flex tw-flex-col tw-justify-center tw-items-center tw-content-center tw-max-w-full

  +m(Google Facebook, login-switch-address)
    @apply tw-mt-5 tw-mb-8

  +m(Google Facebook, external-login-email-address)
    max-width: 280px

  +m(Google Facebook)
    .external-login-form-wrapper
      & > form
        max-width: 280px

  +b(login-form)
    min-width: 280px
    +b(form-group)
      +m(password)
        & + section
          @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full

  .external-login-form-wrapper
    @apply tw-mt-8 tw-text-center
