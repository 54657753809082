$blockName: #{$gik-prefix}saved-items;

.#{$blockName} {
  @apply tw-flex tw-flex-col tw-m-4;

  &__title {
    @apply tw-text-xl tw-font-heading tw-font-semibold tw-mb-4 tw-uppercase;
  }

  &__button {
    @apply tw-mt-3;
  }
}
