+b(table-pagination)
  @apply tw-p-2 tw-select-none tw-text-center
  @screen md
    @apply tw-text-right tw-flex tw-items-center

  &__page-buttons
    @apply tw-gap-1 tw-flex-1 tw-flex tw-justify-end tw-items-center

  +e(page-indicator)
    @apply tw-mb-2 tw-block
    @screen md
      @apply tw-mb-0

  +e(btn-dots)
    @apply tw-mr-1 tw-inline-flex tw-items-center tw-justify-center
    border-radius: 50%
    width: 16px
    height: 36px
    > span
      @apply tw-relative tw-text-lg
      top: -4px

  +e(btn-page)
    @apply tw-mr-1 tw-inline-flex tw-items-center tw-justify-center
    border-radius: 50%
    width: 36px
    height: 36px

  +e(btn-page--active)
    @apply tw-bg-primary-500 tw-text-white
