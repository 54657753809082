@use 'sass:math';
@mixin gik-skeleton-loading-animation-background-gradient($base-color, $shine-width: 600px) {
  //$shine-color: rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(
    90deg,
    rgba($base-color, 0) 0px,
    $base-color math.div($shine-width, 2),
    rgba($base-color, 0) $shine-width
  );
  background-size: $shine-width 100%;
  background-repeat: no-repeat;

  animation: gik-skeleton-loading-animation 2s infinite linear;

  @keyframes gik-skeleton-loading-animation {
    0% {
      background-position: calc(0% - #{$shine-width * 2}) 50%;
    }

    100% {
      background-position: calc(100% + #{$shine-width * 2}) 50%;
    }
  }
}
