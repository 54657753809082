$blockName: '#{$gik-prefix}tips-sample-pages-section';

.#{$blockName} {
  @apply tw-text-center tw-pb-12;

  &__title {
    @apply tw-block tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-mb-16 tw-mt-0;
  }

  .#{$gik-prefix}sample-pages-grid {
    @apply tw-mx-auto tw-mb-8;
  }
}
