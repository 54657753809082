+b(input-mentions)
  @apply tw-w-full tw-rounded

  &--padding
    @apply tw-px-3 tw-pb-2
    padding-top: 0.6rem
    textarea, input
      @apply tw-px-3 tw-pt-3 tw-pb-2

  textarea, input
    @apply tw-w-full tw-outline-none tw-rounded
    &::placeholder
      @apply tw-text-neutral-700

  &__suggestions
    @apply tw-border tw-border-neutral-500 tw-rounded

  // &__suggestions__list

  &--disabled

    .gik-input-mentions__input
      @apply tw-cursor-not-allowed tw-opacity-50

  &__suggestions__item
    @apply tw-px-2 tw-py-1

  &__suggestions__item--focused
    @apply tw-bg-primary-50

  &__highlighter__substring
    @apply tw-bg-primary-50

  &__highlighter
    strong
      @apply tw-bg-primary-100

  &--variant-plain
    // colors
    @apply tw-text-neutral-900 tw-bg-white
    // border
    @apply tw-border tw-border-neutral-500

  &--variant-neutral
    @apply tw-bg-neutral-100 tw-text-neutral-900 tw-border-0

  &__input
    @apply tw-text-neutral-900
