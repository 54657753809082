+b(tabbed-nav)
  --border-bottom-width: 3px
  --selected-line-size: 4px
  --color:theme('colors.primary.600')
  --line-color:theme('colors.primary.500')

  @apply tw-overflow-y-hidden tw-relative tw-border-b
  border-bottom: var(--border-bottom-width) solid theme('colors.neutral.300')

  &__list
    @apply tw-list-none tw-m-0 tw-flex tw-flex-row tw-select-none

  +e(item)
    @apply tw-font-heading tw-font-bold tw-tracking-normal tw-p-2 tw-px-6 tw-cursor-pointer
    @apply tw-whitespace-nowrap tw-relative tw-z-10
    top: 2px

    &--selected
      color: var(--color)
      border-bottom: var(--selected-line-size) solid var(--line-color)
      transition: color 100ms ease-in


  &.gik-tabbed-nav-wallet
    --selected-line-size: 5px
    --color:theme('colors.primary.500')

    .gik-tabbed-nav__item
      @apply tw-px-4