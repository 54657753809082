+b(billing-address-box)
  @apply tw-rounded tw-border tw-border-lgray-100 tw-p-2 tw-text-left tw-flex

  &__header
    @apply tw-p-0 tw-flex tw-border-b tw-border-neutral-300 tw-mb-2
  &__title
    @apply tw-flex-1 tw-font-bold
  &__main
    @apply tw-p-0 tw-flex-1
  &__actions
    @apply tw-flex tw-text-neutral-700 tw-items-center
    // INCONSISTENCY: custom icon size used here
    .gik-svg-icon
      height: 20px
      width: 20px
  address
    @apply tw-text-neutral-700 tw-text-sm tw-font-bold
    // INCONSISTENCY: custom line-height used here
    line-height: 14px

  &__btn-delete.gik-button--icon
    --color: theme('colors.danger.500')
