+b(group-admin-settings-section)
  $blockName: &

  @apply tw-w-full

  +e(title)
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-tight tw-text-neutral-900 tw-hidden
    @screen lg
      @apply tw-block

  +e(section-title)
    @apply tw-font-body tw-font-bold tw-text-base tw-leading-none tw-text-neutral-900 tw-mt-10
    +m(widget-switch)
      @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-4

  &__branding-section, &__widget-section
    @apply tw-border-neutral-100 tw-bg-neutral-100 tw-rounded tw-p-6 tw-px-2
    @screen md
      @apply tw-px-6

  +e(branding-section)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-pb-0
    align-content: stretch

  +e(widget-section)
    @apply tw-relative
    +m(disabled)
      #{$blockName}__disabled-overlay
        @apply tw-block tw-bg-neutral-100 tw-opacity-50

  +e(disabled-overlay)
    @apply tw-absolute tw-inset-0 tw-hidden tw-rounded tw-cursor-not-allowed
