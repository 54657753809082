.#{$gik-prefix}add-to-wishlist-popover-content {
  @apply tw-pb-3;
  &__title {
    @apply tw-text-neutral-900 tw-font-semibold tw-text-lg tw-px-4 tw-pt-2;
  }

  .#{$gik-prefix}popover-list-item {
    @apply tw-px-4;
  }

  .#{$gik-prefix}popover-list-item__icon {
    @apply tw-border tw-border-neutral-500 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-flex-grow-0 tw-flex-shrink-0;
    width: 20px;
    height: 20px;

    > svg {
      width: 14px;
      height: 14px;
    }
  }

  .#{$gik-prefix}inkind-list-item {
    max-width: calc(100% - 28px);
  }

  .gik-search-input {
    @apply tw-mx-4 tw-mb-2 tw-mt-1;
  }

  &__footer {
    @apply tw-text-center tw-py-2;
  }

  &__empty {
    @apply tw-text-center tw-mt-4 tw-text-neutral-700 tw-leading-tight tw-text-base;
  }
}

.#{$gik-prefix}add-to-wishlist-popover {
  > .#{$gik-prefix}popover__popper-content {
    // full width with min and max constraints
    @apply tw-w-full tw-max-w-xs tw-min-w-20 tw-overflow-auto;
    max-height: 21rem;
    h6 {
      @apply tw-m-0;
    }
  }
}
