$blockName: prefix(article-body)

.#{$blockName}
  @apply tw-text-neutral-800
  @extend .gik-article

  & > p
    @apply tw-font-body
    font-size: 16px
    line-height: 25px
    margin-bottom: 10px
    text-align: justify

  h1,
  h1 *,
  h2,
  h2 *,
  h3,
  h3 *,
  h4,
  h4 *,
  h5,
  h5 *,
  h6,
  h6 *
    @apply tw-font-body
    font-weight: 500

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    margin-bottom: 10px
    margin-top: 20px
    clear: both

  h1,
  h1 *
    font-size: 36px
    line-height: 39px

  h2,
  h2 *
    font-size: 30px
    line-height: 33px

  h3,
  h3 *
    font-size: 24px
    line-height: 27px

  h4,
  h4 *
    font-size: 18px
    line-height: 21px

  h5,
  h5 *
    font-size: 14px
    line-height: 17px

  h6,
  h6 *
    font-size: 12px
    line-height: 15px

  strong
    font-weight: 700

  a
    @apply tw-text-primary-600

  a:hover
    @apply tw-text-primary-700

  pre
    display: block
    padding: 9.5px
    margin: 0 0 10px
    font-size: 13px
    line-height: 1.428571429
    word-break: break-all
    word-wrap: break-word
    color: #333
    background-color: #f5f5f5
    border: 1px solid #ccc
    border-radius: 4px


  .#{$blockName}__lead,
  .#{$blockName}__lead *
    font-size: 20px
    line-height: 28px
    margin-bottom: 20px
    text-align: left
    font-style: normal !important
    font-weight: 300 !important

  blockquote
    padding: 10px 20px 10px
    margin: 0 0 20px
    font-size: 17.5px
    border-left: 5px solid theme('colors.neutral.200')
    .#{$blockName}__lead,
    .#{$blockName}__lead *
      margin-bottom: 0px


  /* Wordpress thumbnail gallery */
  .gallery.gallery-columns-4
    margin-top: 20px
    margin-bottom: 0px
    display: grid
    grid-gap: 2rem 2rem
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr))

    .gallery-item
      width: auto
      display: inline-flex
      justify-content: flex-start
      flex-direction: column
      align-items: center
      .gallery-caption
        text-align: center

  .gallery-wrapper,
  .youtube-wrapper
    margin-top: 20px

  .gallery-wrapper,
  .youtube-wrapper,
  .wp-caption-text
    margin-bottom: 40px

  .gallery-wrapper
    border-radius: 20px
    .credits
      padding: 0px 10px

  img.wp-image
    position: relative
    display: block
    border-radius: 4px
    max-width: 100%
    height: auto
    @screen md-down
      width: 100%
      margin: 10px 0px

    &.alignright
      @screen md
        float: right
        margin: 10px 0 10px 20px

    &.alignleft
      @screen md
        float: left
        margin: 10px 20px 10px 0px

    &.aligncenter
      @screen md
        margin: 10px auto

  .react-photo-gallery--gallery
    img
      border-radius: 20px
      object-fit: cover

  .blockquote
    margin: 27px 0px
    border-radius: 20px
    background-color: #f0fbfb
    padding: 30px
    &.justify-left p
      text-align: left

    &.justify-right p
      text-align: right

    &.justify-center p
      text-align: center

    .#{$blockName}__content p,
    .#{$blockName}__content p *
      @apply tw-font-heading
      font-size: 22px
      line-height: 28px

    .#{$blockName}__attribution p
      @apply tw-text-neutral-600
      @apply tw-font-body
      font-size: 16px
      line-height: 21px

  /* start on new line after a floated image*/
  .gik-product-carousel,
  .gik-buying-guide,
  .blockquote,
  .gallery-wrapper,
  .#{$blockName}__youtube-wrapper,
  .gallery.gallery-columns-4
    clear: both

  +b(product-carousel)
    max-width: 748px
