$blockName: '#{$gik-prefix}badge';

@mixin badge-variants($name, $paletteName) {
  &--#{$name} {
    --color: theme('colors.white');
    --bg: theme('colors.#{$paletteName}.500');
  }
}

.#{$blockName} {
  --bg: transparent;
  --color: #fff;

  @apply tw-text-base tw-px-4 tw-font-normal tw-font-body;
  height: 40px;
  @apply tw-inline-flex tw-items-center tw-justify-center;

  &--block {
    // @apply tw-flex;
  }

  &--font-heading {
    @apply tw-font-heading tw-font-semibold;
  }

  &--white {
    --color: theme('colors.neutral.900');
    --bg: theme('colors.white');
  }

  color: var(--color);
  background-color: var(--bg);

  @include badge-variants('default', 'neutral');
  @include badge-variants('primary', 'primary');
  @include badge-variants('secondary', 'secondary');

  // contextual variants
  @include badge-variants('danger', 'danger');
  @include badge-variants('warning', 'warn');
  @include badge-variants('success', 'success');

  &--black {
    --color: theme('colors.neutral.300');
    --bg: theme('colors.neutral.900');
  }

  &--pink {
    --bg: theme('colors.pink.700');
    --color: theme('colors.white');
  }

  &--default-dark {
    --color: theme('colors.white');
    --bg: theme('colors.neutral.600');
  }

  &--info {
    --color: theme('colors.white');
    --bg: theme('colors.info.500');
  }

  &--default-light {
    --color: theme('colors.neutral.800');
    --bg: theme('colors.neutral.100');

    &.#{$blockName}--active {
      --bg: theme('colors.neutral.300');
    }
  }

  &--clickable {
    @apply tw-cursor-pointer;
  }

  &--default-admin {
    --color: theme('colors.neutral.800');
    --bg: theme('colors.neutral.300');
  }

  // ----- Sizes

  &--size-xs {
    @apply tw-text-xs tw-px-1;
    height: 20px;
  }

  &--size-sm {
    @apply tw-text-sm tw-px-2;
    height: 20px;
  }

  &--size-lg {
    @apply tw-text-lg tw-px-4;
    height: 50px;
  }

  &--size-xl {
    @apply tw-text-xl tw-px-6;
    height: 60px;
  }

  // ----- Shapes

  &--squared {
    @apply tw-rounded-none;
    &:before {
      @apply tw-rounded-none;
    }
  }

  &--rounded {
    @apply tw-rounded;
    &:before {
      @apply tw-rounded;
    }
  }

  &--pill {
    @apply tw-rounded-full;
    &:before {
      @apply tw-rounded-full;
    }
  }

  &--circle {
    @apply tw-inline-flex tw-items-center tw-justify-center tw-p-0;
    border-radius: 50%;

    &:before {
      border-radius: 50%;
    }

    &.#{$blockName}--size-xs {
      width: 20px;
      height: 20px;

      @apply tw-text-xs;
    }
    &.#{$blockName}--size-sm {
      width: 30px;
      height: 30px;
      @apply tw-text-base;
    }
    &.#{$blockName}--size-base {
      width: 40px;
      height: 40px;
      @apply tw-text-2xl;
    }
    &.#{$blockName}--size-lg {
      width: 50px;
      height: 50px;
      @apply tw-text-3xl;
    }
    &.#{$blockName}--size-xl {
      width: 60px;
      height: 60px;
      @apply tw-text-4xl;
    }
  }
}
