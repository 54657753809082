+b(stream-input-avatar)
  @apply tw-flex tw-gap-2 tw-items-center

  +b(user-avatar)
    height: 48px
    width: 48px

  &__input-wrapper
    @apply  tw-flex tw-flex-1

  // &--clickable &__input-wrapper
  //   @apply tw-cursor-pointer
  //   > *
  //     @apply tw-pointer-events-none tw-cursor-pointer
