$blockName: prefix(color-input)

.#{$blockName}
  @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full tw-gap-2
  @apply tw-font-normal tw-text-sm tw-leading-tight tw-text-neutral-700

  +e(hex-input)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch tw-text-center
    align-content: stretch

