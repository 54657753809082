+b(inkind-page-background)
  filter: blur(0)
  transition: filter 600ms ease-out

  @apply tw-pb-16

  &, & *
    user-select: none

  +m(blurred)
    filter: blur(0.5rem)

  +e(header)
    @apply tw-py-4 tw-mb-5

  +e(header-inner)
    @apply tw-flex
    @screen md
      @apply tw-flex-row tw-justify-between tw-items-stretch
      align-content: stretch
    @screen md-down
      @apply tw-flex-col tw-justify-start tw-items-center tw-content-center tw-max-w-full

  +e(profile-picture)
    width: 220px
    border: 5px solid white
    @screen md
      margin-right: 2rem

  +e(page-title)
    @apply tw-font-heading tw-font-semibold tw-text-4xl tw-leading-none tw-py-4

  +e(page-location)
    @apply tw-font-bold tw-text-lg tw-leading-none

  +e(page-organizers)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    +b(avatar)
      @apply tw-mx-2
      border: 1px solid theme('colors.primary.600')
  +e(organizer)
    @apply tw-font-bold tw-text-base tw-leading-none

  +e(info-container)
    @apply tw-flex tw-flex-1 tw-flex-col tw-justify-between tw-items-start tw-content-start

  +e(controls)
    @apply tw-flex tw-flex-col tw-justify-between tw-items-end tw-content-end
    @screen md-down
      @apply tw-w-full

  +e(buttons)
    @screen md-down
      @apply tw-w-full tw-mx-auto
      max-width: 320px
      @apply tw-flex tw-flex-row tw-justify-between tw-items-stretch
      align-content: stretch

  +e(right-sidebar)
    //@apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    //align-content: stretch

    @apply tw-flex tw-flex-col tw-justify-start tw-items-stretch
    align-content: stretch
    flex: 0 0 275px

    +b(button)
      @apply tw-my-1

  +e(updates)
    @apply tw-flex tw-flex-col tw-justify-start tw-items-start tw-content-start
    flex: 1
    @screen md
      @apply tw-pr-4

    +b(separator)
      width: 100%

  +e(request-button)
    @apply tw-ml-1

  +e(location-wrapper)
    @screen md-down
      @apply tw-text-center

  +e(organizers-wrapper)
    @screen md-down
      @apply tw-mx-auto tw-my-8

  +e(content)
    @apply tw-flex
    @screen md
      @apply tw-flex-row tw-justify-between tw-items-stretch
      align-content: stretch
    @screen md-down
      @apply tw-flex-col-reverse tw-justify-end tw-items-stretch
      align-content: stretch
      @apply tw-px-4

  +e(section-title)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @apply tw-font-bold tw-text-base tw-leading-none
    @apply tw-mt-6 tw-mb-3
    +m(special-notes)
      @apply tw-text-danger-500

  +e(section-title-2)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @apply tw-font-heading tw-font-semibold tw-text-xl tw-leading-none
    @apply tw-mt-8 tw-mb-3

  +e(section-title-3)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-center tw-content-center tw-max-w-full
    @apply tw-font-heading tw-font-semibold tw-text-2xl tw-leading-none
    @apply tw-mt-16 tw-mb-4

  +e(care-calendar)
    @apply tw-my-2
    @apply tw-w-full

  +e(care-calendar-header)
    height: 40px
    @apply tw-bg-primary-500 tw-rounded-t-md
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-text-center tw-font-heading tw-font-semibold tw-text-lg tw-leading-none tw-uppercase tw-text-white

  +e(calendar-header-nav)
    @apply tw-bg-primary-600 tw-h-full tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full tw-px-3
    &:first-child
      @apply tw-rounded-tl-md
    &:last-child
      @apply tw-rounded-tr-md

  +e(care-calendar-body)
    @apply tw-flex-1 tw-rounded-b-md tw-bg-neutral-100
    height: 560px

  +e(update-cards)
    @apply tw-flex tw-flex-row tw-justify-start tw-items-start tw-content-start
    width: 100%

  +e(update-card)
    height: 100px
    @apply tw-flex-1 tw-rounded-md tw-mr-2 tw-bg-neutral-100
    @screen md-down
      &:last-child
        @apply tw-mr-0

  +e(wishlist-card article-card)
    flex: 0 0 100px
    @apply tw-rounded-md tw-my-2 tw-bg-neutral-100

  +e(story-block special-notes-block)
    height: 300px
    @apply tw-rounded-md tw-bg-neutral-100 tw-w-full

  +e(story-block)
    height: 300px

  +e(special-notes-block)
    height: 80px

  +e(print-month)
    @apply tw-ml-4
