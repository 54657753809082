// some needed tailwind classes are missing so this is the only way to apply them

@mixin direction($breakpoint: none)
  @if $breakpoint == none
    +m(direction-row)
       @apply tw-flex-row
    +m(direction-column)
       @apply tw-flex-col
    +m(direction-row-reverse)
       @apply tw-flex-row-reverse
    +m(direction-column-reverse)
       @apply tw-flex-col-reverse
  @else
    +m(#{$breakpoint}-direction-row)
      @screen #{$breakpoint}
        @apply tw-flex-row
    +m(#{$breakpoint}-direction-column)
      @screen #{$breakpoint}
        @apply tw-flex-col
    +m(#{$breakpoint}-direction-row-reverse)
      @screen #{$breakpoint}
        @apply tw-flex-row-reverse
    +m(#{$breakpoint}-direction-column-reverse)
      @screen #{$breakpoint}
        @apply tw-flex-col-reverse

@mixin align($breakpoint: none)
  @if $breakpoint == none
    +m(align-start)
        @apply tw-justify-start
    +m(align-center)
        @apply tw-justify-center
    +m(align-end)
        @apply tw-justify-end
    +m(align-space-around)
        @apply tw-justify-around
    +m(align-between)
        @apply tw-justify-between
    +m(align-evenly)
        @apply tw-justify-evenly
  @else
    +m(#{$breakpoint}-align-start)
      @screen #{$breakpoint}
        @apply tw-justify-start
    +m(#{$breakpoint}-align-center)
      @screen #{$breakpoint}
        @apply tw-justify-center
    +m(#{$breakpoint}-align-end)
      @screen #{$breakpoint}
        @apply tw-justify-end
    +m(#{$breakpoint}-align-space-around)
      @screen #{$breakpoint}
        @apply tw-justify-around
    +m(#{$breakpoint}-align-between)
      @screen #{$breakpoint}
        @apply tw-justify-between
    +m(#{$breakpoint}-align-evenly)
      @screen #{$breakpoint}
        @apply tw-justify-evenly

@mixin perpendicular($breakpoint: none)
  @if $breakpoint == none
    +m(perpendicular-start)
        @apply tw-items-start tw-content-start
    +m(perpendicular-center)
        @apply tw-items-center tw-content-center tw-max-w-full
    +m(perpendicular-end)
        @apply tw-items-end tw-content-end
    +m(perpendicular-stretch)
        @apply tw-items-stretch tw-content-stretch
  @else
    +m(#{$breakpoint}-perpendicular-start)
      @screen #{$breakpoint}
        @apply tw-items-start tw-content-start
    +m(#{$breakpoint}-perpendicular-center)
      @screen #{$breakpoint}
        @apply tw-items-center tw-content-center tw-max-w-full
    +m(#{$breakpoint}-perpendicular-end)
      @screen #{$breakpoint}
        @apply tw-items-end tw-content-end
    +m(#{$breakpoint}-perpendicular-stretch)
      @screen #{$breakpoint}
        @apply tw-items-stretch tw-content-stretch

+b(layout)
    @apply tw-flex
    +m(inline)
        @apply tw-inline-flex

    +direction()
    +direction(xs)
    +direction(sm)
    +direction(md)
    +direction(lg)
    +direction(xl)

    +align()
    +align(xs)
    +align(sm)
    +align(md)
    +align(lg)
    +align(xl)

    +perpendicular()
    +perpendicular(xs)
    +perpendicular(sm)
    +perpendicular(md)
    +perpendicular(lg)
    +perpendicular(xl)
