@import './FormGroup';
@import './FieldSet';

$blockName: '#{$gik-prefix}form';

.#{$blockName} {
  @apply tw-block;

  > section,
  fieldset > section {
    @apply tw-flex tw-flex-1;
    // space between horizontal groups
    > * + * {
      @apply tw-ml-2;
    }
    > * {
      // don't make fields smaller when other fields need more space
      flex-shrink: 0;
    }
  }

  // space between form groups and sections
  > * + *,
  fieldset > * + * {
    @apply tw-mt-2;
  }

  &--vertical {
    > * + *,
    fieldset > * + * {
      @apply tw-mt-2;
    }

    .gik-form-group__label {
      @apply tw-mt-2;
    }
  }

  &--white {
    .gik-form-group__label {
      @apply tw-text-white;
    }
  }

  // display as a block (full-width)
  &--block {
    @apply tw-block;
  }

  &__label {
    @apply tw-text-neutral-700 tw-font-bold tw-font-body tw-text-sm;
  }

  &__error {
    @apply tw-text-danger-900 tw-text-sm tw-whitespace-pre-wrap;
  }

  &__form-error {
    @apply tw-text-danger-900 tw-text-sm tw-text-center;
  }

  &__actions-new {
    @apply tw-flex tw-justify-between tw-flex-1;

    &--centered {
      @apply tw-justify-center;
    }

    > * + * {
      @apply tw-ml-2;
    }

    > section > * + * {
      @apply tw-ml-2;
    }
  }

  &__actions {
    // extra space between action buttons and the form
    @apply tw-mt-4 tw-text-left;
    // space between action buttons
    > * + * {
      @apply tw-ml-2;
    }
  }

  label.form-label-sm {
    @apply tw-text-xs tw-my-0;
    margin-left: 1px;
  }
}

.gik-form-header {
  @extend .gik-heading;
  @apply tw-text-3xl tw-mb-4 tw-font-semibold;

  & + .gik-form-subheader {
    margin-top: -1rem;
  }
}

.gik-form-subheader {
  @apply tw-text-base tw-text-neutral-700 tw-mt-0 tw-mb-4;
}
