+b(attachment-preview)
  @apply tw-relative

  +e(image)
    @apply tw-cursor-pointer

  +e(grid)
    @apply tw-gap-1

  +e(more-indicator)
    @apply tw-absolute tw-m-4 tw-bottom-0 tw-right-0
    @apply tw-cursor-pointer tw-z-above
    @apply tw-font-heading tw-font-semibold tw-text-base tw-leading-none

  // single image preview
  .gik-keep-proportions__wrapper
    @apply tw-cursor-pointer tw-rounded tw-inset-0 tw-w-full tw-h-full

+b(attachment-image-preview)
  @apply tw-relative tw-cursor-pointer

  +e(image)
    @apply tw-cursor-pointer tw-relative tw-z-above

  +e(loading-indicator)
    @apply tw-absolute tw-inset-0 tw-w-full tw-h-full
    @apply tw-flex tw-flex-row tw-justify-center tw-items-center tw-content-center tw-max-w-full
    @apply tw-m-0 #{!important}
