+b(inkind-page-tile-toolbar)
  @apply tw-flex tw-flex-row tw-items-center tw-justify-around
  @apply tw-text-neutral-600 tw-bg-neutral-50
  @apply tw-rounded-b-md

  > *
    @apply tw-flex-1


  border-top: 2px dotted #e2eded
  fill: theme('colors.neutral.600')

  .gik-svg-icon--share path
    stroke: theme('colors.neutral.700')

  .gik-svg-icon--premium
    @apply tw-text-warn-500

  .gik-svg-icon--close
    @apply tw-text-danger-500
