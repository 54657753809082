+b(calendar-time-and-repeat)

  +e(group1)
    @screen md
      @apply tw-flex tw-items-start
      > * + *
        @apply tw-ml-2

  +e(group2)
    @apply tw-flex tw-mb-4 tw-items-start
    > * + *
      @apply tw-ml-2

  +e(repeat-on)
    @apply tw-mr-4 tw-inline-block tw-font-semibold
    @screen md
       @apply tw-block

  +e(date-time-section)
    @apply tw-flex tw-flex-1 tw-items-start tw-mt-4
    > *
      @apply tw-flex-1
    > * + *
      @apply tw-border-l tw-border-neutral-300 tw-pl-3 tw-ml-3
    @screen md
      @apply tw-items-end tw-mt-0


  +b(form-group--startDate)
    @apply tw-block
    @screen md
      max-width: 140px

  +b(form-group--endDate)
    @apply tw-block
    @screen md
      max-width: 140px

  +e(time-picker)
    @apply tw-flex tw-items-end
    > * + *
      @apply tw-ml-2

  +b(form-group--allDay)
    @apply tw-mt-3
    @screen md
      @apply tw-mb-2 tw-mt-0

  +b(form-group--repeat)
    @apply tw-inline-block tw-mr-2
    width: 160px
    .gik-select__single-value
      @apply tw-pb-0
    @screen md
      @apply tw-mr-0

  +e(repeat-section)

    @screen md
      @apply tw-inline-block

  +b(form-group--weekdays)
    // height: 32px
    // @apply tw-mt-3 tw-block
    @apply tw-mt-4

    @screen md
      @apply tw-mt-0

  +b(weekday-selector)
    @screen md
      margin-top: 35px
