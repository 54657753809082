+b(page-row-item)
  @apply tw-px-3 tw-py-2

  +e(container)
    @apply tw-flex tw-flex-row
    align-items: center

  +e(avatar)
    flex: 0 0 40px
    width: 40px
    height: 40px

  +e(title)
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    @apply tw-pl-4
    @apply tw-font-heading tw-font-semibold tw-text-lg tw-text-neutral-900 tw-flex-1

  +m(hover)
    @apply tw-bg-neutral-200

  +m(organizer)
    .gik-avatar
      @apply tw-border-2 tw-border-secondary-500


