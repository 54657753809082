.#{$gik-prefix}app-header-logo
  @apply tw-pb-4 tw-pt-3 tw-text-center
  svg
    @apply tw-mx-auto
    height: 46px
    overflow: visible

  +breakpoint(sm-down)
    @apply tw-pb-0 tw-pt-2
    svg
      height: 36px
      transform: scale(0.85)

  +breakpoint(md-down)
    @apply tw-mx-auto

  +breakpoint(md)
    @apply tw-ml-6

  +breakpoint(lg)
    @apply tw-mr-4 tw-ml-0
