+b(comment-input-avatar)
  @apply tw-flex tw-gap-2 tw-mb-2

  &__input-message
    @apply tw-text-xs tw-text-neutral-700 tw--mt-2 tw-ml-1

  &__btn_submit
    svg
      transform: rotate(90deg)
      width: 26px !important
      height: 26px !important

  &__input-wrapper
    @apply tw-flex-1

  &--clickable &__input-wrapper
    @apply tw-cursor-pointer
    > *
      @apply tw-pointer-events-none tw-cursor-pointer

  .gik-input-mentions--padding
    padding-top: 0.4rem
    padding-bottom: 0.5rem

  .gik-comment-input
    min-height: 40px

    textarea
      padding-top: 0.4rem
      padding-bottom: 0.5rem
