+b(inkind-group-empty-view)
  @apply tw-bg-neutral-50 tw-rounded-md
  @apply tw-flex tw-items-center tw-justify-center

  min-height: 200px

  @screen md
    min-height: 400px

  &__title
    @apply tw-font-bold tw-mb-1 tw-text-xl

  &__member-description
    @apply tw-mt-4

  &__content-wrapper
    @apply tw-text-center

  &__link
    @apply tw-font-bold

  &__content
    @apply tw-text-neutral-700

  &__member
    @apply tw-text-primary-500 tw-font-bold
