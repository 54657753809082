$blockName: '#{$gik-prefix}competitors-preview';

.#{$blockName} {
  &__title {
    @apply tw-font-heading tw-font-semibold tw-text-5xl tw-mb-6 tw-block tw-whitespace-pre-wrap;
    line-height: 0.9;
  }
  &__description {
    @apply tw-font-normal tw-text-sm tw-leading-normal tw-whitespace-pre-wrap;
  }
  &__content {
    @apply tw-flex;
    > main {
      @apply tw-flex-1;
    }
  }
  &__table {
    @apply tw-select-none;
    //zoom: 0.7;
  }
  &__icon {
    @apply tw-flex-shrink-0 tw-items-end tw-ml-4 tw-cursor-pointer;

    width: 44px;
    height: 44px;
  }
}
